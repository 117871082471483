import React, { Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import { 
	Label, 	
	CardRow,
	CardColumn,
	CardImage,
	Button,
	PdfViewerModal
} from '../';
import cardStyles from './cardSample.module.scss';
import { useTranslation } from 'react-i18next';

const CardSample = props => {

	const { t } = useTranslation();	
	const [open, setOpen] = useState(false);

	const handleClosePdf = () => {
		setOpen('');
	  };
	
	  const handlePDF = () => {
		setOpen(!open);
	  };
	  
	return (
		<Fragment>		
			<CardColumn 
				className={cardStyles.cursorPointer}
				style={{
					backgroundColor: '#F49C00', 
					margin: 5, 
					borderRadius: "5px",
					width: "190px", 
					maxWidth: "190px", 
					flexBasis: "190px", 
					flexGrow: 1, 
					padding: "5px",					
				}}
				onClick={() => props.handleClick()}
			> 
				<CardRow transparent style={cardStyles.imageWrapper}>
					{props.sample?.str_img_brand?.split(".").pop() === 'pdf' ?
						<PdfViewerModal
							label={`${t("common_brand")} PDF`}
							data={props.sample.str_img_brand}
							onClick={() => handlePDF()}
							closeForm={handleClosePdf}
							open={open}
							loading={false}
							downloadFileName={props.sample.str_img_brand}
							name={props.sample.str_img_brand}
						/>
					  :
					  	<CardImage 
							disableDownload
							styleFather={{ width: 'auto', height: "150px",padding:"0px",margin: "5px", borderRadius: "5px"}} 
							styleImg={{ width: "100%", height: "100%", backgroundSize: 'cover', maxHeight: "100%",borderRadius: "5px"}} 
							margin="0px"
							padding={0}
							src={props.sample.str_img_brand}
						/>
					}				 
				</CardRow>
				<CardRow transparent justifyContent="center" padding="5px">					
					<Label bold style={{color: "#FFF"}} className={cardStyles.textNumber} label={`${props.sample.int_order_app+1} - ${props.sample.str_name}`}/>					
				</CardRow>
				<CardRow transparent  justifyContent="center" padding="5px">
					<Button 
						style={{backgroundColor: "#FFF" , color: "#000", fontWeight: "bold"}} 
						label={t('common_taste')}  
						zIndex={0} 
						onClick={() => props.handleClick()}
					/>			
				</CardRow>
			</CardColumn>	
		</Fragment>
	)
}

CardSample.propTypes = {
	sample: PropTypes.object,
	handleClick: PropTypes.func
}

export default CardSample;