import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import styleKpiBrands from './kpiBrands.module.scss';
import {
  Card,
  CardColumn,
  CardRow,
  DropdownField,
  Label,
  TextField,
  Button,
} from '../../../components';
import BrandService from '../../services/brands/brand.service';

import { useUser } from '../../../contexts/User';

const EditNewKpiBrands = props => {
  const brandService = new BrandService();
  const { user } = useUser();

  const { t } = useTranslation();
  const history = useHistory();
  const [kpiBrands, setKpiBrands] = useState({
    uuid_kpi_brand: props.location.state.values.uuid_kpi_brand,
    uuid_zone: user.uuid_zone,
    uuid_country: user.uuid_country,
    uuid_site: user.uuid_site,
  });

  const [globalBrands, setGlobalBrands] = useState([]);
  const [focusBrands, setFocusBrands] = useState([]);
  const [strategicBrands, setStrategicBrands] = useState([]);
  const [brandsGlobal, setBrandsGlobal] = useState([]);
  const [brands, setBrands] = useState([]);
  const [softDrinksBrands, setSoftDrinksBrands] = useState([]);
  const [softDrinksKpiValue, setSoftDrinksKpiValue] = useState([
    {},
    {},
    {},
    {},
  ]);
  const [edit, setEdit] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [kYear, setKYear] = useState([]);

  useEffect(() => {
    const yearNow = new Date().getFullYear();
    const timeInterval = yearNow - 2021;
    const year = [{ str_name: '2021', kpi_year: '2021' }];
    for (let i = 1; i <= timeInterval + 1; i++) {
      year.push({
        str_name: (2021 + i).toString(),
        kpi_year: (2021 + i).toString(),
      });
    }
    setKYear(year);

    if (props.location.state.edit === true) {
      refreshState();
    } else {
      setGlobalBrands([{}, {}, {}, {}]);
      setFocusBrands([{}, {}]);
      setStrategicBrands([{}, {}]);
      setSoftDrinksKpiValue([{}, {}, {}, {}]);
    }
    setEdit(props.location.state.edit);

    brandService.listBrands().then(
      res => {
        if (res.type === 'success' && res.results) {
          const arrZonesGlobal = [];
          const arrZones = [];
          const arrSoftDrinks = [];

          res.results.forEach(b => {
            if (b.str_brand_family_name === 'Soft Drinks') {
              arrSoftDrinks.push(b);
              return;
            }

            b.arr_zones?.forEach(z => {
              if (z.str_name === 'GLOBAL') {
                arrZonesGlobal.push(b);
              } else {
                arrZones.push(b);
              }
            });
          });

          setBrandsGlobal(arrZonesGlobal);
          setBrands(arrZones);
          setSoftDrinksBrands(arrSoftDrinks);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  }, []);

  const isInt = n => {
    return Number(n) === n && n % 1 === 0;
  };

  const isFloat = n => {
    return Number(n) === n && n % 1 !== 0;
  };

  const delayedQuery = (evt, index, type) =>
    handleChangeBrandInfo(evt, index, type);

  const refreshState = () => {
    const arrGlobalBrands = [{}, {}, {}, {}];
    const arrFocusBrands = [{}, {}];
    const arrStrategicBrands = [{}, {}];
    const arrSoftDrinks = [{}, {}, {}, {}];

    props.location.state.values.arr_kpi_brands.map(kpi => {
      if (kpi.str_type === '1') {
        arrGlobalBrands[kpi.int_order] = validateRemovedBrands(kpi);
      } else if (kpi.str_type === '2') {
        arrFocusBrands[kpi.int_order] = validateRemovedBrands(kpi);
      } else if (kpi.str_type === '3') {
        arrStrategicBrands[kpi.int_order] = validateRemovedBrands(kpi);
      } else if (kpi.str_type === '4') {
        arrSoftDrinks[kpi.int_order] = validateRemovedBrands(kpi);
      }
    });

    setGlobalBrands(
      arrGlobalBrands.sort((a, b) => a?.int_order - b?.int_order),
    );

    setFocusBrands(arrFocusBrands.sort((a, b) => a?.int_order - b?.int_order));

    setStrategicBrands(
      arrStrategicBrands.sort((a, b) => a?.int_order - b?.int_order),
    );

    setSoftDrinksKpiValue(
      arrSoftDrinks.sort((a, b) => a?.int_order - b?.int_order),
    );

    setKpiBrands(props.location.state.values);
  };

  const validateRemovedBrands = kpi => {
    if (kpi.bol_removed) {
      kpi.uuid_brand = null;
      kpi.flt_target = '';

      return kpi;
    }

    return kpi;
  };

  const handleChange = evt => {
    if (evt.target !== undefined) {
      const value = evt.target.value;
      if (evt.target.name === 'uuid_site') {
        const siteAux = props.location.state.privilege.arr_sites.filter(
          site => {
            return site.uuid_site === value;
          },
        )[0];
        const countryAux = props.location.state.privilege.arr_countries.filter(
          country => {
            return country.uuid_country === siteAux.uuid_country;
          },
        )[0];
        const zoneAux = props.location.state.privilege.arr_zones.filter(
          zone => {
            return zone.uuid_zone === countryAux.uuid_zone;
          },
        )[0];

        setKpiBrands(prevState => ({
          ...prevState,
          [evt.target.name]: value,
          uuid_country: countryAux.uuid_country,
          uuid_zone: zoneAux.uuid_zone,
        }));
      } else {
        setKpiBrands(prevState => ({
          ...prevState,
          [evt.target.name]: value,
        }));
      }
    }
  };

  const handleChangeBrandInfo = (evt, index, type) => {
    if (evt.target === undefined) {
      return;
    }

    let arr = [];

    let { value, name } = evt.target;

    if (name === 'flt_target') {
      value = value.substring(0, 5);

      if (!(isFloat(Number(value)) || isInt(Number(value)))) {
        alert(t('kpi_only_numbers_warning'));
        value = 1;
      }

      if (value && (Number(value) < 1 || Number(value) > 9)) {
        alert(t('kpi_max_number_range_warning'));
        value = 1;
      }
    }

    if (type === 'global') {
      const arrBrands = globalBrands;
      let newArr = [];

      if (name === 'uuid_brand') {
        const isSelected = arrBrands.find(
          brand => brand?.uuid_brand === value && value != undefined,
        );

        if (isSelected !== undefined) {
          alert(t('kpi_already_selected_brand_warning'));
          return false;
        }
      }

      if (
        index !== '' &&
        index !== undefined &&
        value !== '' &&
        value !== null
      ) {
        if (!!arrBrands[index]?.uuid_brand) {
          arrBrands[index][name] = value;
        } else {
          arrBrands[index] = {
            uuid_kpi_brand_information: null,
            uuid_brand: '',
            flt_target: '',
            str_type: '1',
            int_order: index,
          };

          arrBrands[index][name] = value;
        }

        arrBrands.forEach(brands => {
          newArr.push(brands);
        });
      } else {
        arrBrands[index][name] = value;
        if (name == 'uuid_brand') {
          arrBrands[index]['flt_target'] = '';
        }

        newArr = arrBrands;
      }

      arr = [...newArr, ...focusBrands, ...strategicBrands];

      setGlobalBrands(newArr);
    }

    if (type === 'focus') {
      const arrBrands = focusBrands;
      let newArr = [];

      if (name === 'uuid_brand') {
        const isSelected = arrBrands.find(
          brand => brand?.uuid_brand === value && value != undefined,
        );

        if (isSelected !== undefined) {
          alert(t('kpi_already_selected_brand_warning'));
          return false;
        }
      }

      if (
        index !== '' &&
        index !== undefined &&
        value !== '' &&
        value !== null
      ) {
        if (!!arrBrands[index]?.uuid_brand) {
          arrBrands[index][name] = value;
        } else {
          arrBrands[index] = {
            uuid_kpi_brand_information: null,
            uuid_brand: '',
            flt_target: '',
            str_type: '2',
            int_order: index,
          };

          arrBrands[index][name] = value;
        }

        arrBrands.forEach(brands => {
          newArr.push(brands);
        });
      } else {
        arrBrands[index][name] = value;
        if (name == 'uuid_brand') {
          arrBrands[index]['flt_target'] = '';
        }

        newArr = arrBrands;
      }

      arr = [...newArr, ...globalBrands, ...strategicBrands];

      setFocusBrands(newArr);
    }

    if (type === 'strategic') {
      const arrBrands = strategicBrands;
      let newArr = [];

      if (name === 'uuid_brand') {
        const isSelected = arrBrands.find(
          brand => brand?.uuid_brand === value && value != undefined,
        );

        if (isSelected !== undefined) {
          alert(t('kpi_already_selected_brand_warning'));
          return false;
        }
      }

      if (
        index !== '' &&
        index !== undefined &&
        value !== '' &&
        value !== null
      ) {
        if (!!arrBrands[index]?.uuid_brand) {
          arrBrands[index][name] = value;
        } else {
          arrBrands[index] = {
            uuid_kpi_brand_information: null,
            uuid_brand: '',
            flt_target: '',
            str_type: '3',
            int_order: index,
          };

          arrBrands[index][name] = value;
        }

        arrBrands.forEach(brands => {
          newArr.push(brands);
        });
      } else {
        arrBrands[index][name] = value;
        if (name == 'uuid_brand') {
          arrBrands[index]['flt_target'] = '';
        }

        newArr = arrBrands;
      }

      arr = [...newArr, ...focusBrands, ...globalBrands];

      setStrategicBrands(newArr);
    }

    setKpiBrands(prevState => ({
      ...prevState,
      arr_kpi_brands: arr,
    }));
  };

  const handleChangeSoftDrinkKpi = (value, index) => {
    const isBrandAlreadySelected = !!softDrinksKpiValue.find(
      sd => sd.uuid_brand === value,
    );

    if (isBrandAlreadySelected) {
      alert(t('kpi_already_selected_brand_warning'));
      return false;
    }

    setSoftDrinksKpiValue(prevState => {
      const newState = [...prevState];
      newState[index] = {
        ...prevState[index],
        uuid_brand: value,
      };

      return newState;
    });
  };

  const handleChangeSoftDrinkKpiTarget = (value, index) => {
    let newTargetValue = value;

    if (isFloat(Number(newTargetValue)) && newTargetValue.length > 5) {
      alert(t('kpi_max_decimals_warning'));
      return;
    } else {
      newTargetValue = newTargetValue.substring(0, 5);

      if (isNaN(newTargetValue)) {
        alert(t('kpi_only_numbers_warning'));
        newTargetValue = 1;
      }

      if (
        newTargetValue &&
        (Number(newTargetValue) < 1 || Number(newTargetValue) > 9)
      ) {
        alert(t('kpi_max_number_range_warning'));
        newTargetValue = 1;
      }
    }

    setSoftDrinksKpiValue(prevState => {
      const newState = [...prevState];
      newState[index] = {
        ...prevState[index],
        flt_target: newTargetValue,
      };

      return newState;
    });
  };

  const handleFieldsValidation = () => {
    let error = false;

    if (
      !kpiBrands?.arr_kpi_brands?.find(kpi => !!kpi) &&
      !softDrinksKpiValue.some(kpi => kpi.uuid_brand)
    ) {
      alert(t('kpi_no_kpi_informed_warning'));
      return false;
    }

    if (kpiBrands.kpi_year === '' || kpiBrands.kpi_year === undefined) {
      alert(t('kpi_select_year_warning'));
      return false;
    }

    if (kpiBrands.uuid_site === '' || kpiBrands.uuid_site === undefined) {
      alert(t('kpi_select_site_warning'));
      return false;
    }

    const listFind = props.location.state.listKpiBrands.some(
      list =>
        list.uuid_site === kpiBrands.uuid_site &&
        list.kpi_year === kpiBrands.kpi_year,
    );

    if (listFind === true && props.location.state.from === 'New') {
      alert(t('kpi_existing_configuration_warning'));
      return false;
    }

    let inputs = document.querySelectorAll('input');

    for (let i = 0; i < inputs.length; i++) {
      if (!inputs[i].disabled && !_.trim(inputs[i].value)) {
        alert(inputs[i].dataset.label + ' ' + t('the_value_cannot_be_null'));
        error = true;
      }
    }

    if (error) {
      return false;
    }

    return true;
  };

  const handleSave = () => {
    if (!handleFieldsValidation()) {
      return;
    }

    const params = kpiBrands;
    const softDrinksValues = softDrinksKpiValue.map((sd, i) => ({
      ...sd,
      int_order: String(i),
      str_type: '4',
    }));

    if (!params.arr_kpi_brands) {
      params.arr_kpi_brands = [];
    }

    params.arr_kpi_brands = [...params.arr_kpi_brands, ...softDrinksValues];

    params.arr_kpi_brands = params.arr_kpi_brands.filter(
      kpi =>
        !_.isEmpty(kpi) && (!!kpi.uuid_brand || kpi.uuid_kpi_brand_information),
    );
    params.uuid_status = 'dcb5e440-94a7-11ec-ad45-000000000000';

    brandService.updateKpiBrandInformation(params).then(
      res => {
        if (res.type === 'success' && res.results) {
          kpiBrands.uuid_kpi_brand =
            res.results[0].brand_in_up_kpi_brand_information;
          kpiBrands.arr_kpi_brands = res.results[0].arr_kpi_brands
          setKpiBrands({ ...kpiBrands });
          window.alert(t('kpi_saved_successfully'));
          setSubmitDisabled(true);
          setEdit(true);
        } else {
          window.alert(res.message);
        }
      },
      () => { },
    );
  };

  const handleSubmit = () => {
    if (!handleFieldsValidation()) {
      return;
    }
    const params = kpiBrands;
    const softDrinksValues = softDrinksKpiValue.map((sd, i) => ({
      ...sd,
      int_order: String(i),
      str_type: '4',
    }));

    params.arr_kpi_brands = [...kpiBrands.arr_kpi_brands, ...softDrinksValues];

    params.arr_kpi_brands = params.arr_kpi_brands.filter(
      kpi =>
        !_.isEmpty(kpi) && (!!kpi.uuid_brand || kpi.uuid_kpi_brand_information),
    );
    params.uuid_status = 'dcb5e441-94a7-11ec-ad45-000000000000';

    brandService.updateKpiBrandInformation(params).then(
      res => {
        if (res.type === 'success' && res.results) {
          window.alert(t('kpi_submitted_successfully'));
          history.push({ pathname: '/list_kpi_brands' });
        } else {
          window.alert(res.message);
        }
      },
      () => { },
    );
  };

  return (
    <>
      <div>
        <Label
          label={`${t('common_kpi_brands')} ${props.location.state.from}`}
          bold
          neutral
        />
        <Card bordered>
          <div>
            <CardRow flexWrap="wrap" padding="0px 0px 10px 0px">
              <CardColumn padding="5px" flex={1}>
                <CardRow flexWrap="wrap">
                  <DropdownField
                    name="kpi_year"
                    options={kYear}
                    valueField="kpi_year"
                    labelField="str_name"
                    label={`${t('common_year')}`}
                    margin="10px 10px 0px 0px"
                    value={kpiBrands.kpi_year}
                    onChange={handleChange}
                    disabled={edit}
                  />
                  <DropdownField
                    name="uuid_site"
                    options={props.location.state.privilege.arr_sites}
                    valueField="uuid_site"
                    labelField="str_name"
                    label={`${t('common_site')}`}
                    margin="10px 10px 0px 0px"
                    value={kpiBrands.uuid_site}
                    onChange={handleChange}
                    disabled={edit}
                  />
                </CardRow>
              </CardColumn>
            </CardRow>
          </div>
        </Card>

        <Card bordered>
          <div>
            <CardRow flexWrap="wrap">
              <Label label={`Global Brands`} bold neutral size={18} />
            </CardRow>

            <CardRow flexWrap="wrap" padding="0px 0px 10px 0px">
              <CardColumn padding="5px" flex={1}>
                <CardRow flexWrap="wrap">
                  {globalBrands.map((global, index) => (
                    <DropdownField
                      key={`drop-global${index}`}
                      name={`uuid_brand`}
                      options={brandsGlobal}
                      valueField="uuid_brand"
                      labelField="str_name"
                      label={`Global Brand ${index + 1}`}
                      margin="10px 10px 0px 0px"
                      value={global?.uuid_brand}
                      onChange={evt =>
                        handleChangeBrandInfo(evt, index, 'global')
                      }
                    />
                  ))}
                </CardRow>
                <CardRow flexWrap="wrap">
                  {globalBrands.map((global, index) => (
                    <TextField
                      key={`text-global${index}`}
                      name={`flt_target`}
                      label={`Target Global Brand ${index + 1}`}
                      margin="10px 10px 0px 0px"
                      value={global?.flt_target}
                      onChange={evt => {
                        if (
                          isFloat(Number(evt.target.value)) &&
                          evt.target.value.length > 5
                        ) {
                          alert(t('kpi_max_decimals_warning'));
                        } else {
                          delayedQuery(
                            {
                              target: {
                                value: evt.target.value.substring(0, 5),
                                name: evt.target.name,
                              },
                            },
                            index,
                            'global',
                          );
                        }
                      }}
                      disabled={
                        !!global &&
                        (global?.uuid_brand === '' ||
                          global.uuid_brand == undefined)
                      }
                    />
                  ))}
                </CardRow>
              </CardColumn>
            </CardRow>
          </div>
        </Card>

        <Card bordered>
          <div>
            <CardRow flexWrap="wrap">
              <Label label={`Focus Brands`} bold neutral size={18} />
            </CardRow>
            <CardRow flexWrap="wrap" padding="0px 0px 10px 0px">
              <CardColumn padding="5px" flex={1}>
                <CardRow flexWrap="wrap">
                  {focusBrands.map((focus, index) => (
                    <DropdownField
                      key={`drop-focus${index}`}
                      name={`uuid_brand`}
                      options={brands}
                      valueField="uuid_brand"
                      labelField="str_name"
                      label={`Focus Brand ${index + 1}`}
                      margin="10px 10px 0px 0px"
                      value={focus?.uuid_brand}
                      onChange={evt =>
                        handleChangeBrandInfo(evt, index, 'focus')
                      }
                    />
                  ))}
                </CardRow>
                <CardRow flexWrap="wrap">
                  {focusBrands.map((focus, index) => (
                    <TextField
                      key={`text-focus${index}`}
                      name={`flt_target`}
                      label={`Target Focus Brand ${index + 1}`}
                      margin="10px 10px 0px 0px"
                      value={focus?.flt_target}
                      onChange={evt => {
                        if (
                          isFloat(Number(evt.target.value)) &&
                          evt.target.value.length > 5
                        ) {
                          alert(t('kpi_max_decimals_warning'));
                        } else {
                          delayedQuery(
                            {
                              target: {
                                value: evt.target.value,
                                name: evt.target.name,
                              },
                            },
                            index,
                            'focus',
                          );
                        }
                      }}
                      disabled={
                        !!focus &&
                        (focus?.uuid_brand === '' ||
                          focus.uuid_brand == undefined)
                      }
                    />
                  ))}
                </CardRow>
              </CardColumn>
            </CardRow>
          </div>
        </Card>

        <Card bordered>
          <div>
            <CardRow flexWrap="wrap">
              <Label label={`Strategic Brands`} bold neutral size={18} />
            </CardRow>
            <CardRow flexWrap="wrap" padding="0px 0px 10px 0px">
              <CardColumn padding="5px" flex={1}>
                <CardRow flexWrap="wrap">
                  {strategicBrands.map((strategic, index) => (
                    <DropdownField
                      key={`drop-strategic${index}`}
                      name={`uuid_brand`}
                      options={brands}
                      valueField="uuid_brand"
                      labelField="str_name"
                      label={`Strategic Brand ${index + 1}`}
                      margin="10px 10px 0px 0px"
                      value={strategic?.uuid_brand}
                      onChange={evt =>
                        handleChangeBrandInfo(evt, index, 'strategic')
                      }
                    />
                  ))}
                </CardRow>
                <CardRow flexWrap="wrap">
                  {strategicBrands.map((strategic, index) => (
                    <TextField
                      key={`text-strategic${index}`}
                      name={`flt_target`}
                      label={`Target Strategic Brand ${index + 1}`}
                      margin="10px 10px 0px 0px"
                      value={strategic?.flt_target}
                      onChange={evt => {
                        if (
                          isFloat(Number(evt.target.value)) &&
                          evt.target.value.length > 5
                        ) {
                          alert(t('kpi_max_decimals_warning'));
                        } else {
                          delayedQuery(
                            {
                              target: {
                                value: evt.target.value,
                                name: evt.target.name,
                              },
                            },
                            index,
                            'strategic',
                          );
                        }
                      }}
                      disabled={
                        !!strategic &&
                        (strategic?.uuid_brand === '' ||
                          strategic.uuid_brand == undefined)
                      }
                    />
                  ))}
                </CardRow>
              </CardColumn>
            </CardRow>
          </div>
        </Card>

        <Card bordered>
          <div>
            <CardRow flexWrap="wrap">
              <Label label={t('soft_drinks')} bold neutral size={18} />
            </CardRow>
            <CardRow flexWrap="wrap" padding="0px 0px 10px 0px">
              <CardColumn padding="5px" flex={1}>
                <CardRow flexWrap="wrap">
                  {softDrinksKpiValue.map((softDrinkKpi, i) => (
                    <DropdownField
                      key={`soft-drink-${i}`}
                      name={`uuid_brand`}
                      options={softDrinksBrands}
                      valueField="uuid_brand"
                      labelField="str_name"
                      label={`${t('soft_drinks_brand')} ${i + 1}`}
                      margin="10px 10px 0px 0px"
                      value={softDrinkKpi?.uuid_brand}
                      onChange={evt =>
                        handleChangeSoftDrinkKpi(evt.target.value, i)
                      }
                    />
                  ))}
                </CardRow>
                <CardRow flexWrap="wrap">
                  {softDrinksKpiValue.map((softDrinkKpi, i) => (
                    <div className={styleKpiBrands.fields}>
                      <TextField
                        key={`text-strategic${i}`}
                        name={`flt_target`}
                        label={`${t('soft_drinks_brand_target')} ${i + 1}`}
                        value={softDrinkKpi?.flt_target}
                        onChange={e =>
                          handleChangeSoftDrinkKpiTarget(e.target.value, i)
                        }
                        disabled={
                          !!softDrinkKpi &&
                          (softDrinkKpi?.uuid_brand === '' ||
                            softDrinkKpi.uuid_brand == undefined)
                        }
                      />
                    </div>
                  ))}
                </CardRow>
              </CardColumn>
            </CardRow>
          </div>
        </Card>
      </div>
      <Card bordered>
        <CardRow justifyContent="center" flexWrap="wrap">
          <Button
            label={t('common_cancel')}
            onClick={() => {
              history.goBack();
            }}
            neutral
            margin="35px 10px 0px 0px"
          />
          <Button
            label={t('common_save')}
            onClick={handleSave}
            confirm
            margin="35px 10px 0px 0px"
          />
          <Button
            label={t('common_submit')}
            onClick={handleSubmit}
            greenButton
            margin="35px 10px 0px 0px"
            disabled={!submitDisabled && !props.location.state.edit}
          />
        </CardRow>
      </Card>
    </>
  );
};

export default EditNewKpiBrands;
