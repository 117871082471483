import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import listcategoriesStyle from './listcategories.module.scss';
import {
  GenericTable,
  Label
} from '../../../components';
import AppService from '../../../app.service';
import { useTranslation } from 'react-i18next';
import SampleService from "../../services/samples/sample.service";

const ListCategories = props => {
  
  const { t } = useTranslation();
  const history = useHistory();
  const [results, setResults] = useState([]);
  const [privilege, setPrivilege] = useState([]);
  const [loading, setLoading] = useState(false);

	// services
  const appService = new AppService();
  const sampleService = new SampleService();

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);
    //GET PRIVILEGES
    appService.listPrivileges('SAMPLE_MANAGEMENT_LIST').then((res) => {
      if(res.type === 'success' && res.results){
        setPrivilege(res.results[0]);
      }else{
        window.alert(res.message);
      }
    }, (error) => {
      //console.log(error);
    });
    //GET SAMPLES
    sampleService.listAdminCategories().then((res) => {
      if(res.type === 'success' && res.results){
        setResults(res.results.map(data => {
          data.str_name = data[`str_name_${appService.user.str_language}`];
          return data;
        }));
        setLoading(false);
      }else{
        window.alert(res.message);
        setLoading(false);
      }
    }, (error) => {
      //console.log(error);
      setLoading(false);
    });
  };

  const handleNewCategoriy = useCallback(
    () => {
      history.push({pathname: '/edit_category_ok_nok', state: {values: {}, privilege: privilege}});
    }, [privilege]
  );

  const handleEditCategoriy = useCallback(
    (values) => () => {
      history.push({pathname: '/edit_category_ok_nok', state: {values: values, privilege: privilege}});
    }, [privilege]
  );

  const handleRemoveCategoriy = useCallback(
    (values) => () => {
      if(window.confirm(`${t('categories_remove_category')}: ${values.str_name}`)){
        sampleService.deleteAdminCategories(values.uuid_category).then((res) => {
          if(res.type === 'success' && res.results){
            refreshState();
          }else{
            window.alert(res.message);
          }
        }, (error) => {
          //console.log(error);
        });

      }
    }, []
  );

  const headers = [
    { description: t('common_category_name'), field: 'str_name'},
  ];

  return (
    <div className={listcategoriesStyle.pageContainer}>
      <Label 
        label={t('categories_list_categories')}
        bold
        neutral
      />

      <GenericTable 
        arrHeader={headers}
        arrRow={results}
        searchable={true}
        onEdit={privilege.bol_access ? handleEditCategoriy : null}
        onRemove={privilege.bol_access ? handleRemoveCategoriy : null}
        newRegister={privilege.bol_access ? {
          onClick: handleNewCategoriy,
          label: t('categories_new_category')
        } : null}
        loading={loading}
      />
    </div>
  );
};

export default ListCategories;
