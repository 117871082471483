import MaltVarieties from './MaltVarieties';

export default {
    routeModule: {
        path: '/list_malt_variety',
        component: MaltVarieties,
        key: 'sample_malt_varieties',
        privilege: 'MALT_MANAGEMENT_LIST',
        routeComponents: [],
        subMenu: 'menu_product_management'
    }
};

