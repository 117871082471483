import React, { createContext, useContext } from 'react';
import { useState } from 'react';
import _ from 'lodash';

const UserContext = createContext({});

const handleGetStorage = () => {
  let user = JSON.parse(sessionStorage.getItem('user'));
  return user ? user : {};
};

const handleGetLogged = () => {
  const isLogged = !_.isEmpty(handleGetStorage());
  return isLogged;
};

const handleSaveStorage = user => {
  sessionStorage.setItem('user', JSON.stringify(user));
};

const handleRemoveStorage = user => {
  sessionStorage.setItem('user', '');
  sessionStorage.removeItem('user');
  window.location.reload(false);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(handleGetStorage());
  const [isLogged, setIsLogged] = useState(handleGetLogged());

  const handleUser = change => {
    handleSaveStorage(change);
    setUser(change);
  };

  const handleLogged = () => {
    setIsLogged(!isLogged);
  };

  const removeUser = () => {
    setUser(null);
    setIsLogged(false);
    handleRemoveStorage();
  };

  return (
    <UserContext.Provider
      value={{
        user: user,
        isLogged,
        handleUser: handleUser,
        removeUser: removeUser,
        handleLogged,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const { user, handleUser, removeUser, isLogged, handleLogged } =
    useContext(UserContext);
  return { user, handleUser, removeUser, isLogged, handleLogged };
};

export default UserContext;
