import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid';
import listBrandsStyle from './listbrands.module.scss';
import {
  GenericTable,
  Label
} from '../../../components';
import AppService from '../../../app.service';
import BrandService from '../../services/brands/brand.service';
import { useTranslation } from 'react-i18next';

const ListBrands = props => {
  
  const { t } = useTranslation();
  const history = useHistory();
  const [brands, setBrands] = useState([]);
  const [privilege, setPrivilege] = useState([]);
  const [loading, setLoading] = useState(false);

	// services
  const appService = new AppService();
  const brandService = new BrandService();

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);
    //GET PRIVILEGES
    appService.listPrivileges('BRAND_MANAGEMENT_EDIT').then((res) => {
      if(res.type === 'success' && res.results){
        setPrivilege(res.results[0]);
      }else{
        window.alert(res.message);
      }
    }, (error) => {
      //console.log(error);
    });

    //GET SAMPLES
    brandService.listBrands().then((res) => {
      if(res.type === 'success' && res.results){
        setBrands(res.results.map((brand) => { return handleBrandStructure(brand); }));
        setLoading(false);
      }else{
        window.alert(res.message);
        setLoading(false);
      }
    }, (error) => {
      //console.log(error);
      setLoading(false);
    });
  };

  const handleBrandStructure = (brand) => {
    let brandTemp = {...brand};

    brandTemp.str_zones = brand.arr_zones ? brand.arr_zones.map( (zone) => { return zone.str_name; } ).join(', ') : '';
    brandTemp.str_countries = brand.arr_countries ? brand.arr_countries.map( (country) => { return country.str_name; } ).join(', ') : '';
    brandTemp.str_sites = brand.arr_sites ? brand.arr_sites.map( (site) => { return site.str_name; } ).join(', ') : '';

    return brandTemp;
  };

  const handleNewBrand = useCallback(
    () => {
      history.push({pathname: '/edit_brand', state: {values: {uuid_brand: uuid(), isNew: true}, privilege: privilege}});
    }, [privilege]
  );

  const handleEditBrand = (values,onList) => () => {
    if (onList === 0) {
      history.push({pathname: '/detail_brand', state: {values: values, privilege: privilege}});
      return
    }
    history.push({pathname: '/edit_brand', state: {values: values, privilege: privilege}});
  }
  
    
    const handleRemoveBrand = (values) => {
        if (window.confirm(`${t("brand_remove_brand")}: ${values.str_name}`)) {
          brandService.deleteBrand(values.uuid_brand).then(
            (res) => {
              if (res.type === "success" && res.results) {
                refreshState();
              } else {
                window.alert(res.message);
              }
            },
            (error) => {
              //console.log(error);
            }
          );
        }
      }


  const headers = [
    { description: t('common_logo'), field: 'str_img_brand', type: 'image'},
    { description: t('common_name'), field: 'str_name'},
    { description: t('common_family'), field: 'str_brand_family_name'},
    { description: t('common_zone'), field: 'str_zones', type: 'array'}
  ];

  return (
    <div className={listBrandsStyle.pageContainer}>
      <Label 
        label={t('brand_list_brands')}
        bold
        neutral
      />

      <GenericTable 
        arrHeader={headers}
        arrRow={brands}
        searchable={true}
        onEdit={handleEditBrand}
        onList={privilege.bol_access}
        onRemove={privilege.bol_access ? handleRemoveBrand : null}
        newRegister={privilege.bol_access ? {
          onClick: handleNewBrand,
          label: t('brand_new_brand')
        } : null}
        loading={loading}
      />
    </div>
  );
};

export default ListBrands;