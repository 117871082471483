import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CardSample,
  CardRow,
  CardColumn,
  Label,
  Button,
  NotificationBar,
} from '../../../components';
import { ArrowLeft } from 'react-feather';
import tastingSampleStyle from './tastingSample.module.scss';

import { useTasting } from '../../../contexts/Tasting';
import { GetSocket } from '../../../utils/socketIo';
import { DataDogRum } from '../../../utils/dataDogRum';
import { useUser } from '../../../contexts/User';

const TastingSample = ({ location }) => {
  const history = useHistory();
  const [samples, setSamples] = useState([]);
  const { taste, handleSample, loadTaste } = useTasting();
  const { user } = useUser();
  const [notificationArr, setNotificationArr] = useState([]);
  const bubbleArr = useRef([]);
  const nottificationRef = useRef({});

  useEffect(() => {
    bubbleArr.current = notificationArr;
  }, [notificationArr]);

  const handleDataDog = () => {
    if (location.state.round.uuid_round_type === '4ed984d9-021d-4d06-a992-991a24b3d7ce') {
      DataDogRum(user)
    }
  }

  const handleSocketOn = async (socketName, res) => {
    const resUuidRound = res.uuid_round ? res.uuid_round : '';
    let nowRound = resUuidRound === location.state.round.uuid_round;
    let sampleNameStr = '';

    if (socketName === 'addUpdateSample') {
      for (let i = 0; i < res.updateSampleArr.length; i++) {
        const lastDom = i + 1 !== res.updateSampleArr.length ? ', ' : '';
        sampleNameStr += res.updateSampleArr[i].str_brand_name + lastDom;
      }
      nowRound = nowRound && !!sampleNameStr;
    }
    if (nowRound) {
      const resTaste = await loadTaste(location.state.round);
      setSamples(resTaste.results[0].arr_samples);
      if (
        nottificationRef?.current &&
        bubbleArr.current &&
        bubbleArr.current.length > 4
      ) {
        nottificationRef.current?.scrollBottom &&
          nottificationRef.current.scrollBottom();
      }
    }
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      GetSocket().on('updateSample', async function (res) {
        if (window.location.pathname === '/tasting_samples') {
          handleSocketOn('updateSample', res);
        }
      });
      GetSocket().on('addUpdateSample', async function (res) {
        if (window.location.pathname === '/tasting_samples') {
          handleSocketOn('addUpdateSample', res);
        }
      });
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    handleDataDog();
    refreshState();
  }, []);

  const refreshState = () => {
    setSamples(taste.arr_samples);
  };

  const handleClickSample = async sample => {
    const res = await handleSample(sample);
    if (res) {
      history.replace({
        pathname: `/tasting_voting`,
        state: {
          round: location.state.round,
          from: location.state.from === 'discussion' ? 'discussion' : null,
        },
      });
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Fragment>
      <NotificationBar
        ref={nottificationRef}
        right="5px"
        notificationArr={notificationArr}
        setNotificationArr={row => {
          setNotificationArr([...row]);
        }}
      />
      <CardRow flexWrap="wrap" transparent>
        <CardColumn className={tastingSampleStyle.header}>
          <Button
            label={''}
            onClick={() => handleBack()}
            className={tastingSampleStyle.back}
          >
            <ArrowLeft />
          </Button>
        </CardColumn>
        <CardColumn padding="5px">
          <Label
            style={{
              textAlign: 'center',
              color: '#F49B00',
              fontSize: '1.2em',
            }}
            label={taste.int_round}
            bold
            neutral
          />
        </CardColumn>
      </CardRow>
      {
        <CardRow transparent flexWrap="wrap" style={{ flexGrow: 1 }}>
          {samples?.map(sample => (
            <CardSample
              key={sample.uuid_sample}
              sample={sample}
              handleClick={() => handleClickSample(sample)}
            ></CardSample>
          ))}
        </CardRow>
      }
    </Fragment>
  );
};

export default TastingSample;
