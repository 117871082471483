export function timeRemaining(finish) {
  let date = null;
  if (finish.substr(-1) === 'Z') {
    date = finish;
  } else {
    if (finish.toString().length === 19) {
      finish = finish.slice(0, -3);
    }
    date = finish + ':00.000Z';
  }
  return (
    (new Date(date).getTime() -
      Date.UTC(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )) /
    1000
  );
}

