import React from 'react';
import PropTypes from 'prop-types';
import userImageStyles from './userimage.module.scss';
import configs from '../../configs';
import {
	X
} from 'react-feather';

const UserImage = props => {

	const getClassName = () => {
		let classes = userImageStyles.userImage + ' ';
		if (props.onRemove) {
			classes += userImageStyles.userImage + ' ';
		}
		return classes;
	}

	const handleDownload = () => {
		let link = document.createElement('a');
		link.href = configs.file_api_url + '/v1/' + props.src;
		link.download = props.src;
		link.target='_blank';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const handleRemove = () => {
		const res = {target: {value: null, name: props.name}};
		props.onRemove(res);
	};

	return (
		<div
			style={{
				margin: props.margin ? props.margin : '10px',
				height: (props.size ? props.size : '100') + 'px',
				width: (props.size ? props.size : '100') + 'px'
			}} 
			className={getClassName()}
		>
			{ props.onRemove ? <X onClick={handleRemove}/> : '' }
			<img 
				style={{
					height: (props.size ? props.size : '100') + 'px',
					width: (props.size ? props.size : '100') + 'px'
				}}
				src={configs.file_api_url + '/v1/' + props.src}
				onClick={handleDownload}
				alt={props.alt ? props.alt : ''}
			/>
		</div>
		
	)
}

UserImage.propTypes = {
	size: PropTypes.number,
	name: PropTypes.string,
	src: PropTypes.string,
	alt: PropTypes.string,
	margin: PropTypes.string,
	onRemove: PropTypes.func
}

export default UserImage;