import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import loginSSOStyle from './loginSSO.module.scss';
import logo from '../../logo.png';
import Spinner from '../../components/spinner/Spinner';
import { useUser } from '../../contexts/User';
import configs from '../../configs';
import AppService from '../../app.service';
import CryptoJS from 'crypto-js';
import moment from 'moment';

const LoginAuth = () => {
  const appService = new AppService();

  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { handleUser, handleLogged } = useUser();
  const { pathname } = window.location;
  const urlParts = pathname.split('&&');
  const key = urlParts[0].split('/')[2]
  const decrypt = (encryptedText) => {
    const bytes = CryptoJS.AES.decrypt(encryptedText, key);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  }
  const uuid_round = decrypt(urlParts[urlParts.length - 2]) ?? '';
  const uuid_user = decrypt(urlParts[urlParts.length - 1]) ?? '';
  const dat_now_time = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

  const handleLogin = () => {
    setLoading(true);
    appService.NonAbiLogin({ uuid_round, uuid_user, dat_now_time }).then(res => {
      if (res.type === 'success' && res.results) {
        let user = {
          ...res.results[0],
          currentTab: 'menu_tasting_session',
          currentMenu: 'menu_tasting_session',
          showMenu: false,
          nonABI: true,
          uuid_round,
        };
        handleUser(user);
        handleLogged();
        i18n.changeLanguage(res.results[0].str_language);
      } else {
        window.alert(res.message);
        setLoading(false);
      }
      window.location.replace('/')
    }, err => {
      setLoading(false);
    });
  };

  useEffect(() => {
    handleLogin()
  }, [])

  return (
    <div className={loginSSOStyle.loginContainer}>
      <div className={loginSSOStyle.loginBox}>
        <div className={loginSSOStyle.loginLogo}>
          <img src={logo} alt="ABInBev" height="50" />
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <p style={{ color: 'red' }}>This link has expired</p>
        )}
        {process.env.REACT_APP_STAGE !== 'prod' ? (
          <div
            className={loginSSOStyle.templateUser}
            style={{ color: '#ff6a12', }}
          >
            TEST ENVIRONMENT &nbsp; v<b>{configs.val_version}</b>
          </div>
        ) : (
          <div
            className={loginSSOStyle.templateUser}
            style={{ color: '#ff6a12' }}
          >
            v<b>{configs.val_version}</b>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginAuth;