import ListRounds from './listRounds/ListRounds';
import EditRound from './editRound/EditRound';
import Preview from './EditRoundFlavor/Preview';
import { ProfileProvider } from '../../contexts/Profile';

export default {
  routeModule: {
    path: '/list_rounds',
    component: ListRounds,
    key: 'round_list_rounds',
    privilege: 'ROUND_MANAGEMENT_LIST',
    context: ProfileProvider,
    routeComponents: [
      {
        path: '/edit_round',
        component: EditRound,
        key: 'edit_round',
        privilege: 'ROUND_MANAGEMENT_EDIT',
      },
      {
        path: '/new_round',
        component: EditRound,
        key: 'new_Round',
        privilege: 'ROUND_MANAGEMENT_EDIT',
      },
    ],
    subMenu: 'menu_panel_management',
  },
};
