import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

const Checkbox = props => {
  const [value, setValue] = useState(false);
  const [ID, setID] = useState(1);

  useEffect(() => {
    setID(uuid());
    setValue(props.value ? true : false);
  }, [props.value]);

  const handleChangeCheck = evt => {
    const res = {
      target: { value: evt.target.checked ? 1 : 0, name: props.name },
    };
    props.onChange(res);
  };

  return (
    <div style={props.style}>
      <input
        id={ID}
        type={props.isRadio ? 'radio' : 'checkbox'}
        onChange={handleChangeCheck}
        name={props.name}
        checked={value}
        disabled={props.disabled}
        style={{
          transform: 'scale(1.1)',
          position: 'absolute',
          margin: props.margin ? props.margin : '13px 0px 0px 10px',
          cursor: 'pointer',
        }}
      />
      <label
        htmlFor={ID}
        style={{
          display: 'block',
          padding: props.padding ? props.padding : '8px 10px 8px 24px',
          cursor: 'pointer',
          whiteSpace: 'nowrap',
        }}
      >
        {props.label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func,
  margin: PropTypes.string,
  padding: PropTypes.string,
  style: PropTypes.string,
};

export default Checkbox;
