import MainService from './main.service';

export default class EmailService {
  mainService = null;
  path = null;

  constructor() {
    this.mainService = new MainService();
    this.path = '/v1/email';
  }

  listEmailConfiguration = () => {
    return this.mainService.get(this.path + '/list_email_group');
  };

  listEmailType = () => {
    return this.mainService.get(this.path + '/list_email_type');
  };

  updateEmailConfiguration = obj_group => {
    return this.mainService.post(this.path + '/email_group', obj_group);
  };

  checkEmailGroup = data => {
    return this.mainService.post(this.path + '/check_email_group', data);
  };

  deleteEmailConfiguration = uuid_email_group => {
    return this.mainService.delete(
      this.path + '/del_email/' + uuid_email_group,
    );
  };
}

