import moment from 'moment';
import history from '../modules/Template/history';

export function DataDogRum(user = null) {
  window.DD_RUM.setUser({
    id: user.str_abi_id ?? null,
    uniqueid: user.unique_id ?? null,
    name: user.str_name ?? null,
    email: user.str_email ?? null,
    user: {
      role: user.str_profile_name ?? null,
      zone: user.str_name_zone ?? null,
      login_date:
        user.login_date ?? moment(new Date()).format('MM/DD/YYYY HH:mm:ss'),
      site: user.str_name_site ?? null,
    },
    session: {
      access: {
        access_date:
          user.access_date ?? moment(new Date()).format('MM/DD/YYYY HH:mm:ss'),
      },
    },
    access: {
      current_route: user.current_route ?? history.location.pathname,
      deliver_value: !!user.deliver_value ?? null,
    },
  });
}

