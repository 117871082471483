import { useState, useCallback, useContext, createContext } from 'react';

const TourMaskContext = createContext();

export const TourMaskProvider = ({ children }) => {
  const [isOpenSum, setIsOpenSum] = useState(0);

  const handleIsOpenSum = useCallback(() => {
    setIsOpenSum(prev => prev + 1);
  }, [isOpenSum]);

  return (
    <TourMaskContext.Provider
      value={{ isOpenSum, handleIsOpenSum, setIsOpenSum }}
    >
      {children}
    </TourMaskContext.Provider>
  );
};

export const useTourMask = () => {
  const context = useContext(TourMaskContext);
  if (context === undefined) {
    throw new Error('useTourMask must be used within a TourMaskProvider');
  }
  return context;
};
