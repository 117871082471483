import React from 'react';
import PropTypes from 'prop-types';
import cardImageStyles from './cardimage.module.scss';
import configs from '../../configs';
import { X } from 'react-feather';

const CardImage = props => {
  const getClassName = () => {
    let classes = cardImageStyles.cardImage + ' ';
    if (props.onRemove) {
      classes += cardImageStyles.cardRemove + ' ';
    }
    return classes;
  };

  const handleDownload = () => {
    let link = document.createElement('a');
    link.href = configs.file_api_url + '/v1/' + props.src;
    link.download = props.src;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRemove = () => {
    const res = { target: { value: null, name: props.name } };
    props.onRemove(res);
  };

  let styleImg = {
    height: (props.size ? props.size : '100') + 'px',
    width: (props.size ? props.size : '100') + 'px',
    ...props.styleImg,
  };

  let styleFather = {
    margin: props.margin ? props.margin : '10px',
    height: (props.size ? props.size + 6 : '106') + 'px',
    width: (props.size ? props.size + 6 : '106') + 'px',
    cursor: props.disableDownload ? 'auto' : 'pointer',
    ...props.styleFather,
  };

  if (props.removeStyle) {
    styleImg = { ...props.styleImg };
    styleFather = { ...props.styleFather };
  }

  return (
    <div
      style={{
        margin: '0 auto',
        width: '100%',
        height: '100%',
      }}
    >
      <div style={styleFather} className={getClassName()}>
        {props.onRemove ? <X onClick={handleRemove} /> : ''}
        <img
          style={styleImg}
          src={props.local ? props.src : configs.file_api_url + '/v1/' + props.src}
          onClick={!props.local && props.disableDownload ? null : handleDownload}
          alt={props.alt ? props.alt : ''}
        />
      </div>
    </div>
  );
};

CardImage.propTypes = {
  size: PropTypes.number,
  removeStyle: PropTypes.bool,
  name: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  margin: PropTypes.string,
  onRemove: PropTypes.func,
  styleImg: PropTypes.object,
  styleFather: PropTypes.object,
};

export default CardImage;

