import React from 'react';
import PropTypes from 'prop-types';
import loadingStyle from './loading.module.scss';
import { RefreshCcw } from 'react-feather';

const Loading = props => {
  return (
    <div
      style={{ ...props.style, display: props.loading ? 'block' : 'none' }}
      className={
        loadingStyle.loading + (props.className ? ' ' + props.className : '')
      }
    >
      <RefreshCcw style={{ width: props.width, height: props.height }} />
      {props.label}
    </div>
  );
};

Loading.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool,
};

export default Loading;
