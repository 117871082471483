import React, { useState } from 'react';
import {
  CardRow,
  CardColumn,
  DropdownMultiField,
  DropdownField,
  Button,
  Card,
} from '../../../components';
import { useEffect } from 'react';
import ProfileService from '../../services/users/profile.service';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import { useProfile } from '../../../contexts/Profile';
import { useUser } from '../../../contexts/User';

const ListUsersProfilesLevels = props => {
  const { t } = useTranslation();

  const profileService = new ProfileService();

  const { handleProfileLevels } = useProfile();

  const [profileLevels, setProfileLevels] = useState([]);
  const { user } = useUser();

  const refreshState = () => {
    if (props.profile.uuid_profile !== undefined) {
      profileService.listProfileLevels(props.profile.uuid_profile).then(
        res => {
          if (res.type === 'success' && res.results) {
            setProfileLevels(res.results[0].user_sp_se_profiles_levels);
            handleProfileLevels(res.results[0].user_sp_se_profiles_levels);
          } else {
            window.alert(res.message);
          }
        }
      );
    }
  };

  useEffect(() => {
    refreshState();
  }, [props.profile]);

  const handleNewLevel = () => {
    const oldProfileLevels = profileLevels ? profileLevels : [];
    setProfileLevels([
      {
        uuid_profile_level: uuid(),
        uuid_zone: user.uuid_zone,
        str_zone_name: user.str_name_zone,
        uuid_country: user.uuid_country,
        str_country_name: user.str_name_country,
        uuid_site: user.uuid_site,
        str_site_name: user.str_name_site,
      },
      ...oldProfileLevels,
    ]);
    handleProfileLevels([{ uuid_profile_level: uuid() }, ...oldProfileLevels]);
  };

  const handleRemoveLevel = (e, index) => {
    if (profileLevels[index].uuid_profile_level !== undefined) {
      profileService
        .deleteProfileLevel(
          props.profile.uuid_profile,
          profileLevels[index].uuid_profile_level,
        )
        .then(
          res => {
            if (res.type === 'success' && res.results) {
              refreshState();
            } else {
              window.alert(res.message);
            }
          },
          err => console.log(err),
        );
    } else {
      const updatedProfileLevels = [...profileLevels];
      updatedProfileLevels.splice(index, 1);
      setProfileLevels(updatedProfileLevels);
      handleProfileLevels(updatedProfileLevels);
    }
  };

  const handleLevelChange = (evt, index) => {
    const updatedProfileLevels = [...profileLevels];

    if (evt.target.name === 'uuid_zone') {
      if (evt.target.value !== updatedProfileLevels[index].uuid_zone) {
        updatedProfileLevels[index] = {
          ...updatedProfileLevels[index],
          uuid_country: '',
          uuid_site: '',
        };
      }
    }

    if (evt.target.name === 'uuid_country') {
      if (evt.target.value !== updatedProfileLevels[index].uuid_country) {
        updatedProfileLevels[index] = {
          ...updatedProfileLevels[index],
          uuid_site: '',
        };
      }
    }

    if (evt.target.name === 'uuid_site') {
      if (evt.target.value !== updatedProfileLevels[index].uuid_site) {
        updatedProfileLevels[index] = {
          ...updatedProfileLevels[index],
          uuid_site: '',
        };
      }
    }

    updatedProfileLevels[index] = {
      ...updatedProfileLevels[index],
      [evt.target.name]: evt.target.value,
    };
    setProfileLevels(updatedProfileLevels);
    handleProfileLevels(updatedProfileLevels);
  };

  return (
    <React.Fragment>
      <CardRow justifyContent="right" style={{ marginTop: '10px' }}>

        <Button label="+" onClick={handleNewLevel} confirm round />
      </CardRow>
      {profileLevels?.map((level, index) => (
        <Card key={profileLevels[index].uuid_profile_level} bordered>
          <CardColumn>
            <CardRow>
              <Button
                label="X"
                onClick={e => {
                  handleRemoveLevel(e, index);
                }}
                remove
                round
              />
            </CardRow>
            <DropdownMultiField
              name="arr_privileges"
              options={props.privileges}
              valueField="uuid_privilege"
              labelField="str_name"
              label={`${t('common_privileges')} ${
                profileLevels[index].str_level
                  ? ` level ${profileLevels[index].str_level}`
                  : ''
              }`}
              margin="10px 10px 0px 0px"
              value={profileLevels[index].arr_privileges}
              onChange={e => handleLevelChange(e, index)}
            />
            <DropdownField
              name="uuid_zone"
              options={
                props.privilege.arr_zones ? props.privilege.arr_zones : []
              }
              valueField="uuid_zone"
              labelField="str_name"
              label={t('common_zone')}
              margin="10px 10px 0px 0px"
              value={profileLevels[index].uuid_zone}
              onChange={e => {
                profileLevels.uuid_country = null;
                profileLevels.uuid_site = null;
                handleLevelChange(e, index);
              }}
            />
            <DropdownField
              name="uuid_country"
              options={
                props.privilege.arr_countries && profileLevels[index].uuid_zone
                  ? props.privilege.arr_countries.filter(country => {
                      return (
                        country.uuid_zone === profileLevels[index].uuid_zone
                      );
                    })
                  : []
              }
              valueField="uuid_country"
              labelField="str_name"
              label={t('common_country')}
              margin="10px 10px 0px 0px"
              value={profileLevels[index].uuid_country}
              onChange={e => {
                profileLevels[index].uuid_site = null;
                handleLevelChange(e, index);
              }}
            />
            <DropdownField
              name="uuid_site"
              options={
                props.privilege.arr_sites && profileLevels[index].uuid_country
                  ? props.privilege.arr_sites.filter(site => {
                      return (
                        site.uuid_country === profileLevels[index].uuid_country
                      );
                    })
                  : []
              }
              valueField="uuid_site"
              labelField="str_name"
              label={t('common_site')}
              margin="10px 10px 0px 0px"
              value={profileLevels[index].uuid_site}
              onChange={e => handleLevelChange(e, index)}
            />
          </CardColumn>
        </Card>
      ))}
    </React.Fragment>
  );
};

export default ListUsersProfilesLevels;
