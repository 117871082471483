import React, { useEffect, useState } from 'react';
import { AlerItem } from './AlerItem';
import alertService from '../../modules/services/app/alert.service'

export const Alerts = props => {
    const [alerts, setAlerts] = useState([])

    const handleCloseAlert = (alert) => {
        alertService.hideAlert(alert)
    }

    useEffect(() => {
        alertService.emitter.on('update', (alerts) => {
            setAlerts(alerts || []);
        })
    }, [])

    return  (<React.Fragment>
        {
            alerts.map((alert) =>(<AlerItem key={alert.id} alert={alert} close={handleCloseAlert} />))
        }
        </React.Fragment>);
    
};

export default Alerts;