import React, { useEffect, useState } from 'react';
import editGroupStyle from './editgroup.module.scss';
import { useHistory } from 'react-router-dom';
import {
  Label,
  Card,
  CardRow,
  DropdownField,
  TextField,
  CardColumn,
  Button,
} from '../../../components';
import EditGroupTasters from '../editGroupTasters/EditGroupTasters';
import EditGroupUsers from '../editGroupUsers/EditGroupUsers';
import GroupService from '../../services/groups/group.service';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../contexts/User';

const EditRound = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [group, setGroup] = useState({
    ...props.location.state.values,
  });
  const [privilege, setPrivilege] = useState([]);
  const { user } = useUser();
  const groupService = new GroupService();

  useEffect(() => {
    setPrivilege(props.location.state.privilege);
    if (props.location.state.isNewGroup) {
      setGroup(prevState => {
        return {
          ...prevState,
          uuid_zone: user.uuid_zone,
          uuid_country: user.uuid_country,
          uuid_site: user.uuid_site,
        };
      });
    }
  }, []);

  const handleSave = () => {
    if (!group.str_group_name?.trim()) {
      alert(t('group_no_name_message'));
      return false;
    }
    if (!group.uuid_zone) {
      alert(t('group_no_zone_message'));
      return false;
    }
    if (!group.arr_users?.length) {
      alert(t('group_no_users_message'));
      return false;
    }
    groupService.updateGroup(group).then(
      res => {
        if (res.type === 'success' && res.results) {
          history.goBack();
        } else {
          window.alert(res.message);
        }
      }
    );
  };

  const handleChange = evt => {
    const { name, value } = evt.target;

    setGroup({
      ...group,
      [name]: value,
    });
  };

  return (
    <div className={editGroupStyle.pageContainer}>
      <Label
        label={
          props.location?.state?.isNewGroup
            ? t('group_new_group')
            : t('group_edit_group')
        }
        bold
        neutral
      />

      <Card transparent margin="0px">
        <CardRow transparent flexWrap="wrap" padding="0px 0px 0px 5px">
          <TextField
            flex={2}
            name="str_group_name"
            label={t('common_name')}
            margin="10px 10px 0px 0px"
            value={group.str_group_name}
            onChange={handleChange}
          />
          <DropdownField
            name="uuid_zone"
            options={privilege.arr_zones ? privilege.arr_zones : []}
            valueField="uuid_zone"
            labelField="str_name"
            label={t('common_zone')}
            margin="10px 10px 0px 0px"
            value={group.uuid_zone}
            onChange={value => {
              group.uuid_country = null;
              group.uuid_site = null;
              handleChange(value);
            }}
          />
          <DropdownField
            name="uuid_country"
            options={
              privilege.arr_countries && group.uuid_zone
                ? privilege.arr_countries.filter(country => {
                    return country.uuid_zone === group.uuid_zone;
                  })
                : []
            }
            valueField="uuid_country"
            labelField="str_name"
            label={t('common_country')}
            margin="10px 10px 0px 0px"
            value={group.uuid_country}
            onChange={value => {
              group.uuid_site = null;
              handleChange(value);
            }}
          />
          <DropdownField
            name="uuid_site"
            options={
              privilege.arr_sites && group.uuid_country
                ? privilege.arr_sites.filter(site => {
                    return site.uuid_country === group.uuid_country;
                  })
                : []
            }
            valueField="uuid_site"
            labelField="str_name"
            label={t('common_site')}
            margin="10px 10px 0px 0px"
            value={group.uuid_site}
            onChange={handleChange}
          />
        </CardRow>

        <CardRow transparent flexWrap="wrap">
          <CardColumn padding="5px">
            <EditGroupUsers
              group={group}
              setGroup={setGroup}
              privilege={privilege}
            />
          </CardColumn>

          <CardColumn padding="5px">
            <EditGroupTasters group={group} setGroup={setGroup} />
          </CardColumn>
        </CardRow>

        <CardRow justifyContent="flex-end" flexWrap="wrap" transparent>
          <Button
            label={t('common_cancel')}
            onClick={() => {
              history.goBack();
            }}
            neutral
            margin="10px 10px 0px 0px"
          />
          <Button
            label={t('common_save')}
            onClick={handleSave}
            confirm
            margin="10px 10px 0px 0px"
          />
        </CardRow>
      </Card>
    </div>
  );
};

export default EditRound;
