import React, { useState, useEffect, useRef, Fragment, useMemo } from 'react';
import listDiscussion from './listDiscussion.module.scss';
import {
  CardRow,
  Text,
  CardColumn,
  Button,
  Loading,
  ButtonDownload,
  Modal,
  RoundUpAndDown,
  ImageZoom,
  PdfViewerModal,
  NotificationBar,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { useTasting } from '../../../contexts/Tasting';
import { Key, ArrowLeft, X, Eye } from 'react-feather';
import { useHistory } from 'react-router-dom';
import configs from '../../../configs';
import ReportService from '../../services/reports/report.service';
import _ from 'lodash';
import { useUser } from '../../../contexts/User';
import RoundAppService from '../../services/rounds/roundApp.service';
import SampleAppService from '../../services/samples/sampleApp.service';
import AppService from '../../../app.service';
import edit from '../../../assets/edit.svg';
import editUnlock from '../../../assets/edit_unlock.svg';
import editBlock from '../../../assets/edit_block.svg';
import ListDiscussionItem from './ListDiscussionItem';
import ReactTooltip from 'react-tooltip';
import shade from '../../../assets/shade.png';
import { GetSocket } from '../../../utils/socketIo';

const ListDiscussion = ({ location }) => {
  const history = useHistory();
  const elementRef = useRef(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [tasters, setTasters] = useState([]);
  const [templateTasters, setTemplateTasters] = useState([])
  const [optionsFilter, setFilters] = useState([]);
  const [order, setOrder] = useState('int_order_app');
  const [privilege, setPrivilege] = useState([]);
  const reportService = new ReportService();
  const sampleAppService = new SampleAppService();
  const roundServiceApp = new RoundAppService();
  const appService = new AppService();
  const { taste, loadTasteDiscussion, loadTaste } = useTasting();
  const { user, handleUser } = useUser();
  const [uuidRound, setUuidRound] = useState('');
  const [showModalDownload, setShowModalDownload] = useState(false);
  const [showModalDownloadSample, setShowModalDownloadSample] = useState(null);
  const [open, setOpenPdf] = useState(false);
  const [tasteValues, setTasteValues] = useState('');
  const [isRoundApprove, setIsRoundApprove] = useState(false);
  const rowsFormatted = useMemo(
    () =>
      rows.map(row => {
        return { ...row, int_rank: parseInt(row.int_rank) };
      }),
    [rows],
  );
  const [showImg, setShowImg] = useState(false);
  const [fullButton, setFullButton] = useState('');
  const [fullHeight, setHeight] = useState('');
  const [showSampleDetails, setShowSampleDetails] = useState(false);
  const [notificationArr, setNotificationArr] = useState([]);
  const bubbleArr = useRef([]);
  const nottificationRef = useRef({});
  const nowSplitItemRef = useRef({});
  const optionsByTastersFilter = [
    {
      value: 0,
      label: t('all_tasters'),
    },
    {
      value: 1,
      label: t('key_tasters'),
    },
    {
      value: 2,
      label: t('non_key_tasters'),
    },
  ]

  useEffect(() => {
    bubbleArr.current = notificationArr;
  }, [notificationArr]);

  const ASC_DESC = {
    int_order_app: 'asc',
    str_site_name: 'asc',
    int_rank: 'asc',
    str_brand_name: 'asc',
    flt_average_score: 'desc',
    flt_average_score_key: 'asc',
    overall_rank: 'asc',
    key_rank: 'asc',
  };

  const handleClosePdf = () => {
    setOpenPdf(!open);
  };

  const handlePDF = () => {
    setOpenPdf(!open);
  };

  const fetchDataFromContext = async socketTaste => {
    if (location.state && !_.isEmpty(location.state?.uuid_round)) {
      user.currentMenu = 'menu_tasting_session';
      user.currentTab = 'menu_tasting_session';
      handleUser(user);
      const uuid_round =
        location.state?.isFromDiscussion === true || location.state?.uuid_round
          ? location.state.uuid_round
          : location.state.round.uuid_round;
      setUuidRound(uuid_round);
      const loadTaste = await loadTasteDiscussion(
        location.state?.round ? location.state.round : { uuid_round },
      );

      if (loadTaste.type === 'success') {
        const tasteAux = loadTaste.results[0];
        setTasteValues(tasteAux);
        fetchRows(uuid_round, tasteAux);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setTasteValues(socketTaste ? socketTaste : taste);
      setUuidRound(taste.uuid_round);
      fetchRows(taste.uuid_round, taste);
      setLoading(false);
    }
  };

  const [isSplitRound, setIsSplitRound] = useState(false);
  useEffect(() => {
    const uuid_round =
      location.state?.isFromDiscussion !== undefined &&
        location.state?.hasOwnProperty('uuid_round')
        ? location.state.uuid_round
        : location.state.round.uuid_round;
    roundServiceApp.splitTaste({ uuid_round }).then(res => {
      if (res.results[0].uuid_split_round) {
        setIsSplitRound(true);
      } else {
        setIsSplitRound(false);
      }
      getShowSampleDetails();
    });
  }, []);

  const handleSocketOn = async (socketName, res) => {
    const uuid_round =
      location.state?.isFromDiscussion !== undefined ||
        location.state?.uuid_round !== undefined
        ? location.state.uuid_round
        : location.state.round.uuid_round;
    const splitRes = await roundServiceApp.splitTaste({ uuid_round });
    const splitSamples = splitRes.results[0].round_sample;
    const resUuidRound = res.uuid_round ? res.uuid_round : '';
    let isNowRound =
      splitSamples?.length > 0
        ? splitRes.results[0].round_sample.find(
          item => item.uuid_round === resUuidRound,
        )
        : resUuidRound === uuid_round;
    if (socketName === 'autoUpdateDiscussion') {
      isNowRound =
        isNowRound && res.user_name && res.user_name !== user.str_name;
    }
    let sampleNameStr = '';
    if (socketName === 'addUpdateSample') {
      for (let i = 0; i < res.updateSampleArr.length; i++) {
        const lastDom = i + 1 !== res.updateSampleArr.length ? ', ' : '';
        sampleNameStr += res.updateSampleArr[i].str_brand_name + lastDom;
      }
      isNowRound = isNowRound && !!sampleNameStr;
    }
    if (isNowRound) {
      const nowRound =
        location.state?.round !== undefined
          ? location.state?.round
          : location.state;
      const resDiscussion = await loadTasteDiscussion(nowRound);
      if (resDiscussion) {
        let oldName = '';
        let newName = '';
        let title = '';

        if (socketName === 'autoUpdateDiscussion') {
          oldName = res.user_name ? res.user_name : '';
          title = 'common_finish_session';
          getShowSampleDetails();
          bubbleArr.current.push({
            oldName,
            title,
            newName,
          });
          setNotificationArr([...bubbleArr.current]);
        }

        fetchDataFromContext(resDiscussion.results[0]);

        if (
          nottificationRef?.current &&
          bubbleArr.current &&
          bubbleArr.current.length > 4
        ) {
          nottificationRef.current?.scrollBottom &&
            nottificationRef.current.scrollBottom();
        }
      }
    }
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      GetSocket().on('updateDiscussionSampleID', async function (res) {
        if (window.location.pathname === '/tasting_discussion') {
          handleSocketOn('updateDiscussionSampleID', res);
        }
      });
      GetSocket().on('autoUpdateDiscussion', async function (res) {
        if (window.location.pathname === '/tasting_discussion') {
          handleSocketOn('autoUpdateDiscussion', res);
        }
      });
      GetSocket().on('addUpdateSample', async function (res) {
        if (window.location.pathname === '/tasting_discussion') {
          handleSocketOn('addUpdateSample', res);
        }
      });
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);

  const getShowSampleDetails = async () => {
    const uuid_round =
      location.state?.isFromDiscussion !== undefined ||
        location.state?.uuid_round !== undefined
        ? location.state.uuid_round
        : location.state.round.uuid_round;
    const res = await roundServiceApp.getShowSampleDetails(uuid_round);
    if (res.type === 'success') {
      setShowSampleDetails(
        res.results[0].round_sp_se_round_hide_sample_details,
      );
    }
  };

  useEffect(() => {
    setLoading(true);
    setFilters([
      { label: t('discussion_order_number'), value: 'int_order_app' },
      { label: t('discussion_order_brewery'), value: 'str_site_name' },
      { label: t('discussion_order_ranking'), value: 'int_rank' },
      { label: t('discussion_order_brand'), value: 'str_brand_name' },
      { label: t('discussion_order_score'), value: 'flt_average_score' },
      {
        label: t('discussion_order_key_rank'),
        value: 'key_rank',
      },
      {
        label: t('discussion_order_overall_rank'),
        value: 'overall_rank',
      },
    ]);
    appService.listPrivileges('SAMPLE_DISCUSSION_EDIT').then(
      res => {
        if (res.type === 'success' && res.results) {
          setPrivilege(res.results[0]);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
    fetchDataFromContext();

    return () => {
      sessionStorage.removeItem('roundjump');
      sessionStorage.removeItem('isShowButton');
    };
  }, [t]);

  const fetchRows = async (uuid_round, taste) => {
    //GET DATA FOR DISCUSSION
    let rowsTemp = [];
    let tastersTemp = [];
    let tasterTemp = [];
    let commentsTemp = [];
    let commentTemp = [];
    let scores = [];
    let comments = [];
    let rowsTempOverallRank = [];
    let rowsTempKeyRank = [];

    roundServiceApp.listScoresDiscussion(uuid_round).then(res => {
      if (res.type === 'success' && res.results) {
        scores = res.results;
        roundServiceApp.listCommentsDiscussion(uuid_round).then(async res => {
          if (res.type === 'success' && res.results) {
            comments = res.results;
            const roundData = location.state.round
              ? location.state.round
              : location.state;
            const hasNowUser = taste.arr_tasters?.some(t => t.uuid_user === user.uuid_user);
            const tasteRow = hasNowUser ? await loadTaste(roundData) : null;
            const tasterRow = tasteRow?.results ? tasteRow.results[0] : taste;
            const samplesArr = tasterRow?.arr_split_samples ? tasterRow.arr_split_samples : tasterRow.arr_samples
            scores.forEach(score => {
              const {
                str_temperature,
                str_storage_time,
                str_storage_time_days,
                str_storage_time_weeks,
                str_malt_varieties,
              } = samplesArr?.filter(
                sample => sample.uuid_sample === score.uuid_sample && sample.uuid_round === score.uuid_round,
              )[0];

              commentsTemp = [];
              comments.forEach(comment => {
                if (score.uuid_sample === comment.uuid_sample) {
                  tasterTemp = [];
                  tasterTemp = tastersTemp.filter(
                    taster => taster.uuid_user === comment.uuid_user,
                  );

                  if (tasterTemp.length === 0) {
                    tastersTemp.push({
                      uuid_user: comment.uuid_user,
                      str_user_name: comment.str_user_name,
                      bol_key_taster: comment.bol_key_taster,
                    });
                  }

                  commentTemp = [];
                  commentTemp = commentsTemp.filter(
                    commentTaster =>
                      commentTaster.uuid_user === comment.uuid_user,
                  );

                  if (commentTemp.length === 0) {
                    commentsTemp.push(comment);
                  }
                }
              });

              Object.assign(score, {
                str_temperature,
                str_storage_time_weeks,
                str_storage_time_days,
                str_malt_varieties,
              });

              rowsTemp.push({
                ...score,
                tasters: _.sortBy(commentsTemp, 'str_user_name'),
              });
            });

            rowsTempOverallRank = addRank(
              _.orderBy(
                rowsTemp,
                ['flt_average_score', 'int_order_app'],
                ['desc', 'asc'],
              ),
              'flt_average_score',
              'overall_rank',
            );

            rowsTempKeyRank = addRank(
              _.orderBy(
                rowsTempOverallRank,
                ['flt_average_score_key', 'int_order_app'],
                ['desc', 'asc'],
              ),
              'flt_average_score_key',
              'key_rank',
            );

            setIsRoundApprove(
              taste.bol_is_approve_reject &&
              taste.uuid_round_type ===
              '4ed984d9-021d-4d06-a992-991a24b3d7ce',
            );

            rowsTemp = rowsTempKeyRank;

            setRows([...rowsTemp]);
            setTasters(_.sortBy(tastersTemp, 'str_user_name'));
            setTemplateTasters(_.sortBy(tastersTemp, 'str_user_name'));
          }
        });
      }
    });
  };

  const addRank = (sortedArr, sourceColumn, newColumn) => {
    return sortedArr.map((item, i) => {
      item[newColumn] =
        i === 0 || sortedArr[i][sourceColumn] !== sortedArr[i - 1][sourceColumn]
          ? i + 1
          : sortedArr[i - 1][newColumn];
      return item;
    });
  };

  const onScroll = e => {
    elementRef.current.scrollLeft = e.target.scrollLeft;
  };

  const handleChange = e => {
    setOrder(e.target.value);
  };

  const handleChangeByTasters = e => {
    const value = e.target.value;
    let nowTasters = templateTasters
    if (value == 1) {
      nowTasters = templateTasters.filter(taster => taster.bol_key_taster);
    } else if (value == 2) {
      nowTasters = templateTasters.filter(taster => !taster.bol_key_taster);
    }
    setTasters([...nowTasters])
  };

  const handleEdit = (sample, uuid_user) => {
    history.replace({
      pathname: `/tasting_edit_voting`,
      state: {
        uuid_user: uuid_user,
        sample: sample,
        round: location.state.round,
      },
    });
  };

  const handleDiscussionDetail = (sample, evt) => {
    if (!evt || !evt.target || evt.target.tagName !== 'BUTTON') {
      history.push({
        pathname: '/detail_discussion',
        state: {
          ...sample,
          showSampleDetails,
        },
      });
    }
  };

  const handleDownload = () => {
    setLoading(true);
    reportService
      .generate({
        arr_rounds: [tasteValues?.uuid_round],
        bol_closed: true,
        arr_round_types: [tasteValues?.uuid_round_type],
      })
      .then(
        res => {
          if (
            res.status === 'success' &&
            res.file &&
            res.file === 'report.xlsx'
          ) {
            setLoading(false);
            window.alert(t('common_report_not_have_data'));
          } else if (
            res.status === 'success' &&
            res.file &&
            res.file !== 'report.xlsx'
          ) {
            setLoading(false);
            let a = document.createElement('a');
            a.href = configs.file_api_url + '/v1/' + res.file;
            a.download = res.file;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            setLoading(false);
            window.alert(res.message);
          }
        },
        error => {
          setLoading(false);
          alert(t('you_do_not_have_privilege_to_export_this_report'));
        },
      );
  };

  const handleDownloadAttachments = async file => {
    if (file) {
      try {
        const datetime = new Date();
        const formatedDatetime =
          datetime
            .toLocaleDateString('default', {
              year: '2-digit',
              month: 'short',
              day: '2-digit',
            })
            .replace(/[. -/]/g, '_') +
          datetime.toLocaleTimeString().replace(/[:+-]/g, '_');

        let a = document.createElement('a');
        a.href = `${configs.file_api_url}/v1/${file}`;
        a.download = `sample_info_${formatedDatetime}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (err) {
        //console.log(err);
      }
    }
    history.replace({ pathname: `/tasting_discussion` });
  };

  const handleValidateRank = (voteUser, rank) => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      let rowsTemp = rows;
      let validationTie = [];
      rowsTemp = rowsTemp.map(s => {
        if (s.uuid_sample === voteUser.uuid_sample) {
          s.tasters = s.tasters.map(taster => {
            if (taster.uuid_user === voteUser.uuid_user) {
              delete taster.int_rank;
              taster.int_rank = rank ? rank : null;
              return taster;
            }
            return taster;
          });
        }
        let sampleVoting = s.tasters.find(
          taster => taster.uuid_user === voteUser.uuid_user,
        );
        if (sampleVoting) {
          validationTie.push(sampleVoting);
        }
        return s;
      });

      roundServiceApp.validateRoundTie(validationTie).then(res => {
        handleSubmitRank(voteUser, rank);
        resolve(res);
      });
    });
  };

  const handleSubmitRank = async (sample, rank) => {
    sampleAppService
      .updateRank(
        uuidRound,
        sample.uuid_sample,
        rank != ('-' || '') ? rank : null,
        sample.uuid_user,
      )
      .then(
        res => {
          if (res.type === 'success') {
            sample.int_rank = rank != ('-' || '') ? rank : null;
            setLoading(false);
          } else {
            setLoading(false);
            window.alert(res.message);
          }
        },
        error => {
          //console.log(error);
          setLoading(false);
        },
      );
  };

  const handleBack = () => {
    if (sessionStorage.getItem('roundjump')) {
      history.goBack();
    } else {
      history.replace({
        pathname: '/',
        state: {
          round: location.state.round,
        },
      });
    }
  };

  const handleInteractionLog = () => {
    let a = document.createElement('a');
    a.href = `https://interactionlog.ab-inbev.com/`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const allowOrBlockUserToEditAnswer = (
    uuid_round,
    uuid_sample,
    bol_user_can_edit,
    uuid_user,
  ) => {
    const score = {
      uuid_round,
      uuid_sample,
      bol_user_can_edit,
      uuid_user,
    };

    sampleAppService
      .editScoreEditingPermission(score)
      .then(() => {
        const currentRow = rows.find(x => x.uuid_sample === score.uuid_sample);
        const currentComment = currentRow?.tasters?.find(
          x => x.uuid_user === score.uuid_user,
        );
        if (currentComment) {
          const newData = rows.map(row => {
            row.tasters.forEach(x => {
              if (
                x.uuid_user === score.uuid_user &&
                x.uuid_sample === score.uuid_sample
              ) {
                x.bol_user_can_edit = score.bol_user_can_edit;
              }
            });
            return row;
          });
          setRows(newData);
        } else {
          fetchRows(taste.uuid_round, taste);
        }
      })
      .catch(err => {
        alert(err);
      });
  };

  const renderEditButton = (tasterCommentCheck, row, taster) => {
    if (
      privilege?.arr_sites?.some(
        x => x.uuid_site === tasteValues?.uuid_round_site,
      )
    ) {
      return user.uuid_user === tasterCommentCheck?.uuid_user &&
        tasterCommentCheck?.uuid_round === tasteValues?.uuid_round ? (
        <div>
          <img
            src={edit}
            alt={'edit'}
            className={listDiscussion.penIcon}
            height={24}
            width={24}
            onClick={() => {
              handleEdit(row, user.uuid_user);
            }}
          />
        </div>
      ) : tasterCommentCheck?.bol_user_can_edit ? (
        <img
          src={editUnlock}
          alt={'unlocked'}
          className={listDiscussion.penIcon}
          height={24}
          width={24}
          onClick={() => {
            allowOrBlockUserToEditAnswer(
              tasteValues.uuid_round,
              row.uuid_sample,
              tasterCommentCheck?.bol_user_can_edit ? 0 : 1,
              tasterCommentCheck?.uuid_user,
            );
          }}
        />
      ) : (
        <img
          src={editBlock}
          alt={'locked'}
          className={listDiscussion.penIcon}
          height={24}
          width={24}
          onClick={() => {
            allowOrBlockUserToEditAnswer(
              tasteValues.uuid_round,
              row.uuid_sample,
              tasterCommentCheck?.bol_user_can_edit ? 0 : 1,
              tasterCommentCheck?.uuid_user,
            );
          }}
        />
      );
    } else {
      return user.uuid_user === tasterCommentCheck?.uuid_user &&
        tasterCommentCheck?.bol_user_can_edit ? (
        <img
          src={edit}
          alt={'edit'}
          className={listDiscussion.penIcon}
          height={24}
          width={24}
          onClick={() => {
            handleEdit(row, taster.uuid_user);
          }}
        />
      ) : (
        <Fragment />
      );
    }
  };

  // date of manufacture
  const dateTime = sample => {
    var date = sample.substr(0, 10).split('-').reverse();
    date[2] = date[2].slice(2);
    switch (date[1] - 0) {
      case 1:
        date[1] = 'Jan';
        break;
      case 2:
        date[1] = 'Feb';
        break;
      case 3:
        date[1] = 'Mar';
        break;
      case 4:
        date[1] = 'Apr';
        break;
      case 5:
        date[1] = 'May';
        break;
      case 6:
        date[1] = 'Jun';
        break;
      case 7:
        date[1] = 'Jul';
        break;
      case 8:
        date[1] = 'Aug';
        break;
      case 9:
        date[1] = 'Sep';
        break;
      case 10:
        date[1] = 'Oct';
        break;
      case 11:
        date[1] = 'Nov';
        break;
      case 12:
        date[1] = 'Dec';
        break;
      default:
        date[1] = 'Jan';
        break;
    }
    date = date.join('-');
    return date;
  };

  const handleShowDownload = show => {
    setShowModalDownload(!show);
  };

  const handleShowDownloadSample = (show, i) => {
    if (show === null) {
      setShowModalDownloadSample(i);
    } else {
      setShowModalDownloadSample(null);
    }
  };

  const displayImage = (sample, index) => {
    if (sample.str_zip_document.split('.').pop() !== 'pdf') {
      return (
        <>
          <Modal
            show={open === index}
            fullButton={fullButton}
            fullHeight={fullHeight}
            style={{ position: showImg ? 'fixed' : '' }}
            className={showImg ? listDiscussion.buttonBackGround : ''}
          >
            <ImageZoom
              showClose={true}
              srcImage={sample.str_zip_document}
              onClick={handleClosePdf}
              className={showImg ? listDiscussion.showFull : ''}
              setFullButton={setFullButton}
              setHeight={setHeight}
              onShow={() => {
                setShowImg(!showImg);
              }}
              onClose={() => {
                setShowImg(false);
              }}
            />
          </Modal>
          <Button
            label={''}
            onClick={() => setOpenPdf(index)}
            style={{
              borderRadius: '40px',
              backgroundColor: '#F49B00',
              color: '#fff',
              fontWeight: 'bold',
              width: '40px',
              height: '40px',
              padding: '4px 0px 0px 0px',
            }}
          >
            <Eye />
          </Button>
        </>
      );
    } else {
      return (
        <>
          <PdfViewerModal
            label={`${t('common_brand')} PDF`}
            data={sample.str_zip_document}
            closeForm={handleClosePdf}
            open={open === index}
            loading={false}
            downloadFileName={sample.str_zip_document}
            name={sample.str_zip_document}
          />
          <Button
            label={''}
            onClick={() => setOpenPdf(index)}
            style={{
              borderRadius: '40px',
              backgroundColor: '#F49B00',
              color: '#fff',
              fontWeight: 'bold',
              width: '40px',
              height: '40px',
              padding: '4px 0px 0px 0px',
            }}
          >
            <Eye />
          </Button>
        </>
      );
    }
  };

  const renderHeader = () => {
    return (
      <thead>
        <tr>
          <th>
            <p className={listDiscussion.tasters}>
              Tasters:{
                (
                  tasteValues?.arr_tasters &&
                  tasteValues.arr_tasters?.length > 0 &&
                  tasters &&
                  tasters.length > 0
                ) &&
                <span> {tasters ? tasters.length : 0} / {tasteValues?.arr_tasters ? tasteValues.arr_tasters?.length : 0}</span>
              }
            </p>
            <CardColumn padding="0" margin='10px 5px 10px 5px' className={listDiscussion.cardFilter}>
              <select className={listDiscussion.filter} onChange={handleChangeByTasters}>
                {optionsByTastersFilter?.map((option, i) => (
                  <option key={option.label + i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </CardColumn>
            <CardColumn padding="0" margin='5px' className={listDiscussion.cardFilter}>
              <select className={listDiscussion.filter} onChange={handleChange}>
                {optionsFilter?.map((option, i) => (
                  <option key={option.label + i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </CardColumn>
          </th>
          {_.sortBy(tasters, 'str_user_name').map((taster, i) => {
            const tasterVotes = rows
              .map(row => {
                const votes = row.tasters.find(
                  x => x.uuid_user === taster.uuid_user,
                );
                return votes ? votes.flt_score : 0;
              })
              .filter(o => o);

            const minVote =
              tasterVotes.length > 0
                ? tasterVotes?.reduce((prev, curr) => {
                  return prev < curr ? prev : curr;
                })
                : 0;

            const maxVote =
              tasterVotes.length > 0
                ? tasterVotes?.reduce((prev, curr) => {
                  return prev > curr ? prev : curr;
                })
                : 0;

            const sum =
              tasterVotes.length > 0
                ? tasterVotes?.reduce((a, b) => a + b, 0)
                : 0;
            const average = sum / tasterVotes.length || 0;

            return (
              <th key={i}>
                <CardColumn className={listDiscussion.cardNameTasterParent}>
                  <CardColumn
                    key={taster.uuid_user}
                    className={listDiscussion.cardNameTaster}
                  >
                    <p
                      className={
                        taster.bol_key_taster
                          ? listDiscussion.cardNameTasterText
                          : listDiscussion.cardNameTasterTextW100
                      }
                      data-tip={taster.str_user_name}
                      data-for="tasterName"
                    >
                      {taster.str_user_name}
                    </p>
                    <ReactTooltip
                      id="tasterName"
                      effect="solid"
                      place="top"
                      type="warning"
                      className={listDiscussion.tooltip}
                    />
                    {taster.bol_key_taster ? (
                      <Key className={listDiscussion.keyIcon} />
                    ) : (
                      <Fragment />
                    )}
                  </CardColumn>
                  <CardColumn
                    style={{
                      backgroundColor: '#FFFFFF',
                      display: 'flex',
                      flexDirection: 'column',
                      color: 'black',
                      padding: '5px',
                    }}
                  >
                    <div className={listDiscussion.simpleAlign}>
                      <Text bold={true} className={listDiscussion.textTitle}>
                        {t('common_minimum_score')}:
                      </Text>
                      <Text className={listDiscussion.textSubTitleT}>
                        &nbsp;{minVote.toFixed(2)}
                      </Text>
                    </div>
                    <div className={listDiscussion.simpleAlign}>
                      <Text bold={true} className={listDiscussion.textTitle}>
                        {t('common_maximum_score')}:
                      </Text>
                      <Text className={listDiscussion.textSubTitleT}>
                        &nbsp;{maxVote.toFixed(2)}
                      </Text>
                    </div>
                    <div className={listDiscussion.simpleAlign}>
                      <Text bold={true} className={listDiscussion.textTitle}>
                        {t('common_average_score')}:
                      </Text>
                      <Text className={listDiscussion.textSubTitleT}>
                        &nbsp;{average.toFixed(2)}
                      </Text>
                    </div>
                  </CardColumn>
                </CardColumn>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const renderRenderFirstCollum = (row, j) => {
    return (
      <td>
        <CardColumn
          padding="0px"
          className={listDiscussion.cardInfo}
          onClick={evt => handleDiscussionDetail(row, evt)}
        >
          <CardRow>
            <CardColumn style={{ minWidth: 120 }}>
              <CardRow transparent className={listDiscussion.cardSeparator}>
                <Text bold={true} className={listDiscussion.textTitle}>
                  {t('common_position')}
                </Text>
                <Text className={listDiscussion.textSubTitle}>
                  {isSplitRound ? (<b>{row.str_split_round_order} - {row.int_order_app + 1} </b>) : row.int_order_app + 1}
                </Text>
              </CardRow>
              <CardRow transparent className={listDiscussion.cardSeparator}>
                <Text
                  bold={true}
                  style={{ marginTop: 5 }}
                  className={listDiscussion.textTitle}
                >
                  {t('discussion_overall_ranking')}
                </Text>
                <Text className={listDiscussion.textSubTitle}>
                  {row.overall_rank ? row.overall_rank : 'N/A'}
                </Text>
              </CardRow>
              <CardRow transparent className={listDiscussion.cardSeparator}>
                <Text
                  bold={true}
                  style={{ marginTop: 5 }}
                  className={listDiscussion.textTitle}
                >
                  {t('discussion_key_ranking')}
                </Text>
                <Text className={listDiscussion.textSubTitle}>
                  {row.key_rank ? row.key_rank : 'N/A'}
                </Text>
              </CardRow>
              <CardRow transparent className={listDiscussion.cardSeparator}>
                <Text
                  bold={true}
                  style={{ marginTop: 5 }}
                  className={listDiscussion.textTitle}
                >
                  {t('common_batch')}
                </Text>
                {showSampleDetails ? (
                  <Text className={listDiscussion.textSubTitle}>
                    {row.str_batch ? row.str_batch : 'N/A'}
                  </Text>
                ) : (
                  <img src={shade} className={listDiscussion.detailImg}></img>
                )}
              </CardRow>
              <CardRow transparent className={listDiscussion.cardSeparator}>
                <Text
                  bold={true}
                  style={{ marginTop: 5 }}
                  className={listDiscussion.textTitle}
                >
                  {t('common_score')}
                </Text>
                <Text className={listDiscussion.textSubTitle}>
                  {row.flt_average_score
                    ? row.flt_average_score.toFixed(2)
                    : 'N/A'}
                </Text>
              </CardRow>
              {(tasteValues.uuid_round_type ===
                '43b11c76-043c-11eb-b18d-026fdc1cc8f2' ||
                tasteValues.uuid_round_type ===
                'b8082a00-e1d1-11ec-97a3-000000000000') && (
                  <CardRow transparent className={listDiscussion.cardSeparator}>
                    <Text
                      bold={true}
                      style={{ marginTop: 5 }}
                      className={listDiscussion.textTitle}
                    >
                      {t('common_temperature')}
                    </Text>
                    <Text className={listDiscussion.textSubTitle}>
                      {row.str_temperature ? row.str_temperature : 'N/A'}
                    </Text>
                  </CardRow>
                )}
              <CardRow transparent className={listDiscussion.cardSeparator}>
                <Text bold={true} className={listDiscussion.textTitle}>
                  {t('sample_production_date')}
                </Text>
                {showSampleDetails ? (
                  <Text className={listDiscussion.textSubTitle}>
                    {row.dat_sample_production
                      ? dateTime(row.dat_sample_production)
                      : 'N/A'}
                  </Text>
                ) : (
                  <img src={shade} className={listDiscussion.detailImg}></img>
                )}
              </CardRow>
              {row.uuid_product_type ===
                'e0a056e4-05ba-11eb-b946-02be69c96cde' && (
                  <CardRow transparent className={listDiscussion.cardSeparator}>
                    <Text
                      bold={true}
                      style={{ marginTop: 5 }}
                      className={listDiscussion.textTitle}
                    >
                      {t('sample_malt_varieties')}
                    </Text>
                    {showSampleDetails ? (
                      row.str_malt_varieties ? (
                        <>
                          <Text className={listDiscussion.textSubTitleVarieties}>
                            {row.str_malt_varieties.map((item, i) => {
                              return <Text key={item + i}>{`· ${item}\n`}</Text>;
                            })}
                          </Text>
                        </>
                      ) : (
                        'N/A'
                      )
                    ) : (
                      <img src={shade} className={listDiscussion.detailImg}></img>
                    )}
                  </CardRow>
                )}
            </CardColumn>
            <CardColumn>
              <CardRow transparent className={listDiscussion.cardSeparator}>
                <Text bold={true} className={listDiscussion.textTitle}>
                  {t('common_brewery')}
                </Text>
                {showSampleDetails ? (
                  <Text className={listDiscussion.textSubTitle}>
                    {row.str_site_name ? row.str_site_name : 'N/A'}
                  </Text>
                ) : (
                  <img src={shade} className={listDiscussion.detailImg}></img>
                )}
              </CardRow>
              <CardRow transparent className={listDiscussion.cardSeparator}>
                <Text
                  bold={true}
                  style={{ marginTop: 5 }}
                  className={listDiscussion.textTitle}
                >
                  {t('common_brand')}
                </Text>
                {showSampleDetails ? (
                  <Text className={listDiscussion.textSubTitle}>
                    {row.str_brand_name ? row.str_brand_name : 'N/A'}
                  </Text>
                ) : (
                  <img src={shade} className={listDiscussion.detailImg}></img>
                )}
              </CardRow>
              <CardRow transparent className={listDiscussion.cardSeparator}>
                <Text
                  bold={true}
                  style={{ marginTop: 5 }}
                  className={listDiscussion.textTitle}
                >
                  {t('common_trial_identifier')}
                </Text>
                {showSampleDetails ? (
                  <Text className={listDiscussion.textSubTitle}>
                    {row.str_trial_identifier
                      ? row.str_trial_identifier
                      : 'N/A'}
                  </Text>
                ) : (
                  <img src={shade} className={listDiscussion.detailImg}></img>
                )}
              </CardRow>
              <CardRow transparent className={listDiscussion.cardSeparator}>
                <Text
                  bold={true}
                  style={{ marginTop: 5 }}
                  className={listDiscussion.textTitle}
                >
                  {t('common_key_score')}
                </Text>
                <Text className={listDiscussion.textSubTitle}>
                  {row.flt_average_score_key
                    ? row.flt_average_score_key.toFixed(2)
                    : 'N/A'}
                </Text>
              </CardRow>
              {(tasteValues.uuid_round_type ===
                '43b11c76-043c-11eb-b18d-026fdc1cc8f2' ||
                tasteValues.uuid_round_type ===
                'b8082a00-e1d1-11ec-97a3-000000000000') && (
                  <CardRow transparent className={listDiscussion.cardSeparator}>
                    <Text
                      bold={true}
                      style={{ marginTop: 5 }}
                      className={listDiscussion.textTitle}
                    >
                      {t('common_storage_times')}
                    </Text>
                    {row.str_storage_time_days && row.str_storage_time_weeks ? (
                      <>
                        <Text className={listDiscussion.textSubTitle}>
                          {row.str_storage_time_days
                            ? row.str_storage_time_days + ` days /`
                            : ''}
                        </Text>
                        <Text className={listDiscussion.textSubTitle}>
                          {row.str_storage_time_weeks
                            ? row.str_storage_time_weeks + ` weeks`
                            : ''}
                        </Text>
                      </>
                    ) : (
                      'N/A'
                    )}
                  </CardRow>
                )}
            </CardColumn>
          </CardRow>
          {tasteValues.arr_samples.filter(
            sample => sample.uuid_sample === row.uuid_sample,
          ).length !== 0 &&
            tasteValues.arr_samples.filter(
              sample => sample.uuid_sample === row.uuid_sample,
            )[0]?.str_zip_documents !== null && (
              <CardRow transparent className={listDiscussion.cardSeparator}>
                <Button
                  label={t('common_download_files')}
                  onClick={() =>
                    handleShowDownloadSample(showModalDownloadSample, j)
                  }
                  style={{
                    borderRadius: '5px',
                    backgroundColor: '#F49B00',
                    color: '#fff',
                    fontWeight: 'bold',
                    margin: '0px auto',
                  }}
                />
              </CardRow>
            )}
        </CardColumn>
      </td>
    );
  };

  const renderRankCollumns = row => {
    return _.sortBy(tasters, 'str_user_name').map((taster, i) => {
      let tasterCommentCheck = row.tasters.find(
        tasterRow => tasterRow.uuid_user === taster.uuid_user,
      );

      return (
        <td key={i}>
          <div key={taster.uuid_user} className={listDiscussion.contentCard}>
            <CardColumn
              className={listDiscussion.cardTaster}
              style={{
                backgroundColor: tasterCommentCheck?.str_color
                  ? tasterCommentCheck.str_color
                  : '#DEDEDE',
              }}
            >
              <CardRow transparent>
                <CardColumn>
                  <CardRow transparent style={{ justifyContent: 'center' }}>
                    <Text className={listDiscussion.textScore}>
                      {tasterCommentCheck?.flt_score &&
                        tasterCommentCheck?.flt_score > 0
                        ? parseFloat(tasterCommentCheck.flt_score) + ' '
                        : 'N/A'}
                      {user.bol_key_taster &&
                        tasterCommentCheck?.bol_ranked === 1 ? (
                        <select
                          className={listDiscussion.rank}
                          value={
                            tasterCommentCheck?.int_rank
                              ? tasterCommentCheck?.int_rank
                              : '-'
                          }
                          onChange={value => {
                            handleValidateRank(
                              tasterCommentCheck,
                              value.target.value,
                            );
                          }}
                          name="int_rank"
                          id="int_rank"
                        >
                          <option key="int_rank_null_" value={null}>
                            -
                          </option>
                          {Array.from({ length: rows.length }, (v, i) => (
                            <option key={'int_rank_' + (i + 1)} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </select>
                      ) : (
                        tasterCommentCheck?.flt_score &&
                        tasterCommentCheck?.flt_score > 0 &&
                        tasterCommentCheck.bol_ranked === 1 &&
                        (tasterCommentCheck?.int_rank
                          ? ` (${tasterCommentCheck.int_rank})`
                          : '(-)')
                      )}
                    </Text>

                    {isRoundApprove ? (
                      <Text
                        style={{
                          marginLeft: '2px',
                          backgroundColor:
                            tasterCommentCheck?.bol_user_approve_reject === 0
                              ? '#ba182c'
                              : '#95C511',
                          borderRadius: 20,
                          width: 20,
                          height: 20,
                          textAlign: 'center',
                          fontWeight: '700',
                          color: '#fff',
                        }}
                      >
                        {tasterCommentCheck?.bol_user_approve_reject === 0
                          ? `R`
                          : `A`}
                      </Text>
                    ) : (
                      <></>
                    )}
                  </CardRow>
                </CardColumn>
                {renderEditButton(
                  tasterCommentCheck
                    ? tasterCommentCheck
                    : { uuid_user: taster.uuid_user, uuid_round: row.uuid_round },
                  row,
                  taster,
                )}
              </CardRow>
              <div
                onClick={evt => handleDiscussionDetail(row, evt)}
                className={listDiscussion.click}
              >
                {tasterCommentCheck?.arr_comments?.length > 0 &&
                  tasterCommentCheck.arr_comments?.length !== 0 ? (
                  tasterCommentCheck.arr_comments?.map((comment, j) => {
                    return (
                      <CardRow
                        key={comment.uuid_comment}
                        transparent
                        justifyContent="center"
                      >
                        <Text className={listDiscussion.textComments}>
                          {comment.str_degree ? comment.str_degree + ' - ' : ''}
                          {comment.str_comment}
                        </Text>
                      </CardRow>
                    );
                  })
                ) : (
                  <CardRow transparent justifyContent="center">
                    <Text className={listDiscussion.textComments}>
                      {t('common_no_comments')}
                    </Text>
                  </CardRow>
                )}
              </div>
            </CardColumn>
          </div>
        </td>
      );
    });
  };

  const renderModalDownloadSamples = (row, j) => {
    return (
      <Modal show={showModalDownloadSample === j}>
        <React.Fragment>
          <div
            style={{
              position: 'relative',
              width: '100%',
              padding: '10px 15px',
              justifyContent: 'flex-end',
              display: 'flex',
            }}
          >
            <button
              style={{
                cursor: 'pointer',
                height: '35px',
                width: '35px',
                color: '#fff',
                border: '1px solid',
                borderRadius: '4px',
                fontSize: '18px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#666666',
              }}
              onClick={() =>
                handleShowDownloadSample(showModalDownloadSample, j)
              }
            >
              <X />
            </button>
          </div>
          <CardRow>
            <div
              style={{
                position: 'relative',
                width: '100%',
                padding: '10px 15px',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <h1>{`${t('common_download_files')} Sample`}</h1>
            </div>
          </CardRow>
          <CardColumn>
            {tasteValues.arr_samples.filter(
              sample => sample.uuid_sample === row.uuid_sample,
            )[0]?.str_zip_documents !== null
              ? tasteValues.arr_samples
                .filter(sample => sample.uuid_sample === row.uuid_sample)[0]
                ?.str_zip_documents.map((doc, i) => (
                  <div
                    key={doc.str_zip_document}
                    style={{ display: 'flex' }}
                  >
                    <div style={{ margin: '8px 8px' }}>
                      {displayImage(doc, i)}
                    </div>
                    <ButtonDownload
                      name="str_zip_documents"
                      src={doc.str_zip_document}
                      margin="5px 5px"
                      size={40}
                      width="60px"
                    />
                    <span
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        marginRight: '10px',
                      }}
                    >
                      {doc.str_zip_document}
                    </span>
                  </div>
                ))
              : null}
          </CardColumn>
        </React.Fragment>
      </Modal>
    );
  };
  return (
    <Fragment>
      {loading && tasteValues === undefined ? (
        <CardRow transparent justifyContent="center">
          <Loading loading={loading} />
        </CardRow>
      ) : (
        <>
          <CardRow transparent justifyContent="center">
            <CardColumn
              className={listDiscussion.header}
              style={{ justifyContent: 'flex-start' }}
            >
              <Button
                label={''}
                onClick={() => handleBack()}
                style={{
                  borderRadius: '40px',
                  backgroundColor: '#F49B00',
                  color: '#fff',
                  fontWeight: 'bold',
                  width: '40px',
                  height: '40px',
                  padding: '4px 0px 0px 0px',
                }}

              >
                <ArrowLeft />
              </Button>

              {!sessionStorage.getItem('isShowButton') && !user.nonABI ? (
                <RoundUpAndDown round={location.state.round ?? location.state} />
              ) : (
                ''
              )}
            </CardColumn>
            <CardColumn
              className={listDiscussion.header}
              style={{ justifyContent: 'flex-end' }}
            >
              {tasteValues?.str_zip_documents ? (
                <Button
                  label={t('common_download_files')}
                  onClick={() => handleShowDownload(showModalDownload)}
                  style={{
                    borderRadius: '5px',
                    backgroundColor: '#F49B00',
                    color: '#fff',
                    fontWeight: 'bold',
                    marginRight: '5px',
                  }}
                />
              ) : null}
              <Modal show={showModalDownload}>
                <React.Fragment>
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      padding: '10px 15px',
                      justifyContent: 'flex-end',
                      display: 'flex',
                    }}
                  >
                    <button
                      style={{
                        cursor: 'pointer',
                        height: '35px',
                        width: '35px',
                        color: '#fff',
                        border: '1px solid',
                        borderRadius: '4px',
                        fontSize: '18px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#666666',
                      }}
                      onClick={() => handleShowDownload(showModalDownload)}
                    >
                      <X />
                    </button>
                  </div>
                  <CardRow>
                    <div
                      style={{
                        position: 'relative',
                        width: '100%',
                        padding: '10px 15px',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <h1>{`${t('common_download_files')} Round`}</h1>
                    </div>
                  </CardRow>
                  <CardColumn>
                    {tasteValues?.str_zip_documents
                      ? tasteValues?.str_zip_documents.map((doc, i) => (
                        <div style={{ display: 'flex' }}>
                          <div style={{ margin: '8px 8px' }}>
                            {displayImage(doc, i)}
                          </div>
                          <ButtonDownload
                            name="str_zip_documents"
                            src={doc.str_zip_document}
                            margin="5px 5px"
                            size={40}
                            width="60px"
                          />
                          <span
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              marginRight: '10px',
                            }}
                          >
                            {doc.str_zip_document}
                          </span>
                        </div>
                      ))
                      : null}
                  </CardColumn>
                </React.Fragment>
              </Modal>
              <Button
                label={t('interaction_log')}
                onClick={() => handleInteractionLog()}
                confirm
                className={listDiscussion.confirmButton}
                style={{
                  borderRadius: '5px',
                  color: '#fff',
                  fontWeight: 'bold',
                  width: '150px',
                  marginRight: '5px',
                }}
              />
              <Button
                label={t('round_report')}
                onClick={() => handleDownload()}
                readOnly={!showSampleDetails}
                className={listDiscussion.confirmButton}
                confirm
              />
              <Button
                label={t('group_close_modal')}
                onClick={() => history.replace('/')}
                style={{
                  color: '#fff',
                  background: '#05A463',
                  borderRadius: '5px',
                  fontWeight: 'bold',
                  margin: '5px',
                }}
              ></Button>
            </CardColumn>
          </CardRow>
          <CardRow transparent>
            <h1 className="listDiscussion.roundName">{`${tasteValues.int_round
              } ${tasteValues.str_name_round !== null &&
                tasteValues.str_name_round !== undefined
                ? t('common_name') + ':' + tasteValues.str_name_round
                : ''
              }`}</h1>
          </CardRow>
          <div className={listDiscussion.itemListing}>
            <table className={listDiscussion.listTable}>
              {renderHeader()}
              <tbody>
                {_.orderBy(rowsFormatted, order === 'int_order_app' ? ['str_split_round_order', order] : order, ASC_DESC[order]).map(
                  (row, j) => {
                    return (
                      <tr key={j}>
                        {renderRenderFirstCollum(row, j)}
                        {renderRankCollumns(row)}
                        {renderModalDownloadSamples(row, j)}
                      </tr>
                    );
                  },
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
      <NotificationBar
        ref={nottificationRef}
        right="5px"
        notificationArr={notificationArr}
        setNotificationArr={row => {
          setNotificationArr([...row]);
        }}
      />
    </Fragment>
  );
};

export default ListDiscussion;
