import React, { useEffect, useRef, useState } from 'react';
import {
  CardColumn,
  CardRow,
  Button,
  Label,
  RoundUpAndDown,
  CountDown,
  IntensityScale,
} from '../../../components';
import { useHistory } from 'react-router-dom';
import TastingFlavorSessionStyle from './TastingFlavorSession.module.scss';
import configs from '../../../configs';
import RoundAppService from '../../services/rounds/roundApp.service';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, HelpCircle } from 'react-feather';
import uuid from 'react-uuid';
import SampleAppService from '../../services/samples/sampleApp.service';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import PinyinMatch from 'pinyin-match';
import { timeRemaining } from '../../../utils/timeRemaining';
import UserService from '../../services/users/user.service';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import { useUser } from '../../../contexts/User';
import moment from 'moment';
import nullFlavor from '../../../assets/Null_Flavor.png';
import { GetSocket } from '../../../utils/socketIo';
const fuzzysort = require('fuzzysort');

const TastingFlavorSession = props => {
  const { t } = useTranslation();
  const roundAppService = new RoundAppService();
  const history = useHistory();
  const [show, setshow] = useState(false);
  const [flovarcard, setflovarcard] = useState(false);
  const [i, setI] = useState();
  const [ListCode, setListCode] = useState([]);
  const [flaovrlist, setFlaovrlist] = useState([]);
  const [code, setCode] = useState();
  const [confirm2, setConfirm2] = useState(false);
  const [bol_finished, setbol_finished] = useState(
    props.location.state.round.bol_finished,
  );
  const [round, setRound] = useState(props.location.state.round);
  const [commentShow, setCommentShow] = useState(false);
  const [degrees, setDegrees] = useState([]);
  const [attrs, setAttrs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [goBack, setGoBack] = useState(false);
  const [results, setResults] = useState(false);
  const timeNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  const [searchArr, setSearchArr] = useState([]);
  const { user } = useUser();

  useEffect(() => {
    if (!bol_finished) {
      roundAppService
        .listRoundFlavor({
          uuid_round_type: round.uuid_round_type,
          uuid_round: round.uuid_round,
          uuid_site: round.uuid_round_site,
        })
        .then(res => {
          if (res.type === 'success') {
            setFlaovrlist(res.results);
          }
        });
    }
    if (sessionStorage.getItem('bol_finished')) {
      setbol_finished(sessionStorage.getItem('bol_finished'));
    }
    roundAppService
      .getFlavorCode({ uuid_round: round.uuid_round })
      .then(res => {
        if (
          res.type === 'success' &&
          res.results[0].round_sp_se_flavors_identification_current_code
        ) {
          setCode(
            res.results[0].round_sp_se_flavors_identification_current_code,
          );
          refreshState(
            res.results[0].round_sp_se_flavors_identification_current_code,
          );
        }
      });
    roundAppService.listDegrees().then(res => {
      setDegrees(res);
    });
    roundAppService.listComments().then(res => {
      const reduceArr = res.reduce((accumulator, currentValue) => {
        const existingObject = accumulator.find(
          obj => obj.uuid_comment === currentValue.uuid_comment,
        );
        if (!existingObject) {
          accumulator.push(currentValue);
        }
        return accumulator;
      }, []);
      setAttrs(reduceArr);
    });
    roundAppService.listCommentsCategory().then(res => {
      setCategories(res);
    });
    return () => {
      sessionStorage.removeItem('bol_finished');
    };
  }, []);

  useEffect(() => {
    setSearchArr(flaovrlist || []);
  }, [flaovrlist]);

  useEffect(() => {
    let timer = setTimeout(() => {
      GetSocket().on('updateFinishDate', function (res) {
        if (window.location.pathname === '/tasting_flavor_session') {
          const nowRound = props.location.state.round.uuid_round === res.uuid_round;
          if (nowRound) {
            setRound({ ...props.location.state.round, ...res });
            history.replace({
              pathname: `/tasting_flavor_session`,
              state: { round: { ...props.location.state.round, ...res } },
            });
          }
        }
      });
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);

  const refreshState = code => {
    roundAppService
      .flavorlistCode({
        uuid_round: round.uuid_round,
        code: code,
      })
      .then(res => {
        if (res.type === 'success') {
          setListCode(res.results);
        }
      });
  };

  const down = index => {
    setshow(true);
    setI(index);
  };

  const up = index => {
    setshow(false);
    setI(index);
  };

  const answers = index => {
    setshow(true);
    setI(index);
    setflovarcard(true);
  };

  const select = (item, index) => {
    var obj = {
      uuid_round_flavors_identification_code:
        ListCode[index].uuid_round_flavors_identification_code,
      uuid_flavor: item.uuid_flavor,
    };
    roundAppService.flavorSaveAnswer(obj).then(res => {
      if (res.type === 'success') {
        refreshState(code);
      }
    });
    setshow(true);
    setflovarcard(false);
  };

  const save = () => {
    roundAppService
      .updateFinish({ uuid_round: round.uuid_round }, timeNow)
      .then(res => {
        if (res.type === 'success') {
          setbol_finished(true);
          sessionStorage.setItem('bol_finished', true);
          setshow(false);
          setflovarcard(false);
        }
      });
    setConfirm2(false);
  };

  const Confirmation = () => {
    return (
      <div className={TastingFlavorSessionStyle.Confirmation}>
        <div className={TastingFlavorSessionStyle.Confirm}>
          <h2>{t('confirmation')}</h2>
          <p style={{ color: '#333', margin: '20px 0' }}>
            {t('do_you_confirm_the_evaluation_of_this_sample')}
          </p>
          <div>
            <Button
              className={TastingFlavorSessionStyle.common_cancel}
              label={t('common_cancel')}
              onClick={() => setConfirm2(false)}
            ></Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              className={TastingFlavorSessionStyle.confirm}
              label={t('confirm')}
              onClick={() => save()}
            ></Button>
          </div>
        </div>
      </div>
    );
  };

  const comment = index => {
    setI(index);
    setCommentShow(true);
  };

  const handleBack = () => {
    history.replace('/');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <CardColumn
        className={TastingFlavorSessionStyle.header}
        style={{ justifyContent: 'flex-start' }}
      >
        <Button
          label={''}
          onClick={() => handleBack()}
          className={TastingFlavorSessionStyle.goback}
        >
          <ArrowLeft />
        </Button>
        {
          !user.nonABI &&
          <RoundUpAndDown
            round={props.location.state.round}
            setGoBack={setGoBack}
          />
        }
      </CardColumn>
      {confirm2 && Confirmation()}
      {flovarcard && (
        <div
          className={TastingFlavorSessionStyle.masks}
          onClick={e =>
            e.target.className.indexOf('masks') > -1 && setflovarcard(false)
          }
        >
          <CardRow className={TastingFlavorSessionStyle.maskCardRow}>
            <div className={TastingFlavorSessionStyle.searchDiv}>
              <input
                className={TastingFlavorSessionStyle.searchInput}
                placeholder={t('Search')}
                type="text"
                onChange={e => {
                  setSearchArr(
                    flaovrlist.filter(
                      el =>
                        el.str_name
                          .toLowerCase()
                          .indexOf(e.target.value.toLowerCase()) > -1,
                    ),
                  );
                }}
              />
            </div>
            <div
              className={'iconfont ' + TastingFlavorSessionStyle.off}
              onClick={() => setflovarcard(false)}
            >
              &#xe61d;
            </div>
            <ul className={TastingFlavorSessionStyle.ul}>
              {searchArr?.map((item, index1) => {
                return (
                  <li
                    key={index1}
                    className={TastingFlavorSessionStyle.flaovrlist}
                    onClick={() => select(item, i)}
                  >
                    <img
                      src={
                        item.str_image
                          ? `${configs.file_api_url}/v1/flavors/${item.str_image}`
                          : nullFlavor
                      }
                      className={TastingFlavorSessionStyle.img2}
                    />
                    <p style={{ marginLeft: '15px' }}>{item.str_name}</p>
                  </li>
                );
              })}
              {/* {flaovrlistCard()} */}
            </ul>
          </CardRow>
        </div>
      )}
      <CardRow className={TastingFlavorSessionStyle.headers}>
        <CardColumn>
          {bol_finished ? (
            <h2 className={TastingFlavorSessionStyle.title}>
              {t('common_discussion')}
            </h2>
          ) : (
            <h2 className={TastingFlavorSessionStyle.title}>
              {t('menu_tasting_session')}
            </h2>
          )}

          <h2 className={TastingFlavorSessionStyle.code}>
            {t('code')}{' '}
            {code < 10
              ? '00' + code
              : code >= 10 && code < 100
                ? '0' + code
                : code}
          </h2>
        </CardColumn>
        <CardColumn className={TastingFlavorSessionStyle.center}>
          {round.bol_is_countdown &&
            bol_finished &&
            timeRemaining(round.dat_finish) > 0 ? (
            <div>
              {
                <span className={'iconfont ' + TastingFlavorSessionStyle.alert}>
                  &#xe6e4;
                </span>
              }
              {t('check_the_answer_at_the_end_of_the_countdown')}
            </div>
          ) : null}
        </CardColumn>

        <CardColumn className={TastingFlavorSessionStyle.right}>
          {round.bol_is_countdown ? (
            <CountDown
              round={round}
              setRound={setRound}
              save={save}
              setshow={setResults}
            />
          ) : null}
          <Button
            className={TastingFlavorSessionStyle.tasting_finish_taste}
            label={
              bol_finished || sessionStorage.getItem('bol_finished')
                ? t('group_close_modal')
                : t('tasting_finish_taste')
            }
            onClick={() =>
              bol_finished || sessionStorage.getItem('bol_finished')
                ? history.replace('/')
                : setConfirm2(true)
            }
          ></Button>
        </CardColumn>
      </CardRow>
      <CardRow className={TastingFlavorSessionStyle.int_round}>
        {round.int_round}
      </CardRow>
      <CardRow className={TastingFlavorSessionStyle.card}>
        {ListCode.map((item, index) => {
          return (
            <div>
              <CardColumn
                key={index}
                className={TastingFlavorSessionStyle.ListCodeCardColumn}
                style={{
                  height: index === i && show ? '370px' : '320px',
                }}
              >
                <ul style={{ position: 'relative' }}>
                  <li className={TastingFlavorSessionStyle.img}>
                    <img
                      src={`${configs.file_api_url}/v1/${item.str_img_brand}`}
                      alt=""
                      style={{ width: '48px', height: '48px' }}
                    />
                    <span>
                      {index + 1} - {item.str_brand_name}
                    </span>
                  </li>
                  <li className={TastingFlavorSessionStyle.tasting}>
                    <span>{t('tasting')}</span>
                    {show && index === i ? (
                      <span
                        className={'iconfont ' + TastingFlavorSessionStyle.updown}
                        onClick={() => up(index)}
                      >
                        &#xe62c;
                      </span>
                    ) : (
                      <span
                        className={'iconfont ' + TastingFlavorSessionStyle.updown}
                        onClick={() => down(index)}
                      >
                        &#xe62d;
                      </span>
                    )}
                  </li>
                  <li style={{ margin: '10px 0 0 10px', height: '22px' }}>
                    {item.str_answer_name &&
                      (item.str_answer_name.length > 38 ? (
                        <span className={TastingFlavorSessionStyle.valueLarge}>
                          {item.str_answer_name}
                        </span>
                      ) : (
                        <span className={TastingFlavorSessionStyle.value}>
                          {item.str_answer_name}
                        </span>
                      ))}
                  </li>
                  {item.arr_comments?.length > 0 ? (
                    <li className={TastingFlavorSessionStyle.answer}>
                      {t('common_comments')}
                    </li>
                  ) : (
                    <li className={TastingFlavorSessionStyle.answer}></li>
                  )}
                  {item.arr_comments?.length > 0 ? (
                    <li className={TastingFlavorSessionStyle.commentList}>
                      {item.arr_comments?.map((item, ii) => {
                        return item.str_comment || item.str_comment_name ? (
                          <button
                            key={ii}
                            className={TastingFlavorSessionStyle.ShowComments}
                            style={{
                              background: item.uuid_comment
                                ? 'rgb(244, 156, 45)'
                                : 'rgb(149, 197, 17)',
                            }}
                          >
                            {item.str_degree_name
                              ? item.str_degree_name + ' - '
                              : ''}
                            {item.str_comment_name || item.str_comment}
                          </button>
                        ) : (
                          ''
                        );
                      })}
                    </li>
                  ) : (
                    <li className={TastingFlavorSessionStyle.commentList}></li>
                  )}
                  <li className={TastingFlavorSessionStyle.Button}>
                    <Button
                      label={t('evaluate')}
                      disabled={bol_finished}
                      onClick={() => answers(index)}
                      className={TastingFlavorSessionStyle.evaluate}
                      style={{
                        background:
                          index === i || bol_finished ? '#94A5BB' : '#F6AD55',
                      }}
                    ></Button>
                  </li>
                  <li className={TastingFlavorSessionStyle.Button1}>
                    <Button
                      label={t('common_comments')}
                      disabled={bol_finished}
                      style={{
                        background:
                          index === i || bol_finished ? '#94A5BB' : '#2490F1',
                      }}
                      className={TastingFlavorSessionStyle.evaluate}
                      onClick={() => comment(index)}
                    ></Button>
                  </li>
                </ul>
              </CardColumn>
              {round.bol_is_countdown ? (
                timeRemaining(round.dat_finish) <= 0 || results ? (
                  <Results item={item} index={index} ></Results>
                ) : null
              ) : bol_finished ? (
                <Results item={item} index={index} ></Results>
              ) : null}
            </div>
          );
        })}
      </CardRow>

      {commentShow &&
        ListCode.map((item, index4) => {
          return (
            i === index4 && (
              <Comments
                key={index4}
                index={i}
                comments={item?.arr_comments}
                item={item}
                ListCode={ListCode}
                degrees={degrees}
                attrs={attrs}
                categories={categories}
                setListCode={setListCode}
                refreshState={refreshState}
                setCommentShow={setCommentShow}
                code={code}
              ></Comments>
            )
          );
        })}
    </div>
  );
};

const Results = ({ item, index }) => {
  const { t } = useTranslation();
  return (
    <CardRow className={TastingFlavorSessionStyle.ResultsCardRow}>
      {
        item.bol_tvs && item.uuid_flavor == null ? (
          <CardColumn
            key={index}
            className={TastingFlavorSessionStyle.ResultsCardColumn}
            style={{
              opacity: 0,
            }}
          ></CardColumn>
        ) : (
          <CardColumn
            key={index}
            className={TastingFlavorSessionStyle.ResultsCardColumn}
          >
            <h3 className={TastingFlavorSessionStyle.sanjiao}></h3>
            {item.str_answer_name === item.str_flavor_name ? (
              <p className={'iconfont ' + TastingFlavorSessionStyle.dui}>
                &#xe63f;
              </p>
            ) : (
              <p className={'iconfont ' + TastingFlavorSessionStyle.cuo}>
                &#xe61d;
              </p>
            )}
            <p className={TastingFlavorSessionStyle.p1}>
              {t('correct_answer')}:
            </p>
            <p
              className={TastingFlavorSessionStyle.p2}
              style={{ whiteSpace: 'wrap' }}
            >
              {item.str_flavor_name}
            </p>
            <p className={TastingFlavorSessionStyle.p1}>{t('threshold')}:</p>
            <p className={TastingFlavorSessionStyle.p2}>
              {item.threshold || item.threshold === 0
                ? item.threshold + 'X'
                : ''}
            </p>
            <p className={TastingFlavorSessionStyle.p1}>
              {t('concentration')}:
            </p>
            <p className={TastingFlavorSessionStyle.p2}>
              {item.concentration || item.concentration === 0
                ? item.concentration + ' ' + item.str_unit_name
                : ''}
            </p>
          </CardColumn>
        )
      }
    </CardRow>
  );
};

function Comments (props) {
  const { t } = useTranslation();
  const sampleAppService = new SampleAppService();
  const [commentsList, setcommentsList] = useState(
    props.item.arr_comments ? props.item.arr_comments : [],
  );
  const [comment, setComment] = useState({});
  const [attrs, setAttrs] = useState([]);
  const [nextAttrs, setNextAttrs] = useState([]);
  const [searchIntensitylist, setSearchIntensitylist] = useState([]);
  const [active, setActive] = useState({
    Intensity: null,
    AttrTitle: null,
    AttrClass: null,
  });
  const [searchData, setSearchData] = useState('');

  const common_intensity = useRef(null);
  const common_attributes = useRef(null);
  const [isContinuous, setIsContinuous] = useState(false);
  const userService = new UserService();
  const { user } = useUser();

  useEffect(() => {
    alphabetical();
    userService
      .getUserContinuousTyping()
      .then(res => {
        if (res.results.length == 0) {
          setIsContinuous(false);
        } else {
          setIsContinuous(res.results[0].bol_continuous_type);
        }
      })
      .catch(error => { });
  }, []);

  const categoryList = () => {
    setAttrs(props.categories.categories);
    const attr = props.categories?.categories.filter(item => {
      return item.label === 'Flavor/Aroma';
    });
    nextCategoryList(
      attr.length === 0 ? props.categories.categories[0] : attr[0],
    );
    setActive(prevState => {
      return { ...prevState, AttrTitle: 'left' };
    });
  };

  const alphabetical = () => {
    setAttrs(props.categories.aphabe);
    const arr = props.categories?.aphabe.filter(item => {
      return item.label === 'All';
    });
    nextCategoryList(arr.length === 0 ? props.categories.aphabe[0] : arr[0]);
    setActive(prevState => {
      return { ...prevState, AttrTitle: 'right' };
    });
  };

  const nextCategoryList = arr => {
    let filterComments = [];
    if (arr.value.length <= 10) {
      if (arr?.label === 'All') {
        filterComments = props.attrs;
      } else {
        if (user.str_language === 'zh') {
          filterComments = props.attrs.filter(
            item =>
              PinyinMatch.match(item.str_name, arr.value).length > 0 &&
              PinyinMatch.match(item.str_name, arr.value)[0] === 0,
          );
        } else {
          filterComments = props.attrs.filter(item => {
            return (
              item.str_name.slice(0, 1).toUpperCase() ===
              arr.label.toUpperCase()
            );
          });
        }
      }
      setActive(prevState => {
        return { ...prevState, AttrClass: arr?.label };
      });
    } else {
      filterComments = props.attrs.filter(item => {
        return (
          item.uuid_category === arr.value &&
          item.str_name_comments_categories === arr.label
        );
      });
      setActive(prevState => {
        return { ...prevState, AttrClass: arr?.label };
      });
    }
    const orderedComments = filterComments.sort((a, b) =>
      a.str_name.localeCompare(b.str_name),
    );

    const map = new Map();
    for (const item of orderedComments) {
      if (!map.has(item.str_name) && !map.has(item.uuid_comment)) {
        map.set(item.str_name, item);
      }
    }
    setNextAttrs([...map.values()]);
  };

  const saveComments = obj => {
    setComment({});
    setSearchData('');
    clear();
    setcommentsList(prevState => {
      return [...prevState, obj];
    });
    sampleAppService.flavorSaveComments(obj);
  };

  const handleTypingChange = () => {
    setIsContinuous(!isContinuous);
    userService.updateUserContinuousTyping({
      bol_continuous_type: !isContinuous,
    });
  };

  const handleRow = (dataObj, changeType) => {
    const obj = {
      uuid_degree: comment?.uuid_degree,
      uuid_comment: comment?.uuid_comment,
      str_comment_name: comment?.str_comment_name
        ? comment?.str_comment_name
        : dataObj.str_name,
      str_degree_name: comment?.str_degree_name,
      str_comment: dataObj.str_comment,
      uuid_round_flavors_identification_code_comments: '',
      uuid_round_flavors_identification_code:
        props.item.uuid_round_flavors_identification_code,
      int_order: commentsList[commentsList.length - 1]?.int_order + 1,
    };
    if (dataObj && dataObj.uuid_degree) {
      obj.uuid_degree = dataObj.uuid_degree;
      obj.str_degree_name = dataObj.str_name;
      if (
        dataObj.str_comment &&
        !commentsList.some(item => item.str_comment === dataObj.str_comment)
      ) {
        obj.uuid_round_flavors_identification_code_comments = uuid();
        saveComments(obj);
        setSearchIntensitylist([]);
      }
      if (!dataObj.str_comment) {
        setSearchIntensitylist([]);
      }
      setActive(prevState => {
        return { ...prevState, Intensity: dataObj.uuid_degree };
      });
      setComment(prevState => {
        return { ...prevState, ...obj };
      });
      if (isContinuous) {
        setTimeout(() => {
          common_attributes.current.focus();
        });
      }
    }
    if (dataObj.uuid_comment || dataObj.str_comment) {
      if (
        dataObj.str_comment
          ? !commentsList.some(item => item.str_comment === dataObj.str_comment)
          : !commentsList.some(
            item => item.uuid_comment === dataObj.uuid_comment,
          )
      ) {
        obj.uuid_comment = dataObj.uuid_comment;
        obj.str_comment_name = dataObj.str_name;
        obj.uuid_round_flavors_identification_code_comments = uuid();

        setComment(prevState => {
          return { ...prevState, ...obj };
        });
        saveComments(obj);
        if (changeType === 'click') {
          common_attributes.current && common_attributes.current.blur();
          common_intensity.current && common_intensity.current.blur();
        } else if (isContinuous) {
          setTimeout(() => {
            common_intensity.current && common_intensity.current.focus();
          });
        }
      } else {
        window.alert(t('you_can_not_add_duplicate_attributes'));
      }
    }
    setSearchData('');
    if (!isContinuous) {
      common_attributes.current && common_attributes.current.blur();
      common_intensity.current && common_intensity.current.blur();
    }
    return true;
  };

  const select = (dataObj, changeType) => {
    _.debounce(handleRow(dataObj, changeType), 770);
  };

  const delComment = id => {
    sampleAppService.flavorDelComments(id).then(res => {
      if (res.type === 'success') {
      }
    });
    setcommentsList(prevState => {
      return prevState.filter(item => {
        return item.uuid_round_flavors_identification_code_comments !== id;
      });
    });
  };

  const search = e => {
    let value = e.target.value;
    if (value) {
      let filterArr = [...props.degrees, ...props.attrs];
      let searchList = [];
      if (comment.str_degree_name) {
        filterArr = [...props.attrs];
      }

      if (
        filterArr.some(
          item => item.str_name.toUpperCase().indexOf(value.toUpperCase()) > -1,
        )
      ) {
        searchList = [
          { str_comment: value },
          ...fuzzysort
            .go(value, filterArr, { key: 'str_name' })
            .map(a => a.obj),
        ];
      } else {
        searchList = [{ str_comment: value }];
      }
      setSearchIntensitylist(searchList);
    }
    setSearchData(value);
  };
  const code13 = (e, type) => {
    const code = e.keyCode || e.which || e.charCode;
    if (code === 13) {
      select(
        searchIntensitylist.length > 1
          ? searchIntensitylist[1]
          : searchIntensitylist[0],
      );
    }
  };

  const pullDown = () => {
    return (
      <ul className={TastingFlavorSessionStyle.searchlist}>
        {searchIntensitylist.map((item, index) => {
          return (
            <li key={index} onClick={() => select(item, 'click')}>
              {item.str_name || item.str_comment}
            </li>
          );
        })}
      </ul>
    );
  };

  const clear = () => {
    setSearchIntensitylist([]);
    setSearchData('');
    setActive(prevState => {
      return { ...prevState, Intensity: null };
    });
    setComment({});
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const move = arrayMove(commentsList, oldIndex, newIndex);
    const arr = move.map((comment, index) => {
      comment = {
        uuid_round_flavors_identification_code_comments:
          comment.uuid_round_flavors_identification_code_comments,
        int_order: index,
      };
      return comment;
    });
    sampleAppService.flavorSortComments(arr).then(res => {
      if (res.type === 'success') {
      }
    });

    const newItems = move.map((comment, index) => {
      comment.int_order = index;
      return comment;
    });
    setcommentsList(newItems);
  };

  const SortableItem = SortableElement(({ comment, index }) => (
    <div key={index} className={TastingFlavorSessionStyle.SortableItem}>
      {
        <div
          className={
            comment.uuid_comment
              ? TastingFlavorSessionStyle.ButtonList
              : TastingFlavorSessionStyle.ButtonListCustom
          }
        >
          {comment.str_comment ? (
            <p>
              {comment.str_degree_name ? comment.str_degree_name + ' - ' : ''}
              {comment.str_comment}
            </p>
          ) : (
            <p>
              {comment.str_degree_name ? comment.str_degree_name + ' - ' : ''}
              {comment.str_comment_name}
            </p>
          )}
          <button
            onClick={() =>
              delComment(
                comment.uuid_round_flavors_identification_code_comments,
              )
            }
          >
            X
          </button>
        </div>
      }
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div className={TastingFlavorSessionStyle.SortableList}>
        {items.map((comment, index) => (
          <SortableItem
            //disabled
            key={index}
            comment={comment}
            index={index}
          />
        ))}
      </div>
    );
  });

  const off = () => {
    const arr = JSON.parse(JSON.stringify(props.ListCode));
    arr.forEach(item => {
      if (
        item.uuid_round_flavors_identification_code ===
        props.item.uuid_round_flavors_identification_code
      ) {
        item.arr_comments = commentsList;
      }
    });
    props.setListCode(arr);
    props.setCommentShow(false);
  };
  const stylelist = T => {
    return {
      color: active.AttrTitle === T ? '#fff' : 'rgb(113, 111, 113)',
      border: active.AttrTitle === T ? '0' : '1px solid rgb(198, 198, 198)',
      background: active.AttrTitle === T ? 'rgb(244, 156, 45)' : '#fff',
    };
  };

  return (
    <div
      className={TastingFlavorSessionStyle.comments1}
      onClick={e => e.target.className.indexOf('comments1') > -1 && off()}
    >
      <div
        className={TastingFlavorSessionStyle.comments2}
        onClick={e => e.target.className.indexOf('comments2') > -1 && off()}
      >
        <CardColumn className={TastingFlavorSessionStyle.commentsCard}>
          <div
            className={'iconfont ' + TastingFlavorSessionStyle.commentx}
            onClick={() => off()}
          >
            &#xe61d;
          </div>
          <h3 className={TastingFlavorSessionStyle.hh}>
            {t('common_comments')}
          </h3>

          <CardRow style={{ marginTop: '10px' }}>
            <CardColumn className={TastingFlavorSessionStyle.commentsContent}>
              <SortableList
                items={commentsList}
                onSortEnd={onSortEnd}
                axis="xy"
              />
              <div className={TastingFlavorSessionStyle.commentsSearch}>
                {searchIntensitylist.length > 0 && pullDown()}
                {comment.str_degree_name ? (
                  <>
                    <p>{comment.str_degree_name + ' - '}</p>

                    <input
                      ref={common_attributes}
                      placeholder={t('common_attributes')}
                      className={TastingFlavorSessionStyle.Input}
                      onChange={e => search(e)}
                      onKeyDown={e => code13(e)}
                      value={searchData}
                    />
                  </>
                ) : (
                  <input
                    ref={common_intensity}
                    placeholder={
                      (comment?.str_degree
                        ? comment.str_degree
                        : t('common_intensity')) +
                      ' - ' +
                      t('common_attributes')
                    }
                    className={TastingFlavorSessionStyle.Input}
                    onChange={e => search(e)}
                    onKeyDown={e => code13(e)}
                    value={searchData}
                  />
                )}
                <span
                  onClick={() => clear()}
                  className={'iconfont ' + TastingFlavorSessionStyle.clear}
                >
                  &#xe61d;
                </span>
              </div>
            </CardColumn>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Label
                style={{
                  whiteSpace: 'nowrap',
                }}
                label={t('continuous_typing')}
                bold
              ></Label>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 10,
                }}
              >
                <input
                  type="checkbox"
                  checked={isContinuous}
                  onChange={() => {
                    handleTypingChange();
                  }}
                  className={TastingFlavorSessionStyle.checke}
                />
                <HelpCircle
                  data-tip={t('continuous_typing_tips')}
                  style={{ color: '#B11F24', marginLeft: 5 }}
                  size="20"
                  data-for="continuous_typing_tips"
                />
                <ReactTooltip
                  id="continuous_typing_tips"
                  effect="solid"
                  place="bottom"
                  type="warning"
                  style={{ whiteSpace: 'normal' }}
                />
              </div>
            </div>
          </CardRow>
          <CardRow style={{ flexDirection: 'column' }}>
            <CardRow>
              <CardColumn>
                <IntensityScale />
              </CardColumn>
            </CardRow>
            <CardRow flexWrap="wrap">
              {props.degrees.map((item, index) => {
                return (
                  <Button
                    style={{
                      background:
                        active.Intensity === item.uuid_degree
                          ? 'rgb(244, 156, 45)'
                          : '#666',
                    }}
                    className={TastingFlavorSessionStyle.attrClassList}
                    label={item.str_name}
                    key={item.uuid_degree + index}
                    onClick={() => select(item, 'click')}
                  />
                );
              })}
            </CardRow>
          </CardRow>
          <CardRow
            style={{ flexDirection: 'column', flex: '1', overflowY: 'auto' }}
          >
            <CardRow>
              <CardColumn>
                <Label label={t('common_attributes')} bold></Label>
              </CardColumn>
            </CardRow>
            <CardRow>
              <Button
                label={t('common_comment_by_category')}
                style={stylelist('left')}
                className={TastingFlavorSessionStyle.commentsClass}
                onClick={categoryList}
              />
              <Button
                label={t('common_alphabetic_comment')}
                style={stylelist('right')}
                className={TastingFlavorSessionStyle.commentsClass}
                onClick={alphabetical}
              />
            </CardRow>
            <CardRow style={{ flexWrap: 'wrap' }}>
              {attrs.map((item, index) => {
                return (
                  <Button
                    label={item.label}
                    style={{
                      width: active.AttrTitle === 'right' ? '50px' : '120px',
                      background:
                        active.AttrClass === item.label
                          ? 'rgb(244, 156, 45)'
                          : '#666',
                    }}
                    className={TastingFlavorSessionStyle.commentsClassList}
                    key={item.value + index}
                    onClick={() => nextCategoryList(item)}
                  >
                    {active.AttrClass === item.label ? (
                      <p className={TastingFlavorSessionStyle.triangle}></p>
                    ) : null}
                  </Button>
                );
              })}
            </CardRow>
            <CardRow
              style={{
                flex: '1',
                flexWrap: 'wrap',
                alignContent: 'flex-start',
              }}
            >
              {nextAttrs.map((item, index) => {
                return (
                  <Button
                    label={item.str_name}
                    className={TastingFlavorSessionStyle.commentsButtonList}
                    key={item.uuid_comment + index}
                    onClick={() => select(item, 'click')}
                  />
                );
              })}
            </CardRow>
          </CardRow>
        </CardColumn>
      </div>
    </div>
  );
}

export default TastingFlavorSession;
