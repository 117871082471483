import React, { useState, useEffect, Fragment, useCallback } from 'react';
import tastingThreshold from './tastingThreshold.module.scss';
import { useHistory } from 'react-router-dom';
import {
  CardRow,
  CardColumn,
  Label,
  TextField,
  Spinner,
  Button,
  Modal,
  Text,
  RoundUpAndDown,
  CountDown,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import beer from '../../../assets/beer_threshold.svg';
import { useTasting } from '../../../contexts/Tasting';
import _ from 'lodash';
import { HelpCircle, X, ArrowLeft } from 'react-feather';
import ThresholdService from '../../services/threshold/threshold.service';
import RoundAppService from '../../services/rounds/roundApp.service';
import arrow from '../../../assets/arrow.svg';
import { useUser } from '../../../contexts/User';
import tuli from '../../../assets/tuli.png';
import moment from 'moment';
import { GetSocket } from '../../../utils/socketIo';
const options = ['A', 'B', 'C'];

const TastingSample = ({ location }) => {
  const history = useHistory();
  const { taste, updateFinishRound } = useTasting();
  const [blockSubmittion, setBlockSubmmition] = useState(true);
  const [vertical, setVertical] = useState(true);
  const [code, setCode] = useState(null);
  const [codeFormat, setCodeFormat] = useState();
  const { t } = useTranslation();
  const [uuidRoundsThreshold, setUuidRoundsThreshold] = useState(null);
  const [samples, setSamples] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowInfo, setModalShowInfo] = useState(false);
  const [round, setRound] = useState(location.state.round);
  const [setResults] = useState(false);
  const [isCountDownShow, setIsCountDownShow] = useState(false);
  const thresholdService = new ThresholdService();
  const roundAppService = new RoundAppService();
  const { user } = useUser();
  const timeNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  const [samplesExample, setSamplesExample] = useState([
    {
      name: 'Set 1',
      value: 'A',
      uuid_set: 'example_str_answer_set_1',
    },
    {
      name: 'Set 2',
      value: 'B',
      uuid_set: 'example_str_answer_set_2',
    },
    {
      name: 'Set 3',
      value: 'C',
      uuid_set: 'example_str_answer_set_3',
    },
    {
      name: 'Set 4',
      value: 'A',
      uuid_set: 'example_str_answer_set_4',
    },
    {
      name: 'Set 5',
      value: 'B',
      uuid_set: 'example_str_answer_set_5',
    },
    {
      name: 'Set 6',
      value: 'C',
      uuid_set: 'example_str_answer_set_6',
    },
  ]);

  useEffect(() => {
    setLoading(true);
    handleSearchTasting(null);
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      GetSocket().on('updateFinishDateForThreshold', function (res) {
        if (window.location.pathname === '/tasting_threshold') {
          const nowRound = location.state.round.uuid_round === res.uuid_round;
          if (nowRound) {
            setRound({ ...location.state.round, ...res });
            history.replace({
              pathname: `/tasting_threshold`,
              state: { round: { ...location.state.round, ...res } },
            });
          }
        }
      });
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);

  const delayedQuery = useCallback(
    _.debounce(text => {
      if (text.length > 2) {
        setLoading(true);
        setCode(text.replace(/^0+/, ''));

        let num = text.toString();
        const size = 3;

        while (num.length < size) num = '0' + num;

        setCodeFormat(num);
        handleSearchTasting(text.replace(/^0+/, ''));
      } else {
        setCodeFormat(text);
      }
    }, 10),
    [],
  );

  const handleSearchTasting = code => {
    thresholdService
      .listTasteThreshold({ uuid_round: taste.uuid_round, str_code: code })
      .then(
        async res => {
          setLoading(false);
          if (res.type === 'success' && res.length > 0) {
            if (
              res.results[0].uuid_user !== null &&
              res.results[0].uuid_user !== user.uuid_user
            ) {
              alert(t('threshold_code_already_tasted'));
              setSamples(null);
              setDisabled(false);
              return;
            }

            if (code === undefined || code === null) {
              setDisabled(true);
            }
            setUuidRoundsThreshold(res.results[0].uuid_rounds_threshold_set);
            setCode(res.results[0].str_code);
            let num = res.results[0].str_code.toString();
            const size = 3;

            while (num.length < size) num = '0' + num;

            setCodeFormat(num);
            const samplesAux = [
              {
                name: 'Set 1',
                value: res.results[0].str_answer_set_1,
                uuid_set: 'str_answer_set_1',
              },
              {
                name: 'Set 2',
                value: res.results[0].str_answer_set_2,
                uuid_set: 'str_answer_set_2',
              },
              {
                name: 'Set 3',
                value: res.results[0].str_answer_set_3,
                uuid_set: 'str_answer_set_3',
              },
              {
                name: 'Set 4',
                value: res.results[0].str_answer_set_4,
                uuid_set: 'str_answer_set_4',
              },
              {
                name: 'Set 5',
                value: res.results[0].str_answer_set_5,
                uuid_set: 'str_answer_set_5',
              },
              {
                name: 'Set 6',
                value: res.results[0].str_answer_set_6,
                uuid_set: 'str_answer_set_6',
              },
            ];
            setSamples(samplesAux);
            submitionButtonControl(samplesAux);
            if (res.results[0].str_code && round.bol_is_countdown) {
              setIsCountDownShow(true);
            } else {
              setIsCountDownShow(false);
            }
          } else {
            setSamples(null);
            setDisabled(false);
            if (code !== null && code !== undefined) {
              alert(t('threshold_coder_wrong'));
            }
          }
        },
        error => {
          setLoading(false);
          setDisabled(false);
          setSamples(null);
          console.log(error);
        },
      );
  };
  const handleSubmit = () => {
    if (round.bol_started) {
      return;
    }
    setModalShow(false);
    let sampleNum = 0;
    for (let i = 0; i < samples.length; i++) {
      if (!samples[i].value) {
        sampleNum++;
      }
    }
    if (sampleNum === 6) {
      history.push({
        pathname: '/',
      });
      return;
    }
    roundAppService.updateFinish(taste, timeNow).then(
      res => {
        updateFinishRound();
        if (res.type === 'success') {
          history.replace({
            pathname: `/tasting_discussion_threshold/${taste.uuid_round}`,
            state: {
              round: location.state.round,
            },
          });
        } else {
          window.alert(res.message);
        }
      },
      error => {
        console.log(error);
      },
    );
  };

  const handleGoDiscussion = () => {
    setModalShow(false);
    roundAppService.updateFinish(taste, timeNow).then(
      res => {
        updateFinishRound();
        if (res.type === 'success') {
          history.replace({
            pathname: `/tasting_discussion_threshold/${taste.uuid_round}`,
            state: {
              round: round,
            },
          });
        } else {
          window.alert(res.message);
        }
      },
      error => {
        console.log(error);
      },
    );
  };

  const submitionButtonControl = samples => {
    const shouldBlockSubmit = samples.some(set => set.value == null);
    setBlockSubmmition(shouldBlockSubmit);
  };

  const SamplesSets = props => {
    return (
      <div
        className={
          props.vertical
            ? tastingThreshold.samplesCategorieOptions
            : tastingThreshold.samplesCategorieOptionsHorizontal
        }
      >
        <div className={tastingThreshold.optionSet}>
          <span>{props.name}</span>
        </div>
        {options.map(option => (
          <div
            className={
              props.value === option
                ? tastingThreshold.optionSelect
                : tastingThreshold.optionUnselect
            }
            onClick={() => {
              if (props.clickable) {
                setDisabled(true);

                thresholdService
                  .listTasteThreshold({
                    uuid_round: taste.uuid_round,
                    str_code: code,
                  })
                  .then(
                    res => {
                      setLoading(false);
                      if (res.type === 'success' && res.length > 0) {
                        if (
                          res.results[0].uuid_user !== null &&
                          res.results[0].uuid_user !== user.uuid_user
                        ) {
                          alert(t('threshold_code_already_tasted'));
                          setSamples(null);
                          setDisabled(false);
                          return;
                        } else {
                          const values = props.samples;
                          const arrAux = [];
                          const index = values.findIndex(val => {
                            return val.uuid_set === props.uuid_set;
                          });
                          values[index].value = option;
                          const arr = values;

                          arr.map(sample => {
                            arrAux.push(sample);
                          });

                          thresholdService
                            .updateSetAnswer({
                              pr_uuid_rounds_threshold_set: uuidRoundsThreshold,
                              str_option_set_1: arrAux[0].value,
                              str_option_set_2: arrAux[1].value,
                              str_option_set_3: arrAux[2].value,
                              str_option_set_4: arrAux[3].value,
                              str_option_set_5: arrAux[4].value,
                              str_option_set_6: arrAux[5].value,
                              str_code: code,
                            })
                            .then(
                              res => {
                                if (res.type === 'success' && res.results) {
                                  props.setSamples(arrAux);
                                  submitionButtonControl(props.samples);
                                  if (!round.bol_started) {
                                    roundAppService.updateStart(round, timeNow);
                                  }
                                } else {
                                  window.alert(res.message);
                                }
                              },
                              error => {
                                console.log(error);
                              },
                            );
                        }
                      } else {
                        const values = props.samples;
                        const arrAux = [];
                        const index = values.findIndex(val => {
                          return val.uuid_set === props.uuid_set;
                        });
                        values[index].value = option;
                        const arr = values;

                        arr.map(sample => {
                          arrAux.push(sample);
                        });

                        thresholdService
                          .updateSetAnswer({
                            pr_uuid_rounds_threshold_set: uuidRoundsThreshold,
                            str_option_set_1: arrAux[0].value,
                            str_option_set_2: arrAux[1].value,
                            str_option_set_3: arrAux[2].value,
                            str_option_set_4: arrAux[3].value,
                            str_option_set_5: arrAux[4].value,
                            str_option_set_6: arrAux[5].value,
                            str_code: code,
                          })
                          .then(
                            res => {
                              if (res.type === 'success' && res.results) {
                                props.setSamples(arrAux);
                                submitionButtonControl(props.samples);
                                if (!round.bol_started) {
                                  roundAppService.updateStart(round, timeNow);
                                }
                              } else {
                                window.alert(res.message);
                              }
                            },
                            error => {
                              console.log(error);
                            },
                          );
                      }
                    },
                    error => {
                      setLoading(false);
                      setDisabled(false);
                      setSamples(null);
                      console.log(error);
                    },
                  );
              } else {
                setDisabled(false);
              }
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img
                src={beer}
                alt={'unlocked'}
                height={44}
                width={40}
                style={{ marginLeft: '-10px' }}
              />
              <span
                style={{
                  marginTop: '-28.5px',
                  fontWeight: '700',
                  fontSize: '16px',
                  color: '#000',
                }}
              >
                {option}{' '}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleBack = () => {
    history.replace('/');
  };

  return (
    <Fragment>
      <Modal
        className={tastingThreshold.Modal}
        style={{
          height: '100%',
        }}
        show={modalShow}
      >
        <div>
          <CardRow className={tastingThreshold.cardRowModal}>
            <Text className={tastingThreshold.h3Title}>
              {t('review_submit_threshold')}
            </Text>
          </CardRow>
          <CardRow className={tastingThreshold.cardRowModal}>
            <Button
              label={t('common_cancel')}
              remove
              onClick={() => setModalShow(false)}
            ></Button>
            <Button
              label={t('common_submit')}
              confirm
              onClick={() => handleGoDiscussion()}
            ></Button>
          </CardRow>
        </div>
      </Modal>

      <Modal
        className={tastingThreshold.Modal}
        onClick={() => setModalShowInfo(false)}
        show={modalShowInfo}
      >
        <div>
          {taste.bol_layout_horizontal ? (
            <div>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  padding: '10px 15px',
                  justifyContent: 'flex-end',
                  display: 'flex',
                }}
              >
                <button
                  style={{
                    cursor: 'pointer',
                    height: '35px',
                    width: '35px',
                    color: '#fff',
                    border: '1px solid',
                    borderRadius: '4px',
                    fontSize: '18px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#666666',
                  }}
                  onClick={() => setModalShowInfo(false)}
                >
                  <X />
                </button>
              </div>
              <CardRow
                className={tastingThreshold.cardRowModal}
                style={{ marginTop: '-30px' }}
              >
                <Text className={tastingThreshold.h3Title}>
                  {t('threshold_aml_method')}
                </Text>
              </CardRow>
              <CardColumn style={{ marginTop: '-30px', marginBottom: '-30px' }}>
                <img src={arrow} style={{ width: '100%', padding: '20px' }} />
                <Text
                  style={{
                    color: '#ffff',
                    textAlign: 'left',
                    opacity: 1,
                    paddingLeft: '55px',
                    fontSize: '16px',
                    fontWeight: '700',
                    marginTop: '-88px',
                  }}
                >
                  {t('threshold_component_concentration')}
                </Text>
              </CardColumn>
              <CardRow
                transparent
                flexWrap="wrap"
                justifyContent="center"
                style={{ flexGrow: 1, marginTop: '20px' }}
              >
                {true ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Text
                      style={{
                        color: '#707070',
                        textAlign: 'center',
                        opacity: 1,
                        paddingTop: '80px',
                      }}
                    >
                      {t('threshold_sample_different_help')}
                    </Text>
                    <div
                      style={{
                        marginTop: '145px',
                      }}
                    >
                      {options.map(option => (
                        <div className={tastingThreshold.optionLeft}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <span
                              style={{
                                marginTop: '-28.5px',
                                fontWeight: '700',
                                fontSize: '16px',
                                color: '#000',
                              }}
                            >
                              {option}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div style={{ display: 'flex' }}>
                      {samplesExample.map(set => (
                        <SamplesSets
                          clickable={false}
                          name={set.name}
                          uuid_set={set.uuid_set}
                          value={set.value}
                          setSamples={setSamplesExample}
                          samples={samplesExample}
                          vertical={vertical}
                        />
                      ))}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '5px',
                    }}
                  >
                    <div>
                      {samplesExample.map(set => (
                        <SamplesSets
                          clickable={false}
                          name={set.name}
                          uuid_set={set.uuid_set}
                          value={set.value}
                          setSamples={setSamplesExample}
                          samples={samplesExample}
                          vertical={!vertical}
                        />
                      ))}
                    </div>
                    <div
                      style={{
                        marginTop: '15px',
                        display: 'flex',
                        flexDirection: 'row',
                        paddingLeft: '10px',
                      }}
                    >
                      {options.map(option => (
                        <div className={tastingThreshold.optionTop}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <span
                              style={{
                                marginTop: '-28.5px',
                                fontWeight: '700',
                                fontSize: '16px',
                                color: '#000',
                              }}
                            >
                              {option}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </CardRow>
              <CardRow
                className={tastingThreshold.cardRowModal}
                style={{ marginTop: '-30px' }}
              >
                <Text
                  style={{ color: '#707070', textAlign: 'center', opacity: 1 }}
                >
                  {t('threshold_each_set_random_order')}
                </Text>
              </CardRow>
            </div>
          ) : (
            <div
              style={{
                width: '350px',
                height: '700px',
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: ['translate(-50%,-50%)'],
              }}
            >
              <button
                style={{
                  cursor: 'pointer',
                  height: '35px',
                  width: '35px',
                  color: '#fff',
                  border: '1px solid',
                  borderRadius: '4px',
                  fontSize: '18px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#666666',
                  position: 'absolute',
                  right: '3px',
                  top: '3px',
                }}
                onClick={() => setModalShowInfo(!modalShowInfo)}
              >
                <X />
              </button>
              <img src={tuli} style={{ width: '100%', height: '100%' }}></img>
            </div>
          )}
        </div>
      </Modal>

      <CardRow flexWrap="wrap" transparent>
        <CardColumn className={tastingThreshold.header}>
          <Button
            label={''}
            onClick={() => handleBack()}
            className={tastingThreshold.back}
          >
            <ArrowLeft />
          </Button>
          {
            !user.nonABI &&
            <RoundUpAndDown round={location.state.round} />
          }
        </CardColumn>
        <CardColumn padding="5px">
          <Label
            style={{
              textAlign: 'center',
              color: '#F49B00',
              fontSize: '1.2em',
            }}
            label={taste.int_round}
            bold
            neutral
          />
        </CardColumn>
        <CardColumn className={tastingThreshold.countDownColumn}>
          <div className={tastingThreshold.countDownDiv}>
            {round.bol_is_countdown && isCountDownShow ? (
              <CountDown
                round={round}
                setRound={setRound}
                save={handleSubmit}
                setshow={setResults}
              />
            ) : null}
          </div>
        </CardColumn>
      </CardRow>
      <CardRow
        flexWrap="wrap"
        transparent
        style={{ justifyContent: 'center' }}
      ></CardRow>
      <CardRow
        transparent
        flexWrap="wrap"
        style={{ flexGrow: 1, marginTop: '0px' }}
      >
        <CardColumn padding="5px" flex={8}>
          <TextField
            name="flt_concentration"
            label={t('code')}
            margin="10px 10px 0px 0px"
            value={codeFormat}
            onChange={evt => {
              delayedQuery(evt.target.value);
            }}
            text="number"
            disabled={disabled}
            placeholder={t('code_digits')}
          />
        </CardColumn>
        <CardColumn
          className={tastingThreshold.circleInfo}
          onClick={() => setModalShowInfo(true)}
        >
          <HelpCircle style={{ color: '#f49b00' }} />
        </CardColumn>
      </CardRow>
      {!loading && code ? (
        samples &&
        code && (
          <>
            <CardRow
              transparent
              style={{ justifyContent: 'center', paddingTop: '10px' }}
            >
              <Label
                style={{ paddingRight: '5px' }}
                label={t('threshold_sample_spiked_with')}
              />{' '}
              <Label bold label={taste.str_name_flavor} />
            </CardRow>
            <CardRow
              transparent
              flexWrap="wrap"
              justifyContent="center"
              style={{ flexGrow: 1, marginTop: '20px' }}
            >
              {taste.bol_layout_horizontal ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      marginTop: '145px',
                    }}
                  >
                    {options.map(option => (
                      <div className={tastingThreshold.optionLeft}>
                        <div className={tastingThreshold.optionDiv}>
                          <span className={tastingThreshold.optionSpan}>
                            {option}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div style={{ display: 'flex' }}>
                    {samples.map(set => (
                      <SamplesSets
                        clickable={true}
                        name={set.name}
                        uuid_set={set.uuid_set}
                        value={set.value}
                        setSamples={setSamples}
                        samples={samples}
                        vertical={vertical}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '5px',
                  }}
                >
                  <div>
                    {samples.map(set => (
                      <SamplesSets
                        clickable={true}
                        name={set.name}
                        uuid_set={set.uuid_set}
                        value={set.value}
                        setSamples={setSamples}
                        samples={samples}
                        vertical={!vertical}
                      />
                    ))}
                  </div>
                  <div
                    style={{
                      marginTop: '15px',
                      display: 'flex',
                      flexDirection: 'row',
                      paddingLeft: '10px',
                    }}
                  >
                    {options.map(option => (
                      <div className={tastingThreshold.optionTop}>
                        <div className={tastingThreshold.optionDiv}>
                          <span className={tastingThreshold.optionSpan}>
                            {option}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </CardRow>
            <CardRow
              transparent
              flexWrap="wrap"
              justifyContent="end"
              style={{ flexGrow: 1, marginTop: '20px' }}
            >
              <Button
                disabled={blockSubmittion}
                label={t('common_finish_session')}
                confirm
                onClick={() => setModalShow(true)}
              />
            </CardRow>
          </>
        )
      ) : loading ? (
        <Spinner />
      ) : (
        <CardRow
          transparent
          flexWrap="wrap"
          justifyContent="center"
          style={{ flexGrow: 1, marginTop: '20px' }}
        >
          {t('threshold_didnt_enter_the_code')}
        </CardRow>
      )}
    </Fragment>
  );
};

export default TastingSample;
