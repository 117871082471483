import React from "react";
import PropTypes from "prop-types";
import cardDocumentStyles from "./buttondownload.module.scss";
import configs from "../../configs";
import { X, Download } from "react-feather";
import { Button } from "../../components";

const ButtonDownload = (props) => {
  const getClassName = () => {
    let classes = cardDocumentStyles.cardDocument + " ";
    if (props.onRemove) {
      classes += cardDocumentStyles.cardRemove + " ";
    }
    return classes;
  };

  const handleDownload = () => {
    let link = document.createElement("a");
    link.href = configs.file_api_url + "/v1/" + props.src;
    link.download = props.src;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRemove = () => {
    const res = { target: { value: null, name: props.name } };
    props.onRemove(res);
  };

  return (
    <div
      style={{
        width: props.width ? props.width : "100%",
      }}
    >
      <div
        style={{
          margin: props.margin ? props.margin : "10px",
          height: (props.size ? props.size + 6 : "86") + "px",
          width: (props.size ? props.size + 6 : "86") + "px",
        }}
        className={getClassName()}
      >
        {props.onRemove ? <X onClick={handleRemove} /> : null}
        <Button
          label={""}
          onClick={() => handleDownload()}
          style={{
            borderRadius: "40px",
            backgroundColor: "#F49B00",
            color: "#fff",
            fontWeight: "bold",
            height: (props.size ? props.size : "40") + "px",
            width: (props.size ? props.size : "40") + "px",
            padding: "4px 0px 0px 0px",
          }}
          alt={props.alt ? props.alt : ""}
          title={props.src}
        >
          <Download />
        </Button>
      </div>
    </div>
  );
};

ButtonDownload.propTypes = {
  size: PropTypes.number,
  name: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  margin: PropTypes.string,
  onRemove: PropTypes.func,
};

export default ButtonDownload;
