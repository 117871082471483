import EventEmitter  from 'events';
import uuid from 'react-uuid';

export const TIMEOUT = 3000;
export const FADE_TIMEOUT = 3000;

export const AlertTypes = {
    INFO: 'info',
    ERROR: 'error',
    SUCCESS: 'success',
    WARNING: 'warning'
}

export const AlertPositions = {
    TOP: 'top',
    BOTTOM: 'bottom'
}

export function prepareAlertToShow(alert) {
    if(typeof alert === 'string'){
        alert = { 
            msg: alert.toString(),
        }
    }
    alert.id = alert.id || uuid();
    alert.type = alert.type || AlertTypes.INFO;
    alert.position = alert.position || AlertPositions.TOP;
    alert.hide = false;
    return alert
}

export function compareAlert(curr, alert) {
    if(typeof alert === 'string') {
        return (curr.msg === alert);
    }
    return ((curr.id || curr) === (alert.id || alert));
}

export class AlertService {
    static alertEmitter = new EventEmitter();
    static alerts = [];
    
    get emitter() {
        return AlertService.alertEmitter;
    }

    get alerts() {
        return AlertService.alerts;
    }

    constructor() {
        // update
        AlertService.alertEmitter.on('update', (alerts) => {
            AlertService.alerts = alerts;
        });
        // remove
        AlertService.alertEmitter.on('remove', (alert) => {
            let newValue = [...AlertService.alerts];
            if(!alert){ return; }
            newValue = newValue.filter((curr) => !compareAlert(curr, alert));
            AlertService.alertEmitter.emit('update', newValue);
        });
        // hide
        AlertService.alertEmitter.on('hide', (alert) => {
            let newValue = [...AlertService.alerts];
            if(!alert){ return; }
            newValue = newValue.map((curr) => compareAlert(curr, alert) ? {...alert, hide: true } : curr);
            AlertService.alertEmitter.emit('update', newValue);
            setTimeout(() => AlertService.alertEmitter.emit('remove', alert), FADE_TIMEOUT);
        });
        // show
        AlertService.alertEmitter.on('show', (alert) => {
            alert = prepareAlertToShow(alert);
            AlertService.alertEmitter.emit('update', [...AlertService.alerts, alert]);
            setTimeout(() => AlertService.alertEmitter.emit('hide', alert), TIMEOUT);
        });
    }

    showAlert(alert) {
        AlertService.alertEmitter.emit('show', alert);
    }

    hideAlert(alert) {
        AlertService.alertEmitter.emit('hide', alert);
    }

    removeAlert(alert) {
        AlertService.alertEmitter.emit('remove', alert);
    }
}

export default new AlertService();