import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import uuid from 'react-uuid';
import editPackageStyle from './editpackage.module.scss';
import {
    Label,
    Card,
    CardRow,
    TextField,
    CardColumn,
    Button,
} from '../../../components';
import EditPackageVolume from '../editPackageVolume/EditPackageVolume';
import PackageService from '../../services/packages/package.service';
import { useTranslation } from 'react-i18next';

const EditPackage = props => {
    const { t } = useTranslation();
    const history = useHistory();
    const [pack, setPack] = useState({});
    const [privilege, setPrivilege] = useState([]);
    const packageService = new PackageService();

    useEffect(() => {
        setPack(props.location.state.values);
        setPrivilege(props.location.state.privilege);
    }, []);

    const handleSave = () => {
        if (!pack.str_name_en?.trim()) {
            window.alert(t('package_has_no_english_name'));
            return false;
        }
        packageService
            .updatePackage({
                ...pack,
                arr_volumes: pack.arr_volumes?.filter(
                    e => e.str_name?.length > 0,
                ),
            })
            .then(
                res => {
                    if (res.type === 'success' && res.results) {
                        if (res.results[0].package_sp_in_up_packages) {
                            window.alert(t('package_duplicate'));
                        } else {
                            history.goBack();
                        }
                    } else {
                        window.alert(res.message);
                    }
                },
                error => {
                    //console.log(error);
                },
            );
    };

    const handleChange = evt => {
        const value = evt.target.value;
        setPack({
            ...pack,
            [evt.target.name]: value,
        });
    };

    const handleVolumeChange = (evt, index) => {
        const volumes = [...pack.arr_volumes];
        volumes[index] = {
            ...volumes[index],
            [evt.target.name]: evt.target.value,
        };
        setPack({
            ...pack,
            arr_volumes: volumes,
        });
    };

    const handleRemoveVolume = index => {
        const volumes = [...pack.arr_volumes];
        if (
            volumes[index].added ||
            window.confirm(
                `${t('package_remove_volume')}: ${volumes[index].str_name}`,
            )
        ) {
            volumes.splice(index, 1);
            setPack({
                ...pack,
                arr_volumes: volumes,
            });
        }
    };

    const handleNewVolume = () => {
        const volumes = pack.arr_volumes ? [...pack.arr_volumes] : [];
        const updatedVolumes = [
            { uuid_volume: uuid(), str_name: '', added: true },
            ...volumes,
        ];
        setPack({
            ...pack,
            arr_volumes: updatedVolumes,
        });
    };

    return (
        <div className={editPackageStyle.pageContainer}>
            <Label label={t('package_edit_package')} bold neutral />

            <Card bordered>
                <CardRow flexWrap="wrap">
                    <CardColumn padding="10px">
                        <CardRow flexWrap="wrap">
                            <TextField
                                name="str_name_en"
                                label={`${t('sample_package')} (English)`}
                                margin="0px 10px 0px 0px"
                                value={pack.str_name_en}
                                onChange={handleChange}
                            />
                            <TextField
                                name="str_name_pt"
                                label={`${t('sample_package')} (Portuguese)`}
                                margin="0px 10px 0px 0px"
                                value={pack.str_name_pt}
                                onChange={handleChange}
                            />
                            <TextField
                                name="str_name_es"
                                label={`${t('sample_package')} (Spanish)`}
                                margin="0px 10px 0px 0px"
                                value={pack.str_name_es}
                                onChange={handleChange}
                            />
                        </CardRow>
                    </CardColumn>
                </CardRow>

                <CardRow flexWrap="wrap">
                    <CardColumn padding="10px">
                        <CardRow flexWrap="wrap">
                            <TextField
                                name="str_name_de"
                                label={`${t('sample_package')} (German)`}
                                margin="10px 10px 0px 0px"
                                value={pack.str_name_de}
                                onChange={handleChange}
                            />
                            <TextField
                                name="str_name_fr"
                                label={`${t('sample_package')} (French)`}
                                margin="10px 10px 0px 0px"
                                value={pack.str_name_fr}
                                onChange={handleChange}
                            />
                            <TextField
                                name="str_name_it"
                                label={`${t('sample_package')} (Italian)`}
                                margin="10px 10px 0px 0px"
                                value={pack.str_name_it}
                                onChange={handleChange}
                            />
                        </CardRow>
                    </CardColumn>
                </CardRow>

                <CardRow flexWrap="wrap">
                    <CardColumn padding="10px">
                        <CardRow flexWrap="wrap">
                            <TextField
                                name="str_name_nl"
                                label={`${t('sample_package')} (Dutch)`}
                                margin="10px 10px 0px 0px"
                                value={pack.str_name_nl}
                                onChange={handleChange}
                            />
                            <TextField
                                name="str_name_ru"
                                label={`${t('sample_package')} (Russian)`}
                                margin="10px 10px 0px 0px"
                                value={pack.str_name_ru}
                                onChange={handleChange}
                            />
                            <TextField
                                name="str_name_uk"
                                label={`${t('sample_package')} (Ukranian)`}
                                margin="10px 10px 0px 0px"
                                value={pack.str_name_uk}
                                onChange={handleChange}
                            />
                        </CardRow>
                    </CardColumn>
                </CardRow>
                <CardRow flexWrap="wrap">
                    <CardColumn padding="10px">
                        <CardRow flexWrap="wrap">
                            <TextField
                                name="str_name_zh"
                                label={`${t('sample_package')} (Chinese)`}
                                margin="10px 10px 0px 0px"
                                value={pack.str_name_zh}
                                onChange={handleChange}
                            />
                            <TextField
                                name="str_name_kr"
                                label={`${t('sample_package')} (Korean)`}
                                margin="10px 10px 0px 0px"
                                value={pack.str_name_kr}
                                onChange={handleChange}
                            />

                            <CardColumn></CardColumn>
                        </CardRow>
                    </CardColumn>
                </CardRow>
            </Card>
            <Card bordered>
                <Label
                    label={'Volumes'}
                    margin="0px 0px 0px 0px"
                    bold
                    neutral
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        label={t('package_new_volume')}
                        confirm
                        margin="10px 10px 0px 0px"
                        onClick={handleNewVolume}
                    />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {pack.arr_volumes
                        ? pack.arr_volumes.map((volume, index) => (
                              <EditPackageVolume
                                  key={volume.uuid_volume}
                                  index={index}
                                  volume={pack.arr_volumes[index]}
                                  onChange={e => handleVolumeChange(e, index)}
                                  onRemove={() => handleRemoveVolume(index)}
                              />
                          ))
                        : null}
                </div>
            </Card>
            <CardRow justifyContent="flex-end" flexWrap="wrap" transparent>
                <Button
                    label={t('common_cancel')}
                    onClick={() => history.goBack()}
                    neutral
                    margin="10px 10px 0px 0px"
                />
                <Button
                    label={t('common_save')}
                    onClick={handleSave}
                    confirm
                    margin="10px 10px 0px 0px"
                />
            </CardRow>
        </div>
    );
};

export default EditPackage;
