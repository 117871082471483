import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '..';
import { Search } from 'react-feather';
import autoCompleteStyle from './autocompletesearch.module.scss';

const AutoCompleteSearch = props => {
  const handleSelect = evt => {
    const res = { target: { value: evt, name: props.name } };
    props.onSelect(res);

    if (props.focus) {
      this['str_text'].focus();
    }
  };

  const handleShowList = () => (
    <div>
      <div
        className={autoCompleteStyle.autoCompleteList}
        style={props.styleList ?? props.styleList}
      >
        {props.options.map((option, index) => (
          <div
            data-testid="autoCompleteOptList"
            className={autoCompleteStyle.autoCompleteOption}
            key={index}
            onClick={evt => {
              handleSelect(option);
            }}
            onKeyPress={e => {
              switch (e.key) {
                case 'Enter':
                  handleSelect(option);
                  break;
                default:
                  e.preventDefault();
                  break;
              }
            }}
          >
            <div className={autoCompleteStyle.option}>
              <div className={autoCompleteStyle.optionLabel}>
                <label>{option.str_abi_id}</label>
                {' - '}
                <label>{option.str_name}</label>
              </div>
              <label>{option.str_email}</label>
            </div>
          </div>
        ))}
      </div>
      <div
        className={autoCompleteStyle.autoCompleteOuterList}
        onClick={evt => {
          props.setShow(false);
        }}
      ></div>
    </div>
  );

  const handleHintEnter = value => {
    handleSelect(value);
  };

  return (
    <div className={autoCompleteStyle.autoCompleteContainer}>
      <div>
        {!props.nonSearch ? <Search /> : ''}
        <TextField
          name="str_text"
          label={props.label}
          value={props.text}
          style={{
            padding: props.padding ? props.padding : '11px 5px 11px 34px',
            margin: props.margin,
            ...props.style,
          }}
          onChange={props.onChange}
          placeholder={props.placeholder ? props.placeholder : ''}
          disabled={props.disabled}
          withouBorder={props.withouBorder}
          focus={props.refFocus}
          onHintEnter={handleHintEnter}
        />
      </div>
      {props.show ? handleShowList() : null}
    </div>
  );
};

AutoCompleteSearch.propTypes = {
  name: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  nonSearch: PropTypes.bool,
  padding: PropTypes.string,
  placeholder: PropTypes.string,
  withouBorder: PropTypes.bool,
  minimalToSearch: PropTypes.number,
  refFocus: PropTypes.any,
  autoFocus: PropTypes.bool,
  style: PropTypes.object,
  styleList: PropTypes.object,
  useDebounce: PropTypes.bool,
  options: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  show: PropTypes.bool,
  setShow: PropTypes.any,
};

export default AutoCompleteSearch;
