import React from 'react';
import Modal from 'react-modal'
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import style from './modalScoreScale.module.scss';
import { Label, Button, FreshnessFiveScoreScale } from '../../../components';

const ModalScoreScale = props => {

    const { t } = useTranslation();

    const closeModal = () => {
      props.onCloseModal();
    }

    return (
        <Modal isOpen={props.isOpen} {...props} className={style.modal} >
          <>
            <div>
                <Label 
                    bold
                    size={27}
                    margin={'30px'}
                    label={t('custom_scale_title')}
                />
            </div>
            <FreshnessFiveScoreScale uuid_brand={props.brand.uuid_brand} isAdminEdit={true} disabled={props.disabled}/>
            <div className={style.footerDiv}>
              <Button confirm onClick={closeModal} > OK </Button>
            </div>
          </>
        </Modal>
    );


};

ModalScoreScale.propTypes = {
  isOpen: PropTypes.bool,
  brand: PropTypes.object,
  onCloseModal: PropTypes.func.isRequired,
}

export default ModalScoreScale;