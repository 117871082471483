import React from 'react';
import PropTypes from 'prop-types';
import modalStyle from './modal.module.scss';

const Modal = props => {
  const styleObj = { ...props.style };

  return (
    <div
      style={styleObj}
      className={props.show ? modalStyle.modal : modalStyle.modalHide}
      onClick={props.onClick}
    >
      <div
        className={modalStyle.modalContainer + ' ' + props.className}
        style={
          props.fullButton
            ? {
                width: props.fullButton,
                height: props.fullHeight,
                overflowY: 'scroll',
              }
            : null
        }
      >
        <div className={modalStyle.modalBody}>{props.children}</div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Modal;

