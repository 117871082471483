import ListComments from './listComments/ListComments';
import EditComments from './editComment/EditComment';

export default {
    routeModule: {
        path: '/list_attributes',
        component: ListComments,
        key: 'comment_list_comments',
        privilege: 'COMMENT_MANAGEMENT_LIST',
        routeComponents: [{
            path: '/edit_attribute',
            component: EditComments,
            key: 'Edit Comment',
            privilege: 'COMMENT_MANAGEMENT_EDIT'
        }],
        subMenu: 'menu_attributes_management'
    }
};
