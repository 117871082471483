import ListTasting from './listTasting/ListTasting';
import TastingSample from './tastingSample/TastingSample';
import TastingVoting from './tastingVoting/TastingVoting';
import { TastingProvider } from '../../contexts/Tasting';
import ListReviews from './listReviews/ListReviews';
import ListDiscussion from './listDiscussion/ListDiscussion';
import DetailDiscussion from './detailDiscussion/DetailDiscussion';
import EditVoting from './editVoting/EditVoting';
import TastingOkNok from './tastingOkNok/TastingOkNok';
import ListDiscussionOkNok from './listDiscussionOkNok/ListDiscussionOkNok';
import TastingThreshold from './tastingThreshold/TastingThreshold';
import TastingDiscussionThreshold from './tastingDiscussionThreshold/TastingDiscussionThreshold';
import CodeFlavor from './CodeFlavor/CodeFlavor';
import TastingFlavorSession from './TastingFlavorSession/TastingFlavorSession';
import TastingTriangle from './TastingTriangle/TastingTriangle';
import TastingDiscussionTriangle from './tastingDiscussionTriangle/TastingDiscussionTriangle';
export default {
  routeModule: {
    path: '/',
    component: ListTasting,
    key: 'menu_tasting_session',
    exact: true,
    context: TastingProvider,
    routeComponents: [
      {
        path: '/tasting_samples',
        component: TastingSample,
        key: 'tasting_sample',
      },
      {
        path: '/tasting_voting',
        component: TastingVoting,
        key: 'tasting_voting',
      },
      {
        path: '/tasting_review',
        component: ListReviews,
        key: 'tasting_review',
      },
      {
        path: '/tasting_edit_voting',
        component: EditVoting,
        key: 'tasting_edit_voting',
        //privilege: 'SAMPLE_DISCUSSION_EDIT'
      },
      {
        path: '/tasting_discussion/:uuid_round',
        component: ListDiscussion,
        key: 'tasting_discussion',
      },
      {
        path: '/tasting_discussion',
        component: ListDiscussion,
        key: 'tasting_discussion_without_params',
      },
      {
        path: '/detail_discussion',
        component: DetailDiscussion,
        key: 'detail_discussion',
      },
      {
        path: '/tasting_ok_nok',
        component: TastingOkNok,
        key: 'tasting_ok_nok',
      },
      {
        path: '/tasting_discussion_ok_nok/:uuid_round',
        component: ListDiscussionOkNok,
        key: 'tasting_discussion_ok_nok',
      },
      {
        path: '/tasting_discussion_ok_nok',
        component: ListDiscussionOkNok,
        key: 'tasting_discussion_ok_nok_without_params',
      },
      {
        path: '/tasting_threshold',
        component: TastingThreshold,
        key: 'tasting_threshold',
      },
      {
        path: '/tasting_discussion_threshold/:uuid_round',
        component: TastingDiscussionThreshold,
        key: 'tasting_discussion_threshold',
      },
      {
        path: '/code_flavor',
        component: CodeFlavor,
        key: 'tasting_code_flavor',
      },
      {
        path: '/tasting_flavor_session',
        component: TastingFlavorSession,
        key: 'tasting_flavor_session',
      },
      {
        path: '/tasting_triangle',
        component: TastingTriangle,
        key: 'tasting_triangle',
      },
      {
        path: '/tasting_discussion_triangle/:uuid_round',
        component: TastingDiscussionTriangle,
        key: 'tasting_discussion_triangle',
      },
    ],
  },
};
