import React from 'react';
import listUsersStyle from './listusers.module.scss';
import {
  Label,
  Tab
} from '../../../components';
import { useTranslation } from 'react-i18next';
import ListUsersUsers from '../listUsersUsers/ListUsersUsers';
import ListUsersProfiles from '../listUsersProfiles/ListUsersProfiles';

const ListUsers = props => {
  
  const { t } = useTranslation();
  
  const tabs = [
    {
      index: 1, 
      description: t('user_list_users'),
      component: () => (
        <ListUsersUsers/>
      )
    },
    {
      index: 2,
      description: t('user_list_profiles'),
      component: () => (
        <ListUsersProfiles/>
      )
    }
  ];

  return (
    <div className={listUsersStyle.pageContainer}>
      <Label 
        label={t('user_list_users')}
        bold
        neutral
      />

      <Tab
        tabs={tabs}
        selectedTabIndex={1}
        justifyContent="flex-start"
      />
    </div>
  );
};

export default ListUsers;