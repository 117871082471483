import React from 'react';
import PropTypes from 'prop-types';
import textStyle from './text.module.scss';

const Text = props => {

	const getClassName = () => {
		let classes = props.className;
		if (props.bold) {
			classes += ' ' + textStyle.boldLabel;
		}
		if (props.neutral) {
			classes += ' ' + textStyle.neutralLabel;
		}
		if (props.capital) {
			classes += ' ' + textStyle.capitalLabel;
		}
		classes += ' ' + props.className;
		return classes;
	};

	let styleObj = {
		fontSize: (props.fontSize ? props.fontSize : 'auto'),
		margin: (props.margin ? props.margin : 0),
		...props.style
	};

	return (
		<p style={styleObj}
		   className={getClassName()}
		   onClick={props.onClick}>
			{ props.children }
		</p>
	);
}

Text.propTypes = {
	label: PropTypes.string,
	bold: PropTypes.bool,
	neutral: PropTypes.bool,
	capital: PropTypes.bool,
	fontSize: PropTypes.string,
	margin: PropTypes.string,
	className: PropTypes.string,
	onClick: PropTypes.func,
	style: PropTypes.object,
};

export default Text;
