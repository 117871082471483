import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import paginationTableStyle from './paginationTable.module.scss';
import { DropdownField } from '../';
import { useTranslation } from 'react-i18next';
import { ChevronRight, ChevronLeft } from 'react-feather';
import _ from 'lodash';

const PaginationTable = props => {
  const { t } = useTranslation();
  const arrRowsPerPage = [
    { int_page: 5, str_name: `05 ${t('common_per_page')}` },
    { int_page: 10, str_name: `10 ${t('common_per_page')}` },
    { int_page: 15, str_name: `15 ${t('common_per_page')}` },
    { int_page: 20, str_name: `20 ${t('common_per_page')}` },
    { int_page: 40, str_name: `40 ${t('common_per_page')}` },
    { int_page: 80, str_name: `80 ${t('common_per_page')}` },
    { int_page: 160, str_name: `160 ${t('common_per_page')}` },
  ];

  const [filters, setFilters] = useState(props.filter);

  useEffect(() => {
    setFilters(props.filter);
  }, [props.filter]);

  const goToPage = useCallback(
    (selectedPage, intRows, filter) => () => {
      props.goToPage(selectedPage, intRows, filter);
    },
    [],
  );

  const pageButton1 = () => {
    if (props.selectedPage - 2 > 0) {
      return (
        <div onClick={goToPage(props.selectedPage - 2, props.intRows, filters)}>
          {props.selectedPage - 2}
        </div>
      );
    }
    return null;
  };

  const pageButton2 = () => {
    if (props.selectedPage - 1 > 0) {
      return (
        <div onClick={goToPage(props.selectedPage - 1, props.intRows, filters)}>
          {props.selectedPage - 1}
        </div>
      );
    }
    return null;
  };

  const pageButton3 = () => {
    if (props.selectedPage * props.rowsPerPage < props.totalRows) {
      return (
        <div onClick={goToPage(props.selectedPage + 1, props.intRows, filters)}>
          {props.selectedPage + 1}
        </div>
      );
    }
    return null;
  };

  const pageButton4 = () => {
    if ((props.selectedPage + 1) * props.rowsPerPage < props.totalRows) {
      return (
        <div onClick={goToPage(props.selectedPage + 2, props.intRows, filters)}>
          {props.selectedPage + 2}
        </div>
      );
    }
    return null;
  };

  const renderPagination = () => {
    return (
      <div
        style={{
          position: 'relative'
        }}
      >
        {props.setRowsPerPage ? (
          <div className={paginationTableStyle.pageRows}>
            <DropdownField
              hidedropdownOuterList={props.hidedropdownOuterList}
              name="pages"
              options={_.orderBy(arrRowsPerPage, o => +o.int_page)}
              valueField="int_page"
              labelField="str_name"
              margin="-5px 0px 0px 0px"
              value={props.rowsPerPage}
              bolOrder={false}
              hideArrow={true}
              onChange={evt => {
                props.setRowsPerPage(evt.target.value);
              }}
              hideRemoveButton
            />
          </div>
        ) : null}
        <div className={paginationTableStyle.paginationContainer}>
          <div className={paginationTableStyle.pagination}>
            {props.totalRows / props.rowsPerPage !== 0 &&
              !isNaN(props.totalRows / props.rowsPerPage) &&
              props.rowsPerPage !== 0 ? (
              <div
                style={{
                  margin: '10px',
                  whiteSpace: 'nowrap',
                  cursor: 'default',
                  width: '100px',
                }}
              >
                {t('Page1', {
                  field: `${props.selectedPage} / ${Math.ceil(Number(props.totalRows / props.rowsPerPage))}`
                })}
              </div>
            ) : (
              <></>
            )}
            <div>
              <ChevronLeft
                onClick={goToPage(
                  props.selectedPage - 1 > 0
                    ? props.selectedPage - 1
                    : props.selectedPage,
                  props.intRows,
                  filters,
                )}
              />
            </div>
            {pageButton1()}
            {pageButton2()}
            <div className={paginationTableStyle.selected}>
              {props.selectedPage}
            </div>
            {pageButton3()}
            {pageButton4()}
            <div>
              <ChevronRight
                onClick={goToPage(
                  props.selectedPage * props.rowsPerPage < props.totalRows
                    ? props.selectedPage + 1
                    : props.selectedPage,
                  props.intRows,
                  filters,
                )}
              />
            </div>
          </div>
          {props.totalRows ? <div className={paginationTableStyle.count}>{t('page_total')}：{props.totalRows}</div> : null}
        </div>
      </div>
    );
  };

  return renderPagination();
};

PaginationTable.propTypes = {
  totalRows: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  selectedPage: PropTypes.number.isRequired,
  goToPage: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func,
  filter: PropTypes.object,
};

export default PaginationTable;
