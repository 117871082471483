import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid';
import listGroupsStyle from './listgroups.module.scss';
import {
  GenericTable,
  Label,
} from '../../../components';
import GroupService from '../../services/groups/group.service';
import AppService from '../../../app.service';
import { useTranslation } from 'react-i18next';
import ListGroupTastersModal from '../listGroupTastersModal/ListGroupTastersModal';

const ListGroups = props => {
  
  const { t } = useTranslation();
  const history = useHistory();
  const [groups, setGroups] = useState([]);
  const [tasters, setTasters] = useState([]);
  const [privilege, setPrivilege] = useState([]);
  const [isOpenModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

	// services
  const groupService = new GroupService();
  const appService = new AppService();

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);
    //GET PRIVILEGES
    appService.listPrivileges('GROUP_MANAGEMENT_EDIT').then((res) => {
      if(res.type === 'success' && res.results){
        setPrivilege(res.results[0]);
      }else{
        window.alert(res.message);
      }
    }, (error) => {
      //console.log(error);
    });

    //GET GROUPS
    groupService.listGroups().then((res) => {
      if(res.type === 'success' && res.results){
        setGroups(res.results.map((group) => { return {...group, int_tasters: (group.arr_users ? group.arr_users.length: 0) }; }));
        setLoading(false);
      }else{
        window.alert(res.message);
        setLoading(false);
      }
    }, (error) => {
      //console.log(error);
      setLoading(false);
    });
  };

  const handleNewGroup = useCallback(
    () => {
      history.push({pathname: '/new_group', state: {values: {uuid_group: uuid()}, privilege: privilege, isNewGroup: true}});
    }, [privilege]
  );

  const handleShowTasters = useCallback(
		(values, index) => () => {
      setTasters(values.arr_users);
		}, []
  );

  const handleHideTasters = useCallback(
		(values, index) => () => {
      setTasters([]);
		}, []
  );

  const handleEditGroup = useCallback(
    (values) => () => {
      history.push({pathname: '/edit_group', state: {values: values, privilege: privilege, isNewGroup: false}});
    }, [privilege]
  );

  const handleRemoveGroup = useCallback(
    (values) => {
      //console.log(values);
      if(window.confirm(`${t('group_remove_group')}: ${values.str_group_name}`)){
        
        groupService.deleteGroup(values.uuid_group).then((res) => {
          if(res.type === 'success' && res.results){
            refreshState();
          }else{
            window.alert(res.message);
          }
        }, (error) => {
          //console.log(error);
        });

      }
    }, []
  );

  const handleGroupClick = useCallback(
    (values) => () => {
      setTasters(values.arr_users);
      setOpenModal(true);
    }, []
  ) 

  const headers = [
    { description: t('common_name'), field: 'str_group_name'},
    { description: t('common_zone'), field: 'str_zone_name'},
    { description: t('common_country'), field: 'str_country_name'},
    { description: t('common_site'), field: 'str_site_name'},
    { description: t('common_tasters'), field: 'int_tasters', 
      action: handleGroupClick,
    }
  ];

  return (
    <div className={listGroupsStyle.pageContainer}>
      <Label 
        label={t('group_list_groups')}
        bold
        neutral
      />
      <ListGroupTastersModal 
        isOpen={isOpenModal} 
        onRequestClose={() => setOpenModal(false)}
        tasters={tasters}
      />
      <GenericTable 
        arrHeader={headers}
        arrRow={groups}
        searchable={true}
        onEdit={privilege.bol_access ? handleEditGroup : null}
        onRemove={privilege.bol_access ? handleRemoveGroup : null}
        newRegister={privilege.bol_access ? {
          onClick: handleNewGroup,
          label: t('group_new_group')
        } : null}
        loading={loading}
      />
    </div>
  );
};

export default ListGroups;