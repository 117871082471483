import React, { useState, useEffect, Fragment, useRef, useCallback } from 'react';
import editVoting from './editVoting.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import arrayMove from 'array-move';
import {
  Label,
  CardColumn,
  CardRow,
  Slider,
  Button,
  TextField,
  ButtonList,
  AttributesCategory,
  AutoComplete,
  ImageZoom,
  Modal,
  ScoreScale,
  Accordion,
  PdfViewerModal,
  Checkbox,
  SortableListComments,
  FreshnessFiveScoreScale,
  NotificationBar,
  IntensityScale,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import RoundAppService from '../../services/rounds/roundApp.service';
import SampleAppService from '../../services/samples/sampleApp.service';
import { X, ArrowLeft, HelpCircle } from 'react-feather';
import uuid from 'react-uuid';
import { useTasting } from '../../../contexts/Tasting';
import { useUser } from '../../../contexts/User';
import Switch from 'react-switch';
import { GetSocket } from '../../../utils/socketIo';
import UserService from '../../services/users/user.service';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import configs from '../../../configs';

const fuzzysort = require('fuzzysort');

const EditVoting = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const roundAppService = new RoundAppService();
  const sampleAppService = new SampleAppService();

  const { taste, updateApproveSample } = useTasting();
  const { user } = useUser();

  const [score, setScore] = useState('');
  const [degrees, setDegrees] = useState([]);
  const [attrs, setAttrs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [comments, setComments] = useState([]);
  const [countComment, setCountComment] = useState(0);
  const [comment, setComment] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [sample, setSample] = useState();
  const [round, setRound] = useState();
  const [userComments, setUserComments] = useState('');
  const [imgPdf, setImgPdf] = useState('');
  const [open, setOpen] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [isRoundApprove, setIsRoundApprove] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [fullButton, setFullButton] = useState('');
  const [fullHeight, setHeight] = useState('');
  const isZhOrKr = JSON.parse(
    user.str_language === 'zh' || user.str_language === 'kr',
  );
  const [isContinuous, setIsContinuous] = useState(false);
  const userService = new UserService();

  const inputText = useRef(null);
  const [notificationArr, setNotificationArr] = useState([]);
  const bubbleArr = useRef([]);
  const nottificationRef = useRef({});
  const sampleRef = useRef(sample);
  const [temporaryScore, setTemporaryScore] = useState()
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    refreshState();
    userService
      .getUserContinuousTyping()
      .then(res => {
        if (res.results.length == 0) {
          setIsContinuous(false);
        } else {
          setIsContinuous(res.results[0].bol_continuous_type);
        }
      })
  }, []);

  useEffect(() => {
    bubbleArr.current = notificationArr;
  }, [notificationArr]);

  useEffect(() => {
    sampleRef.current = sample ? sample : location.state.sample;
  }, [sample]);

  const handleTypingChange = () => {
    setIsContinuous(!isContinuous);
    userService.updateUserContinuousTyping({
      bol_continuous_type: !isContinuous,
    });
  };

  const handleSocketOn = (socketName, res) => {
    const resUuidRound = res.uuid_round ? res.uuid_round : '';
    const uuid_round = location.state?.round?.uuid_round
      ? location.state?.round?.uuid_round
      : round?.uuid_round;
    let nowRound = resUuidRound === uuid_round;

    let sampleNameStr = '';
    if (socketName === 'addUpdateSample') {
      for (let i = 0; i < res.updateSampleArr.length; i++) {
        const lastDom = i + 1 !== res.updateSampleArr.length ? ', ' : '';
        sampleNameStr += res.updateSampleArr[i].str_brand_name + lastDom;
      }
      nowRound = nowRound && !!sampleNameStr;
    }

    if (nowRound) {
      if (socketName === 'updateEditSampleID') {
        const resUuidOldSample = res.uuid_old_sample ? res.uuid_old_sample : '';
        const resStrName = res.str_name ? res.str_name : '';
        const resUuidSample = res.uuid_sample ? res.uuid_sample : '';
        console.log(sampleRef.current.uuid_sample === resUuidOldSample);
        if (sampleRef.current.uuid_sample === resUuidOldSample) {
          handleSample({
            ...sampleRef.current,
            uuid_sample: resUuidSample,
            str_brand_name: resStrName,
          });
        }
      }

      if (
        nottificationRef?.current &&
        bubbleArr.current &&
        bubbleArr.current.length > 4
      ) {
        nottificationRef.current?.scrollBottom &&
          nottificationRef.current.scrollBottom();
      }
    }
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      GetSocket().on('updateEditSampleID', function (res) {
        if (window.location.pathname === '/tasting_edit_voting') {
          handleSocketOn('updateEditSampleID', res);
        }
      });
      GetSocket().on('addUpdateSample', async function (res) {
        if (window.location.pathname === '/tasting_edit_voting') {
          handleSocketOn('addUpdateSample', res);
        }
      });
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);

  const handleSample = row => {
    setSample(row);
  };
  const refreshState = () => {
    let userComments = location.state.sample.tasters.find(
      taste => taste.uuid_user === location.state.uuid_user,
    );

    setRound(taste);
    setSample(location.state.sample);
    setIsRoundApprove(
      taste.bol_is_approve_reject &&
      taste.uuid_round_type === '4ed984d9-021d-4d06-a992-991a24b3d7ce',
    );
    setIsApprove(
      (userComments && userComments?.bol_user_approve_reject === null) ||
        userComments?.bol_user_approve_reject === 0 ||
        userComments?.bol_user_approve_reject === false
        ? false
        : true,
    );

    setUserComments(userComments);
    if (location.state.sample?.str_img_profile) {
      setImgPdf(location.state.sample?.str_img_profile.split('.').pop());
    }

    setScore(
      userComments && userComments.flt_score
        ? userComments.flt_score.toString()
        : null,
    );
    setTemporaryScore(
      userComments && userComments.flt_score
        ? userComments.flt_score.toString()
        : null,
    );
    setComments(
      userComments && userComments.arr_comments
        ? userComments.arr_comments
        : [],
    );
    setCountComment(
      userComments && userComments?.arr_comments
        ? userComments?.arr_comments.length
        : 0,
    );

    roundAppService
      .listComments()
      .then(res => {
        const reduceArr = res.reduce((accumulator, currentValue) => {
          const existingObject = accumulator.find(
            obj => obj.uuid_comment === currentValue.uuid_comment,
          );
          if (!existingObject) {
            accumulator.push(currentValue);
          }
          return accumulator;
        }, []);
        setAttrs(reduceArr);
      })
      .catch(error => {
        //console.log(error);
      });

    roundAppService
      .listDegrees()
      .then(res => {
        setDegrees(res);
      })
      .catch(error => {
        //console.log(error);
      });

    roundAppService
      .listCommentsCategory()
      .then(res => {
        setCategories(res);
      })
      .catch(error => {
        //console.log(error);
      });
  };
  const delayedQuery = useCallback(
    _.debounce(objScore => handleScoreChange(objScore), configs.defaultDebounce),
    [],
  );

  const handleScoreChange = objScore => {
    setIsEdit(true);
    let value = objScore
      ? objScore.score
        .toString()
        .replace(/[^0-9\,.]+/g, '')
        .replace(/[,]+/g, '.')
      : null;

    if (value) {
      if (objScore.step % 1 !== 0) {
        if (value.trim() !== '.') {
          value = objScore.step === 0.25 ? value.match(/^-?\d+(?:\.\d{0,2})?/)[0] : value.match(/^-?\d+(?:\.\d{0,1})?/)[0];
        }
        let decimal = value.split('.');

        if (decimal.length > 1) {
          if (
            Number(objScore.step === 0.25 && decimal[1].length === 1 ? decimal[1] + '0' : decimal[1]) %
            Number(objScore.step.toString().split('.')[1]) !==
            0
          ) {
            alert(t('the_score_entered_is_invalid'))
            value = `${decimal[0]}.`;
          }
        }
      } else {
        value = value.match(/^-?\d+(?:.\\d{0,0})?/)[0];
      }
    }

    if (Number(value) >= objScore.min && Number(value) <= objScore.max) {
      setTemporaryScore(value);
      setScore(value);
    } else {
      setScore('');
      setTemporaryScore('');
      if (objScore.score) {
        window.alert(
          `"${objScore.score}" ${t('common_message_out_score_range')}`,
        );
      }
    }
  };

  const handleClickDegrees = e => {
    if (!score) {
      window.alert(t('tasting_need_select_score'));
      return;
    }
    if (e.target.value) {
      let degreeSelected = degrees.filter(
        degree => degree.str_name === e.target.value,
      );
      setComment({
        str_degree: degreeSelected[0].str_name,
        uuid_degree: degreeSelected[0].uuid_degree,
      });
    }
  };

  const handleClickAttrs = (e, current) => {
    if (!score) {
      window.alert(t('tasting_need_select_score'));
      return;
    }

    if (e.target.value) {
      const res = {
        target: {
          value: attrs.filter(attr => attr.str_name === e.target.value)[0],
          name: 'comments',
        },
      };
      handleSelectComments(res);
    }
  };

  const handleSearchComments = text => {
    return new Promise((resolve, reject) => {
      let searchArray = [];
      const keyword = text.toString().trim().toLowerCase();
      if (!comment?.str_degree && keyword) {
        searchArray = [...degrees, ...attrs];
      } else {
        searchArray = [...attrs];
      }

      let returnedArray = [];

      returnedArray = fuzzysort
        .go(keyword, searchArray, { key: 'str_name' })
        .map(a => a.obj);

      if (keyword.length <= 2) {
        returnedArray = [];
      }

      if (!comment?.str_degree && keyword.length == 0) {
        resolve([]);
      } else {
        resolve(returnedArray);
      }
    });
  };

  const handleSelectComments = evt => {
    if (!score) {
      window.alert(t('tasting_need_select_score'));
      return;
    }
    setIsEdit(true);
    if (typeof evt.target.value == 'string' && !evt.target.value.trim()) return;

    if (evt.target && evt.target.value) {
      if (
        comment ||
        evt.target.value.uuid_comment ||
        typeof evt.target.value === 'string'
      ) {
        if (
          (evt.target.value.str_name && evt.target.value.uuid_comment) ||
          typeof evt.target.value === 'string'
        ) {
          let hasComment = comments.find(
            commentFilter =>
              commentFilter.uuid_comment === evt.target.value.uuid_comment ||
              (!comment &&
                commentFilter.uuid_comment === evt.target.value.uuid_comment) ||
              (!comment && commentFilter.str_comment === evt.target.value) ||
              (commentFilter.str_comment === evt.target.value &&
                comment?.uuid_degree === commentFilter.uuid_degree),
          );

          if (!!!hasComment) {
            let uuid_answer = uuid();
            let newComment = {
              ...comment,
              str_comment: evt.target.value.str_name
                ? evt.target.value.str_name
                : evt.target.value,
              uuid_comment: evt.target.value.uuid_comment
                ? evt.target.value.uuid_comment
                : uuid(),
              uuid_round: round.uuid_round,
              uuid_sample: sample.uuid_sample,
              uuid_answers_rounds_users_samples_comments: uuid_answer,
              uuid_user: location.state.uuid_user,
              int_order: countComment,
              int_order_app: sample?.int_order_app,
            };

            if (!newComment.uuid_degree && !newComment.str_degree) {
              newComment.uuid_degree = null;
              newComment.str_degree = null;
            }

            if (typeof evt.target.value === 'string') {
              newComment.uuid_comment = null;
            }

            setComments([...comments, newComment]);
            setCountComment(prevState => {
              return prevState + 1;
            });
          } else {
            window.alert(t('you_can_not_add_duplicate_attributes'));
          }
          setComment(null);
        }
      } else {
        if (evt.target.value.str_name && evt.target.value.uuid_degree) {
          setComment({
            str_degree: evt.target.value.str_name,
            uuid_degree: evt.target.value.uuid_degree,
          });
        }
      }
    }
  };

  const handleRemoveComment = rmComment => {
    if (
      !rmComment.uuid_answers_rounds_users_samples_comments ||
      !(comments.length > 1)
    ) {
      alert(t('message_you_cannot_delete_all_comments'));
      setComment(null);
      return;
    }
    setIsEdit(true);

    const newComments = comments.filter(
      commentfilter =>
        !(
          commentfilter.uuid_answers_rounds_users_samples_comments ===
          rmComment.uuid_answers_rounds_users_samples_comments
        ),
    );
    setCountComment(prevState => {
      return prevState - 1;
    });
    setComments(newComments);
  };

  const handleShowImage = show => {
    setShowModal(!show);
  };

  const handleFinishSample = () => {
    setLoading(true);
    const data = {
      uuid_round: taste.uuid_round,
      uuid_sample: sample.uuid_sample,
      approve_reject: !isRoundApprove ? 1 : isApprove ? 1 : 0,
      flt_score: score,
      arr_answer_comment: comments,
    }
    sampleAppService.submitScore(data).then(res => {
      setLoading(false);
      history.replace({
        pathname: `/tasting_discussion`,
        state: {
          from: 'discussion',
          round: props.location.state.round
            ? props.location.state.round
            : round,
        },
      });
    })
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setIsEdit(true);
    const move = arrayMove(comments, oldIndex, newIndex);
    move.map((comment, index) => {
      comment.int_order = index
      return comment
    });

    setComments(move);
  };

  const handleClosePdf = () => {
    setOpen(false);
  };

  const handlePDF = () => {
    setOpen(true);
  };

  const handleApproveReject = () => {
    setIsEdit(true);
    setIsApprove(!isApprove);
  };

  const checkEdit = () => {
    if (isEdit) {
      return window.confirm(t('changes_you_made_may_not_be_saved'))
    } else {
      return true;
    }
  }
  const handleBack = () => {
    if (checkEdit()) {
      history.replace({
        pathname: `/tasting_discussion`,
        state: {
          from: 'discussion',
          round: props.location.state.round ? props.location.state.round : round,
        },
      });
    }
  };
  const buttonBackGround = {
    position: 'fixed',
  };
  return (
    <Fragment>
      <NotificationBar
        ref={nottificationRef}
        right="5px"
        notificationArr={notificationArr}
        setNotificationArr={row => {
          setNotificationArr([...row]);
        }}
      />
      {/* HEADER */}
      <CardRow transparent>
        <CardColumn
          className={editVoting.header}
          style={{ justifyContent: 'flex-start' }}
        >
          <Button
            label={''}
            onClick={() => handleBack()}
            className={editVoting.back}
          >
            <ArrowLeft />
          </Button>
        </CardColumn>
        <CardColumn>
          <Label
            bold
            label={`${sample?.int_order_app + 1} - ${sample?.str_brand_name}`}
            style={{
              borderRadius: '5px',
              backgroundColor: '#fff',
              fontWeight: 'bold',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </CardColumn>
        <CardColumn>
          {imgPdf === 'pdf' ? (
            <PdfViewerModal
              label={t('tasting_beer_profile')}
              data={location.state.sample?.str_img_profile}
              onClick={handlePDF}
              closeForm={handleClosePdf}
              open={open}
              loading={false}
              downloadFileName={location.state.sample?.str_img_profile}
            />
          ) : (
            <>
              <Button
                label={t('tasting_beer_profile')}
                onClick={() => handleShowImage(showModal)}
                style={{
                  borderRadius: '5px',
                  backgroundColor: '#F49B00',
                  color: '#fff',
                  fontWeight: 'bold',
                  width: '100%',
                }}
              />
              <Modal
                fullButton={fullButton}
                fullHeight={fullHeight}
                show={showModal}
                style={{ position: showImg ? 'fixed' : '' }}
                className={showImg ? editVoting.buttonBackGround : ''}
              >
                <ImageZoom
                  className={showImg ? editVoting.showFull : ''}
                  srcImage={sample?.str_img_profile}
                  onClick={() => {
                    handleShowImage(showModal);
                  }}
                  setFullButton={setFullButton}
                  setHeight={setHeight}
                  onShow={() => {
                    setShowImg(!showImg);
                  }}
                  onClose={() => {
                    setShowImg(false);
                  }}
                />
              </Modal>
            </>
          )}
        </CardColumn>
        <CardColumn>
          <Button
            Ids='cannelAutoSave'
            label={t('tasting_finish_sample')}
            onClick={() => handleFinishSample()}
            style={{
              borderRadius: '5px',
              backgroundColor: '#95c511',
              color: '#fff',
              fontWeight: 'bold',
              width: '100%',
            }}
            disabled={loading}
          />
        </CardColumn>
      </CardRow>
      {/* HEADER */}

      {/* SCORE */}
      <CardRow>
        <CardColumn>
          <Checkbox
            label={`N/A`}
            padding="8px 10px 8px 30px"
            name="bol_key_taster"
            value={score !== '' && score !== null ? false : true}
            onChange={() => {
              if (score === '' || score == null) {
                setScore(1);
              } else {
                setScore(null);
              }
              temporaryScore !== '' && temporaryScore !== null ? setTemporaryScore(null) : setTemporaryScore(1);
              setIsEdit(true);
            }}
          />
        </CardColumn>
        <CardColumn>
          <Label bold label={t('common_score')} />
        </CardColumn>
        <CardColumn flex={10}>
          {round && (
            <Slider
              min={round?.obj_score.min}
              max={round?.obj_score.max}
              step={round?.obj_score.step}
              datalist
              onClick={handleScoreChange}
              currentScore={score ? Number(score) : null}
              funcData={{
                uuid_round: round?.uuid_round,
                uuid_sample: sample?.uuid_sample,
                uuid_user:
                  userComments && userComments.uuid_user
                    ? userComments.uuid_user
                    : location.state.uuid_user,
              }}
            />
          )}
        </CardColumn>
        <CardColumn>
          <TextField
            name="score"
            value={temporaryScore}
            maxLength={round?.obj_score.step === 0.5 ? 3 : 4}
            onChange={(e) => {
              setTemporaryScore(e.target.value)
              delayedQuery({
                score: e.target.value,
                step: round?.obj_score.step,
                min: round?.obj_score.min,
                max: round?.obj_score.max,
                uuid_round: round?.uuid_round,
                uuid_sample: sample?.uuid_sample,
                uuid_user: location.state.uuid_user,
                int_order_app: sample?.int_order_app,
              })
            }} />
        </CardColumn>
      </CardRow>
      {/* SCORE */}

      {isRoundApprove ? (
        <CardRow style={{ marginTop: '10px' }}>
          <CardColumn flex={1}>
            <Label bold label={t('common_approve')} />
          </CardColumn>
          <CardColumn flex={10}>
            <Switch
              onChange={() => handleApproveReject()}
              checked={isApprove}
              offColor={'#ba182c'}
              onColor={'#95C511'}
            />
          </CardColumn>
        </CardRow>
      ) : (
        <></>
      )}

      {/* SCORE SCALE*/}
      <CardRow style={{ marginTop: '10px' }}>
        <CardColumn>
          {round &&
            sample &&
            (round.uuid_round_type !==
              'b8082a00-e1d1-11ec-97a3-000000000000' ? (
              <ScoreScale
                roundType={round?.uuid_round_type}
                productType={sample?.uuid_product_type}
              ></ScoreScale>
            ) : (
              <Accordion label={t('common_score_scale')} loading={false}>
                <FreshnessFiveScoreScale
                  uuid_brand={sample.uuid_brand}
                  isAdminEdit={false}
                />
              </Accordion>
            ))}
        </CardColumn>
      </CardRow>
      {/* SCORE SCALE*/}

      {/*COMMENTS*/}
      <CardRow style={{ marginTop: '10px' }}>
        <CardColumn>
          <Label label={t('tasting_comments')} bold></Label>
          <div
            style={{
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '5px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <SortableListComments
              items={comments}
              onSortEnd={onSortEnd}
              axis="xy"
              handleRemoveComment={handleRemoveComment}
            />
            <div
              style={{
                padding: '0px 5px',
                width: '100%'
              }}
            >
              {comment ? (
                <Button
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    width: 'auto',
                    padding: '10px',
                    flexFlow: 'row nowrap',
                    margin: '0 5px',
                  }}
                  transparent
                  onClick={e => console.log(1)}
                >
                  <p>{comment.str_degree + ' - '}</p>
                  <AutoComplete
                    refFocus={inputText}
                    freeComment
                    autoFocus={isContinuous}
                    nonSearch
                    name="comments"
                    margin="0px"
                    style={{
                      margin: '0px',
                      padding: '5px 10px',
                      fontSize: '1em',
                    }}
                    styleList={{
                      marginTop: '0px',
                    }}
                    withouBorder
                    placeholder={t('common_attributes')}
                    optionKeys={['str_name']}
                    onSearch={handleSearchComments}
                    onSelect={handleSelectComments}
                    minimalToSearch={isZhOrKr ? 1 : 3}
                  />
                  <X
                    style={{ marginLeft: '5px' }}
                    width="15px"
                    height="15px"
                    strokeWidth="5px"
                    onClick={() => handleRemoveComment(comment)}
                  ></X>
                </Button>
              ) : (
                <AutoComplete
                  style={{ display: 'flex' }}
                  refFocus={inputText}
                  autoFocus={isContinuous}
                  freeComment
                  nonSearch
                  name="comments"
                  margin="0px"
                  padding="15px 0px"
                  withouBorder
                  placeholder={
                    (comment?.str_degree
                      ? comment.str_degree
                      : t('common_intensity')) +
                    ' - ' +
                    t('common_attributes')
                  }
                  optionKeys={['str_name']}
                  onSearch={handleSearchComments}
                  onSelect={handleSelectComments}
                  minimalToSearch={isZhOrKr ? 1 : 3}
                />
              )}
            </div>
          </div>
        </CardColumn>
        <CardColumn flex={0}>
          <Label
            style={{ whiteSpace: 'nowrap' }}
            label={t('continuous_typing')}
            bold
          ></Label>
          <view
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <input
              type="checkbox"
              checked={isContinuous}
              onChange={() => {
                handleTypingChange();
              }}
              className={editVoting.checke}
            />
            <HelpCircle
              data-tip={t('continuous_typing_tips')}
              style={{ color: '#B11F24', marginLeft: 5 }}
              size="20"
              data-for="continuous_typing_tips"
            />
            <ReactTooltip
              id="continuous_typing_tips"
              effect="solid"
              place="bottom"
              type="warning"
              style={{ whiteSpace: 'normal' }}
            />
          </view>
        </CardColumn>
      </CardRow>
      {/*COMMENTS*/}

      {/*DEGREES*/}
      <CardRow>
        <CardColumn>
          <IntensityScale />
        </CardColumn>
      </CardRow>
      <CardRow>
        <CardColumn>
          <ButtonList
            style={{ width: '120px' }}
            zIndex={0}
            list={degrees}
            valueField="str_name"
            labelField="str_name"
            onClick={e => handleClickDegrees(e)}
            comment={comment?.str_degree}
          ></ButtonList>
        </CardColumn>
      </CardRow>
      {/*DEGREES*/}

      {/*ATTRS*/}
      <CardRow>
        <CardColumn>
          <Label label={t('common_attributes')} bold></Label>
        </CardColumn>
      </CardRow>
      <CardRow>
        <CardColumn>
          <AttributesCategory
            list={attrs}
            valueField="str_name"
            labelField="str_name"
            onClick={handleClickAttrs}
            categories={categories}
            freshness={
              round?.uuid_round_type === '43b11c76-043c-11eb-b18d-026fdc1cc8f2'
                ? true
                : false
            }
          ></AttributesCategory>
        </CardColumn>
      </CardRow>
      {/*ATTRS*/}
    </Fragment >
  );
};

export default EditVoting;
