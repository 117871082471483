import React, { useState, useEffect } from 'react';
import editBreweryProductionSuspensionStyle from './editbreweryproductionsuspension.module.scss';
import { useHistory } from 'react-router-dom';
import {
  Label,
  Card,
  CardRow,
  Button,
  DropdownField,
  DatePickerField,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import Moment from 'moment';
import BrandService from '../../services/brands/brand.service';
import RoundService from '../../services/rounds/round.service';
import { useUser } from '../../../contexts/User';

const EditBreweryProductionSuspension = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [brandData, setBrandData] = useState([]);
  const brandService = new BrandService();
  const roundService = new RoundService();

  const [suspensionObj, setSuspensionObj] = useState({});
  const { user } = useUser();

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = async () => {
    brandService.listBrands().then(res => {
      if (res) setBrandData(res.results);
    });
    let suspensionNowObj = {
      ...suspensionObj,
      uuid_zone: user.uuid_zone,
      uuid_country: user.uuid_country,
      uuid_site: user.uuid_site,
      str_month: Moment(
        props.location.state?.values?.str_month
          ? props.location.state?.values?.str_month.substring(0, 19)
          : new Date(),
      ).format('YYYY-MM'),
    };
    if (props.location.state.isNewSuspension === false) {
      suspensionNowObj = {
        ...suspensionNowObj,
        ...props.location.state.values,
        str_month: Moment(
          props.location.state.values.str_month.substring(0, 19),
        ).format('YYYY-MM'),
      };
    }
    setSuspensionObj({
      ...suspensionNowObj,
    });
  };

  const handleChange = evt => {
    const value = evt.target.value;
    const name = evt.target.name;
    setSuspensionObj({
      ...suspensionObj,
      [name]: value,
    });
  };

  const handleSave = async () => {
    if (loading) return;
    if (
      suspensionObj.uuid_zone === undefined ||
      suspensionObj.uuid_zone === null ||
      suspensionObj.uuid_zone === ''
    ) {
      window.alert(
        t('common_cannot_be_empty', { field: `${t('common_zone')}` }),
      );
      return;
    }
    if (
      suspensionObj.uuid_country === undefined ||
      suspensionObj.uuid_country === null ||
      suspensionObj.uuid_country === ''
    ) {
      window.alert(
        t('common_cannot_be_empty', { field: `${t('common_country')}` }),
      );
      return;
    }
    if (
      suspensionObj.uuid_site === undefined ||
      suspensionObj.uuid_site === null ||
      suspensionObj.uuid_site === ''
    ) {
      window.alert(
        t('common_cannot_be_empty', { field: `${t('common_site')}` }),
      );
      return;
    }
    if (
      suspensionObj.str_month === undefined ||
      suspensionObj.str_month === null ||
      suspensionObj.str_month === ''
    ) {
      window.alert(
        t('common_cannot_be_empty', {
          field: `${t('Month_of_Discontinued_Production')}`,
        }),
      );
      return;
    }
    setLoading(true);
    let data = {
      ...suspensionObj,
      uuid_production_suspension:
        props.location.state.isNewSuspension === false
          ? suspensionObj.uuid_production_suspension
          : uuid(),
      uuid_zone: suspensionObj.uuid_zone,
      uuid_country: suspensionObj.uuid_country,
      uuid_site: suspensionObj.uuid_site,
      uuid_brand: suspensionObj.uuid_brand,
      str_month: Moment(suspensionObj.str_month).format('YYYYMM'),
    };
    const res = await roundService.saveSuspension(data);
    if (res.type === 'success') {
      history.push({
        pathname: '/list_brewery_production_suspension',
      });
    }
  };

  return (
    <div className={editBreweryProductionSuspensionStyle.pageContainer}>
      <Label
        label={
          props.location.state.isNewSuspension
            ? t('new_suspension_page')
            : t('edit_suspension_page')
        }
        bold
        neutral
      />

      <Card bordered>
        <CardRow style={{ flexWrap: 'wrap' }}>
          <DropdownField
            name="uuid_zone"
            options={
              props.location.state.privilege.arr_zones
                ? props.location.state.privilege.arr_zones
                : []
            }
            valueField="uuid_zone"
            labelField="str_name"
            label={t('common_zone')}
            margin="10px 10px 0px 0px"
            value={
              suspensionObj.uuid_zone !== undefined
                ? suspensionObj.uuid_zone
                : ''
            }
            onChange={evt => {
              setSuspensionObj({
                ...suspensionObj,
                uuid_zone: evt.target.value,
                uuid_country: null,
                uuid_site: null,
              });
            }}
            isRequired={true}
            style={{
              flex: 'auto',
              width: '32%',
            }}
            hideRemoveButton
          />
          <DropdownField
            name="uuid_country"
            options={
              props.location.state.privilege.arr_countries &&
              (true || suspensionObj.uuid_zone !== undefined)
                ? props.location.state.privilege.arr_countries.filter(
                    country => {
                      const idZoneAux =
                        suspensionObj.uuid_zone !== undefined
                          ? suspensionObj.uuid_zone
                          : '';
                      return country.uuid_zone === idZoneAux;
                    },
                  )
                : []
            }
            valueField="uuid_country"
            labelField="str_name"
            label={`${t('common_country')}`}
            margin="10px 10px 0px 0px"
            value={
              suspensionObj.uuid_country !== undefined
                ? suspensionObj.uuid_country
                : ''
            }
            onChange={evt => {
              setSuspensionObj({
                ...suspensionObj,
                uuid_country: evt.target.value,
                uuid_site: null,
              });
            }}
            isRequired={true}
            style={{
              flex: 'auto',
              width: '32%',
            }}
            hideRemoveButton
          />
          <DropdownField
            name="uuid_site"
            options={
              props.location.state.privilege.arr_sites &&
              (true || suspensionObj.uuid_country !== undefined)
                ? props.location.state.privilege.arr_sites.filter(site => {
                    const idCountryAux =
                      suspensionObj.uuid_country !== undefined
                        ? suspensionObj.uuid_country
                        : '';
                    return site.uuid_country === idCountryAux;
                  })
                : []
            }
            valueField="uuid_site"
            labelField="str_name"
            label={`${t('common_site')}`}
            margin="10px 10px 0px 0px"
            value={
              suspensionObj.uuid_site !== undefined
                ? suspensionObj.uuid_site
                : ''
            }
            onChange={evt => {
              const valueCountry =
                suspensionObj.uuid_country !== undefined
                  ? suspensionObj.uuid_country
                  : props.round.uuid_country;
              const siteLength =
                props.location.state.privilege.arr_sites.filter(site => {
                  return (
                    site.uuid_country === valueCountry &&
                    evt.target.value === site.uuid_site
                  );
                });
              if (siteLength.length > 0) {
                setSuspensionObj({
                  ...suspensionObj,
                  uuid_site: evt.target.value,
                });
              }
            }}
            isRequired={true}
            style={{
              flex: 'auto',
              width: '32%',
            }}
            hideRemoveButton
          />
        </CardRow>
        <CardRow flexWrap="wrap">
          <DropdownField
            name="uuid_brand"
            options={brandData}
            valueField="uuid_brand"
            labelField="str_name"
            label={`${t('common_brand')}`}
            margin="10px 10px 0px 0px"
            value={
              suspensionObj.uuid_brand !== undefined
                ? suspensionObj.uuid_brand
                : ''
            }
            bolOrder={false}
            onChange={handleChange}
            style={{
              flex: 'auto',
              width: '32%',
            }}
          />
          <DatePickerField
            name="str_month"
            type="month"
            label={`${t('Month_of_Discontinued_Production')}`}
            step={1}
            margin="10px 10px 0px 0px"
            padding="8px 4px 8px 5px"
            height="39px"
            value={
              suspensionObj.str_month !== undefined
                ? suspensionObj.str_month
                : ''
            }
            onChange={handleChange}
            isRequired={true}
            style={{
              flex: 'auto',
              width: '32%',
            }}
          />
          <div
            style={{
              flex: 'auto',
              width: '32.5%',
            }}
          ></div>
        </CardRow>
        <CardRow justifyContent="flex-end" flexWrap="wrap">
          <Button
            label={t('common_cancel')}
            onClick={() => {
              history.push({
                pathname: '/list_brewery_production_suspension',
              });
            }}
            neutral
            disabled={loading}
            margin="35px 10px 0px 0px"
          />
          <Button
            label={t('common_save')}
            onClick={handleSave}
            confirm
            loading={loading}
            margin="35px 10px 0px 0px"
          />
        </CardRow>
      </Card>
    </div>
  );
};
export default EditBreweryProductionSuspension;

