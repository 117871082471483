import MainService from './main.service';

export default class GroupService {
    mainService = null;
    path = null;

    constructor() {
        this.mainService = new MainService();
        this.path = '/v1/admin';
    }

    listGroups = () => {
        return this.mainService.get(this.path + '/list_groups');
    }

    findGroup = (obj_group) => {
        return this.mainService.post(this.path + '/find', obj_group);
    }

    updateGroup = (obj_group) => {
        return this.mainService.post(this.path + '/group', obj_group);
    }

    deleteGroup = (uuid_group) => {
        return this.mainService.delete(this.path + '/group/' + uuid_group);
    }

}