import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import scoreStyles from "../scoreScale/scoreScale.module.scss";
import RoundAppService from "../../modules/services/rounds/roundApp.service";
import Spinner from "../spinner/Spinner";
import { CardRow, Text, Label, CardColumn } from "../";
import { ArrowDown, ArrowUp } from "react-feather";
import { useTranslation } from "react-i18next";

const ScoreScale = (props) => {
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("");
  const [arrScores, setArrScores] = useState([]);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  // services
  const roundAppService = new RoundAppService();

  useEffect(() => {
    setLoading(true);
    
    const isFreshnessFivePoints = props.roundType === 'b8082a00-e1d1-11ec-97a3-000000000000' ? null : props.productType;
    roundAppService
      .listScoreScales({
        uuid_round: props.roundType,
        uuid_product:  isFreshnessFivePoints ,
      })
      .then(
        (res) => {
          if (res.type === "success" && res.results) {
            let info = res.results[0];
            setType(
              info.str_name_round_type === "In-Profile"
                ? "INPROFILE"
                : "FRESHNESS"
            );
            let arrGroup = res.results.map((score) => {
              return {
                str_name_product: score.str_name_product,
                str_name_round_type: score.str_name_round_type,
                uuid_product_type: score.uuid_product_type,
                uuid_round_type: score.uuid_round_type,
                color: score.color,
                arr_score: score.scores
                  .map((sc) => {
                    return sc.val_score;
                  })
                  .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                  }),
                arr_name: score.scores
                  .map((sc) => {
                    return sc.str_name;
                  })
                  .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                  }),
                arr_appearance: score.scores
                  .map((sc) => {
                    return sc.str_appearance;
                  })
                  .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                  }),
                arr_comment: score.scores
                  .map((sc) => {
                    return sc.str_comment;
                  })
                  .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                  }),
                arr_description: score.scores
                  .map((sc) => {
                    return sc.str_description;
                  })
                  .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                  }),
                arr_information: score.scores
                  .map((sc) => {
                    return sc.str_information;
                  })
                  .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                  }),
              };
            });
            setArrScores(arrGroup);
            setLoading(false);
          } else {
            window.alert(res.message);
          }
        },
        (error) => {
          //console.log(error);
        }
      );
  }, [props.roundType, props.productType]);

  const handleClickShowScore = (state) => {
    setIsOpen(!state);
  };

  const handleInprofileTable = () => {
    return (
      <Fragment>
        <CardRow>
          <CardColumn>
            <CardRow>
              <CardColumn style={{ maxWidth: "120px" }}>
                <Text>{t('score_scale')}</Text>
              </CardColumn>
              <CardColumn>
                <Text>{t('description')}</Text>
              </CardColumn>
              <CardColumn>
                <Text>{t('aditional_criteria')}</Text>
              </CardColumn>
            </CardRow>
            {arrScores.map((obj, index) => (
              <CardRow
                key={index}
                itemAlign="center"
                style={{ backgroundColor: obj.color }}
              >
                <CardColumn
                  className={scoreStyles.borderStyleProfile}
                  style={{
                    maxWidth: "120px",
                    borderWidth: "0px",
                    padding: "0px",
                  }}
                >
                  {obj.arr_score.map((score, index_score) => (
                    <Text
                      key={index_score}
                      className={scoreStyles.textScore}
                      style={{ paddingLeft: "15px" }}
                    >
                      {score}
                    </Text>
                  ))}
                </CardColumn>
                <CardColumn
                  className={scoreStyles.borderStyleProfile}
                  style={{
                    justfyContent: "center",
                    borderWidth: "0px",
                    padding: "0px",
                  }}
                >
                  {obj.arr_name.map((score, index_score) => (
                    <Text key={index_score} className={scoreStyles.textScore}>
                      {score}
                    </Text>
                  ))}
                </CardColumn>
                <CardColumn
                  className={scoreStyles.borderStyleProfile}
                  style={{
                    justfyContent: "center",
                    borderWidth: "0px",
                    padding: "0px",
                  }}
                >
                  {obj.arr_description.map((score, index_score) => (
                    <Text key={index_score} className={scoreStyles.textScore}>
                      {score}
                    </Text>
                  ))}
                </CardColumn>
              </CardRow>
            ))}
          </CardColumn>
        </CardRow>
      </Fragment>
    );
  };

  const handleFreshnessTable = () => {
    let colors = [];
    return (
      <Fragment>
        <CardRow>
          <CardColumn>
            <CardRow>
              <CardColumn style={{ maxWidth: "75px" }}></CardColumn>
              <CardColumn
                style={{ maxWidth: "150px" }}
                className={scoreStyles.borderStyle}
              >
                <CardRow>&nbsp;</CardRow>
                <CardRow>
                  <Text>Score</Text>
                </CardRow>
              </CardColumn>
              <CardColumn>
                <CardRow justifyContent="flex-end">
                  <Text>Forced Ageing Scale</Text>
                </CardRow>
                <CardRow>
                  <CardColumn>
                    <Text style={{ textAlign: "center" }}>
                      Profile changes due to Ageing
                    </Text>
                  </CardColumn>
                </CardRow>
              </CardColumn>
              <CardColumn className={scoreStyles.borderStyle}>
                <CardRow>&nbsp;</CardRow>
                <CardRow>
                  <CardColumn>
                    <Text style={{ textAlign: "center" }}>Oxidation Notes</Text>
                  </CardColumn>
                </CardRow>
              </CardColumn>
              <CardColumn></CardColumn>
            </CardRow>

            {arrScores.map((obj, index) => {
              colors.push(obj.color);
              return (
                <CardRow key={index} style={{ backgroundColor: obj.color }}>
                  <CardColumn
                    style={{ maxWidth: "75px", flex: 1 }}
                    className={scoreStyles.borderStyle}
                    padding="0px"
                  >
                    {obj.arr_score.map((score, index_score) => (
                      <Text
                        key={index_score}
                        className={scoreStyles.textScore}
                        style={{ padding: "10px" }}
                      >
                        {score}
                      </Text>
                    ))}
                  </CardColumn>
                  <CardColumn
                    style={{
                      maxWidth: "150px",
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      padding: "0px",
                    }}
                    className={scoreStyles.borderStyle}
                  >
                    {obj.arr_name.map((name, index_name) => (
                      <Text key={index_name} className={scoreStyles.textScore}>
                        {name}
                      </Text>
                    ))}
                  </CardColumn>
                  <CardColumn
                    className={scoreStyles.borderStyle}
                    style={{
                      justifyContent: "center",
                      padding: "0px",
                      textAlign: "center",
                    }}
                  >
                    {obj.arr_description.map(
                      (description, index_description) => (
                        <view key={index_description}>
                          {type == "FRESHNESS" &&
                            obj.arr_appearance.map(
                              (appearance, index_appearance) => {
                                const textReturn = !(
                                  obj.arr_appearance[index_appearance - 1] &&
                                  obj.arr_appearance[index_appearance - 1] ==
                                    appearance
                                ) ? (
                                  <Text
                                    key={index_appearance}
                                    className={scoreStyles.textScore}
                                  >
                                    {appearance}
                                  </Text>
                                ) : null;
                                return textReturn;
                              }
                            )}
                          <Text
                            className={scoreStyles.textScore}
                            style={{ textAlign: "center" }}
                          >
                            {description}
                          </Text>
                        </view>
                      )
                    )}
                  </CardColumn>
                  <CardColumn className={scoreStyles.borderStyle} padding="0px">
                    {obj.arr_comment.map((comment, index_comment) => {
                      const textReturn = !(
                        obj.arr_comment[index_comment - 1] &&
                        obj.arr_comment[index_comment - 1] == comment
                      ) ? (
                        <Text
                          key={index_comment}
                          className={scoreStyles.textScore}
                        >
                          {comment}
                        </Text>
                      ) : null;
                      return textReturn;
                    })}
                  </CardColumn>
                  <CardColumn
                    className={scoreStyles.borderStyle}
                    style={{ backgroundColor: "#fff" }}
                    padding="0px"
                  >
                    {obj.arr_information.map((info, index_info) => {
                      const textReturn = !(
                        obj.arr_information[index_info - 1] &&
                        obj.arr_information[index_info - 1] == info
                      ) ? (
                        <Text
                          key={index_info}
                          className={scoreStyles.textScore}
                        >
                          {info}
                        </Text>
                      ) : null;
                      return textReturn;
                    })}
                  </CardColumn>
                </CardRow>
              );
            })}
          </CardColumn>

          <div className={scoreStyles.arrow}>
            <div className={scoreStyles.line}></div>
          </div>
        </CardRow>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <CardRow>
        <CardColumn
          padding="15px"
          style={{ position: "relative", cursor: "pointer" }}
          onClick={() => handleClickShowScore(isOpen)}
        >
          <Label
            style={{ cursor: "pointer" }}
            label={t("common_score_scale")}
            bold
          />
          <span className={scoreStyles.buttonShow}>
            {isOpen ? <ArrowUp /> : <ArrowDown />}
          </span>
        </CardColumn>
      </CardRow>
      {isOpen ? (
        <Fragment>
          {loading ? (
            <Spinner />
          ) : type === "INPROFILE" ? (
            handleInprofileTable()
          ) : (
            handleFreshnessTable()
          )}
        </Fragment>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default ScoreScale;
