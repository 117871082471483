import React, { useState, useEffect } from 'react';
import ListBreathalyzerStyle from './listBreathalyzers.module.scss';
import { Label, Tab } from '../../../components';
import { useTranslation } from 'react-i18next';
import ListInputResult from '../listInputResult/ListInputResult';
import ListConfiguration from '../listConfiguration/ListConfiguration';
import ProfileService from '../../services/users/profile.service';
import { useUser } from '../../../contexts/User';

const ListBreathalyzers = props => {
  const { t } = useTranslation();
  const [privilegeArray, setPrivilegeArray] = useState([]);
  const { user } = useUser();
  useEffect(() => {
    refreshState();
  }, []);

  const hasPrivilege = arr => {
    return arr?.some(item => {
      return item === 'Breathalyzer';
    });
  };

  const refreshState = async () => {
    try {
      setPrivilegeArray(user.arr_privileges);
      hasPrivilege(user.arr_privileges);
    } catch (error) {
      alert(error);
    }
  };

  const hasPrivilegeTabs = [
    {
      index: 1,
      description: t('input_result'),
      component: () => <ListInputResult state={props.location.state} />,
    },
    {
      index: 2,
      description: t('configuration'),
      component: () => <ListConfiguration />,
    },
  ];

  const notHasPrivilegeTabs = [
    {
      index: 1,
      description: t('input_result'),
      component: () => <ListInputResult state={props.location.state} />,
    },
  ];

  return (
    <div className={ListBreathalyzerStyle.pageContainer}>
      <Label label={t('breathalyzer')} bold neutral />

      <Tab
        tabs={
          hasPrivilege(privilegeArray) ? hasPrivilegeTabs : notHasPrivilegeTabs
        }
        selectedTabIndex={1}
        justifyContent="flex-start"
      />
    </div>
  );
};

export default ListBreathalyzers;

