import ListBrands from './listBrands/ListBrands';
import EditBrand from './editBrand/EditBrand';
import DetailBrand from './editBrand/DetailBrand';

export default {
    routeModule: {
        path: '/list_brands',
        component: ListBrands,
        key: 'brand_list_brands',
        privilege: 'BRAND_MANAGEMENT_LIST',
        routeComponents: [
            {
                path: '/edit_brand',
                component: EditBrand,
                key: 'Edit Brand',
                privilege: 'BRAND_MANAGEMENT_EDIT'
            },
            {
                path: '/detail_brand',
                component: DetailBrand,
                key: 'detail Brand',
                privilege: 'BRAND_MANAGEMENT_LIST'
            }
        ],
        subMenu : 'menu_product_management'
    }
};

