import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid';
import ListEmailConfigurationStyle from './listEmailConfiguration.module.scss';
import {
  GenericTable,
  ImageZoom,
  Label,
  Modal,
} from '../../../components';
import EmailService from '../../services/email/email.service';
import AppService from '../../../app.service';
import { useTranslation } from 'react-i18next';
import ListEmailConfigurationTastersModal from '../listEmailConfigurationTastersModal/ListEmailConfigurationTastersModal';
import KpiSamplesTastingSteps from '../../../assets/kpi_samples_tasting_steps.png'

const ListEmailConfiguration = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const [groups, setGroups] = useState([]);
  const [tasters, setTasters] = useState([]);
  const [privilege, setPrivilege] = useState([]);
  const [isOpenModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fullButton, setFullButton] = useState('');
  const [fullHeight, setHeight] = useState('');
  const [showImg, setShowImg] = useState(false);

  // services
  const emailService = new EmailService();
  const appService = new AppService();

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);
    appService.listPrivileges('EMAIL_MANAGEMENT_LIST').then((res) => {
      if (res.type === 'success' && res.results) {
        setPrivilege(res.results[0]);
      } else {
        window.alert(res.message);
      }
    });

    emailService.listEmailConfiguration().then((res) => {
      if (res.type === 'success' && res.results) {
        setGroups(
          res.results.map((group) => {
            return {
              ...group,
              int_to_tasters: (group.arr_users
                ? group.arr_users.filter(item => item.bol_cc_recipient === false)
                : 0).length,
              int_cc_tasters: (group.arr_users
                ? group.arr_users.filter(item => item.bol_cc_recipient !== false)
                : 0).length
            };
          })
        );
        setLoading(false);
      } else {
        window.alert(res.message);
        setLoading(false);
      }
      setLoading(false);
    });
  };



  const handleNewGroup = useCallback(
    () => {
      history.push({ pathname: '/new_email_configuration', state: { values: { uuid_email_group: uuid() }, privilege: privilege, isNewGroup: true } });
    }, [privilege]
  );

  const handleEditGroup = useCallback(
    (values) => () => {
      history.push({ pathname: '/edit_email_configuration', state: { values: values, privilege: privilege, isNewGroup: false } });
    }, [privilege]
  );

  const handleRemoveGroup = useCallback(
    (values) => {
      if (window.confirm(`${t('group_remove_group')}: ${values.str_email_group_name}`)) {
        emailService.deleteEmailConfiguration(values.uuid_email_group).then((res) => {
          if (res.type === 'success' && res.results) {
            refreshState();
          } else {
            window.alert(res.message);
          }
        });
      }
    }, []
  );

  const handleEmailToClick = useCallback(
    (values) => () => {
      setTasters(values.arr_users?.filter(item => item.bol_cc_recipient === false));
      setOpenModal(true);
    }, []
  )
  const handleEmailCCClick = useCallback(
    (values) => () => {
      setTasters(values.arr_users?.filter(item => item.bol_cc_recipient !== false));
      setOpenModal(true);
    }, []
  )

  const headers = [
    { description: t('common_name'), field: 'str_email_group_name' },
    { description: t('common_zone'), field: 'str_zone_name' },
    { description: t('common_country'), field: 'str_country_name' },
    { description: t('common_site'), field: 'str_site_name' },
    {
      description: t('recipients'), field: 'int_to_tasters',
      action: handleEmailToClick,
    },
    {
      description: t('cc_recipients'), field: 'int_cc_tasters',
      action: handleEmailCCClick,
    }
  ];

  const handleShowImage = show => {
    setShowModal(!show);
  }

  return (
    <div className={ListEmailConfigurationStyle.pageContainer}>
      <Label
        label={t('email_distribution')}
        bold
        neutral
      />
      <ListEmailConfigurationTastersModal
        isOpen={isOpenModal}
        onRequestClose={() => setOpenModal(false)}
        tasters={tasters}
      />
      <GenericTable
        arrHeader={headers}
        arrRow={groups}
        searchable={true}
        onEdit={privilege.bol_access ? handleEditGroup : null}
        onRemove={privilege.bol_access ? handleRemoveGroup : null}
        newRegister={privilege.bol_access ? {
          onClick: handleNewGroup,
          label: t('new_email_distribution')
        } : null}
        buttonList={privilege.bol_access ? [
          {
            onClick: () => {
              setShowModal(true)
            },
            label: t('kpi_instruction'),
            dataTip: t('list_email_page_helps'),
            id: 'list_email_configuration_tips',
            blue: true,
            style: { zIndex: '0' }
          },
        ] : null}
        loading={loading}
      />
      <Modal
        fullButton={fullButton}
        fullHeight={fullHeight}
        show={showModal}
        className={
          showImg ? ListEmailConfigurationStyle.buttonBackGround : ''
        }
        style={{ position: showImg ? 'fixed' : '' }}
      >
        <ImageZoom
          className={
            showImg ? ListEmailConfigurationStyle.showFull : ''
          }
          srcImage={KpiSamplesTastingSteps}
          onClick={() => {
            handleShowImage(showModal);
          }}
          setFullButton={setFullButton}
          setHeight={setHeight}
          onShow={() => {
            setShowImg(!showImg);
          }}
          onClose={() => {
            setShowImg(false);
          }}
          local
        />
      </Modal>
    </div >
  );
};

export default ListEmailConfiguration;