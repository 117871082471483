import React from 'react';
import PropTypes from 'prop-types';
import cardColumnStyles from './cardColumn.module.scss';

const CardColumn = props => {

	const getClassName = () => {
		let classes = cardColumnStyles.cardColumn + ' ';
		if (props.bordered) {
			classes += cardColumnStyles.cardColumnBorder + ' ';
		}

		classes += ' ' + props.className;
		return classes;
	}
	const styleDefault = {
		flex: props.flex || props.flex === 0 ? props.flex : '1',
		justifyContent: props.justifyContent ? props.justifyContent : '',
		margin: props.margin ? props.margin : '0',
		padding: props.padding ? props.padding : '10px',
		maxWidth: props.maxWidth ? props.maxWidth : 'auto',
	};

	const styleObj = { ...styleDefault, ...props.style };

	return (
		<div
			className={getClassName()}
			style={styleObj}
			onClick={props.onClick}
		>
			{props.children}
		</div>
	)
}

CardColumn.propTypes = {
	className: PropTypes.string,
	flex: PropTypes.number,
	bordered: PropTypes.bool,
	margin: PropTypes.string,
	padding: PropTypes.string,
	maxWidth: PropTypes.string,
	style: PropTypes.object,
	onClick: PropTypes.func
};

export default CardColumn;
