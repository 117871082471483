import { useTranslation } from 'react-i18next';
import { useState } from "react";
import Style from './ListNextWeekPlan.module.scss';
import ReactTooltip from "react-tooltip";

const SessionContent = (props) => {
  const { t } = useTranslation();
  const [arrUsers, setArrUsers] = useState(props.arr_users)
  const handleToggleChange = (uuid_user) => {
    const index = arrUsers.findIndex(item => item.uuid_user === uuid_user)
    arrUsers[index].bol_confirm = !arrUsers[index].bol_confirm
    setArrUsers([...arrUsers]);
  };

  const getCount = (status) => {
    return arrUsers.filter(item => item.str_response_status === status).length
  }

  const renderUser = (item, index) => {
    return <div
      key={index}
      className={Style.user}
      style={{
        borderColor:
          item.str_response_status === 'declined' ?
            '#D82D2C' :
            item.str_response_status === 'accepted' ?
              '#87c700' :
              '#8e939b'
      }}
    >
      <div className={Style.userInfo}>
        <p
          data-tip={item.str_name + '\n-\n' + item.str_email}
          data-for={item.str_email}
        >
          {item.str_name}
        </p>
        <ReactTooltip
          id={item.str_email}
          effect="solid"
          place="top"
          type="warning"
          className={Style.tooltip}
        />
      </div>
      <div>
        <input
          type="checkbox"
          checked={item.bol_confirm}
          onChange={() => {
            handleToggleChange(item.uuid_user)
          }}
          className={Style.checkConfirm}
        />
      </div>
    </div>
  }

  return <>
    <div className={Style.tips}>
      <b>{t('email_response')}</b>:
      <b>
        <span className={Style.lump}></span>
        {t('accepted')}:<b>&ensp;{getCount('accepted')}</b>
      </b>
      <b>
        <span
          className={Style.lump}
          style={{
            borderColor: '#c73e36'
          }}
        ></span>
        {t('declined')}:<b>&ensp;{getCount('declined')}</b>
      </b>
      <b>
        <span
          className={Style.lump}
          style={{
            borderColor: '#8e939b'
          }}
        ></span>
        {t('no_response')}:<b>&ensp;{getCount('none')}</b>
      </b>
    </div>
    {
      arrUsers.filter(item => item.bol_key_taster).length > 0 ?
        <>
          <b className={Style.title}>{t('key_tasters')}</b>
          <div className={Style.userContent}>
            {
              arrUsers.filter(item => item.bol_key_taster)
                .map((item, index) => (
                  renderUser(item, index)
                ))
            }
          </div>
        </>
        : null
    }
    {
      arrUsers.filter(item => !item.bol_key_taster).length > 0 ?
        <>
          <b className={Style.title}>{t('non_key_tasters')}</b>
          <div className={Style.userContent}>
            {
              arrUsers.filter(item => !item.bol_key_taster)
                .map((item, index) => (
                  renderUser(item, index)
                ))
            }
          </div>
        </>
        : null
    }
  </>
}

export default SessionContent;