import React, { useState, useEffect } from 'react';
import editProcessCheck from './editProcessCheck.module.scss';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  Label,
  Card,
  CardRow,
  Button,
  DropdownField,
  TextArea,
  DatePickerField,
} from '../../../components';
import uuid from 'react-uuid';
import Moment from 'moment';
import SampleService from '../../services/samples/sample.service';
import BrandService from '../../services/brands/brand.service';
import PackageService from '../../services/packages/package.service';
import RoundService from '../../services/rounds/round.service';

import { useTranslation } from 'react-i18next';
import { useUser } from '../../../contexts/User';

const EditProcessCheck = props => {
  const { t } = useTranslation();
  const sampleService = new SampleService();
  const brandService = new BrandService();
  const packageService = new PackageService();
  const roundService = new RoundService();
  const history = useHistory();
  const [departmentData, setDepartmentData] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [productionLineData, setProductionLineData] = useState([]);
  const [sampleData, setSampleData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [packageData, setPackageData] = useState([]);

  const [processCheckObj, setProcessCheckObj] = useState({});
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = async () => {
    let processCheckNowObj = {
      ...processCheckObj,
      uuid_zone: user.uuid_zone,
      uuid_country: user.uuid_country,
      uuid_site: user.uuid_site,
      dat_sample_date: Moment(
        props.location.state?.values?.dat_sample_date
          ? props.location.state?.values?.dat_sample_date.substring(0, 19)
          : new Date(),
      ).format('YYYY-MM-DD[T]HH:mm:ss'),
    };
    if (props.location.state.isNewSample === false) {
      processCheckNowObj = {
        ...processCheckNowObj,
        ...props.location.state.values,
        dat_sample_date: Moment(
          props.location.state.values.dat_sample_date.substring(0, 19),
        ).format('YYYY-MM-DD[T]HH:mm:ss'),
      };
    }
    if (props.location.state.isCopySample) {
      processCheckNowObj = {
        ...processCheckNowObj,
        ...props.location.state.values,
        dat_sample_date: Moment(new Date()).format('YYYY-MM-DD[T]HH:mm:ss'),
      };
    }
    const res = await sampleService.listDepartment();
    if (res) {
      setDepartmentData(res.results);
      const defaultDepartArr = res.results.filter(
        item => item.bol_default === true,
      );
      sampleService.listProductionLine().then(res => {
        if (res) {
          setProductionLineData(res.results);
          const defaultProductionLineData = res.results.filter(
            item => item.bol_default === true,
          );
          processCheckNowObj = {
            ...processCheckNowObj,
            uuid_production_line:
              defaultProductionLineData.length > 0 &&
              defaultProductionLineData[0].uuid_production_line
                ? defaultProductionLineData[0].uuid_production_line
                : res.results[0].uuid_production_line,
          };
        }
        setProcessCheckObj({
          uuid_department:
            defaultDepartArr.length > 0 && defaultDepartArr[0].uuid_department
              ? defaultDepartArr[0].uuid_department
              : 'f1e83a3c-1306-11ee-a478-0017fa02c1e1',
          ...props.location.state.values,
          ...processCheckNowObj,
        });
      });
    }

    sampleService.listShift().then(res => {
      if (res) setShiftData(res.results);
    });

    sampleService.listCategoriesSamples().then(res => {
      if (res) setSampleData(res.results);
    });

    brandService.listBrands().then(res => {
      if (res) setBrandData(res.results);
    });
    packageService.listPackages().then(res => {
      if (res) setPackageData(res.results);
    });
  };

  const handleChange = evt => {
    const value = evt.target.value;
    const name = evt.target.name;
    if (name === 'dat_sample_date') {
      const nowDateTime = new Date().getTime();
      const changeDateTime = new Date(value).getTime();
      if (changeDateTime > nowDateTime) {
        window.alert(
          t('the_sample_date_cannot_be_earlier_than_the_current_time'),
        );
        return;
      }
    }
    setProcessCheckObj({
      ...processCheckObj,
      [name]: value,
    });
  };
  const hasTank = () => {
    return (
      processCheckObj.uuid_sample === '5f5b20ba-2eb3-11eb-ba19-0229531a6f14' ||
      processCheckObj.uuid_sample === '5f368f98-2eb3-11eb-ba17-0229531a6f14' ||
      processCheckObj.uuid_sample === '5f493756-2eb3-11eb-ba18-0229531a6f14' ||
      processCheckObj.uuid_sample === '36907a60-ee44-11ed-ba08-0017fa02c1e1' ||
      processCheckObj.uuid_sample === '3c2f932a-ee44-11ed-ba09-0017fa02c1e1' ||
      processCheckObj.uuid_sample === '4e72e744-ee44-11ed-ba0c-0017fa02c1e1' ||
      processCheckObj.uuid_sample === '32892408-ee44-11ed-ba07-0017fa02c1e1' ||
      processCheckObj.uuid_sample === '8effb8a4-aa80-4e7b-91fd-126199082de3' ||
      processCheckObj.uuid_sample === '556ad3d6-ee44-11ed-ba0d-0017fa02c1e1'
    );
  };

  const handleSave = async () => {
    if (loading) return;
    if (
      processCheckObj.uuid_zone === undefined ||
      processCheckObj.uuid_zone === null ||
      processCheckObj.uuid_zone === ''
    ) {
      window.alert(
        t('common_cannot_be_empty', { field: `${t('common_zone')}` }),
      );
      return;
    }
    if (
      processCheckObj.uuid_country === undefined ||
      processCheckObj.uuid_country === null ||
      processCheckObj.uuid_country === ''
    ) {
      window.alert(
        t('common_cannot_be_empty', { field: `${t('common_country')}` }),
      );
      return;
    }
    if (
      processCheckObj.uuid_site === undefined ||
      processCheckObj.uuid_site === null ||
      processCheckObj.uuid_site === ''
    ) {
      window.alert(
        t('common_cannot_be_empty', { field: `${t('common_site')}` }),
      );
      return;
    }
    if (
      processCheckObj.uuid_sample === undefined ||
      processCheckObj.uuid_sample === null ||
      processCheckObj.uuid_sample === ''
    ) {
      window.alert(
        t('common_cannot_be_empty', { field: `${t('common_sample')}` }),
      );
      return;
    }
    if (
      processCheckObj.uuid_sample === '5f5b20ba-2eb3-11eb-ba19-0229531a6f14' &&
      (processCheckObj.uuid_brand === undefined ||
        processCheckObj.uuid_brand === null ||
        processCheckObj.uuid_brand === '')
    ) {
      window.alert(
        t('common_cannot_be_empty', { field: `${t('common_brand')}` }),
      );
      return;
    }
    if (
      processCheckObj.uuid_shift === undefined ||
      processCheckObj.uuid_shift === null ||
      processCheckObj.uuid_shift === ''
    ) {
      window.alert(
        t('common_cannot_be_empty', { field: `${t('common_shift')}` }),
      );
      return;
    }
    if (
      processCheckObj.dat_sample_date === undefined ||
      processCheckObj.dat_sample_date === null ||
      processCheckObj.dat_sample_date === ''
    ) {
      window.alert(
        t('common_cannot_be_empty', { field: `${t('common_sample_date')}` }),
      );
      return;
    }
    if (
      processCheckObj.uuid_production_line === undefined ||
      processCheckObj.uuid_production_line === null ||
      processCheckObj.uuid_production_line === ''
    ) {
      if (
        processCheckObj.uuid_department ===
        'f1e83a3c-1306-11ee-a478-0017fa02c1e1'
      ) {
        window.alert(
          t('common_cannot_be_empty', {
            field: `${t('common_production_line')}`,
          }),
        );
        return;
      }
    }
    if (
      processCheckObj.str_ok_nok === undefined ||
      processCheckObj.str_ok_nok === null ||
      processCheckObj.str_ok_nok === ''
    ) {
      window.alert(
        t('common_cannot_be_empty', {
          field: `${t('OKNOK')}`,
        }),
      );
      return;
    }
    if (
      hasTank() &&
      (processCheckObj.str_tank === undefined ||
        processCheckObj.str_tank === null ||
        processCheckObj.str_tank === '')
    ) {
      window.alert(
        t('common_cannot_be_empty', {
          field: `${t('common_tank')}`,
        }),
      );
      return;
    }
    if (
      processCheckObj.str_ok_nok === 'NOK' &&
      (processCheckObj.str_remark === '' ||
        processCheckObj.str_remark === undefined ||
        processCheckObj.str_remark === null)
    ) {
      window.alert(
        t('common_cannot_be_empty', {
          field: `${t('common_remark')}`,
        }),
      );
      return;
    }
    setLoading(true);
    let data = {
      ...processCheckObj,
      uuid_production_line:
        processCheckObj.uuid_department ===
        'f1e83a3c-1306-11ee-a478-0017fa02c1e1'
          ? processCheckObj.uuid_production_line
          : '',
      uuid_round:
        props.location.state.isNewSample === false
          ? processCheckObj.uuid_round
          : uuid(),
    };
    const res = await roundService.saveProcessCheck(data);
    if (res.type === 'success') {
      history.push({
        pathname: '/list_process_check',
      });
    }
  };

  return (
    <div className={editProcessCheck.pageContainer}>
      <Label
        label={
          props.location.state.isNewProcessCheck
            ? t('new_process_check')
            : t('edit_process_check')
        }
        bold
        neutral
      />

      <Card bordered>
        <CardRow style={{ flexWrap: 'wrap' }}>
          <DropdownField
            name="uuid_zone"
            options={
              props.location.state.privilege.arr_zones
                ? props.location.state.privilege.arr_zones
                : []
            }
            valueField="uuid_zone"
            labelField="str_name"
            label={t('common_zone')}
            margin="10px 10px 0px 0px"
            value={
              processCheckObj.uuid_zone !== undefined
                ? processCheckObj.uuid_zone
                : ''
            }
            onChange={evt => {
              setProcessCheckObj({
                ...processCheckObj,
                uuid_zone: evt.target.value,
                uuid_country: null,
                uuid_site: null,
              });
            }}
            hideRemoveButton
            isRequired={true}
            style={{
              flex: 'auto',
              width: '32%',
            }}
          />
          <DropdownField
            name="uuid_country"
            options={
              props.location.state.privilege.arr_countries &&
              (true || processCheckObj.uuid_zone !== undefined)
                ? props.location.state.privilege.arr_countries.filter(
                    country => {
                      const idZoneAux =
                        processCheckObj.uuid_zone !== undefined
                          ? processCheckObj.uuid_zone
                          : '';
                      return country.uuid_zone === idZoneAux;
                    },
                  )
                : []
            }
            valueField="uuid_country"
            labelField="str_name"
            label={`${t('common_country')}`}
            margin="10px 10px 0px 0px"
            value={
              processCheckObj.uuid_country !== undefined
                ? processCheckObj.uuid_country
                : ''
            }
            onChange={evt => {
              setProcessCheckObj({
                ...processCheckObj,
                uuid_country: evt.target.value,
                uuid_site: null,
              });
            }}
            isRequired={true}
            hideRemoveButton
            style={{
              flex: 'auto',
              width: '32%',
            }}
          />
          <DropdownField
            name="uuid_site"
            options={
              props.location.state.privilege.arr_sites &&
              (true || processCheckObj.uuid_country !== undefined)
                ? props.location.state.privilege.arr_sites.filter(site => {
                    const idCountryAux =
                      processCheckObj.uuid_country !== undefined
                        ? processCheckObj.uuid_country
                        : '';
                    return site.uuid_country === idCountryAux;
                  })
                : []
            }
            valueField="uuid_site"
            labelField="str_name"
            label={`${t('common_site')}`}
            margin="10px 10px 0px 0px"
            value={
              processCheckObj.uuid_site !== undefined
                ? processCheckObj.uuid_site
                : ''
            }
            onChange={evt => {
              const valueCountry =
                processCheckObj.uuid_country !== undefined
                  ? processCheckObj.uuid_country
                  : props.round.uuid_country;
              const siteLength =
                props.location.state.privilege.arr_sites.filter(site => {
                  return (
                    site.uuid_country === valueCountry &&
                    evt.target.value === site.uuid_site
                  );
                });
              if (siteLength.length > 0) {
                setProcessCheckObj({
                  ...processCheckObj,
                  uuid_site: evt.target.value,
                });
              }
            }}
            isRequired={true}
            hideRemoveButton
            style={{
              flex: 'auto',
              width: '32%',
            }}
          />
          <DropdownField
            name="uuid_department"
            options={departmentData}
            valueField="uuid_department"
            labelField="str_name_department"
            label={`${t('common_department')}`}
            margin="10px 10px 0px 0px"
            value={
              processCheckObj.uuid_department !== undefined
                ? processCheckObj.uuid_department
                : ''
            }
            bolOrder={false}
            onChange={handleChange}
            isRequired={true}
            hideRemoveButton
            style={{
              flex: 'auto',
              width: '32%',
            }}
          />
          <DropdownField
            name="uuid_sample"
            options={sampleData}
            valueField="uuid_category_option"
            labelField="str_name_category_sample_option"
            label={`${t('common_sample')}`}
            margin="10px 10px 0px 0px"
            value={
              processCheckObj.uuid_sample !== undefined
                ? processCheckObj.uuid_sample
                : ''
            }
            bolOrder={false}
            onChange={handleChange}
            isRequired={true}
            hideRemoveButton
            style={{
              flex: 'auto',
              width: '32%',
            }}
          />
          {(processCheckObj.uuid_department ===
            'f1e83a3c-1306-11ee-a478-0017fa02c1e1' ||
            processCheckObj.uuid_sample ===
              '5f5b20ba-2eb3-11eb-ba19-0229531a6f14') && (
            <DropdownField
              name="uuid_brand"
              options={brandData}
              valueField="uuid_brand"
              labelField="str_name"
              label={`${t('common_brand')}`}
              margin="10px 10px 0px 0px"
              value={
                processCheckObj.uuid_brand !== undefined
                  ? processCheckObj.uuid_brand
                  : ''
              }
              bolOrder={false}
              onChange={handleChange}
              style={{
                flex: 'auto',
                width: '32%',
              }}
              isRequired={
                processCheckObj.uuid_sample ===
                '5f5b20ba-2eb3-11eb-ba19-0229531a6f14'
              }
            />
          )}
          {processCheckObj.uuid_department ===
            'f1e83a3c-1306-11ee-a478-0017fa02c1e1' && (
            <DropdownField
              name="uuid_package"
              options={packageData}
              valueField="uuid_package"
              labelField="str_name"
              label={`${t('sample_package')}`}
              margin="10px 10px 0px 0px"
              value={
                processCheckObj.uuid_package !== undefined
                  ? processCheckObj.uuid_package
                  : ''
              }
              bolOrder={false}
              onChange={handleChange}
              style={{
                flex: 'auto',
                width: '32%',
              }}
            />
          )}
          <DropdownField
            name="uuid_shift"
            options={shiftData}
            valueField="uuid_shift"
            labelField="str_name_shift"
            label={`${t('common_shift')}`}
            margin="10px 10px 0px 0px"
            value={
              processCheckObj.uuid_shift !== undefined
                ? processCheckObj.uuid_shift
                : ''
            }
            bolOrder={false}
            onChange={handleChange}
            hideRemoveButton
            isRequired={true}
            style={{
              flex: 'auto',
              width: '32%',
            }}
          />
          <DatePickerField
            name="dat_sample_date"
            type={'datetime-local'}
            label={`${t('common_sample_date')}`}
            step={1}
            margin="10px 10px 0px 0px"
            padding="8px 4px 8px 5px"
            height="39px"
            value={
              processCheckObj.dat_sample_date !== undefined
                ? processCheckObj.dat_sample_date
                : ''
            }
            onChange={handleChange}
            isRequired={true}
            maxDate={Moment(new Date()).format('YYYY-MM-DD[T]HH:mm:ss')}
            style={{
              flex: 'auto',
              width: '32%',
            }}
          />
          {processCheckObj.uuid_department ===
            'f1e83a3c-1306-11ee-a478-0017fa02c1e1' && (
            <DropdownField
              name="uuid_production_line"
              options={productionLineData}
              valueField="uuid_production_line"
              labelField="str_name_production_line"
              label={`${t('common_production_line')}`}
              margin="10px 10px 0px 0px"
              value={
                processCheckObj.uuid_production_line !== undefined
                  ? processCheckObj.uuid_production_line
                  : ''
              }
              bolOrder={false}
              onChange={handleChange}
              hideRemoveButton
              isRequired={true}
              style={{
                flex: 'auto',
                width: '32%',
              }}
            />
          )}
          <DropdownField
            name="str_ok_nok"
            options={[{ str_ok_nok: 'OK' }, { str_ok_nok: 'NOK' }]}
            valueField="str_ok_nok"
            labelField="str_ok_nok"
            label={`${t('OKNOK')}`}
            margin="10px 10px 0px 0px"
            value={
              processCheckObj.str_ok_nok !== undefined
                ? processCheckObj.str_ok_nok
                : ''
            }
            bolOrder={false}
            onChange={handleChange}
            hideRemoveButton
            isRequired={true}
            style={{
              flex: 'auto',
              width: '32%',
            }}
          />
          <TextField
            name="str_tank"
            label={t('common_tank')}
            margin="10px 10px 0px 0px"
            value={
              processCheckObj.str_tank !== undefined
                ? processCheckObj.str_tank
                : ''
            }
            onChange={handleChange}
            boxStyle={{
              flex: 'auto',
              width: '32%',
            }}
            isRequired={hasTank()}
          />
        </CardRow>
        <CardRow>
          <TextArea
            name="str_remark"
            label={t('common_remark')}
            margin="10px 10px 0px 0px"
            value={
              processCheckObj.str_remark !== undefined
                ? processCheckObj.str_remark
                : ''
            }
            onChange={handleChange}
            maxLength={300}
            isRequired={processCheckObj.str_ok_nok === 'NOK'}
          />
        </CardRow>
        <CardRow justifyContent="flex-end" flexWrap="wrap">
          <Button
            label={t('common_cancel')}
            onClick={() => {
              history.push({
                pathname: '/list_process_check',
              });
            }}
            neutral
            disabled={loading}
            margin="35px 10px 0px 0px"
          />
          <Button
            label={t('common_save')}
            onClick={handleSave}
            confirm
            loading={loading}
            margin="35px 10px 0px 0px"
          />
        </CardRow>
      </Card>
    </div>
  );
};

export default EditProcessCheck;

