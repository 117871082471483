import React, { createContext, useContext } from 'react';
import { useState } from 'react';
import RoundAppService from '../modules/services/rounds/roundApp.service';

const ProfileContext = createContext({});

const handleGetProfileLevels = () =>{ 
    let profileLevels = JSON.parse(localStorage.getItem('profileLevels'));
    return profileLevels ? profileLevels : null;
}

const handleSaveProfileLevels = (profileLevels) =>{
    if (profileLevels == null) {profileLevels = ''};
    localStorage.setItem("profileLevels", JSON.stringify(profileLevels));
}


export const ProfileProvider = ({ children }) => {
    const [profileLevels, setProfileLevels] = useState(handleGetProfileLevels());

    const handleProfileLevels = (change) => {
        //console.log(change);
        setProfileLevels(change);
        handleSaveProfileLevels(change);
    };

    
    return (
        <ProfileContext.Provider 
            value={{ 
                profileLevels: profileLevels, 
                handleProfileLevels: handleProfileLevels              
            }} 
        >
            {children}
        </ProfileContext.Provider>
    );
};

export const useProfile = () =>{
    const {
        profileLevels, 
        handleProfileLevels   
    } = useContext(ProfileContext);    

    return {
        profileLevels, 
        handleProfileLevels
    };
}

export default ProfileContext;