import React, { useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { 
	Label, 
	CardColumn,
	CardRow,
	CardImage,
	Button
} from '../';
import cardStyles from './cardRound.module.scss';
import { useTranslation } from 'react-i18next';
import beerImg from '../../assets/beer.png';

const CardRound = props => {

	const { t } = useTranslation();
	const [status, setStatus] = useState(t('common_not_started'));
    const [color, setColor] = useState('#7B7B7B');
    const [date, setDate] = useState('N/A');
    const [time, setTime] = useState('N/A');	

	useEffect(() => {
        refreshState();
    }, [props.round]);

	useEffect(()=>{
		setstyle()
	})

    const refreshState = () => {

        if(props.round.dat_start){

			let hourStart = null;
			let dateStartAux = null;
			let dateStart = null;
	  
			if(props.round.dat_start !== undefined && props.round.dat_start !== null ){
			  dateStart = props.round.dat_start.toString();
				  
			  hourStart = dateStart.substring(11,19).split(':');
			  
			  dateStart = dateStart.substring(0,10).split('-');
			  
			  dateStartAux = `${dateStart[0]}-${dateStart[1]}-${dateStart[2]}`;
			}
	  
			const dateTime = new Date(Date.parse(props.round.dat_start));

            const day = dateStart[2];
            const month = dateStart[1];
            setDate( day + '/' + month );

            const hour = hourStart[0];
            const minute = hourStart[1];
            setTime( hour + ':' + minute );
        }
		 
	};

	const setstyle = ()=>{
		if(!props.round.bol_started && !props.round.bol_finished){
            setStatus(t('common_start'));
            setColor('#7B7B7B');
        }
        if(props.round.bol_started && !props.round.bol_finished){
            setStatus(t('common_continue'));
            setColor('#F49C00');
        }
        if(props.round.bol_finished){
            setStatus(t("common_discussion"));
            setColor('#95C511');
        }
	}

	const getType = (id) => {
		if( id === '4ed984d9-021d-4d06-a992-991a24b3d7ce'){
			return 'IP';
		}

		if( id === '43b11c76-043c-11eb-b18d-026fdc1cc8f2'){
			return 'F';
		}

		if( id === 'be229b18-2eb4-11eb-ba1d-0229531a6f14'){
			return 'PTS';
		}
	} 

	return (
		<CardColumn  
			style={{
				backgroundColor: '#fff' ,
				margin: 5, 
				borderRadius: "5px",
				width: "190px", 
				maxWidth: "190px", 
				flexBasis: "190px", 
				flexGrow: 1,
				cursor: "pointer",
				borderLeft: `6px solid ${color}`,
				borderTop: `1px solid ${color}`,
				borderRight: `1px solid ${color}`,
				borderBottom: `1px solid ${color}`,
			}}
			onClick={(e) => { 
				e.stopPropagation();
				return props.handleClick() 
			}}
		>
			{/*Header*/}	
			<CardRow transparent>				 										
				<CardColumn padding="0px">
					<img className={cardStyles.beerImage} src={beerImg} alt="beer" />
				</CardColumn>
				<CardColumn flex={8} padding="5px">
					{props.round.str_name_round !== null ?
					(
						<CardColumn flex={8} padding="0px" style={{paddingLeft: "5px"}}>
							<Label bold style={{color: '#000'}} label={`${props.round.str_name_round}`}></Label>
						</CardColumn>
					) : (null)}
				
					<CardColumn flex={5} padding="0px" style={{paddingLeft: "5px"}}>
						<Label bold style={{color: '#000', fontSize: '11px'}} label={props.round.int_round}></Label>
					</CardColumn>				
				</CardColumn>
			</CardRow>

			{/*Center*/}
			<CardRow transparent> 			
				<div className={cardStyles.innerCard}>
					<Label label={t('common_round_type')+ ":"}></Label>
					<Label label={props.round.str_round_type_name}></Label>
				</div>			
			</CardRow>
			
			<CardRow transparent>			
				<div className={cardStyles.innerCard}>
					<Label label={t('common_start_date')+ ":"}></Label>
					<Label label={date + "-" + time}></Label>
				</div>
			</CardRow>
			
			<CardRow transparent>
				<div className={cardStyles.innerCard}>
					<Label label={t('common_zone')+ ":"}></Label>
					<Label label={props.round.str_round_zone}></Label>
				</div>
			</CardRow>
			
			<CardRow transparent>
				<div className={cardStyles.innerCard}>
					<Label label={t('common_status')+ ":"}></Label>
					<Label label={status}></Label>
				</div>							
			</CardRow>		

			{/*Button*/}	
			<CardRow transparent padding="5px" justifyContent="center">
				<Button 
					style={{
						backgroundColor: color,
						color: '#fff'
					}}
					label={status} 
					zIndex={0} 
					onClick={(e) => { 
						e.stopPropagation();
						props.handleClick()
					}}
				/>			
			</CardRow>
		</CardColumn>
	)
}

CardRound.propTypes = {
	round: PropTypes.object,
	handleClick: PropTypes.func
}

export default CardRound;