import React, { useCallback, useState, useEffect } from 'react';
import uuid from 'react-uuid';
import listUsersProfilesStyle from './listusersprofiles.module.scss';
import {
  GenericTable,
  Card,
  CardColumn,
  CardRow,
  TextField,
  Button,
  DropdownField,
} from '../../../components';
import ProfileService from '../../services/users/profile.service';
import AppService from '../../../app.service';
import { useTranslation } from 'react-i18next';
import ListUsersProfilesLevels from '../listUsersProfilesLevels/ListUsersProfilesLevels';
import { useProfile } from '../../../contexts/Profile';
import { useUser } from '../../../contexts/User';

const ListUsersProfiles = props => {
  const { t } = useTranslation();
  const [profile, setProfile] = useState({});
  const [profiles, setProfiles] = useState([]);
  const [privilege, setPrivilege] = useState([]);
  const [privileges, setPrivileges] = useState([]);
  const [editingProfile, setEditingProfile] = useState();
  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const [uuidZone, setUuidZone] = useState(user.uuid_zone);
  const [uuidCountry, setUuidCountry] = useState(user.uuid_country);
  const [uuidSite, setUuidSite] = useState(user.uuid_site);
  const profileService = new ProfileService();
  const appService = new AppService();
  const [edit, setEdit] = useState(false);

  const { profileLevels } = useProfile();

  useEffect(() => {
    refreshState();
  }, []);

  const handleChange = evt => {
    const value = evt.target.value;
    setProfile({
      ...profile,
      [evt.target.name]: value,
    });
  };

  const refreshState = () => {
    setLoading(true);
    //GET PRIVILEGES
    appService.listPrivileges('USER_MANAGEMENT_EDIT').then(
      res => {
        if (res.type === 'success' && res.results) {
          setPrivilege(res.results[0]);
        } else {
          window.alert(res.message);
        }
      },
      err => console.log(err),
    );

    //GET PROFILES
    profileService.listAllProfiles().then(
      res => {
        if (res.type === 'success' && res.results) {
          setProfiles(res.results);
        } else {
          window.alert(res.message);
        }
      },
      err => console.log(err),
    );

    //GET ALL PRIVILEGES
    profileService.listPrivileges().then(
      res => {
        if (res.type === 'success' && res.results) {
          setPrivileges(res.results);
          setLoading(false);
        } else {
          window.alert(res.message);
          setLoading(false);
        }
      },
      err => {
        setLoading(false);
      },
    );
  };

  const handleNewProfile = () => {
    const newProfile = {
      uuid_profile: uuid(),
      ...profile,
      uuid_zone: uuidZone,
      uuid_country: uuidCountry,
      uuid_site: uuidSite,
    };

    if (!profile.str_name?.trim().length > 0) {
      window.alert(t('common_message_empty_profile'));
      return;
    }

    if (!uuidZone) {
      window.alert(t('common_message_zone_empty'));
      return;
    }

    profileService.saveProfile(newProfile).then(
      res => {
        if (res.type === 'success' && res.results) {
          window.alert(t('user_new_profile_save'));
          refreshState();
        } else {
          window.alert(res.message);
        }
      }
    );
    handleCancel();
  };

  const handleEditProfile = useCallback(
    row => () => {
      setEditingProfile(row);
      setUuidZone(row.uuid_zone);
      setUuidCountry(row.uuid_country);
      setUuidSite(row.uuid_site);
      setProfile({
        uuid_profile: row.uuid_profile,
        str_name: row.str_name,
      });
      setEdit(true);
    },
    [privileges],
  );

  const handleRemoveProfile = row => {
    if (window.confirm(`${t('user_profile_remove')}: ${row.str_name} ?`)) {
      profileService.deleteProfile(row.uuid_profile).then(
        res => {
          if (res.type === 'success' && res.results) {
            refreshState();
          } else {
            window.alert(res.message);
          }
        }
      );
    }
  };

  const headers = [
    { description: t('common_name'), field: 'str_name' },
    { description: t('common_zone'), field: 'str_zone_name' },
    { description: t('common_country'), field: 'str_country_name' },
    { description: t('common_site'), field: 'str_site_name' },
  ];

  const handleZoneChange = evt => {
    setUuidZone(evt.target.value);
    setUuidCountry('');
    setUuidSite('');
  };

  const handleCountryChange = evt => {
    setUuidCountry(evt.target.value);
    setUuidSite('');
  };

  const handleSiteChange = evt => {
    setUuidSite(evt.target.value);
  };

  const handleCancel = () => {
    setUuidZone(user.uuid_zone);
    setUuidCountry(user.uuid_country);
    setUuidSite(user.uuid_site);
    setEdit(false);
    setEditingProfile('');
    setProfile({
      str_name: '',
    });
  };

  const handleSaveEditProfile = () => {
    if (
      profile.uuid_profile === '794c8100-7827-11ea-90a0-0212e6aa9a6a' &&
      user.uuid_profile !== profile.uuid_profile
    ) {
      alert('No permission to change admin profile');
      return false;
    }

    let send = true;

    const newProfile = {
      ...profile,
      uuid_zone: uuidZone,
      uuid_country: uuidCountry,
      uuid_site: uuidSite,
    };

    if (!profile.str_name?.trim().length > 0) {
      window.alert(t('common_message_empty_profile'));
      send = false;
      return;
    }

    if (!uuidZone) {
      window.alert(t('common_message_zone_empty'));
      send = false;
      return;
    }

    if (profileLevels !== null) {
      profileLevels.forEach((profileLevel, index) => {
        if (!profileLevel.uuid_zone) {
          window.alert(
            `${t('common_message_zone_empty')} ${t('common_in_privileges')}`,
          );
          send = false;
          return;
        }

        if (send && profileLevel.arr_privileges?.length > 0) {
          profileService
            .saveProfileLevel(profile.uuid_profile, profileLevel)
            .then(
              res => {
                if (!(res.type === 'success' && res.results)) {
                  window.alert(res.message);
                } else {
                  if (profileLevels.length - 1 === index) {
                    //window.alert(t('user_profile_save'));
                  }
                }
              },
              err => console.log(err),
            );
        }
      });
    }

    if (send) {
      profileService.saveProfile(newProfile).then(
        res => {
          if (res.type === 'success' && res.results) {
            refreshState();
            window.alert(t('user_profile_save'));
          } else {
            window.alert(res.message);
          }
        },
        error => {
          //console.log(error);
        },
      );

      handleCancel();
    }
  };

  return (
    <div className={listUsersProfilesStyle.pageContainer}>
      <Card transparent margin="0px">
        <CardRow transparent flexWrap="wrap">
          <CardColumn padding="5px">
            <Card bordered>
              <CardRow>
                <TextField
                  name="str_name"
                  label={t('user_profile_name')}
                  margin="0px 10px 0px 0px"
                  value={profile.str_name}
                  onChange={handleChange}
                />
              </CardRow>
              <DropdownField
                name="uuid_zone"
                options={privilege.arr_zones ? privilege.arr_zones : []}
                valueField="uuid_zone"
                labelField="str_name"
                label={t('common_zone')}
                margin="10px 10px 0px 0px"
                value={uuidZone}
                onChange={e => {
                  handleZoneChange(e);
                }}
              />
              <DropdownField
                name="uuid_country"
                options={
                  privilege.arr_countries && uuidZone
                    ? privilege.arr_countries.filter(country => {
                        return country.uuid_zone === uuidZone;
                      })
                    : []
                }
                valueField="uuid_country"
                labelField="str_name"
                label={t('common_country')}
                margin="10px 10px 0px 0px"
                value={uuidCountry}
                onChange={e => {
                  handleCountryChange(e);
                }}
              />
              <DropdownField
                name="uuid_site"
                options={
                  privilege.arr_sites && uuidCountry
                    ? privilege.arr_sites.filter(site => {
                        return site.uuid_country === uuidCountry;
                      })
                    : []
                }
                valueField="uuid_site"
                labelField="str_name"
                label={t('common_site')}
                margin="10px 10px 0px 0px"
                value={uuidSite}
                onChange={e => handleSiteChange(e)}
              />
              <CardRow justifyContent="flex-end" padding={10}>
                {edit === false ? (
                  <Button
                    className={listUsersProfilesStyle.newProfileButton}
                    label="New profile"
                    onClick={handleNewProfile}
                    round
                    confirm
                  />
                ) : (
                  <>
                    <Button
                      className={listUsersProfilesStyle.newProfileButton}
                      label={t('user_profile_save')}
                      onClick={handleSaveEditProfile}
                      style={{ marginRight: '5px' }}
                      round
                      confirm
                    />

                    <Button
                      className={listUsersProfilesStyle.newProfileButton}
                      label={t('common_cancel')}
                      onClick={handleCancel}
                      round
                      confirm
                    />
                  </>
                )}
              </CardRow>
            </Card>
          </CardColumn>

          <CardColumn padding="5px">
            {editingProfile ? (
              <ListUsersProfilesLevels
                profile={editingProfile}
                privilege={privilege}
                privileges={privileges}
              />
            ) : (
              <GenericTable
                arrHeader={headers}
                arrRow={profiles}
                searchable={true}
                onEdit={privilege.bol_access ? handleEditProfile : null}
                onRemove={privilege.bol_access ? handleRemoveProfile : null}
                loading={loading}
              />
            )}
          </CardColumn>
        </CardRow>
      </Card>
    </div>
  );
};

export default ListUsersProfiles;
