import AppService from '../app.service';
import configs from '../configs';
const appService = new AppService();

export function checkVersion() {
  appService.validateVersion().then(res => {
    if (res.type === 'success' && res.results) {
      if (res.results[0].val_version !== configs.val_version) {
        if (
          window.confirm('You have a different version than the one published!')
        ) {
          window.location.reload(true);
        } else {
          window.location.reload(false);
        }
      }
    } else {
      window.alert(res.message);
    }
  });
}
