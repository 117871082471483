import React, { useState, useEffect } from 'react';
import editCommentStyle from './editcomment.module.scss';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  Label,
  Card,
  CardRow,
  DropdownField,
  Button,
  CardColumn,
  DropdownMultiField,
} from '../../../components';
import RoundService from '../../services/rounds/round.service';
import { useTranslation } from 'react-i18next';

const EditComment = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [comment, setComment] = useState({});

  // services
  const roundService = new RoundService();

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    roundService.listCategories().then(
      res => {
        if (res.type === 'success' && res.results) {
          setCategories(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );

    setComment(props.location.state.values);
  };

  const handleChange = evt => {
    const value = evt.target.value;
    setComment({
      ...comment,
      [evt.target.name]: value,
    });
  };

  const handleSave = () => {
    const commentTemp = comment;
    if (!commentTemp.uuid_category || commentTemp.uuid_category.length === 0) {
      window.alert(
        t('common_cannot_be_empty', { field: `${t('common_category')} ` }),
      );
      return;
    }

    if (
      commentTemp.str_name_en?.trim().length === 0 ||
      commentTemp.str_name_en === undefined
    ) {
      window.alert(
        t('common_cannot_be_empty', {
          field: `${t('common_comment')} (English)`,
        }),
      );
      return;
    }
    commentTemp.uuid_category = commentTemp.uuid_category.map(item => {
      return item.uuid_category;
    });

    roundService.updateComment(commentTemp).then(
      res => {
        if (res.type === 'success' && res.results) {
          history.goBack();
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  };

  return (
    <div className={editCommentStyle.pageContainer}>
      <Label label={t('comment_edit_comment')} bold neutral />

      <Card bordered>
        <div className={editCommentStyle.pageContainerHeader}>
          <DropdownMultiField
            name="uuid_category"
            options={categories}
            valueField="uuid_category"
            labelField="str_name"
            label={t('common_category')}
            margin="10px 10px 0px 0px"
            value={comment.uuid_category}
            onChange={handleChange}
          />
        </div>

        <CardRow flexWrap="wrap">
          <TextField
            name="str_name_en"
            label={`${t('common_comment')} (English)`}
            margin="10px 10px 0px 0px"
            value={comment.str_name_en}
            onChange={handleChange}
          />
          <TextField
            name="str_name_pt"
            label={`${t('common_comment')} (Portuguese)`}
            margin="10px 10px 0px 0px"
            value={comment.str_name_pt}
            onChange={handleChange}
          />
          <TextField
            name="str_name_es"
            label={`${t('common_comment')} (Spanish)`}
            margin="10px 10px 0px 0px"
            value={comment.str_name_es}
            onChange={handleChange}
          />
        </CardRow>

        <CardRow flexWrap="wrap">
          <TextField
            name="str_name_de"
            label={`${t('common_comment')} (German)`}
            margin="10px 10px 0px 0px"
            value={comment.str_name_de}
            onChange={handleChange}
          />
          <TextField
            name="str_name_fr"
            label={`${t('common_comment')} (French)`}
            margin="10px 10px 0px 0px"
            value={comment.str_name_fr}
            onChange={handleChange}
          />
          <TextField
            name="str_name_it"
            label={`${t('common_comment')} (Italian)`}
            margin="10px 10px 0px 0px"
            value={comment.str_name_it}
            onChange={handleChange}
          />
        </CardRow>

        <CardRow flexWrap="wrap">
          <TextField
            name="str_name_nl"
            label={`${t('common_comment')} (Dutch)`}
            margin="10px 10px 0px 0px"
            value={comment.str_name_nl}
            onChange={handleChange}
          />
          <TextField
            name="str_name_ru"
            label={`${t('common_comment')} (Russian)`}
            margin="10px 10px 0px 0px"
            value={comment.str_name_ru}
            onChange={handleChange}
          />
          <TextField
            name="str_name_uk"
            label={`${t('common_comment')} (Ukranian)`}
            margin="10px 10px 0px 0px"
            value={comment.str_name_uk}
            onChange={handleChange}
          />
        </CardRow>
        <CardRow flexWrap="wrap">
          <TextField
            name="str_name_zh"
            label={`${t('common_comment')} (Chinese)`}
            margin="10px 10px 0px 0px"
            value={comment.str_name_zh}
            onChange={handleChange}
          />
          <TextField
            name="str_name_kr"
            label={`${t('common_comment')} (Korean)`}
            margin="10px 10px 0px 0px"
            value={comment.str_name_kr}
            onChange={handleChange}
          />

          <CardColumn></CardColumn>
        </CardRow>
        <CardRow justifyContent="flex-end" flexWrap="wrap">
          <Button
            label={t('common_cancel')}
            onClick={() => {
              history.goBack();
            }}
            neutral
            margin="35px 10px 0px 0px"
          />
          <Button
            label={t('common_save')}
            onClick={handleSave}
            confirm
            margin="35px 10px 0px 0px"
          />
        </CardRow>
      </Card>
    </div>
  );
};

export default EditComment;
