import ListEmailConfiguration from './listEmailConfiguration/ListEmailConfiguration';
import EditEmailConfiguration from './editEmailConfiguration/EditEmailConfiguration.jsx';

export default {
  routeModule: {
    path: '/list_email_configuration',
    component: ListEmailConfiguration,
    key: 'email_distribution',
    privilege: 'EMAIL_MANAGEMENT_LIST',
    routeComponents: [
      {
        path: '/edit_email_configuration',
        component: EditEmailConfiguration,
        key: 'eidt_email_distribution',
        privilege: 'EMAIL_MANAGEMENT_LIST',
      },
      {
        path: '/new_email_configuration',
        component: EditEmailConfiguration,
        key: 'new_email_configuration',
        privilege: 'EMAIL_MANAGEMENT_LIST',
      },
    ],
  },
};

