import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import uuid from 'react-uuid';
import listRoundsStyle from './listrounds.module.scss';
import { GenericTable, Label, CustomColumns } from '../../../components';
import ListRoundsStatus from '../listRoundsStatus/ListRoundsStatus';
import RoundService from '../../services/rounds/round.service';
import AppService from '../../../app.service';
import { Copy, Lock, Unlock } from 'react-feather';
import UserService from '../../services/users/user.service';

const ListRounds = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [privilege, setPrivilege] = useState([]);
  const [blockRoundPrivilege, setBlockRoundPrivilege] = useState([]);
  const [sampleEditPrivilege, setSampleEditPrivilege] = useState([])
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editPermission, setEditPermission] = useState([
    { str_name: 'common_id', bol_status: null },
    { str_name: 'common_name', bol_status: null },
    { str_name: 'common_status', bol_status: null },
    { str_name: 'common_zone', bol_status: null },
    { str_name: 'common_country', bol_status: null },
    { str_name: 'common_site', bol_status: null },
    { str_name: 'common_start_date', bol_status: null },
    { str_name: 'common_finish_date', bol_status: null },
    { str_name: 'common_round_type', bol_status: null },
    { str_name: 'common_tasters', bol_status: null },
    { str_name: 'common_samples', bol_status: null },
    { str_name: 'common_template', bol_status: null },
    { str_name: 'common_discussion', bol_status: null },
    { str_name: 'round_close', bol_status: null },
    { str_name: 'report_list_report', bol_status: null },
  ]);
  const roundService = new RoundService();
  const appService = new AppService();
  const userService = new UserService();
  const [showCustomColumns, setShowCustomColumns] = useState(false);

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = async () => {
    setLoading(true);
    await appService.listPrivileges('SAMPLE_MANAGEMENT_EDIT').then(
      res => {
        if (res.type === 'success' && res.results) {
          setSampleEditPrivilege(res.results[0]);
        } else {
          window.alert(res.message);
        }
        //GET PRIVILEGES
        appService.listPrivileges('ROUND_MANAGEMENT_EDIT').then(
          res => {
            if (res.type === 'success' && res.results) {
              setPrivilege(res.results[0]);
              setLoading(false);
            } else {
              window.alert(res.message);
              setLoading(false);
            }
          },
          error => {
            //console.log(error);
            setLoading(false);
          },
        );

        appService.listPrivileges('ROUND_BLOCK_RESULTS').then(
          res => {
            if (res.type === 'success' && res.results) {
              setBlockRoundPrivilege(res.results[0]);
            } else {
              //console.log(res);
            }
          },
          error => {
            //console.log(error);
          },
        );

        userService.listCustomColumns('round').then(res => {
          if (
            res.type === 'success' &&
            res.results[0].user_sp_se_column_options &&
            res.results[0].user_sp_se_column_options.length ===
            editPermission.length
          ) {
            setEditPermission(res.results[0].user_sp_se_column_options);
          }
        });
      }
    );
  };

  const handleNewRound = useCallback(() => {
    const uuid_round = uuid();
    roundService.updateRound({ uuid_round: uuid_round }).then(
      res => {
        if (res.type === 'success' && res.results) {
          history.push({
            pathname: '/new_round',
            state: {
              values: {
                uuid_round: uuid_round,
                int_round: res.results[0].round_sp_in_up_admin_round,
                str_concentration: ['', '', '', '', '', ''],
              },
              privilege: privilege,
              sampleEditPrivilege: sampleEditPrivilege,
              isNewRound: true,
            },
          });
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  }, [privilege]);

  const handleShowStatus = useCallback(
    (values, index) => () => {
      //console.log(values.arr_status);
      setStatus(values.arr_status);
    },
    [],
  );

  const handleHideStatus = useCallback(
    (values, index) => () => {
      setStatus([]);
    },
    [],
  );

  const handleEditRound = useCallback(
    values => () => {
      if (values.dat_finish_real !== null) {
        window.alert(t('round_closed_message'));
        return;
      }
      values.str_concentration = values.str_concentration
        ? values.str_concentration.split(',')
        : ['', '', '', '', '', ''];
      history.push({
        pathname: '/edit_round',
        state: {
          values: values,
          privilege: privilege,
          sampleEditPrivilege: sampleEditPrivilege,
          isNewRound: false,
        },
      });
    },
    [privilege],
  );

  const handleRemoveRound = values => {
    return new Promise(async (resolve, reject) => {
      if (appService?.user?.uuid_user !== values.uuid_usu_ins) {
        await appService
          .listPrivileges('ROUND_DELETE_OTHER_USERS_ROUND')
          .then(res => {
            if (
              !res ||
              !res.results ||
              !res.results.length ||
              !(
                res.results[0].arr_sites &&
                res.results[0].arr_sites.some(
                  x => x.uuid_site === values.uuid_site,
                )
              ) ||
              !(
                res.results[0].arr_countries &&
                res.results[0].arr_countries.some(
                  x => x.uuid_country === values.uuid_country,
                )
              ) ||
              !(
                res.results[0].arr_zones &&
                res.results[0].arr_zones.some(
                  x => x.uuid_zone === values.uuid_zone,
                )
              )
            ) {
              alert(t('round_delete_other_users_rounds'));
              throw t('round_delete_other_users_rounds');
            }
          });
      }
      if (window.confirm(`${t('round_remove_round')} ${values.int_round} ?`)) {
        roundService.deleteRound(values.uuid_round).then(
          res => {
            if (res.type === 'success' && res.results) {
              resolve(true);
            } else {
              window.alert(res.message);
              resolve(true);
            }
          },
          error => {
            //console.log(error);
            reject(error);
          },
        );
      }
    });
  };

  const handleCloseRound = async values => {
    if (window.confirm(`${t('round_close_message')}: ${values.int_round}`)) {
      const res = await roundService.closeRound({
        uuid_round: values.uuid_round,
        uuid_site: values.uuid_site,
      });
      if (res.type === 'success' && res.results) {
        await refreshState();
        setPrivilege(res.results[0]);
        setLoading(false);
      } else {
        window.alert(res.message);
        setLoading(false);
      }
    }
  };

  const handleGoDicussion = roundClicked => {
    sessionStorage.setItem('roundjump', 1);
    sessionStorage.setItem('isShowButton', 'isShow');
    if (
      roundClicked.uuid_round_type === 'be229b18-2eb4-11eb-ba1d-0229531a6f14'
    ) {
      history.push({ pathname: `/tasting_discussion_ok_nok` }, roundClicked);
      return;
    } else if (
      roundClicked.uuid_round_type === '02095a80-6cc4-11ec-8d68-000000000000'
    ) {
      history.push({
        pathname: `/tasting_discussion_threshold/${roundClicked.uuid_round}`,
      });
      return;
    } else {
      history.push({
        pathname: `/tasting_discussion`,
        state: {
          ...roundClicked,
          round: roundClicked,
          isFromDiscussion: true,
        },
      });
      return;
    }
  };

  const handleDuplicateRound = useCallback((values, index) => () => {
    let round = values;
    let uuid_round_old = round.uuid_round;
    round.uuid_round = uuid();
    round.bol_template = 0;
    round.uuid_split_round = null;
    if (round.str_round_type !== 'OK/NOK') {
      round.str_name_round = '';
      round.str_zip_documents = null;
      round.uuid_kpi_codes = null;
      round.bol_is_approve_reject = 0;
      round.bol_ranked = 0;
      round.uuid_brand = '';
      round.threshold_batch_code = '';
      round.bol_is_flavor_id_random = 0;
    }

    round.str_concentration = round.str_concentration
      ? round.str_concentration.split(',')
      : ['', '', '', '', '', ''];
    roundService.updateRound(round).then(
      res => {
        if (res.type === 'success' && res.results) {
          round.int_round = res.results[0].round_sp_in_up_admin_round;
          roundService
            .duplicateRound({
              uuid_round: round.uuid_round,
              uuid_round_copy: uuid_round_old,
            })
            .then(res => {
              if (res.type === 'success' && res.results) {
                history.push({
                  pathname: '/edit_round',
                  state: {
                    values: {
                      ...round,
                      arr_status: [],
                    },
                    privilege: privilege,
                    sampleEditPrivilege: sampleEditPrivilege,
                    isNewRound: false,
                    isCopyRound: true,
                  },
                });
              }
            });
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
      [privilege],
    );
  });
  const handleChangeBlockRoundsResults = values => {
    return new Promise(async (resolve, reject) => {
      if (
        !blockRoundPrivilege.arr_sites?.some(
          x => x.uuid_site === values.uuid_site,
        )
      ) {
        alert(t('round_block_results_no_privilege'));
        reject(true);
        return false;
      }
      if (
        window.confirm(
          `${t('round_block_results_confirm')} ${values.int_round}?`,
        )
      ) {
        roundService
          .blockRoundResults({
            uuid_round: values.uuid_round,
            bol_block_results: values.bol_block_results === 0 ? 1 : 0,
            uuid_user: appService?.user?.uuid_user,
          })
          .then(
            res => {
              if (res.type === 'success' && res.results) {
                resolve(true);
              } else {
                window.alert(res.message);
                resolve(true);
              }
            },
            err => {
              alert(t('round_block_results_no_privilege'));
              reject(true);
            },
          );
      }
    });
  };

  const handleReopen = async values => {
    if (window.confirm(`${t('round_reopen_message')}: ${values.int_round}`)) {
      setLoading(true);
      const res = await roundService.reOpenRound({
        uuid_round: values.uuid_round,
        uuid_site: values.uuid_site,
      });

      if (res.type === 'success' && res.results) {
        setLoading(false);
      } else {
        window.alert(res.message);
        setLoading(false);
      }
    }
  };

  const filterHeaders = [
    {
      description: t('common_id'),
      field: 'int_round',
      cache: true,
      fieldCache: 'round.int_round',
    },
    {
      description: t('common_name'),
      field: 'str_name_round',
      cache: true,
      fieldCache: 'round.str_name_round',
    },
    {
      description: t('common_status'),
      field: 'str_status',
      shouldTranslate: true,
      onMouseEnter: handleShowStatus,
      onMouseLeave: handleHideStatus,
      cache: true,
      fieldCache: 'round.str_status',
    },
    {
      description: t('common_zone'),
      field: 'str_zone_name',
      cache: true,
      fieldCache: 'round.str_zone_name',
    },
    {
      description: t('common_country'),
      field: 'str_country_name',
      cache: true,
      fieldCache: 'round.str_country_name',
    },
    {
      description: t('common_site'),
      field: 'str_site_name',
      cache: true,
      fieldCache: 'round.str_site_name',
    },
    {
      description: t('common_start_date'),
      width: '100px',
      field: 'dat_start',
      type: 'datetime',
      cache: true,
      fieldCache: 'round.dat_start',
    },
    {
      description: t('common_finish_date'),
      width: '100px',
      field: 'dat_finish',
      type: 'datetime',
      cache: true,
      fieldCache: 'round.dat_finish',
    },
    {
      description: t('common_round_type'),
      field: 'str_round_type',
      cache: true,
      fieldCache: 'round.str_round_type',
    },
    {
      description: t('common_tasters'),
      field: 'int_tasters',
      notSearchable: true,
    },
    {
      description: t('common_samples'),
      field: 'int_samples',
      cache: true,
      fieldCache: 'round.int_samples',
    },
    {
      description: `${t('common_template')}`,
      field: 'uuid_round_type',
      fieldToSearch: 'str_template_name',
      type: 'template_hidden',
      action: handleDuplicateRound,
      ruleToShow: 'EQUAL_CHECKBOX',
      content_template: (
        <>
          <Copy />
          <p
            style={{
              marginTop: '-18px',
              fontSize: '12px',
              fontWeight: 'bold',
              marginLeft: '6px',
            }}
          >
            T
          </p>
        </>
      ),
      content: <Copy />,
      className: listRoundsStyle.buttonContainer,
      equal: 'be229b18-2eb4-11eb-ba1d-0229531a6f14',
      is_true: 'bol_template',
      content_compare: 'Yes',
      buttonWidth: '30px',
    },
    {
      description: t('common_discussion'),
      field: 'dat_finish_real',
      type: 'button',
      action: handleGoDicussion,
      ruleToShow: 'NULL',
      hideElement: row =>
        row.uuid_round_type === '02095a80-6cc4-11ec-8d68-000000000000',
      buttonReadOnly: row =>
        !row?.int_users_finished || row.int_users_finished === '0',
      dataTip: row =>
        !row?.int_users_finished || row.int_users_finished === '0'
          ? t('round_disabled_discussion_tooltip')
          : '',
    },
    {
      description: t('round_close'),
      field: 'dat_finish_real',
      type: 'button',
      action: handleCloseRound,
      async: true,
      ruleToShow: 'NULL',
      reOpenAction: handleReopen,
      refresh: true,
    },
    {
      description: t('report_list_report'),
      type: 'template_hidden',
      action: handleChangeBlockRoundsResults,
      content_template: <Lock color="#B11F24" />,
      content: <Unlock color="#95c511" />,
      is_true: 'bol_block_results',
      content_compare: 1,
      buttonWidth: '30px',
      className: listRoundsStyle.buttonContainer,
      async: true,
      hasAction: true,
      ruleToShow: 'NULL',
      notSearchable: true,
    },
  ];
  const headers = filterHeaders.filter((item, index) => {
    return (
      editPermission[index].bol_status === null ||
      editPermission[index].bol_status === 1
    );
  });

  return (
    <div className={listRoundsStyle.pageContainer}>
      {showCustomColumns && (
        <CustomColumns
          title={t('column_options')}
          explain={t('select_which_of_the_columns_do_you_want_to_be_displayed')}
          round={'round'}
          arrHeader={headers}
          setShow={setShowCustomColumns}
          onChange={setEditPermission}
          values={editPermission}
        />
      )}
      <Label label={t('round_list_rounds')} bold neutral />
      <ListRoundsStatus key="status" status={status} />
      <GenericTable
        setShowCustomColumns={setShowCustomColumns}
        originHeader={filterHeaders}
        arrHeader={headers}
        dataServer={roundService.listRoundsByServer}
        searchable={true}
        onEdit={privilege.bol_access ? handleEditRound : null}
        onRemove={privilege.bol_access ? handleRemoveRound : null}
        loading={loading}
        newRegister={
          privilege.bol_access
            ? {
              onClick: handleNewRound,
              label: t('round_new_round'),
            }
            : null
        }
        headerStyle={{ minWidth: '60px' }}
      />
    </div>
  );
};
export default ListRounds;

