import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid';
import listPackagesStyle from './listpackages.module.scss';
import {
  GenericTable,
  Label
} from '../../../components';
import PackageService from '../../services/packages/package.service';
import AppService from '../../../app.service';
import { useTranslation } from 'react-i18next';

const ListPackages = props => {
  
  const { t } = useTranslation();
  const history = useHistory();
  const [packages, setPackages] = useState([]);
  const [privilege, setPrivilege] = useState([]);
  const [loading, setLoading] = useState(false);
  const packageService = new PackageService();
  const appService = new AppService();

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);
    //GET PRIVILEGES
    appService.listPrivileges('PACKAGE_MANAGEMENT_EDIT').then((res) => {
      if(res.type === 'success' && res.results){
        setPrivilege(res.results[0]);
      }else{
        window.alert(res.message);
      }
    }, (error) => {
      //console.log(error);
    });

    //GET Packages
    packageService.listPackages().then(res => {
      if (res.type === 'success' && res.results) {
        setPackages(res.results.map(e => ({...e, str_volumes: e.arr_volumes?.map(f => f.str_name).join(', ')})));
        setLoading(false);
      } else {
        window.alert(res.message);
        setLoading(false);
      }
    }, err => {
      //console.log(err);
      setLoading(false);
    });
  };

  const handleNewPackage = useCallback(
    () => {
      history.push({pathname: '/edit_packages', state: {values: {uuid_package: uuid()}, privilege: privilege}});
    }, [privilege]
  );

  const handleEditGroup = useCallback(
    (values) => () => {
      history.push({pathname: '/edit_packages', state: {values: values, privilege: privilege}});
    }, [privilege]
  );

  const handleRemoveGroup = (values) => {
      if(window.confirm(`${t('package_remove_package')}: ${values.str_name}`)){
        packageService.deletePackage(values.uuid_package).then((res) => {
          if(res.type === 'success' && res.results){
            refreshState();
          }else{
            window.alert(res.message);
          }
        }, (error) => {
          //console.log(error);
        });

      }
    }

  const headers = [
    { description: t('common_name'), field: 'str_name'},
    { description: t('package_volumes'), field: 'str_volumes'}
  ];

  return (
    <div className={listPackagesStyle.pageContainer}>
      <Label 
        label={t('package_list_packages')}
        bold
        neutral
      />
      <GenericTable 
        arrHeader={headers}
        arrRow={packages}
        searchable={true}
        onEdit={privilege.bol_access ? handleEditGroup : null}
        onRemove={privilege.bol_access ? handleRemoveGroup : null}
        newRegister={privilege.bol_access ? {
          onClick: handleNewPackage,
          label: t('package_new_package')
        } : null}
        loading={loading}
      />
    </div>
  );
};

export default ListPackages;