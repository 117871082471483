import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styles from './UpdateSprintPatchNotes.module.scss';
import userService from '../../services/users/user.service';
import {
  Button,
  Card,
  CardColumn,
  CardRow,
  Label,
  TextField,
} from '../../../components';

const UpdateSprintPatchNotes = props => {
  const userServices = new userService();

  const { t } = useTranslation();
  const history = useHistory();

  const [sprintNumber, setSprintNumber] = useState('');
  const [notes, setNotes] = useState(['']);

  const handleChangeSprintNumber = value => {
    setSprintNumber(value);
  };

  const handleChangeNotes = (value, index) => {
    setNotes(prevState => {
      const notesTemp = [...prevState];
      notesTemp[index] = value;

      return notesTemp;
    });
  };

  const handleAddNote = () => {
    setNotes(prevState => {
      const notesTemp = [...prevState];
      notesTemp.push('');

      return notesTemp;
    });
  };

  const handleValidateForm = () => {
    if (sprintNumber < 1) {
      window.alert(t('sprint_number_greater_than_zero'));
      return false;
    }

    if (notes.length < 1) {
      window.alert(t('sprint_notes_at_least_one_note'));
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    const formData = {
      patchNotes: notes,
      currentSprint: sprintNumber,
    };

    try {
      const res = await userServices.updateSprintPatchNotes(formData);

      if (res.type === 'success') {
        window.alert('sprint_patch_notes_success_update');
        return;
      }

      window.alert('sprint_patch_notes_error_update');
    } catch (err) {
      window.alert('sprint_patch_notes_error_update');
    }
  };

  const handleSubmit = () => {
    const validateResult = handleValidateForm();

    if (validateResult) {
      handleSave();
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div>
      <Label label={t('update_sprint_patch_notes')} bold neutral />

      <Card bordered>
        <CardRow flexWrap="wrap">
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                label={t(`sprint_number`)}
                margin="10px 10px 0px 0px"
                value={sprintNumber}
                onChange={e => handleChangeSprintNumber(e.target.value)}
                number
              />
            </CardRow>

            <div className={styles.sprintPatchNotesContainer}>
              <div className={styles.patchNotesHeaderLabel}>
                <div>{t('sprint_patch_notes')}</div>
              </div>

              <div>
                {notes.map((note, i) => (
                  <TextField
                    label={`${t('note')} ${i + 1}`}
                    margin="10px 10px 0px 0px"
                    value={note}
                    onChange={e => handleChangeNotes(e.target.value, i)}
                    text
                  />
                ))}
              </div>
            </div>
            <div className={styles.buttonAddNoteContainer}>
              <div>
                <Button
                  className={styles.buttonAddNote}
                  confirm
                  onClick={handleAddNote}
                >
                  +
                </Button>
              </div>
            </div>
          </CardColumn>
        </CardRow>

        <CardRow justifyContent="flex-end" flexWrap="wrap">
          <Button
            label={t('common_cancel')}
            onClick={handleCancel}
            neutral
            margin="35px 10px 0px 0px"
          />
          <Button
            label={t('common_save')}
            onClick={handleSubmit}
            confirm
            margin="35px 10px 0px 0px"
          />
        </CardRow>
      </Card>
    </div>
  );
};

export default UpdateSprintPatchNotes;
