import React, {useState, Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import buttonStyle from './buttonList.module.scss';

const ButtonList = props => {
	const [currentSelected, setCurrentSelected] = useState("");	
	
	useEffect(() => {
    refreshState();
  }, [props.list, props.comment, props.currentSelected]);

	const refreshState = () => { 			
		if(props.comment){			
			setCurrentSelected(props.comment);
		} else {
			setCurrentSelected(props.currentSelected || props.defaultSelected);
		}		
	};

	const getClassName = (active) => {		
		let classes = buttonStyle.button + ' ';

		if (active) {
			classes += buttonStyle.active + ' ';			
		}

		if (props.arrow) {
			classes += buttonStyle.buttonArrow + ' ';
		}

		
		return classes;
	}

	const styleObj = {				
		zIndex: props.zIndex !== undefined ? props.zIndex : 1,
		...props.style
	}
	
	const handleOnClick = (e, funcData) =>{		
		if(props.comment){
			setCurrentSelected(e.target.value);
		}		
		
		if(funcData){
			props.onClick(e, funcData);
		} else {
			props.onClick(e, setCurrentSelected);
		}		
		refreshState();
	}
		
	return (		
		props.list ? 
		props.list.map((button, i) => {
			let active = !currentSelected? (props.defaultSelected === button[props.valueField? props.valueField: 'value']? 1 : 0) : (currentSelected === button[props.valueField? props.valueField: 'value']? 1 : 0);						
			let styleObjTemp = styleObj;			
			if (active){
				if(button.activeColor){
					styleObjTemp = {...styleObjTemp, backgroundColor: `${button.activeColor}`};
				}				
			}
			return (
				<button 
					key={i}
					className={getClassName(button.activeColor? false : active)} 
					onClick={ (e) => props.disabled ? null : handleOnClick(e,button.funcData? button.funcData : null)}
					style={styleObjTemp}
					value={button[props.valueField ? props.valueField : 'value']}
				>
					{ button[props.labelField? props.labelField: 'label'] }
				</button>
			);
		})
		: 
		''
	);
}

ButtonList.propTypes = {
	defaultSelected: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	list: PropTypes.array,		
	labelField: PropTypes.string,
	valueField: PropTypes.string,		
	zIndex: PropTypes.number,
	style: PropTypes.object,
	arrow: PropTypes.bool,
	comment: PropTypes.string
}

export default ButtonList