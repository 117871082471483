import MainService from './main.service';

export default class ThresholdService {
  mainService = null;

  constructor() {
    this.mainService = new MainService();
    this.path = '/v1/admin';
    this.path_app = '/v1/app';
  }

  listSets = body => {
    return this.mainService.post(`${this.path}/list-sets`, body);
  };

  suffleSets = body => {
    return this.mainService.post(`${this.path}/shuffle-sets`, body);
  };

  updateSet = body => {
    return this.mainService.post(this.path + '/update-set', body);
  };

  listFlavors = body => {
    return this.mainService.post(`${this.path}/list-flavors`, body);
  };

  listSuppliers = body => {
    return this.mainService.post(`${this.path}/list-suppliers`, body);
  };

  listUnits = () => {
    return this.mainService.post(`${this.path}/list-units`);
  };

  listTasteThreshold = body => {
    return this.mainService.post(`${this.path_app}/list-taste-threshold`, body);
  };

  updateSetAnswer = body => {
    return this.mainService.post(this.path_app + '/update-set-answer', body);
  };

  listColors = body => {
    return this.mainService.post(`${this.path}/list-colors`, body);
  };
  updateFlavorRandom = body => {
    return this.mainService.post(`${this.path}/update_flavor_random`, body);
  };
}
