import React from 'react';
import listReportsStyle from './listureports.module.scss';
import { Label, Tab } from '../../../components';
import { useTranslation } from 'react-i18next';
import ListReportsKpi from '../listReportsKpi/ListReportsKpi';
import ListReportsTasting from '../listReportsTasting/ListReportsTasting';
import ListReportsProfile from '../ListReportsProfile/ListReportsProfile.jsx';
import ListReportsZone from '../ListReportsZone/ListReportsZone.jsx';

const ListReports = props => {
  const { t } = useTranslation();

  const tabs = [
    {
      index: 1,
      description: t('report_list_report_taste'),
      component: () => <ListReportsTasting state={props.location.state} />,
    },
    {
      index: 2,
      description: t('GBSI BSSI & Dispersion'),
      dataTip: 'Verifying',
      component: () => <ListReportsZone />,
    },
    {
      index: 3,
      description: t('reports_by_producing_site'),
      component: () => <ListReportsProfile />,
    },
    {
      index: 4,
      description: t('Brand Report'),
      component: () => <ListReportsKpi />,
    },
  ];

  return (
    <div className={listReportsStyle.pageContainer}>
      <Label label={t('report_list_report')} bold neutral />

      <Tab tabs={tabs} selectedTabIndex={1} justifyContent="flex-start" />
    </div>
  );
};

export default ListReports;
