import ListPackages from './listPackages/ListPackages';
import EditPackage from './editPackage/EditPackage';

export default {
    routeModule: {
        path: '/list_packages',
        component: ListPackages,
        key: 'package_list_packages',
        privilege: 'PACKAGE_MANAGEMENT_LIST',
        routeComponents: [{
            path: '/edit_packages',
            component: EditPackage,
            key: 'Edit Package',
            privilege: 'PACKAGE_MANAGEMENT_EDIT'
        }],
        subMenu: 'menu_product_management'
    }
};
