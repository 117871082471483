import MainService from './main.service';

export default class UserService {
  mainService = null;
  path = null;

  constructor() {
    this.mainService = new MainService();
    this.path = '/v1/user';
  }

  SaveCustomColumns = body => {
    return this.mainService.post(this.path + '/user_column_options', body);
  };

  listCustomColumns = type => {
    return this.mainService.get(this.path + '/user_column_options/' + type);
  };

  listUsers = uuid_user => {
    return this.mainService.get(this.path + '/list_users/' + uuid_user);
  };

  listUsersByServer = body => {
    return this.mainService.post(this.path + '/list_users/pagination', body);
  };

  findUser = obj_user => {
    return this.mainService.post(this.path + '/find_users', obj_user);
  };

  updateUser = obj_user => {
    return this.mainService.post(this.path + '/update_user', obj_user);
  };

  updateNonAbiUser = obj_user => {
    return this.mainService.post(this.path + '/update_non_abi_user', obj_user);
  };

  deleteUser = uuid_user => {
    return this.mainService.delete(this.path + '/delete_user/' + uuid_user);
  };

  setImage = obj_image => {
    return this.mainService.post(`${this.path}/image`, obj_image);
  };

  setLanguage = obj_language => {
    return this.mainService.post(`${this.path}/language`, obj_language);
  };

  getSprintPatchNotes = () => {
    return this.mainService.get(`${this.path}/sprint_path_notes`);
  };

  updateSprintPatchNotes = body => {
    return this.mainService.post(`${this.path}/sprint_path_notes`, body);
  };

  getJobPosition = () => {
    return this.mainService.get(`${this.path}/job_position`);
  };

  updateUserRowPage = body => {
    return this.mainService.post(`${this.path}/up_user_row_page`, body);
  };

  getUserRowPage = () => {
    return this.mainService.get(`${this.path}/user_row_page`);
  };

  getUserContinuousTyping = () => {
    return this.mainService.get(`${this.path}/user_continuous_type`);
  };

  updateUserContinuousTyping = bol_continuous_type => {
    return this.mainService.post(
      this.path + '/up_user_continuous_type',
      bol_continuous_type,
    );
  };

  listAbsenceReason = () => {
    return this.mainService.get(`${this.path}/absence_reason`);
  };

  getAbsenceReason = data => {
    return this.mainService.post(`${this.path}/user_absent/pagination`, data);
  };

  delAbsenceReason = uuid_user_absence => {
    return this.mainService.delete(
      `${this.path}/user_absent/${uuid_user_absence}`,
    );
  };

  saveAbsenceReason = data => {
    return this.mainService.post(`${this.path}/user_absent`, data);
  };
}
