import ListDegrees from './listDegrees/ListDegrees';
import EditDegrees from './editDegree/EditDegree';

export default {
    routeModule: {
        path: '/list_intensities',
        component: ListDegrees,
        key: 'degree_list_degrees',
        privilege: 'COMMENT_MANAGEMENT_LIST',
        routeComponents: [{
            path: '/edit_intensity',
            component: EditDegrees,
            key: 'edit_degree',
            privilege: 'COMMENT_MANAGEMENT_EDIT'
        }],
        subMenu: 'menu_attributes_management'
    }
};
