import { useState, useCallback } from 'react';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';
import BrandService from '../../modules/services/brands/brand.service';
import RoundAppService from '../../modules/services/rounds/roundApp.service';

const useFreshnessFiveScoreScaleHooks = () => {
  const fuzzysort = require('fuzzysort');

  const { t } = useTranslation();

  // States
  const [attrs, setAttrs] = useState([]);
  const [scale, setScales] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const [comment, setComment] = useState([]);
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Service
  const brandService = new BrandService();
  const roundAppService = new RoundAppService();

  const loadComments = useCallback(() => {
    roundAppService
      .listComments()
      .then(res => {
        setAttrs(res);
      })
      .catch(error => {
        window.alert(error.message);
      });
  });

  const loadDegress = useCallback(() => {
    roundAppService
      .listDegrees()
      .then(res => {
        setDegrees(res);
      })
      .catch(error => {
        window.alert(error.message);
      });
  });

  const loadCustomComments = useCallback(uuid_brand => {
    if (!!uuid_brand) {
      setIsLoading(true);
      brandService
        .getBrandCustomScale(uuid_brand)
        .then(res => {
          let commentsScales = [];
          const freshnessScales = res.results.reduce(
            (accumulator, currentValue) => {
              if (!!currentValue.scales) {
                currentValue.scales.forEach(scale => {
                  let newComment = {};
                  newComment[currentValue.uuid_score_scales] = {
                    ...comment[currentValue.uuid_score_scales],
                    uuid_brand: uuid_brand,
                    uuid_comment: scale.uuid_comment,
                    str_comment: formatComment(scale),
                    uuid_score_scales: currentValue.uuid_score_scales,
                    uuid_custom_score_scale: scale.uuid_custom_score_scale,
                  };

                  commentsScales = [...commentsScales, newComment];
                });
                setComments([...comments, ...commentsScales]);
              }

              if (accumulator.length == 0) {
                accumulator.push([currentValue]);
              } else {
                if (
                  accumulator[accumulator.length - 1]?.[0].str_color ==
                  currentValue.str_color
                ) {
                  const currentIndexToPush =
                    accumulator[accumulator.length - 1];
                  accumulator[accumulator.length - 1] = [
                    ...currentIndexToPush,
                    currentValue,
                  ];
                } else {
                  accumulator.push([currentValue]);
                }
              }
              return accumulator;
            },
            [],
          );

          setScales(freshnessScales);
          setIsLoading(false);
        })
        .catch(err => {
          window.alert(err.message);
        });
    }
  });

  const formatComment = rawComment => {
    let formatedComment = [];
    if (!!rawComment.str_degree) {
      formatedComment.push(rawComment.str_degree);
    }

    formatedComment.push(
      rawComment.uuid_comment
        ? rawComment.str_comment
        : rawComment.str_comment_custom_scale,
    );

    return formatedComment.join(' - ');
  };

  const handleRemoveComment = (rmComment, fieldName) => {
    if (!rmComment.uuid_custom_score_scale) {
      delete comment[fieldName];
      setComment({ ...comment });
    } else {
      const newComments = comments.filter((commentfilter, index) => {
        return (
          commentfilter[fieldName]?.uuid_custom_score_scale !=
          rmComment.uuid_custom_score_scale
        );
      });

      brandService
        .deleteCustomScaleComment(rmComment.uuid_custom_score_scale)
        .then(res => {
          //success
        })
        .catch(err => {
          window.alert(err.message);
        });
      setComments(newComments);
    }
  };

  const handleSearchComments = evt => {
    const inputName = evt.target.name;

    return new Promise((resolve, reject) => {
      let searchArray = [];
      const keyword = evt.target.value.toString().trim().toLowerCase();
      if (!comment[inputName]?.str_degree && keyword) {
        searchArray = [...degrees, ...attrs];
      } else {
        searchArray = [...attrs];
      }

      let returnedArray = [];

      returnedArray = fuzzysort
        .go(keyword, searchArray, { key: 'str_name' })
        .map(a => a.obj);

      if (keyword.length <= 2) {
        returnedArray = [];
      }

      if (!comment[inputName]?.str_degree && keyword.length == 0) {
        resolve([]);
      } else {
        resolve(returnedArray);
      }
    });
  };

  const handleSelectComments = (evt, uuid_brand) => {
    const inputName = evt.target.name;

    let selectedComment = evt.target.value;
    if (typeof evt.target.value == 'string' && !evt.target.value.trim()) return;
    if (evt.target && selectedComment) {
      if (
        comment[inputName] ||
        selectedComment.uuid_comment ||
        typeof selectedComment === 'string'
      ) {
        if (
          (selectedComment.str_name && selectedComment.uuid_comment) ||
          typeof selectedComment === 'string'
        ) {
          let hasComment = comments[inputName]?.find(
            commentFilter =>
              commentFilter.uuid_comment === selectedComment.uuid_comment ||
              (!comment[inputName] &&
                commentFilter.uuid_comment === selectedComment.uuid_comment) ||
              (!comment[inputName] &&
                commentFilter.str_comment === selectedComment) ||
              (commentFilter.str_comment === selectedComment &&
                comment[inputName]?.uuid_degree === commentFilter.uuid_degree),
          );

          if (!!!hasComment) {
            const uuid_custom_score_scale = uuid();
            let newComment = {};

            newComment[inputName] = {
              ...comment[inputName],
              str_comment: selectedComment.str_name
                ? selectedComment.str_name
                : selectedComment,
              uuid_comment: selectedComment.uuid_comment
                ? selectedComment.uuid_comment
                : uuid(),
              uuid_custom_score_scale: uuid_custom_score_scale,
              uuid_brand: uuid_brand,
              uuid_score_scales: inputName,
            };

            if (
              !newComment[inputName].uuid_degree &&
              !newComment[inputName].str_degree
            ) {
              newComment[inputName].uuid_degree = null;
              newComment[inputName].str_degree = null;
            }

            if (typeof selectedComment === 'string') {
              newComment[inputName].uuid_comment = null;
            }

            setComments([...comments, newComment]);

            const formatedPostObject = Object.values(newComment).pop();
            brandService
              .saveCustomScoreScaleBrand(formatedPostObject)
              .then(result => {})
              .catch(err => {
                window.alert('Error inserting custom scale');
              });
          }

          delete comment[inputName];
          setComment(comment);
        }
      } else {
        if (selectedComment.str_name && selectedComment.uuid_degree) {
          const inputComment = {};
          inputComment[inputName] = {
            ...comment[inputName],
            str_degree: selectedComment.str_name,
            uuid_degree: selectedComment.uuid_degree,
          };

          setComment({ ...comment, ...inputComment });
        }
      }
    }
  };

  return {
    t,
    attrs,
    scale,
    degrees,
    comment,
    comments,
    setAttrs,
    fuzzysort,
    isLoading,
    setDegrees,
    setComment,
    setComments,
    loadDegress,
    loadComments,
    setIsLoading,
    brandService,
    formatComment,
    loadCustomComments,
    handleRemoveComment,
    handleSearchComments,
    handleSelectComments,
  };
};

export default useFreshnessFiveScoreScaleHooks;

