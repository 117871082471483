import React, { useMemo} from 'react';
import { Transition } from 'react-transition-group';
import classes from './alerts.module.scss';

export const AlerItem = props => {
    const { alert, close, state } = props;
    const inProp = useMemo(() => !alert.hide, [alert.hide])
    return (<Transition
        in={inProp}
        timeout={300}>{(state) =>
        <div
            className={[
                classes.alert,
                classes[alert.type],
                classes[alert.position],
                classes[`alert-${state}`],
            ].join(' ')}>
            <p>
                <span className={classes.closebtn} onClick={() => close(alert)}>&times;</span>
                {alert.msg}
            </p>
        </div>
    }</Transition>);
}