import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import uuid from 'react-uuid';
import listBreweryProductionSuspensionStyle from './listbreweryproductionsuspension.module.scss';
import { GenericTable, Label } from '../../../components';
import AppService from '../../../app.service';
import { useTranslation } from 'react-i18next';
import RoundService from '../../services/rounds/round.service';
import configs from '../../../configs';

const ListBreweryProductionSuspension = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [privilege, setPrivilege] = useState([]);
  const [loading, setLoading] = useState(false);
  const appService = new AppService();
  const roundService = new RoundService();
  const [ID, setID] = useState('');

  // services

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);
    appService.listPrivileges('SAMPLE_MANAGEMENT_EDIT').then(
      res => {
        if (res.type === 'success' && res.results) {
          setPrivilege(res.results[0]);
          setLoading(false);
        } else {
          window.alert(res.message);
          setLoading(false);
        }
      },
      error => {
        //console.log(error);
        setLoading(false);
      },
    );
  };

  const handleNewSuspension = useCallback(() => {
    history.push({
      pathname: '/edit_brewery_production_suspension',
      state: {
        values: { uuid_production_suspension: uuid() },
        privilege: privilege,
        isNewSuspension: true,
      },
    });
  }, [privilege]);

  const handleEditSuspension = useCallback(
    values => () => {
      history.push({
        pathname: '/edit_brewery_production_suspension',
        state: {
          values: values,
          privilege: privilege,
          isNewSuspension: false,
        },
      });
    },
    [privilege],
  );

  const handleRemoveSuspension = values => {
    return new Promise((resolve, reject) => {
      if (
        window.confirm(
          t('Remove_Suspension') +
            values.str_site_name +
            '-' +
            values.str_month,
        )
      ) {
        roundService.delSuspension(values.uuid_production_suspension).then(
          res => {
            if (res.type === 'success' && res.results) {
              resolve(true);
            } else {
              window.alert(res.message);
              resolve(true);
            }
          },
          error => {
            //console.log(error);
            reject(error);
          },
        );
      }
    });
  };

  const handleUpdateValue = async evt => {
    return new Promise((resolve, reject) => {
      const { name, value, row } = evt;
      roundService
        .uploadSuspension(row.uuid_production_suspension, value)
        .then(res => {
          if (res.type === 'success' && res.results) {
            resolve(true);
            setID(evt.row.uuid_production_suspension);
            window.alert(t('Upload_successfully'));
          } else {
            reject(false);
            window.alert(res.message);
          }
        });
    });
  };

  const handleDownload = async evt => {
    let link = document.createElement('a');
    link.href = configs.file_api_url + '/v1/' + evt.str_file;
    link.download = evt.str_file;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const headers = [
    {
      description: t('common_zone'),
      field: 'str_zone_name',
    },
    {
      description: t('common_country'),
      field: 'str_country_name',
    },
    {
      description: t('common_site'),
      field: 'str_site_name',
    },
    {
      description: t('Brand_name'),
      field: 'str_name_brand',
    },
    {
      description: t('Month_of_Discontinued_Production'),
      field: 'str_month',
    },
    {
      description: 'Upload',
      field: 'str_file',
      type: 'button',
      action: handleUpdateValue,
      ruleToShow: 'Upload',
      className: listBreweryProductionSuspensionStyle.buttonContainer,
    },
    {
      description: 'Download',
      field: 'str_file',
      type: 'button',
      action: handleDownload,
      ruleToShow: 'Download',
      className: listBreweryProductionSuspensionStyle.buttonContainer,
    },
  ];
  return (
    <div className={listBreweryProductionSuspensionStyle.pageContainer}>
      <Label label={t('List_Brewery_Production_Suspension')} bold neutral />
      <GenericTable
        arrHeader={headers}
        dataServer={roundService.getSuspensionPagination}
        searchable={true}
        onRemove={handleRemoveSuspension}
        onEdit={handleEditSuspension}
        newRegister={{
          onClick: handleNewSuspension,
          label: t('New_Suspension'),
        }}
        loading={loading}
        upDateValue={handleUpdateValue}
      />
    </div>
  );
};

export default ListBreweryProductionSuspension;
