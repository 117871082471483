import en from './en';
import pt from './pt';
import es from './es';
import nl from './nl';
import fr from './fr';
import de from './de';
import ru from './ru';
import uk from './uk';
import it from './it';
import zh from './zh';
import kr from './kr';

export default {
  en: { translation: { ...en } },
  pt: { translation: { ...pt } },
  es: { translation: { ...es } },
  nl: { translation: { ...nl } },
  fr: { translation: { ...fr } },
  de: { translation: { ...de } },
  ru: { translation: { ...ru } },
  uk: { translation: { ...uk } },
  it: { translation: { ...it } },
  zh: { translation: { ...zh } },
  kr: { translation: { ...kr } },
};
