export default {
  sample_no_production_date_message:
    'La date de fabrication doit être sélectionnée',
  round_list_rounds: 'Session',
  round_edit_round: 'Modifier la session',
  round_new_round: 'Nouvelle session',
  round_remove_round: 'Êtes-vous sûr de vouloir supprimer Supprimer la session',
  round_schedule: 'Le Planning',
  round_tasters_unselect_all: 'Désélectionner tous les dégustateurs',
  round_tasters_select_all: 'Sélectionnez tous les dégustateurs',
  round_close: 'Fermer la session',
  round_close_message: 'Êtes - vous sûr de vouloir terminer ce tour',
  round_create_site_message:
    'Vous créez une session qui ne provient pas de votre site!',
  round_closed_message: 'Les sessions fermées ne peuvent pas être modifiées',
  round_status_created: 'Créé',
  round_status_samples: 'Échantillons',
  round_status_scheduled: 'Programmé',
  round_status_tasters: 'Dégustateurs',
  round_status_in_progress: 'En cours',
  round_status_finished: 'Terminé',
  round_status_closed: 'Fermé',
  round_schedule_start_finish_date:
    'La date de début ne peut pas être postérieure à la date de fin',
  round_schedule_finish_date_no_start_date:
    "Vous devez d'abord sélectionner une date de début",
  round_disabled_discussion_tooltip:
    "Vous ne pouvez pas aller à une discussion d'un tour inachevé",
  round_delete_other_users_rounds:
    "Vous n'êtes pas autorisé à supprimer une session que vous n'avez pas créée",
  round_randomize_samples_order_button: 'Mélanger',
  round_block_results_tooltip:
    "Verrouillez les résultats de ce tour d'être téléchargés dans l'écran des rapports. Si le bouton est désactivé, vous n'avez pas le privilège de verrouiller les résultats des rondes à partir de cet emplacement",
  round_block_results_confirm:
    'Êtes-vous sûr de vouloir bloquer/débloquer les résultats du tour ',
  round_block_results_no_privilege:
    "Vous n'avez pas le privilège de bloquer/débloquer les résultats des rondes à partir de cet emplacement.",
  sample_list_samples: 'Échantillons',
  sample_edit_sample: "Modifier l'échantillon",
  sample_new_sample: 'Nouvel échantillon',
  sample_remove_sample: "Supprimer l'échantillon",
  sample_upload_document: 'Télécharger des documents',
  sample_download_documents: 'Exemples de documents',
  sample_tasted_dates: 'Date de dégustation',
  sample_upload_message:
    'Pour charger plusieurs documents, ajoutez-les à un fichier .zip',
  sample_not_for_kpi: 'Pas pour KPI',
  sample_package: 'Emballage',
  sample_volume: 'Volume',
  sample_production_date: 'Date de production',
  sample_expire_date: 'Date de péremption',
  sample_trial_identifier: "Identification de l'essai",
  sample_project_name: 'Nom du projet',
  sample_linked: 'Lié à une session',
  sample_expire_bigger_than_production:
    "La date de production ne peut pas être supérieure à la date d'expiration",
  sample_no_site_message:
    'Il est obligatoire de sélectionner une Zone, un Pays et un Site pour créer un échantillon',
  sample_no_brand_message: 'Il est obligatoire de sélectionner une Marque',
  sample_duplicated_kpi_message:
    'Il existe déjà un KPI pour le mois, la marque et le site que vous avez sélectionnés et vous ne pourrez pas enregistrer',
  sample_duplicated_project_message:
    "Il existe déjà un échantillon avec ce nom de projet et cet identifiant d'essai pour ce site et cette marque, et vous ne pourrez pas l'enregistrer",
  sample_project_no_name_or_trial:
    "Le nom du projet et l'identifiant d'essai sont obligatoires lors de la création d'un échantillon de type de projet",
  sample_production_to_project_confirm_message:
    'Vous changez le type de cet échantillon de PRODUCTION à PROJET. Souhaitez-vous continuer?',
  sample_project_to_production_confirm_message:
    'Vous changez le type de cet échantillon de PROJECT à PRODUCTION. Souhaitez-vous continuer?',
  sample_no_volume_message:
    "Package et Volume sont obligatoires lors de la création/modification d'un échantillon.",
  sample_id_code_message: 'Veuillez inclure le numéro de lot complet',
  sample_show_all_samples_hint:
    "Affichage des échantillons créés jusqu'à un an à partir d'aujourd'hui. Cochez cette case si vous souhaitez également voir des échantillons plus anciens",
  sample_show_all_samples_label: 'Afficher tous les échantillons',
  package_duplicate: 'Il existe déjà un emballage avec ce nom',
  common_production: 'Production',
  common_project: 'Project',
  group_list_groups: 'Groupes',
  group_edit_group: 'Modifier le groupe',
  group_new_group: 'Nouveau groupe',
  group_remove_group: 'Supprimer le groupe',
  group_close_modal: 'Fermer',
  group_duplicated_user: 'Cet utilisateur a déjà été ajouté à ce groupe!',
  group_no_name_message: 'Il est nécessaire de saisir un nom de groupe',
  group_no_zone_message: 'Il est nécessaire de sélectionner une zone',
  group_no_users_message:
    'Il est nécessaire de sélectionner au moins un dégustateur',
  family_list_families: 'Styles',
  family_edit_family: 'Modifier le style',
  family_new_family: 'Nouveau style',
  family_remove_family: 'Supprimer le style',
  family_has_no_english_name:
    "Il est obligatoire d'avoir le nom du style en anglais",
  brand_list_brands: 'Marques',
  brand_edit_brand: 'Modifier la marque',
  brand_new_brand: 'Nouvelle marque',
  brand_remove_brand: 'Supprimer la marque',
  brand_profile_picture: 'Charger une photo du profil de bière',
  brand_upload_brand_logo: 'Charger le logo de la marque',
  brand_duplicate: 'Attention: il y a déjà une bière avec ce nom',
  brand_has_no_english_name:
    'Vous devez remplir un nom de marque et un profil de bière correspondant',
  brand_upload_help_message: 'Solo archivos PNG y JPG',
  brand_has_no_product_type: 'Vous devez sélectionner un type de produit',
  package_list_packages: 'Emballage',
  package_edit_package: "Modifier l'emballage",
  package_new_package: 'Nouvel emballage',
  package_remove_package: "Supprimer l'emballage",
  package_has_no_english_name: 'le nom du Emballage en anglais est obligatoire',
  package_volumes: 'Volumes',
  package_new_volume: 'Nouveau volume',
  package_remove_volume: 'Supprimer le volume',
  degree_list_degrees: 'Intensités',
  degree_edit_degree: "Modifier l'intensité",
  degree_new_degree: 'Nouvelle intensité',
  degree_remove_degree: "Supprimer l'intensité",
  category_list_categories: 'Catégories',
  category_edit_category: 'Modifier la catégorie de saveur',
  category_new_category: 'Nouvelle catégorie de saveur',
  category_remove_category: 'Supprimer la catégorie de saveur',
  comment_list_comments: 'Attributs',
  comment_edit_comment: "Modifier l'attribut de saveur",
  comment_new_comment: 'Nouvel attribut de saveur',
  comment_remove_comment: "Supprimer l'attribut de saveur",
  user_list_users: 'Utilisateurs',
  user_edit_user: "Modifier l'utilisateur",
  user_new_user: 'Nouveau contractant ABI',
  user_export_users: 'Export Users',
  user_list_profiles: 'Profils',
  user_profile_name: 'Nom de profil',
  user_profile_select: 'Sélectionnez un profil',
  user_profile_remove: 'Supprimer le profil',
  user_profile_save: 'Enregistrer le profil',
  user_remove_user: "Supprimer l'utilisateur",
  user_use_terms: "Conditions d'utilisation acceptées",
  report_list_report: 'Rapports',
  report_end_date: 'Date de fin',
  common_privileges: 'Privilèges',
  common_abi_name: 'Nom ABI',
  common_id: 'ID',
  common_ranked: 'Classé',
  common_week: 'La semaine',
  common_weeks: 'Semaines',
  common_days: 'Journées',
  common_category: 'Catégorie de saveur',
  common_category_sample: 'Catégorie',
  common_degree: 'Intensité',
  common_comment: 'Attribut de saveur',
  common_status: 'Statut',
  common_zone: 'Zone',
  common_country: 'Pays',
  common_site: 'Site',
  common_start_date: 'Date de début',
  common_finish_date: 'Date de fin',
  common_round_type: 'Type de test',
  common_round: 'Session',
  common_round_id: 'ID de session',
  common_tasters: 'Dégustateurs',
  common_sample_performance: 'Exemple de performances',
  common_taste: 'Goût',
  common_key_taster: 'Dégustateur Key',
  common_sample: 'Échantillon',
  common_samples: 'Échantillons',
  common_sample_type: "Type d' échantillon",
  common_family: 'Style',
  common_brand: 'Marque',
  common_product_type: 'Type de produit',
  common_temperature: 'Conditions de stockage',
  common_storage_times: 'Temps de stockage',
  common_sku: 'SKU',
  common_batch: "Code d'identification",
  common_kpi_month: 'Mois KPI',
  common_logo: 'Logo',
  common_name: 'Nom',
  common_notes: 'Remarques',
  common_email: 'E-mail',
  common_language: 'Langue',
  common_terms_of_use: "Conditions d'utilisation",
  common_profile: 'Profil',
  common_filter: 'Filtrer les utilisateurs',
  common_download: 'Télécharger',
  common_save: 'Sauvegarder',
  common_cancel: 'Annuler',
  common_search_users: 'Rechercher des utilisateurs',
  common_search_group: 'Groupe de recherche',
  common_search_taster: 'Rechercher un dégustateur',
  common_no_data: 'Aucune donnée à afficher',
  common_loading: 'Chargement',
  common_cannot_be_empty: '{{field}} ne peut pas être vide',
  common_select_option: "Sélectionnez l'option ...",
  common_per_page: 'Lignes par page',
  common_attributes: 'Les attributs',
  common_not_started: 'Pas commencé',
  common_in_progress: 'In Progress',
  common_finished: 'Finie',
  common_start: 'Début',
  common_detail: 'Détails',
  common_position: 'Position',
  common_score: 'But',
  common_score_scale: 'Échelle de score',
  common_intensity: 'Intensité',
  common_report_not_have_data: "Les tours sélectionnés n'ont eu aucun vote",
  month_0: 'Janvier',
  month_1: 'Février',
  month_2: 'Mars',
  month_3: 'Avril',
  month_4: 'Mai',
  month_5: 'Juin',
  month_6: 'Julliet',
  month_7: 'Août',
  month_8: 'Septembre',
  month_9: 'Octobre',
  month_10: 'Novembre',
  month_11: 'Décembre',
  dropdown_change_picture: "Changer l'image",
  dropdown_language: 'Langue',
  dropdown_logout: 'Se déconnecter',
  round_not_started: 'Pas commencé',
  round_in_progress: 'En cours',
  round_finished: 'Finie',
  round_filter_start: 'Début',
  round_round_type_in_profile: 'In-Profile',
  tasting_list_rounds: 'Liste des rondes',
  menu_tasting_session: 'Session de dégustation',
  menu_panel_management: 'Gestion des panneaux',
  menu_process_management: 'La gestion des processus',
  menu_product_management: 'Gestion des produits',
  menu_attributes_management: 'Gestion des attributs',
  tasting_finish_sample: "Finir l'échantillon",
  tasting_comments: 'Commentaires',
  tasting_beer_profile: 'Profil de la bière',
  tasting_need_select_score_comments:
    'Vous devez définir un score et ajouter au moins un commentaire pour continuer ...',
  tasting_finish_taste: 'Goût de finition',
  common_no_comments: 'Sans commentaires',
  review_submit_title: "Êtes-vous sûr d'être prêt à soumettre vos résultats?",
  review_submit_description: 'Vous ne pourrez plus modifier plus tard',
  review_finish_taste: 'Fin de dégustation',
  review_warning_sample_title: 'Vous avez des échantillons non dégustés!',
  review_warning_sample_description:
    'Vous souhaitez continuer sans les goûter?',
  review_warning_sample_review: 'Vérifier',
  review_warning_sample_ok: 'Continuer',
  review_warning_tie_sample_title: 'Vous avez des échantillons de cravates!',
  review_warning_tie_sample_description:
    'Vous ne pouvez pas continuer sans les classer!',
  review_warning_tie_sample_review: 'La revue',
  common_submit: 'Soumettre',
  common_discussion: 'Discussion',
  discussion_order_number: 'Trier par position',
  discussion_order_brewery: 'Trier par brasserie',
  discussion_order_ranking: 'Trier par classement',
  discussion_order_brand: 'Trier par marque',
  discussion_order_score: 'Trier par score',
  discussion_order_key_score: 'Trier par score clé',
  discussion_order_overall_rank: 'Trier par score de classement général',
  discussion_order_key_rank: 'Trier par rang de clé',
  discussion_key_ranking: 'Classement clé',
  discussion_export_error: "Le fichier n'a pas pu être enregistré!",
  discussion_ranking: 'Classement',
  discussion_overall_ranking: 'Classement général',
  common_brewery: 'Brasserie',
  common_trial_identifier: "Identification de l'essai",
  common_key_score: 'Score dégustateur Key',
  round_report: 'Exporter vers Excel',
  common_comments: 'Commentaires',
  common_message_empty_profile: 'Nom de profil non autorisé vide ou vide',
  common_message_out_score_range: 'score hors de portée',
  common_duplicate: 'Dupliquer',
  tasting_raw_materials: 'Matières premières',
  tasting_example_sensory_risk:
    "Exemple de risque sensoriel principal: non-conformité à l'arôme attendu",
  tasting_procedure: 'Procédure',
  tasting_sample_preparation: 'La préparation des échantillons',
  tasting_need_select_score: "Vous devez d'abord définir un score!",
  common_category_name: 'Catégorie',
  categories_new_category: 'Nouvelle catégorie',
  categories_remove_category: 'Voulez-vous vraiment supprimer la catégorie',
  categories_list_categories: 'Liste des catégories',
  categories_list_all_categories: 'All Categories',
  common_category_sample_name: 'Échantillons',
  categories_new_category_sample: 'Nouvel échantillon',
  categories_remove_category_sample: "Voulez-vous vraiment supprimer l'exemple",
  categories_list_categories_samples: 'Exemple de liste',
  common_collection_point: 'Point de collecte',
  common_collection_date: 'Date de collecte',
  common_brew_number: "Numéro d'infusion/MES numéro",
  common_need_explain_nok:
    "Vous ne pouvez pas avoir d'échantillons NOK sans commentaire!",
  common_show_all: 'Afficher tout',
  common_import: 'Importer',
  common_download_template: 'Télécharger le modèle',
  common_need_volte_first:
    "Vous ne pouvez pas commenter des échantillons sans d'abord voter!",
  common_name_template: 'Nom du modèle',
  common_all: 'Tout',
  common_continue: 'Continuer',
  common_minimum_score: 'Note minimale',
  common_maximum_score: 'Note maximale',
  common_average_score: 'Score moyen',
  file_upload_fail:
    'Échec du téléchargement du fichier, les erreurs suivantes se sont produites: ',
  file_upload_success_with_error:
    "Le téléchargement a réussi, à l'exception des éléments suivants: ",
  missing_fields: 'champs manquants',
  column_error: 'erreur de colonne',
  common_line: 'Ligne',
  file_no_samples: 'Aucun échantillon trouvé dans le fichier !',
  file_success_upload: 'Fichier de téléchargement réussi !',
  file_duplicated_kpi_project: 'KPI ou projet en double',
  round_schedule_maximum_length:
    'La longueur maximale du nom rond est de 40 caractères',
  sample_malt_varieties: 'Variétés de malt',
  malt_varieties_has_no_english_name:
    'Vous devez avoir au moins un nom de variété de malt.',
  malt_varieties_duplicate:
    'Attention : Il existe une variété de malt portant ce nom',
  malt_varieties_remove: 'Supprimer la variété de malt',
  common_back: 'Arrière',
  common_next: 'Suivant',
  malt_varieties_new: 'Nouvelle variété',
  reports_no_dates: 'La date de début et la date de fin sont obligatoires !',
  reports_date_range_message:
    'La plage de dates ne peut pas être supérieure à 1 an',
  round_save_select_site:
    'Pour enregistrer une partie, vous devez sélectionner un site',
  common_error: "Oups! Une erreur s'est produite. Veuillez réessayer",
  common_horizontal: 'Horizontale',
  common_unit: 'Unité',
  common_supplier: 'Fournisseur',
  common_flavor: 'Saveur',
  concentration_of_the_base_beer: 'Concentration de la bière de base',
  threshold_aml_method: 'Méthode AML (Méthode Ascendante des Limites)',
  threshold_component_concentration: 'Concentration des composants',
  threshold_sample_different_help: 'Quel échantillon est différent ?',
  threshold_each_set_random_order: 'Dans chaque ensemble, ordre aléatoire.',
  threshold_select_base_beer: 'Sélectionnez la bière de base',
  common_unity: 'Unité',
  common_finish_session: 'Terminer la session',
  review_submit_threshold: 'Voulez-vous vraiment enregistrer ?',
  threshold_coder_wrong:
    'Votre code est faux. Veuillez renseigner le code correctement !',
  threshold_didnt_enter_the_code: 'Veuillez entrer votre numéro de siège',
  code: 'Code',
  threshold_samples_required: 'La bière de base et la saveur sont requises',
  threshold_information_helper_sample:
    "Pour enregistrer les informations sur l'échantillon de seuil, veuillez cliquer sur le bouton Enregistrer pour conserver vos modifications",
  code_digits: 'Saisissez le code à 3 chiffres qui vous a été attribué',
  common_batch_code: 'Code du lot',
  threshold_sample_spiked_with:
    "Sur chaque ensemble, choisissez l'échantillon qui a été dopé avec ",
  threshold_code_already_tasted:
    'Ce code a déjà été goûté par un autre utilisateur. Entrez un autre code.',
  common_kpi_brands: 'Marques KPI',
  common_year: 'An',
  common_new_kpi_brand: 'Nouvelle marque KPI',
  common_action: 'Action',
  common_comment_by_category: 'Commentaires par catégorie',
  common_alphabetic_comment: 'Commentaires alphabétiques',
  common_name_pts_code: 'PTS code',
  common_name_pts_code_placeholder: 'Code',
  common_name_process_item: 'Item de processus',
  common_name_process_item_placeholder: 'Item',
  kpi_only_numbers_warning:
    'Accepter uniquement les nombres sur les valeurs cibles',
  kpi_max_number_range_warning:
    'Votre marque cible doit être comprise entre 1 et 9',
  kpi_already_selected_brand_warning:
    'Vous avez sélectionné cette marque, veuillez choisir une autre marque',
  kpi_no_kpi_informed_warning: "Vous n'avez aucune valeur ajoutée",
  kpi_select_year_warning: 'Sélectionnez une année',
  kpi_select_site_warning: 'Sélectionnez un emplacement',
  kpi_existing_configuration_warning:
    'Vous avez des valeurs kpi du même site et de la même année',
  kpi_target_empty_warning: "Une cible d'une de vos marques est vide",
  kpi_saved_successfully: 'Vos valeurs ont été enregistrées avec succès!',
  kpi_submitted_successfully: 'Vos valeurs ont été soumises avec succès!',
  kpi_max_decimals_warning: "Votre valeur cible doit être jusqu'à 3 décimales",
  kpi_count_as_kpi_sample: 'Compter comme KPI',
  report_list_report_kpi: 'Rapports KPI',
  report_list_report_taste: 'Rapports Goût',
  select_brand_to_filter: 'Sélectionnez une marque mondiale!',
  select_year_to_filter: 'Sélectionnez une année!',
  samples_uncheck_kpi_sample_tooltip:
    'Décochez cette case si vous ne voulez pas que cet échantillon compte dans le KPI',
  reports_mandatory_zone_filter: 'Sélectionnez au moins une zone',
  submission_type: 'Type de validation',
  custom_scale_title: 'Échelle personnalisée',
  custom_scale_grid_header_score: 'Score',
  custom_scale_grid_header_ageind_scale: 'Échelle de vieillissement forcé',
  the_value_cannot_be_null: 'La valeur ne peut pas être vide!',
  unique_id: 'Identifie de manière unique',
  reports_mandatory_test_type: "Sélectionnez le type d'essai.",
  report_filter_rounds_description:
    'Vous devez sélectionner les champs obligatoires pour charger les options des tours.',
  round_reports_month_warning:
    'La période entre la date de début et la date de fin doit être de 6 mois maximum.',
  zone_can_not_empty: 'La regione non può essere vuota',
  error_loading_data:
    'Erreur lors du chargement des données.Veuillez vérifier votre connexion et recharger.',
  flavor_identification: 'Identification des saveurs',
  beer_batch: 'Lot de bière',
  list_of_beers: 'Liste des bières',
  random: 'Aléatoire',
  preview: 'Aperçu',
  select_the_flavor: 'Sélectionnez la saveur',
  flavor_threshold: 'Seuil de perception des saveurs',
  input_digital_number: "Numéro numérique d'entrée",
  concentration: 'Concentration',
  flavor_order: 'Ordre des saveurs',
  last: 'Dernier',
  tasting: 'dégustation',
  confirmation: 'Confirmation',
  do_you_confirm_the_evaluation_of_this_sample:
    "Confirmez-vous l'évaluation de cet échantillon ?",
  confirm: 'Confirmer',
  evaluate: 'évaluer',
  correct_answer: 'bonne réponse',
  threshold: 'Seuil de perception',
  let_a_comment_about_the_tasting: 'Laisser un commentaire sur la dégustation',
  send: 'Envoyer',
  right_now: "à l'heure actuelle",
  minute_ago: 'Il y a une minute',
  hour_ago: 'Une heure avant',
  day_before: 'le jour précédent',
  year_ago: 'Il ya un an',
  add_a_flavor: 'Ajoutez une saveur',
  soft_drinks: 'Boissons non alcoolisées',
  soft_drinks_brand: 'Marque de boissons gazeuses',
  soft_drinks_brand_target: 'Cible de la marque de boissons gazeuses',
  column_options: 'Choix des colonnes',
  select_which_of_the_columns_do_you_want_to_be_displayed:
    'Sélectionnez les colonnes que vous souhaitez voir apparaître.',
  countdown: 'Compte à rebours',
  less_than_five_minutes:
    'Cette séance de dégustation se terminera dans 5 minutes, si vous avez besoin de continuer, veuillez contacter l’administration pour retarder la date de fin.',
  the_countdown_to_the_evaluation_has_ended:
    'Le compte à rebours pour les commentaires est terminé. Les commentaires ne sont pas disponibles.',
  counting_how_much_time_does_you_have_to_finish_tasting_the_samples:
    'Calculez combien de temps vous aurez besoin pour terminer la dégustation des échantillons',
  only_six_flavors_can_be_added_at_most:
    'Seuls six arômes au maximum peuvent être ajoutés!',
  concentration_of_the_flavor: 'Concentration de saveur',
  input_value: "Valeur d'entrée",
  Concentration_of_the_flavor_is_required:
    "Concentration requise de l'assaisonnement",
  previous_round: 'Cycle précédent',
  next_round: 'Prochain tour',
  the_end_time_is_greater_than_the_current_time:
    'L’heure de fin doit être supérieure à l’heure actuelle, si vous sélectionnez le même jour que le jour en cours, sélectionnez d’abord l’heure et la minute!',
  check_the_answer_at_the_end_of_the_countdown:
    'Le compte à rebours n’est pas terminé. Vous pouvez voir les réponses après le compte à rebours',
  incremental_step: 'Étapes progressives',
  set3_concentration: 'Set3 Concentration',
  concentration_increments_between_levels:
    'Augmentation de la concentration entre les niveaux',
  incremental_set3_concentration_required:
    'Nécessite une étape progressive et une concentration de set3',
  sprint: 'Sprint',
  sprint_number_greater_than_zero:
    'Le numéro de sprint doit être supérieur à 0',
  sprint_notes_at_least_one_note:
    'Les notes de sprint doivent avoir au moins une note',
  sprint_patch_notes_success_update:
    'Notes de patch Sprint mises à jour avec succès.',
  sprint_patch_notes_error_update: `Il n'a pas été possible d'enregistrer les notes.`,
  update_sprint_patch_notes: 'Mettre à jour les notes de mise à jour du sprint',
  sprint_number: 'Numéro de sprint',
  sprint_patch_notes: 'Notes de mise à jour de sprint',
  note: 'Noter',
  concentration_increments_between_levels:
    'Augmentation de la concentration entre les niveaux',
  incremental_set3_concentration_required:
    'Nécessite une étape progressive et une concentration de set3',
  select_all: 'Sélectionner tout',
  please_add_samples_first: 'Veuillez d abord ajouter un échantillon',
  concentration_increments_between_levels:
    'Augmentation de la concentration entre les niveaux',
  incremental_set3_concentration_required:
    'Nécessite une étape progressive et une concentration de set3',
  reverse: 'Rollback',
  new_test: 'Tests ajoutés',
  triangle_test_name: 'Nom du test de triangulation',
  triangle_test: 'Test de triangulation',
  you_can_not_set_three_identical_samples:
    'Vous ne pouvez pas définir les trois mêmes options',
  its_mandatory_to_input_test_name: 'Veuillez remplir le nom du test',
  its_mandatory_to_input_sample_information:
    'Veuillez remplir l’exemple d’informations',
  attention: 'prompt',
  test_number: 'Numéro de série du test de triangulation',
  you_selected_the: 'Vous avez choisi',
  next_sample: 'Exemple suivant',
  remove_triangle_test: 'Supprimer ce test',
  you_can_only_select_up_to_five: 'Un maximum de cinq sélections est autorisé',
  test: 'Test',
  confirm_remove: 'Confirmer la suppression du test?',
  reports_mandatory_zone_country_site_filter:
    'Les régions, les pays, les usines sont nécessaires!',
  reports_mandatory_brand_filter: 'Sélectionnez au moins une marque',
  reports_by_producing_site: 'Rapport d’analyse complet',
  download_label: 'Bouton Télécharger',
  standard: 'Valeur standard',
  input_test: 'Saisie des résultats',
  detecting: 'Détection...',
  connected: 'Relié',
  current_taster: 'Examinateurs actuels',
  alcohol_concentration: 'Concentration d’alcool',
  your_alcohol_test_result_exceeds_standard_please_test_again_later:
    'Votre taux d’alcoolémie est excessif. S’il vous plaît retester plus tard!',
  alcohol_test_qualified: 'Réussir le test d’alcoolémie',
  configuration: 'Disposition',
  input_result: 'Saisie des résultats',
  maximum_alcohol_concentration: "Concentration excessive d'alcool",
  breathalyzer: 'Détecteur d’alcool',
  please_input_a_positive_number_up_to_n_decimal_places:
    'Veuillez entrer un nombre positif de {{field}} décimales maximum',
  you_must_input: 'Vous devez entrer',
  driving: 'Drive',
  test_time: 'Temps de test',
  input_type: 'Type d’entrée',
  list_breathalyzer: 'Analyseur expiratoire',
  Manual: 'Manuel',
  Breathalyzer: 'Testeur d’alcool',
  you_do_not_have_privilege_to_export_this_report:
    "Vous n'êtes pas autorisé à exporter ce rapport",
  competitor_beer: 'Bières concurrentes',
  split_round: 'Split circulaire',
  reset: 'Réinitialisation',
  how_many_rounds_splitting_do_you_want_to_make:
    'Vous voulez en quelques tours',
  you_cannot_split_a_round_that_has_already_been_split_or_in_progress:
    'Impossible de fractionner un tour fractionné ou en cours',
  hide_sample_details: 'Cacher les détails de l’exemple',
  data_will_be_hidden_from_the_taster_brewery_production_date_brand_malt_variety_and_sample_id:
    'Les données seront cachées aux dégustateurs: brasserie, date de production, marque, variété de malt et ID de l’échantillon',
  data_will_be_hidden_for_ok_nok:
    'Toutes les données pertinentes seront cachées aux dégustateurs',
  you_can_not_add_duplicate_attributes:
    'Vous ne pouvez pas ajouter d’attributs en double.',
  menu: 'Le menu',
  link_breathalyzer: 'Avec contact',
  not_link_breathalyzer: 'Non connecté',
  breathalyzer_to_link: 'Connecter un analyseur respiratoire',
  breathalyzer_can_not_to_link_for_this_browser:
    "La fonctionnalité du testeur d'alcool a été développée pour l'utilisation d'appareils PC sur Google Chrome.",
  reopen: 'Rouvrir',
  round_reopen_message: 'Êtes - vous sûr de vouloir recommencer la course',
  common_production_line: 'Ligne de production',
  common_packaged_beer_data_code: 'Emballer le Code beeradata',
  common_masking_process_for_bottles: 'Processus de masquage des bouteilles',
  common_str_number_days_over_aged: 'Jours de dépassement',
  common_over_aged_beer: 'Bière overage',
  sample_successfully_swapped: 'Échantillon échangé avec succès.',
  scoring: 'Score',
  configure_available_flavors: 'Configurer les saveurs disponibles',
  process_check: 'Inspection du processus',
  new_process_check: 'Inspection des nouveaux processus',
  edit_process_check: 'Modifier la vérification du processus',
  remove_process_check: 'Vérification du processus de suppression',
  common_package: 'Le paquet',
  common_department: 'Département',
  common_shift: 'Transfert',
  common_tank: 'Tanks',
  common_oknok: 'OKNOK',
  common_remark: 'Commentaires',
  show_all_process_check: 'Afficher tous les processus check',
  show_all_process_check_help_circle:
    'Afficher les données créées dans un demi-an à partir d’aujourd’hui. Cochez cette case si vous avez encore besoin de voir les données créées dans un an à partir d’aujourd’hui',
  the_sample_date_cannot_be_earlier_than_the_current_time:
    "La date d'échantillonnage ne peut pas être antérieure à l'heure actuelle!",
  common_job_position: 'Poste de travail',
  decimal_sep: 'Séparateur décimal',
  is_swapped_to: 'Sont échangés pour',
  review_warning_comment_title: 'Vous avez des échantillons non commentés!',
  continuous_typing: 'Typage continu',
  continuous_typing_tips:
    'Après avoir sélectionné / tapé Intensity, count tapez attribute.',
  BSSI_Reports: 'BSSI rapports',
  data_analysis_sip: 'Analyse des données - SIP',
  select_the_color: 'Sélectionnez la couleur',
  Color: 'La couleur',
  download_document: 'Documents à télécharger',
  Month_of_Discontinued_Production: 'Mois hors production',
  Suspension_List: 'Liste hors production',
  New_Suspension: 'Nouvelle application discontinuée',
  new_suspension_page: 'Nouvelles informations discontinuées',
  edit_suspension_page: 'Modifier les informations discontinuées',
  Brand_name: 'Nom de marque',
  List_Brewery_Production_Suspension: 'Liste des usines discontinuées',
  Upload_successfully: 'Fichier téléchargé avec succès',
  training_materials: 'Cliquez pour voir le matériel de formation.',
  Remove_Suspension: 'Retirer la Suspension: ',
  Add_Absence_Reason: 'Ajouter raison d’absence',
  Reason: 'La raison',
  You_have_duplicate_absence_dates: 'Vous avez des dates d’absence en double',
  Remove_Absence: 'Supprimer l’absence: ',
  Absence_Title:
    'Veuillez sélectionner l’heure et la raison de votre absence de dégustation',
  Shipping_Site: 'Site d’expédition',
  shipping_received: 'Livraison reçue',
  receive_sample_alert: 'Êtes-vous sûr d’avoir reçu l’échantillon?',
  receive_uncheck_alert: 'Êtes-vous sûr de vouloir le décocher?',
  all_received: 'Tous reçus',
  not_all_received: 'Pas tous reçus',
  not_for_shipping: 'Pas pour l’expédition',
  receive_hint: 'Sélectionnez tous les sites qui recevront cet échantillon',
  absence_reason: 'Raison de l’absence',
  Page1: 'Page {{field}}',
  Search: 'Recherche recherche',
  page_total: 'Total',
  start_date: 'Date de début',
  end_date: 'Date de fin',
  ok_for_all: 'OK pour tous',
  its_mandatory_to_select_sample_A_and_sample_B:
    'Il est obligatoire de sélectionner l’échantillon A et l’échantillon B.',
  its_mandatory_to_select_sample_A:
    'Il est obligatoire de sélectionner l’échantillon A.',
  its_mandatory_to_select_sample_B:
    'Il est obligatoire de sélectionner l’échantillon B.',
  triangle_comment: 'comment',
  select_sample: 'Sélectionner un échantillon',
  sample_date_filter_last_7_days_in_default_export_to_Excel_based_on_this_filtered_duration:
    'Exemple de filtre de date, par défaut les 7 derniers jours, exporté vers Excel en fonction de la durée de ce filtre.',
  select_all_flavor: 'Tout sélectionner',
  deselect_all: 'Désélectionner tout',
  email_distribution: 'Distribution par courriel',
  new_email_distribution: 'Nouvelle distribution email',
  edit_email_distribution: 'Modifier la distribution par e - mail',
  distribution_type: 'Type de distribution',
  the_score_entered_is_invalid:
    "Le score saisi n'est pas valide. Veuillez vous référer au tableau des scores pour saisir à nouveau votre score.",
  service_now:
    'Cliquez pour créer un ticket d’incident pour signaler un problème et obtenir de l’aide.',
  service_now_tips:
    "Hey, si vous voyez un problème ou souhaitez demander de l'aide, vous pouvez le signaler en cliquant ici et directement dans le service. Maintenant, notre équipe de support vous contactera dès que possible.",
  done: 'Dorn',
  changes_you_made_may_not_be_saved:
    'Les modifications que vous avez apportées peuvent ne pas être sauvegardées. Voulez - vous continuer et perdre vos modifications?',
  cannel_auto_save_tips: `Maintenant que les performances de la page de dégustation se sont améliorées, n'oubliez pas de cliquer sur "terminer l'échantillon" pour enregistrer et soumettre vos résultats de dégustation.`,
  list_email_page_tips:
    'Ceci est l’instruction de la façon de configurer l’échantillon KPI correct, afin de s’assurer que le rapport mensuel de Zone peut refléter l’analyse exacte pour votre dégustation de Zone MCS',
  list_email_page_helps:
    'Des instructions sur la façon de configurer des exemples de KPI pour refléter les données exactes du Bulletin mensuel régional.',
  kpi_instruction: 'Description des KPI',
  group_no_recipients_message: 'Au moins un destinataire est requis.',
  the_current_distribution_type_already_exists:
    'Le type de distribution actuel existe déjà, veuillez le sélectionner à nouveau ou revenir à la liste.',
  distribution_type_message_empty:
    "Le type de distribution n'est pas autorisé à être vide ou vide",
  add_samples: 'Ajouter un ou plusieurs échantillons à la série',
  copy_this_sample: `Dupliquer l'échantillon`,
  remove_this_sample: `Supprimer l'échantillon`,
  multiple_selection_tips: `Vous pouvez sélectionner plusieurs échantillons à la fois pour le même type d'échantillon. Cliquez sur '+' et '-' pour essayer.`,
  all_add_once_tips: `Cliquez sur le bouton "Tout sélectionner" pour ajouter automatiquement un échantillon à chaque type d'échantillon sélectionné.`,
  copy_confirm_tip: `Êtes-vous sûr de vouloir reproduire l'échantillon ?`,
  user_non_abi_user: 'Nouvel utilisateur temporaire',
  language_of_email_invitation:
    "Langue de l'invitation par courrier électronique",
  add_non_abi_users_to_round:
    "L'utilisateur temporaire recevra une invitation par courriel avec un lien pour assister à la dégustation en question.",
  change_non_abi_users_to_round:
    'Le dégustateur temporaire que vous avez invité recevra une invitation par courriel avec un lien pour participer à cette dégustation.',
  guest: 'Invité',
  email_to_all: 'Envoyer un courriel à tous',
  send_email: "Envoyer un courriel d'invitation",
  temporary_non_abi_taster: 'Dégustateur temporaire',
  user_type: "Type d'utilisateur",
  email_invitation_delivery_request_sent:
    "Demande d'invitation envoyée par courriel.",
  incorrect_e_mail_formatting: 'Formato incorrecto del correo electrónico',
  all_tasters: 'Tous les dégustateurs',
  key_tasters: 'Dégustateurs principaux',
  non_key_tasters: 'Dégustateurs non primaires',
  temporary: 'Utilisateur temporaire',
  maximum_number_of_samples_is: `Le nombre maximum d'échantillons est de`,
  sample_added: 'Échantillon ajouté',
  click_to_view_scale_of_flavour_intensities: `Cliquez sur le lien pour voir les échelles d'intensité détaillées`,
  list_next_week_plan: 'Plan de la semaine prochaine',
  common_time_zone: 'Fuseau horaire',
  common_last_response_time: 'Dernière heure de réponse',
  new_week_plan: 'Plan de la semaine suivante',
  invited_week: 'Semaine invitée',
  email_language: `Langue de l'e-mail`,
  save_and_send_email: `Sauvegarder et envoyer l'email`,
  common_start_time: 'Heure de début',
  common_end_time: 'Heure de fin',
  session_name_email_subject: `Nom de la session / Objet de l'e-mail`,
  location: 'Lieu de la session',
  edit_week_plan: 'Modifier le plan de la semaine',
  email_response: 'Réponse par courriel',
  round_remove_plan: 'Étes-vous certain de vouloir supprimer le plan',
  view_week_plan: 'Voir le plan de la semaine',
  are_you_confirming_to_send_the_confirmation_email: `Confirmez-vous l'envoi de l'email de confirmation`,
  every_10_minutes_system_will_update_the_email_response_from_next_week_plan_invitation: `Toutes les 10 minutes, le système mettra à jour la réponse à l'invitation du plan de la semaine suivante`,
  documents_upload_tips:
    'Les téléchargements de fichiers ne prennent en charge que les fichiers {{field}}',
  ignore_submit: 'Ignorer&Envoyer',
  if_count_as_kpi:
    'Si vous comptez comme KPI, cliquez sur le lien pour voir les étiquettes de KPI',
  confirmed_count: 'Nombre confirmé',
  accepted: 'Accepté',
  declined: 'Refusé',
  no_response: 'Pas de réponse',
  reminder_time: 'Heure de rappel',
  are_you_sure_you_want_to_change_the_week_of_invitation: `Êtes-vous sûr de vouloir supprimer l'heure de début et de fin de la session que vous avez sélectionnée et de vouloir passer à une autre semaine sur invitation `,
  apply_to_all_sessions_location_tasters: `Appliquer 'Lieu de la session' et 'Tasters' aux sessions suivantes`,
};
