import React, { useCallback, useState, useEffect } from 'react';
import uuid from 'react-uuid';
import listMaltsStyle from './maltVarieties.module.scss';
import {
  GenericTable,
  Label,
  CardRow,
  CardColumn,
  TextField,
  Button,
  Card
} from '../../components';
import AppService from '../../app.service';
import MaltService from '../services/malt-varieties/malt_varieties.service';
import { useTranslation } from 'react-i18next';

const ListBrands = props => {
  
  const { t } = useTranslation();
  const [malts, setMalts] = useState([]);
  const [malt, setMalt] = useState({
    str_name_en: '',
    str_name_pt: '',
    str_name_es: '',
    str_name_de: '',
    str_name_fr: '',
    str_name_it: '',
    str_name_nl: '',
    str_name_ru: '',
    str_name_uk: '',
    uuid_malt_varieties: '',
  });
    
  const [privilege, setPrivilege] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editNew, setEditNew] = useState(false);
  const appService = new AppService();
  const maltService = new MaltService();

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);
    //GET PRIVILEGES
    //appService.listPrivileges('MALT_MANAGEMENT_EDIT').then((res) => {
    appService.listPrivileges('MALT_MANAGEMENT_EDIT').then((res) => {
      if(res.type === 'success' && res.results){
        setPrivilege(res.results[0]);
      }else{
        window.alert(res.message);
      }
    }, (error) => {
      //console.log(error);
    });

    //GET SAMPLES
    maltService.listMaltVarieties().then((res) => {
      if(res.type === 'success' && res.results){
        setMalts(res.results);
        setLoading(false);
      }else{
        window.alert(res.message);
        setLoading(false);
      }
    }, (error) => {
      //console.log(error);
      setLoading(false);
    });
  };

  const handleNew = useCallback(
    ( values ) => {
      setMalt({
        ...malt,
        'uuid_malt_varieties' : uuid()
      });
      setEditNew(true);
    }, [privilege]
  );

  const handleEdit = useCallback(
    (values) => () => {
      setMalt({
        str_name_en: values.str_name_en,
        str_name_pt: values.str_name_pt,
        str_name_es: values.str_name_es,
        str_name_de: values.str_name_de,
        str_name_fr: values.str_name_fr,
        str_name_it: values.str_name_it,
        str_name_nl: values.str_name_nl,
        str_name_ru: values.str_name_ru,
        str_name_uk: values.str_name_uk,
        uuid_malt_varieties: values.uuid_malt_varieties,
      });
      setEditNew(true);
    }, [privilege]
  );
    
  const handleRemove = (values) => {
    if (window.confirm(`${t("malt_varieties_remove")}: ${values.str_name}`)) {
      maltService.deleteMaltVarieties(values.uuid_malt_varieties).then(
        (res) => {
          if (res.type === "success" && res.results) {
            refreshState();
          } else {
            window.alert(res.message);
          }
        },
        (error) => {
          //console.log(error);
        }
      );
    }
  }

  const handleChange = (evt) => {
    if(evt.target !== undefined){
      const value = evt.target.value;
      setMalt({
        ...malt,
        [evt.target.name]: value
      });
    }
  };

  const handleSave = () => {
    if (!malt.str_name_en?.trim() && !malt.str_name_pt?.trim() && !malt.str_name_es?.trim() && !malt.str_name_de?.trim() && !malt.str_name_fr?.trim() && !malt.str_name_it?.trim() && !malt.str_name_nl?.trim() && !malt.str_name_ru?.trim() && !malt.str_name_uk?.trim()){
      window.alert(t('malt_varieties_has_no_english_name'));
      return false
    }

    maltService.updateMaltVarieties(malt).then((res) => {
      if(res.type === 'success' && res.results){
        if (res.results[0].malt_varieties_sp_in_up_malt_varieties) {
          window.alert(t('malt_varieties_duplicate'));
        }

        setMalt({
          str_name_en: '',
          str_name_pt: '',
          str_name_es: '',
          str_name_de: '',
          str_name_fr: '',
          str_name_it: '',
          str_name_nl: '',
          str_name_ru: '',
          str_name_uk: '',
          uuid_malt_varieties: '',
        });

        setEditNew(false);

        refreshState();
      }else{
        window.alert(res.message);
      }
    }, (error) => {
      //console.log(error);
    });
  };


  const headers = [
    { description: t('common_name'), field: 'str_name'}
  ];

  return (
    <div className={listMaltsStyle.pageContainer}>
      <Label 
        label={`${t('sample_malt_varieties')}`}
        bold
        neutral
      />
      <div style={{ display: editNew ? 'flex' : 'none', marginBottom: '10px'}}>
        <Card>
          <CardRow flexWrap="wrap">
            <CardColumn padding="10px">
              <CardRow flexWrap="wrap">
                <TextField
                  name="str_name_en"
                  label={`${t('common_name')}`}
                  margin="10px 10px 0px 0px"
                  value={malt.str_name_en}
                  onChange={handleChange}
                />
              </CardRow>
            </CardColumn>
            <CardColumn padding="10px">
            <CardRow justifyContent="flex-start" flexWrap="wrap">
              <Button 
                  label={t('common_cancel')}
                  onClick={() => {
                    setEditNew(false);
                    setMalt({
                      str_name_en: '',
                      str_name_pt: '',
                      str_name_es: '',
                      str_name_de: '',
                      str_name_fr: '',
                      str_name_it: '',
                      str_name_nl: '',
                      str_name_ru: '',
                      str_name_uk: '',
                      uuid_malt_varieties: '',
                    });
                  }}
                  neutral
                  margin="35px 10px 0px 0px"
                />
                {
                    <Button 
                      label={t('common_save')}
                      onClick={handleSave}
                      confirm
                      margin="35px 10px 0px 0px"
                    />
                }
              </CardRow>
            </CardColumn>
          </CardRow>
        </Card>
      </div>
      <GenericTable 
        arrHeader={headers}
        arrRow={malts}
        searchable={true}
        onEdit={privilege.bol_access ? handleEdit : null}
        onRemove={privilege.bol_access ? handleRemove : null}
        newRegister={privilege.bol_access ? {
          onClick: handleNew,
          label: t('malt_varieties_new')
        } : null}
        loading={loading}
      />
    </div>
  );
};

export default ListBrands;