import ListCategories from "./listCategories/ListCategories";
import EditCategories from "./editCategories/EditCategories";

export default {
    routeModule: {
        path: '/list_categories_ok_nok',
        component: ListCategories,
        key: 'categories_list_categories',
        privilege: 'SAMPLE_MANAGEMENT_LIST',
        routeComponents: [{
            path: '/edit_category_ok_nok',
            component: EditCategories,
            key: 'edit_category',
            privilege: 'SAMPLE_MANAGEMENT_EDIT'
        }],
        subMenu: 'menu_process_management'
    }
};
