import ListCategoriesSamples from "./listCategoriesSamples/ListCategoriesSamples";
import EditCategoriesSamples from "./editCategoriesSamples/EditCategoriesSamples";

export default {
    routeModule: {
        path: '/list_categories_samples_ok_nok',
        component: ListCategoriesSamples,
        key: 'categories_list_categories_samples',
        privilege: 'SAMPLE_MANAGEMENT_LIST',
        routeComponents: [{
            path: '/edit_categories_samples_ok_nok',
            component: EditCategoriesSamples,
            key: 'edit_category_samples',
            privilege: 'SAMPLE_MANAGEMENT_EDIT'
        }],
        subMenu: 'menu_process_management'
    }
};
