import DataAnalysisSIP from './SIP';

export default {
  routeModule: {
    path: '/data_analysis_sip',
    component: DataAnalysisSIP,
    key: 'data_analysis_sip',
    privilege: 'REPORT_LIST',
    routeComponents: [],
  },
};

