import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import notificationBarStyle from './NotificationBar.module.scss';
import PropTypes from 'prop-types';
import ItemBar from './itemBar';
import cleanImg from '../../assets/clean.png';

const NotificationBar = forwardRef((props, ref) => {
  const ItemBarBox = useRef();

  useEffect(() => {
    props.notificationArr.length > 0 &&
      ItemBarBox.current.scrollTo(0, ItemBarBox.current.scrollHeight);
  }, [props.notificationArr]);

  const scrollBottom = () => {
    ItemBarBox.current.scrollTo(0, ItemBarBox.current.scrollHeight);
  };

  useImperativeHandle(ref, () => ({
    scrollBottom,
  }));

  const handleRemoveItem = index => {
    const nowArr = props.notificationArr.filter((item, nowIndex) => {
      return index !== nowIndex;
    });
    props.setNotificationArr(nowArr);
  };

  return (
    props.notificationArr.length > 0 && (
      <div
        className={notificationBarStyle.backPosition}
        style={{
          top: props.top ? props.top : '',
          right: props.right ? props.right : '',
          bottom: props.bottom ? props.bottom : '',
          left: props.left ? props.left : '',
        }}
      >
        <div className={notificationBarStyle.oneKey}>
          <div
            className={notificationBarStyle.cleanBtn}
            onClick={() => {
              props.setNotificationArr([]);
            }}
          >
            <img src={cleanImg} alt="" />
          </div>
        </div>
        <div className={notificationBarStyle.ItemBarBox} ref={ItemBarBox}>
          {props.notificationArr.map((item, index) => {
            return (
              <ItemBar
                key={item}
                row={item}
                autoCloseTimer={props.autoCloseTimer}
                removeItem={() => {
                  handleRemoveItem(index);
                }}
              />
            );
          })}
        </div>
      </div>
    )
  );
});
NotificationBar.propTypes = {
  notificationArr: PropTypes.array.isRequired,
};
export default NotificationBar;

