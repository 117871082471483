import React, { useState, useEffect, useRef } from 'react';
import EditRoundTrangleStyle from './editRoundTriangle.module.scss';
import EditRoundTriangleSamples from '../editRound/EditRoundTriangleSamples/EditRoundTriangleSamples.jsx';
import { Tab } from '../../../components';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import TriangleService from '../../services/triangleTest/triangle.service';
const triangleService = new TriangleService();

export default function EditRoundTriangle(props) {
  const [round] = useState(props.round);
  const [tabs, setTabs] = useState([]);
  const tabRef = useRef(null);
  const originSample = {
    uuid_round: props.round.uuid_round,
    uuid_sample: uuid(),
    str_triangle_test_name: '',
    uuid_brand: '',
    str_sample_a: '',
    str_sample_b: '',
    list_set: [],
    bol_del: false,
  };
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const dataRef = useRef(data);

  useEffect(() => {
    refreshState();
  }, []);
  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  const delTab = async (index, uuid_sample) => {
    const delObj = {
      uuid_round: round?.uuid_round,
      uuid_sample,
      bol_del: true,
    };
    const res = await triangleService.saveTriangleRound({ ...delObj });
    if (res) {
      await handleSearch();
    }
    const nowIndex = index === 0 ? 0 : index - 1;
    tabRef.current.setSelectedTab(nowIndex);
  };
  const handleTab = arr => {
    const samples = arr?.map((item, index) => {
      return {
        index: index,
        description: `${t('test')} ${index + 1}`,
        component: () => (
          <EditRoundTriangleSamples
            index={index}
            key={index}
            round={props.round}
            setData={setData}
            deleteTab={uuid_sample => {
              delTab(index, uuid_sample);
            }}
            tabs={tabs}
            samples={item}
          />
        ),
      };
    });
    setTabs([...samples]);
  };

  const handleSearch = async () => {
    const res = await triangleService.listTriangleSets({
      uuid_round: props.round.uuid_round,
    });
    if (res.results.length > 0) {
      handleTab(res.results);
    } else {
      setTabs([
        {
          index: 0,
          key: 0,
          description: `${t('test')} 1`,
          component: () => (
            <EditRoundTriangleSamples
              key={originSample}
              round={props.round}
              setData={setData}
              samples={originSample}
              tabs={tabs}
              index="0"
            />
          ),
        },
      ]);
    }
    return res.results.length;
  };

  const refreshState = async () => {
    const res = await handleSearch();
    if (res === 0) {
      await triangleService.saveTriangleRound({ ...originSample });
      await handleSearch();
    }
  };

  const addTab = async tabs => {
    if (!data?.str_triangle_test_name) {
      alert(t('its_mandatory_to_input_test_name'));
      return;
    } else if (!data?.uuid_sample_a && !data?.uuid_sample_b) {
      alert(t('its_mandatory_to_select_sample_A_and_sample_B'));
      return;
    } else if (!data?.uuid_sample_a) {
      alert(t('its_mandatory_to_select_sample_A'));
      return;
    } else if (!data?.uuid_sample_b) {
      alert(t('its_mandatory_to_select_sample_B'));
      return;
    }
    tabRef.current.setSelectedTab(tabs.length - 1);
    const triangleRes = await triangleService.saveTriangleRound({
      ...originSample,
      str_triangle_test_name: data?.str_triangle_test_name,
      dat_expire_a: data?.dat_expire_a,
      dat_expire_b: data?.dat_expire_b,
      dat_production_a: data?.dat_production_a,
      dat_production_b: data?.dat_production_b,
      str_batch_a: data?.str_batch_a,
      str_batch_b: data?.str_batch_b,
      str_brand_name_a: data?.str_brand_name_a,
      str_brand_name_b: data?.str_brand_name_b,
      str_sample_a: data?.str_sample_a,
      str_sample_b: data?.str_sample_b,
      str_site_name_a: data?.str_site_name_a,
      str_site_name_b: data?.str_site_name_b,
      uuid_sample_a: data?.uuid_sample_a,
      uuid_sample_b: data?.uuid_sample_b,
    });
    if (triangleRes) {
      const newTabs = [
        ...tabs,
        {
          index: tabs.length,
          description: `${t('test')} ${tabs.length + 1}`,
          component: () => (
            <EditRoundTriangleSamples
              deleteTab={() => {
                delTab(tabs.length, originSample.uuid_sample);
              }}
              round={props.round}
              key={originSample}
              setData={setData}
              tabs={tabs}
              samples={triangleRes.results[0]}
              index={tabs.length}
            />
          ),
        },
      ];
      setTabs(newTabs);
      tabRef.current.setSelectedTab(newTabs.length - 1);
      setData({
        ...data,
        str_triangle_test_name: triangleRes.results[0]?.str_triangle_test_name,
      });
    }
  };

  const disabledStatus = () => {
    return (
      typeof props.round.arr_status === 'object' &&
      props.round.arr_status.length >= 4 &&
      props.round.arr_status[3] === 'IN_PROGRESS'
    );
  };

  return (
    <div className={EditRoundTrangleStyle.pageContainer}>
      <Tab
        tabs={tabs}
        ref={tabRef}
        selectedTabIndex={0}
        justifyContent="flex-start"
        showButton={true}
        clickAdd={() => {
          addTab(tabs);
        }}
        disabled={disabledStatus}
        onClick={() => {
          handleSearch();
        }}
      />
    </div>
  );
}
