import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import style from './SprintPatchNotesModal.module.scss';
import { Button } from '..';
import userService from '../../modules/services/users/user.service';

const SprintPatchNotesModal = () => {
  const userServices = new userService();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [patchNotes, setPatchNotes] = useState({
    notes: [],
    sprint: 0,
  });

  useEffect(() => {
    getSprintPatchNotes();
  }, []);

  const getSprintPatchNotes = () => {
    userServices
      .getSprintPatchNotes()
      .then(response => {
        if (response.type !== 'success') {
          return;
        }

        setPatchNotes({
          notes: response.patchNotes,
          sprint: response.sprintNumber,
        });

        if (response.notifyUser) {
          setIsOpen(true);
        }
      })
      .catch(() => {});
  };

  const getSprintLabel = () => {
    if (!patchNotes.sprint) {
      return `${t('sprint')}`;
    }

    return `${t('sprint')} ${patchNotes.sprint}`;
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} className={style.modal}>
      <div>
        <div className={style.header}>
          <h2>{getSprintLabel()}</h2>
        </div>

        <div className={style.patchNoteItems}>
          {patchNotes.notes.map(pn => (
            <li className={style.patchNoteItem} key={pn}>
              {pn}
            </li>
          ))}
        </div>

        <div className={style.footer}>
          <Button confirm onClick={closeModal}>
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SprintPatchNotesModal;
