import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { CardRow, CardColumn } from "../../components";

import modalStyle from "./modal.module.scss";

import beer from "../../assets/beer_threshold.svg";
import tuli from '../../assets/tuli.png'

const HelpModalThreshold = (props) => {
  const styleObj = { ...props.style };
  const { t } = useTranslation();

  const sets = ["Set 1", "Set 2", "Set 3", "Set 4", "Set 5", "Set 6"];
  const selecteds = ["A", "B", "C", "A", "B", "C"];

  const options = ["A", "B", "C"];

  const SamplesSets = (props) => {
    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={beer}
          alt={"unlocked"}
          height={44}
          width={40}
          style={{ marginLeft: "-10px", opacity: !props.option ? 0.7 : 1 }}
        />
        {props.option && (
          <span
            style={{
              position: "absolute",
              fontWeight: "700",
              fontSize: "16px",
              color: "#000",
            }}
          >
            {props.option}
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      style={styleObj}
      className={props.show ? modalStyle.modal : modalStyle.modalHide}
      onClick={props.onClick}
    >
      <div className={modalStyle.modalContainer + " " + props.className}>
        <div className={modalStyle.modalBody}>
        {
          props.filter === 1 ?
            <div style={{ padding: "20px" }}>
              {props.children}
              <CardRow>
                <h2>{t("threshold_aml_method")}</h2>
              </CardRow>
              <CardRow style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    display: "flex",
                    height: "30px",
                    margin: "10px 0px 10px 0px",
                    width: "100%",
                    backgroundColor: "#B11F24",
                    alignItems: "center",
                  }}
                >
                  <h3
                    style={{
                      paddingLeft: "50px",
                      color: "white",
                    }}
                  >
                    {t("threshold_component_concentration")}
                  </h3>
                </div>
                <div
                  style={{
                    width: "0px",
                    height: "0px",
                    borderTop: "40px solid transparent",
                    borderBottom: "40px solid transparent",
                    borderLeft: "60px solid #B11F24",
                  }}
                ></div>
              </CardRow>
              <CardRow margin="20px 0px 20px 0px">
                <h3>{t("threshold_sample_different_help")}</h3>
              </CardRow>
              <CardRow>
                <CardColumn flex={1} margin="20px 0px 0px 0px">
                  {options.map((option, key) => (
                    <div
                      key={`${key}-${option}`}
                      style={{
                        display: "flex",
                        height: "80px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h2
                        style={{
                          width: "100%",
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        {option}
                      </h2>
                    </div>
                  ))}
                </CardColumn>
                <CardColumn
                  flex={20}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  {sets.map((set, index) => (
                    <div
                      key={`${index}-${set}`}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justfifyContent: "center",
                        alignItems: "center",
                        flex: 1,
                      }}
                    >
                      <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
                        {set}
                      </h2>
                      {options.map((option,key) => (
                        <div
                          key={`${key}-${option}`}
                          style={{
                            height: "80px",
                            width: "100%",
                            maxWidth: "80px",
                            backgroundColor:
                              selecteds[index] === option ? "#F1F1F1" : "white",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <SamplesSets
                            option={selecteds[index] === option ? option : null}
                            str_code={props.str_code}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </CardColumn>
              </CardRow>
              <CardRow>
                <h3
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "normal",
                    color: "gray",
                  }}
                >
                  {t("threshold_each_set_random_order")}
                </h3>
              </CardRow>
            </div>
          : 
          <div style={{width:'350px',height:'700px',position:'fixed',top:'50%',left:'50%',transform:['translate(-50%,-50%)']}} >
            {props.children}
            <img src={tuli} style={{width:'100%',height:'100%'}}></img>
          </div>
        }
        </div>
      </div> 
    </div>
  );
};

HelpModalThreshold.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default HelpModalThreshold;
