import { useTranslation } from 'react-i18next';
import { Button } from '../../../components';
import DataAnalysisSIPStyle from './index.module.scss';

const DataAnalysisSIP = props => {
  const { t } = useTranslation();

  return (
    <view className={DataAnalysisSIPStyle.Bg}>
      <view className={DataAnalysisSIPStyle.content}>
        <h1>{t('Sensory One Data Analysis Platform (SIP)')}</h1>
        <a
          target="_blank"
          href=" https://app.powerbi.com/groups/me/apps/f90da2f8-40e5-4fcc-ada1-f845581c7836/reports/02739e89-fee1-47df-943c-f5c057ec94c2?ctid=cef04b19-7776-4a94-b89b-375c77a8f936&experience=power-bi"
        >
          <Button
            confirm
            // style={{
            //   width: 'auto',
            // }}
          >
            <h1>Access</h1>
          </Button>
        </a>
      </view>
    </view>
  );
};

export default DataAnalysisSIP;

