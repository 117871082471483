import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid';
import listCommentsStyle from './listcomments.module.scss';
import {
  GenericTable,
  Label
} from '../../../components';
import AppService from '../../../app.service';
import RoundService from '../../services/rounds/round.service';
import { useTranslation } from 'react-i18next';

const ListComments = props => {

  const { t } = useTranslation();
  const history = useHistory();
  const [comments, setComments] = useState([]);
  const [privilege, setPrivilege] = useState([]);
  const [loading, setLoading] = useState(false);

  // services
  const appService = new AppService();
  const roundService = new RoundService();

  useEffect(() => {
    refreshState();
  }, []);

  const handleCommentStructure = (comment) => {
    let commentTemp = { ...comment };
    commentTemp.str_category_name = comment.uuid_category ? comment.uuid_category.map(category => {
      return category.str_name
    }).join(' , ') : ""

    return commentTemp;
  };

  const refreshState = () => {
    setLoading(true);
    //GET PRIVILEGES
    appService.listPrivileges('COMMENT_MANAGEMENT_EDIT').then((res) => {
      if (res.type === 'success' && res.results) {
        setPrivilege(res.results[0]);
      } else {
        window.alert(res.message);
      }
    });

    //GET SAMPLES
    roundService.listComments().then((res) => {
      if (res.type === 'success' && res.results) {
        setComments(res.results.map((comment) => {
          return handleCommentStructure(comment)
        }))
        setLoading(false);
      } else {
        window.alert(res.message);
        setLoading(false);
      }
    }, (error) => {
      //console.log(error);
      setLoading(false);
    });
  };


  const handleNewComment = useCallback(
    () => {
      history.push({ pathname: '/edit_attribute', state: { values: { uuid_comment: uuid() }, privilege: privilege } });
    }, [privilege]
  );

  const handleEditComment = useCallback(
    (values) => () => {
      history.push({ pathname: '/edit_attribute', state: { values: values, privilege: privilege } });
    }, [privilege]
  );

  const handleRemoveComment = useCallback(
    (values) => {
      if (window.confirm(`${t('comment_remove_comment')}: ${values.str_name}`)) {

        roundService.deleteComment(values.uuid_comment).then((res) => {
          if (res.type === 'success' && res.results) {
            refreshState();
          } else {
            window.alert(res.message);
          }
        }, (error) => {
          //console.log(error);
        });

      }
    }, []
  );

  const headers = [
    { description: t('common_name'), field: 'str_name' },
    { description: t('common_category'), field: 'str_category_name' }
  ];

  return (
    <div className={listCommentsStyle.pageContainer}>
      <Label
        label={t('comment_list_comments')}
        bold
        neutral
      />

      <GenericTable
        arrHeader={headers}
        arrRow={comments}
        searchable={true}
        onEdit={privilege.bol_access ? handleEditComment : null}
        onRemove={privilege.bol_access ? handleRemoveComment : null}
        newRegister={privilege.bol_access ? {
          onClick: handleNewComment,
          label: t('comment_new_comment')
        } : null}
        loading={loading}
      />
    </div>
  );
};

export default ListComments;