import React, { useEffect, useState } from 'react';
import { Label, Modal, Spinner } from '..';
import style from './IntensityScale.module.scss';
import { X } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import intensityScaleIcon from '../../assets/intensityScaleIcon.svg'
import BrandService from '../../modules/services/brands/brand.service';
import { useTranslation } from 'react-i18next';
import { useTourMask } from '../tourMask/tourMaskHooks';
const IntensityScale = (props) => {
  const brandService = new BrandService();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [scale, setScale] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { handleIsOpenSum } = useTourMask();
  useEffect(() => {
    setIsLoading(true)
    brandService.getScaleIntensities().then(res => {
      const obj = res.results.reduce((acc, obj) => {
        const description = obj.str_description;
        if (!acc[description]) {
          acc[description] = [];
        }
        acc[description].push(obj);
        return acc;
      }, {});
      const arr = Object.keys(obj).map(key => {
        return {
          'str_description': key,
          'str_color': obj[key][0].str_color,
          'values': obj[key],
        }
      });
      setScale(arr);
      setIsLoading(false);
      handleIsOpenSum();
    });
  }, []);

  return (
    <>
      <div className={style.scale} id='intensityScaleTips'>
        <span
          className={style.text}
          data-tip={t('click_to_view_scale_of_flavour_intensities')}
          data-for={'intensityScale'}
          onClick={() => { setShowModal(true) }}>
          {t('common_intensity')}
          <img src={intensityScaleIcon} width={'12px'} />
        </span>
      </div>
      <ReactTooltip
        id={'intensityScale'}
        effect="solid"
        place="top"
        type="warning"
        className={style.tooltip}
      />
      <Modal
        show={showModal}
        style={{ zIndex: '30' }}
      >
        <div
          className={style.closeButton}
        >
          <button
            className={style.button}
            onClick={() => {
              setShowModal(false)
            }}
          >
            <X />
          </button>
        </div>
        {isLoading ? (
          <Spinner styles={{ marginTop: '10%' }} />
        ) : (
          <>
            <div className={style.tableGrid}>
              <div id={style.headerScore}>
                General description
              </div>
              <div id={style.headerAgeingScale}>
                Intensity scale
              </div>
              <div id={style.headerAttributes}>
                Detailed informative description
              </div>
              {
                scale.length > 0 &&
                <div className={style.tableGridBody}>
                  {scale.map((scale, indexScale) => {
                    return (
                      <div className={style.scoreGrid} key={indexScale}>
                        <div className={style.secondColumn}>
                          <div style={{ display: 'flex', width: '100%' }}>
                            <div className={style.CategoryScale}>
                              <label
                                className={style.labelScale}
                                style={{ backgroundColor: scale.str_color }}
                              >
                                {scale.str_description}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className={style.secondColumn}>
                          <div className={style.intensity}>
                            {
                              scale.values.map(item => {
                                return <div style={{ display: 'flex', width: '100%', flex: '1' }}>
                                  <div className={style.CategoryScale}>
                                    <label
                                      className={style.labelScale}
                                      style={{ backgroundColor: scale.str_color }}
                                    >
                                      {item.str_scale_intensities}
                                    </label>
                                  </div>
                                  <div className={style.CategoryScale}>
                                    <label
                                      className={style.labelScale}
                                      style={{ backgroundColor: scale.str_color }}
                                    >
                                      {item.str_detailed}
                                    </label>
                                  </div>
                                </div>
                              })
                            }
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className={style.ps}>
                    *intensity is suggested to use ONLY for visual attributes to keep the comment tracking analysis clean
                  </div>
                </div>
              }

            </div>
          </>
        )}
      </Modal>
    </>
  );
};


export default IntensityScale;
