import ListSamples from './listSamples/ListSamples';
import EditSample from './editSample/EditSample';

export default {
    routeModule: {
        path: '/list_samples',
        component: ListSamples,
        key: 'sample_list_samples',
        privilege: 'SAMPLE_MANAGEMENT_LIST',
        routeComponents: [
            {
                path: '/edit_sample',
                component: EditSample,
                key: 'Edit Sample',
                privilege: 'SAMPLE_MANAGEMENT_EDIT'
            },
            {
                path: '/new_sample',
                component: EditSample,
                key: 'New Sample',
                privilege: 'SAMPLE_MANAGEMENT_EDIT'
            }
        ],
        subMenu: 'menu_panel_management'
    }
};

