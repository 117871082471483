import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '..';
import { Search } from 'react-feather';
import autoCompleteStyle from './autocomplete.module.scss';
import _ from 'lodash';
import configs from '../../configs';
import { useTranslation } from 'react-i18next';

const AutoComplete = props => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [text, setText] = useState('');
  const [options, setOptions] = useState([]);

  const delayedQuery = useCallback(
    _.debounce(text => handleSearchText(text), configs.defaultDebounce),
    [],
  );

  const handleSearchText = text => {
    props.onSearch(text).then(
      res => {
        if (res.length > 0) {
          setOptions([...res]);
          setShow(true);
        } else {
          setOptions([]);
          setShow(false);
        }
      },
      error => {
        console.log(error);
      },
    );
  };

  const handleChange = evt => {
    if (evt && evt.target) {
      setText(evt.target.value);
      const searchResult = props.useSearchEvent ? evt : evt.target.value;
      if (evt.target.value.length === 0) setShow(false);
      if (props.minimalToSearch) {
        if (evt.target.value.length >= props.minimalToSearch) {
          //GET DATA
          if (props.useDebounce) {
            delayedQuery(searchResult);
          } else {
            handleSearchText(searchResult);
          }
        }
      } else {
        //GET DATA
        if (props.useDebounce) {
          delayedQuery(searchResult);
        } else {
          handleSearchText(searchResult);
        }
      }
    }
  };

  const handleSelect = evt => {
    const res = { target: { value: evt, name: props.name } };
    props.onSelect(res);
    setText('');
    setShow(false);
    if (props.focus) {
      this[props.name].focus();
    }
  };

  const getValues = option => {
    return [
      option[props.optionKeys?.[0]] || '',
      option[props.optionKeys?.[1]] || '',
      option[props.optionKeys?.[2]] || '',
    ];
  };

  const getRowComponent = option => {
    const optionValues = getValues(option);

    return (
      <div className={autoCompleteStyle.option}>
        <div className={autoCompleteStyle.optionLabel}>
          <label>{optionValues[0]}</label>

          {optionValues[1] && (
            <>
              {' - '}
              <label>{optionValues[1]}</label>
            </>
          )}
        </div>
        {optionValues[2] && <label>{optionValues[2]}</label>}
      </div>
    );
  };

  const handleShowList = () => (
    <div>
      <div
        className={autoCompleteStyle.autoCompleteList}
        style={props.styleList ?? props.styleList}
      >
        {props.freeComment && (
          <div
            data-testid="autoCompleteFreeComment"
            className={autoCompleteStyle.autoCompleteOption}
            onClick={evt => {
              handleSelect(text);
            }}
          >
            <div style={{ display: 'flex', 'flex-direction': 'column' }}>
              <div style={{ display: 'flex', 'flex-direction': 'row' }}>
                <label>{t('free_comment')}</label>
              </div>
            </div>
          </div>
        )}
        {options.length > 0 &&
          options?.map((option, index) => (
            <div
              data-testid="autoCompleteOptList"
              className={
                index == 0
                  ? autoCompleteStyle.autoCompleteOptionActive
                  : autoCompleteStyle.autoCompleteOption
              }
              key={index}
              onClick={evt => {
                handleSelect(option);
              }}
            >
              {getRowComponent(option)}
            </div>
          ))}
      </div>
      <div
        className={autoCompleteStyle.autoCompleteOuterList}
        onClick={evt => {
          setShow(false);
        }}
      ></div>
    </div>
  );

  const handleHintEnter = value => {
    if (options.length > 0) {
      handleSelect(options[0]);
    } else {
      handleSelect(value);
    }
  };

  return (
    <div
      className={autoCompleteStyle.autoCompleteContainer}
      style={props.mainDivStyle}
    >
      <div>
        {!props.nonSearch ? <Search /> : ''}
        <TextField
          name={props.name}
          label={props.label}
          value={text}
          style={{
            padding: props.padding ? props.padding : '11px 5px 11px 34px',
            margin: props.margin,
            ...props.style,
          }}
          onChange={handleChange}
          placeholder={props.placeholder ? props.placeholder : ''}
          disabled={props.disabled}
          withouBorder={props.withouBorder}
          focus={props.refFocus}
          onHintEnter={handleHintEnter}
          autoFocus={props.autoFocus}
        />
      </div>
      {show ? handleShowList() : null}
    </div>
  );
};

AutoComplete.propTypes = {
  name: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  optionKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  nonSearch: PropTypes.bool,
  padding: PropTypes.string,
  placeholder: PropTypes.string,
  withouBorder: PropTypes.bool,
  minimalToSearch: PropTypes.number,
  refFocus: PropTypes.any,
  autoFocus: PropTypes.bool,
  style: PropTypes.object,
  styleList: PropTypes.object,
  useDebounce: PropTypes.bool,
  useSearchEvent: PropTypes.bool,
};

export default AutoComplete;
