import React, { useState, useEffect } from 'react';
import listReportsStyle from './listreports.module.scss';
import {
  Label,
  Card,
  CardRow,
  Button,
  CardColumn,
  DropdownField,
  Modal,
  ImageZoom,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../components/spinner/Spinner';
import useListReportTasting from '../listReportsTasting/hooks.js';
import ReportService from '../../services/reports/report.service';
import configs from '../../../configs';
import KpiSamplesTastingSteps from '../../../assets/kpi_samples_tasting_steps.png'


const ListReportsZone = props => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState({});
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { privilege, getPrivilegies } = useListReportTasting();
  const [kYear, setKYear] = useState([{ str_name: 2021, kpi_year: 2021 }]);
  const reportService = new ReportService();
  const [fullButton, setFullButton] = useState('');
  const [fullHeight, setHeight] = useState('');
  const [showImg, setShowImg] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const yearNow = new Date().getFullYear();
    const timeInterval = yearNow - 2021;
    const year = [{ str_name: 2021, kpi_year: 2021 }];
    for (let i = 1; i <= timeInterval; i++) {
      year.push({ str_name: 2021 + i, kpi_year: 2021 + i });
    }
    setKYear(year);
    setFilter({
      ...filter,
      kpi_year: yearNow
    });
    getPrivilegies();
    setLoading(false);
  }, []);

  const handleChange = evt => {
    const value = evt.target.value;
    setFilter({
      ...filter,
      [evt.target.name]: value,
    });
  };

  const handleDownload = () => {
    if (!filter.kpi_year) {
      alert(t('select_year_to_filter'));
      return false;
    }

    if (!filter.arr_zones) {
      alert(t('Select a Zone!'));
      return false;
    }
    setButtonLoading(true);
    reportService.generateBSSIReport(filter.kpi_year, filter.arr_zones).then(
      res => {
        if (res.status === 'success' && res.file) {
          let a = document.createElement('a');
          a.setAttribute('target', '_blank');
          a.href = configs.file_api_url + '/v1/' + res.file;
          a.download = res.file;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          window.alert(res.message);
        }
        setButtonLoading(false);
      },
      error => {
        setButtonLoading(false);
      },
    );
  };

  const handleShowImage = show => {
    setShowModal(!show);
  }

  return (
    <div className={listReportsStyle.pageContainer}>
      <CardRow itemAlign={'center'} justifyContent={'space-between'}>
        <Label label={`GBSI BSSI & Dispersion`} bold neutral />
        <Button
          onClick={
            () => {
              setShowModal(true)
            }
          }
          blue
          style={{
            marginRight: '36px'
          }}
        >
          {t('kpi_instruction')}
        </Button>
      </CardRow>

      <Card bordered>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <CardRow flexWrap="wrap" padding="0px 0px 10px 0px">
              <CardColumn padding="5px">
                <CardRow flexWrap="wrap">
                  <DropdownField
                    name="kpi_year"
                    options={kYear}
                    valueField="kpi_year"
                    labelField="str_name"
                    label={`${t('common_year')}`}
                    margin="10px 10px 0px 0px"
                    value={filter.kpi_year}
                    onChange={handleChange}
                    disabled={false}
                    isRequired={true}
                  />
                  <DropdownField
                    name="arr_zones"
                    options={privilege.arr_zones ? privilege.arr_zones : []}
                    valueField="uuid_zone"
                    labelField="str_name"
                    label={t('common_zone')}
                    margin="10px 10px 0px 0px"
                    value={filter.arr_zones}
                    onChange={handleChange}
                    isRequired={true}
                  />
                </CardRow>
              </CardColumn>

              <CardColumn padding="5px">
                <CardRow justifyContent="flex-end" flexWrap="wrap">
                  <Button
                    style={{ zIndex: 0 }}
                    label={t('common_download')}
                    onClick={handleDownload}
                    confirm
                    margin="35px 10px 0px 0px"
                    loading={buttonLoading}
                  />
                </CardRow>
              </CardColumn>
            </CardRow>
          </>
        )}
      </Card>

      <Modal
        fullButton={fullButton}
        fullHeight={fullHeight}
        show={showModal}
        className={
          showImg ? listReportsStyle.buttonBackGround : ''
        }
        style={{ position: showImg ? 'fixed' : '' }}
      >
        <ImageZoom
          className={
            showImg ? listReportsStyle.showFull : ''
          }
          srcImage={KpiSamplesTastingSteps}
          onClick={() => {
            handleShowImage(showModal);
          }}
          setFullButton={setFullButton}
          setHeight={setHeight}
          onShow={() => {
            setShowImg(!showImg);
          }}
          onClose={() => {
            setShowImg(false);
          }}
          local
        />
      </Modal>
    </div >
  );
};

export default ListReportsZone;

