import React, { useState, useEffect, useCallback } from 'react';
//import kpiBrands from './kpiBrands.module.scss';
import { useHistory } from "react-router-dom";
import {
  GenericTable,
  Label
} from '../../components';
import AppService from '../../app.service';
import BrandService from '../services/brands/brand.service';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';

const KpiBrands = () => {
  
  const { t } = useTranslation();
  const [kpiBrands, setKpiBrands] = useState([]);
  const history = useHistory();    
  const [privilege, setPrivilege] = useState([]);
  const [loading, setLoading] = useState(false);

	// services
  const appService = new AppService();
  const brandService = new BrandService();

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);
    //GET PRIVILEGES FOR EDIT/NEW
    appService.listPrivileges('KPI_BRAND_EDIT').then((res) => {
      if(res.type === 'success' && res.results){
        setPrivilege(res.results[0]);
      }else{
        window.alert(res.message);
      }
    }, () => {
      //console.log(error);
    });

    //GET KPI BRANDS
    brandService.listKpiBrands().then((res) => {
        if(res.type === 'success' && res.results){
            setKpiBrands(res.results);
            setTimeout(() => {
              setLoading(false);
            }, 1500) 
        }else{
            window.alert(res.message);
            setTimeout(() => {
              setLoading(false);
            }, 1500)
        }
        }, () => {
        //console.log(error);
        setTimeout(() => {
          setLoading(false);
        }, 1500)
      });
  };

  const handleNew = useCallback(
    () => {
      history.push({pathname: '/new_kpi_brand', state: {listKpiBrands: kpiBrands, values: { uuid_kpi_brand: null }, privilege: privilege, edit: false, from: 'New'}});
    }, [privilege, kpiBrands]
  )

  const handleEdit = useCallback(
    (values) => () => {
      history.push({pathname: '/edit_kpi_brand', state: {listKpiBrands: kpiBrands, values: values, privilege: privilege, edit: true, from: 'Edit'}});
    }, [privilege, kpiBrands]
  )

  const handleDetail = useCallback(
    (values) => () => {
      history.push({pathname: '/details_kpi_brand', state: {listKpiBrands: kpiBrands, values: values, privilege: privilege}});
    }, [privilege, kpiBrands]
  )

  const headers = [
    { description: t('common_site'), field: 'str_name_site', cache: true, fieldCache: 'kpi.str_name_site'},
    { description: t('common_zone'), field: 'str_name_zone', cache: true, fieldCache: 'kpi.str_name_zone'},
    { description: t('common_status'), field: 'status', cache: true, fieldCache: 'kpi.status'},
    { description: t('common_year'), field: 'kpi_year', cache: true, fieldCache: 'kpi.kpi_year'},
  ];

  return (
    <div>
      <Label 
        label={`${t('common_kpi_brands')}`}
        bold
        neutral
      />
      <GenericTable 
        arrHeader={headers}
        arrRow={kpiBrands}
        searchable={true}
        onEditPermission={(evt) => { 
          const privilegeSite = privilege.arr_sites?.some(site => site.uuid_site === evt.uuid_site)
          return privilege.bol_access && privilegeSite ? handleEdit : null
         }}
        onDetail={privilege.bol_access ? handleDetail : null}
        onEdit={privilege.bol_access ? handleEdit : null}
        newRegister={privilege.bol_access ? {
          onClick: handleNew,
          label: t('common_new_kpi_brand')
        } : null}
        loading={loading}
      />
    </div>
  );
};

export default KpiBrands;
