import UpdateSprintPatchNotes from './UpdateSprintPatchNotes/UpdateSprintPatchNotes';

const routes = {
  routeModule: {
    path: '/update_sprint_patch_notes',
    component: UpdateSprintPatchNotes,
    key: 'update_sprint_patch_notes',
    privilege: 'UPDATE_SPRINT_PATCH_NOTES',
    exact: true,
    routeComponents: [],
  },
};

export default routes;
