import MainService from './main.service';

export default class GroupService {
  mainService = null;
  path = null;

  constructor() {
    this.mainService = new MainService();
    this.path = '/v1/alcohol';
  }

  alcoholGetStandard = uuid_site => {
    return this.mainService.get(
      `${this.path}/get_alcohol_standard/${uuid_site}`,
    );
  };

  alcoholSaveStandard = alcoholInfo => {
    return this.mainService.post(`${this.path}/alcohol_standard`, alcoholInfo);
  };

  listUserPagination = () => {
    return this.mainService.get(`${this.path}/list_users/pagination`);
  };

  alcoholUserDrive = driveInfo => {
    return this.mainService.post(`${this.path}/user_drive`, driveInfo);
  };

  alcoholResult = resultInfo => {
    return this.mainService.post(`${this.path}/alcohol_result`, resultInfo);
  };

  listAlcoholResult = date => {
    return this.mainService.post(
      `${this.path}/list_alcohol_result/pagination`,
      date,
    );
  };

  userAlcoholStandard = () => {
    return this.mainService.get(`${this.path}/user_alcohol_standard`);
  };

  getDeviceInformation = () => {
    return this.mainService.get(`${this.path}/breathalyzer_device`);
  };

  getZoneAlcoholUnit = uuid_zone => {
    return this.mainService.get(`${this.path}/zone_alcohol_unit/${uuid_zone}`);
  };
}

