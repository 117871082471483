import React, { useCallback, useState, useEffect } from 'react';
import uuid from 'react-uuid';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import listDegreesStyle from './listdegrees.module.scss';
import AppService from '../../../app.service';
import RoundService from '../../services/rounds/round.service';
import { GenericTable, Label } from '../../../components';

const ListDegrees = props => {
  
  const { t } = useTranslation();
  const history = useHistory();
  const [degrees, setDegrees] = useState([]);
  const [privilege, setPrivilege] = useState([]);
  const [loading, setLoading] = useState(false);
  
  //services
  const appService = new AppService();
  const roundService = new RoundService();

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);
    //GET PRIVILEGES
    appService.listPrivileges('COMMENT_MANAGEMENT_EDIT').then((res) => {
      if(res.type === 'success' && res.results){
        setPrivilege(res.results[0]);
      }else{
        window.alert(res.message);
      }
    }, (error) => {
      //console.log(error);
    });

    //GET SAMPLES
    roundService.listDegrees().then((res) => {
      if(res.type === 'success' && res.results){
        setDegrees(res.results);
        setLoading(false);
      }else{
        window.alert(res.message);
        setLoading(false);
      }
    }, (error) => {
      //console.log(error);
      setLoading(false);
    });
  };

  const handleNewDegree = useCallback(
    () => {
      history.push({pathname: '/edit_intensity', state: {values: {uuid_degree: uuid()}, privilege: privilege}});
    }, [privilege]
  );

  const handleEditDegree = useCallback(
    (values) => () => {
      history.push({pathname: '/edit_intensity', state: {values: values, privilege: privilege}});
    }, [privilege]
  );

  const handleRemoveDegree = useCallback(
    (values) => {
      if(window.confirm(`${t('degree_remove_degree')}: ${values.str_name}`)){

        roundService.deleteDegree(values.uuid_degree).then((res) => {
          if(res.type === 'success' && res.results){
            refreshState();
          }else{
            window.alert(res.message);
          }
        }, (error) => {
          //console.log(error);
        });

      }
    }, []
  );

  const headers = [
    { description: t('common_name'), field: 'str_name' }
  ];

  return (
    <div className={listDegreesStyle.pageContainer}>
      <Label 
        label={t('degree_list_degrees')}
        bold
        neutral
      />

      <GenericTable 
        arrHeader={headers}
        arrRow={degrees}
        searchable={true}
        onEdit={privilege.bol_access ? handleEditDegree : null}
        onRemove={privilege.bol_access ? handleRemoveDegree : null}
        newRegister={privilege.bol_access ? {
          onClick: handleNewDegree,
          label: t('degree_new_degree')
        } : null}
        loading={loading}
      />
    </div>
  );
};

export default ListDegrees;