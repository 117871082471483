import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '..';
import useFreshnessFiveScoreScaleHooks from './hooks';
import style from './freshnessFiveScoreScale.module.scss';

const FreshnessFiveScoreScale = props => {
  const {
    t,
    scale,
    isLoading,
    loadDegress,
    loadComments,
    loadCustomComments,
  } = useFreshnessFiveScoreScaleHooks();

  useEffect(() => {
    loadComments();
    loadDegress();
  }, []);

  useEffect(() => {
    loadCustomComments(props.uuid_brand);
  }, [props.uuid_brand]);

  return (
    <>
      {isLoading ? (
        <Spinner styles={{ marginTop: '10%' }} />
      ) : (
        <>
          <div className={style.tableGrid}>
            <div id={style.headerScore}>
            </div>
            <div id={style.headerAgeingScale}>
              {t('common_score_scale')}
            </div>
            <div id={style.headerAttributes}>
              {t('common_attributes')}
            </div>

            <div className={style.tableGridBody}>
              {scale.map((scale, indexScale) => {
                return (
                  <div className={style.scoreGrid} key={indexScale}>
                    <div
                      className={style.firstColumn}
                      style={{ backgroundColor: scale[0].str_color }}
                    >
                      <div className={style.scoreNumber}>
                        {scale.map(scaleItem => (
                          <label className={style.labelScale}>
                            {scaleItem.val_score}
                          </label>
                        ))}
                      </div>

                    </div>
                    <div className={style.secondColumn}>
                      <div style={{ display: 'flex', width: '100%' }}>
                        <div className={style.CategoryScale}>
                          <label
                            className={style.labelScale}
                            style={{ backgroundColor: scale[0].str_color }}
                          >
                            {scale[0].str_category}
                          </label>
                        </div>
                        <div className={style.ageingScaleGrid} style={indexScale === 0 ? { gridTemplateRows: '33.33% auto' } : indexScale !== 2 ? { gridTemplateRows: '50% 50%' } : {}}>
                          {
                            scale.map((scaleItem, index) => (
                              ((index === 0 || index === 2)) && <div className={style.customScaleDiv} style={{ minHeight: '18px' }}>
                                <label
                                  className={style.labelScale}
                                  style={{ backgroundColor: scaleItem.str_color }}
                                >
                                  {scaleItem.str_label}
                                </label>
                              </div>
                            ))
                          }
                        </div>

                      </div>
                      <div className={style.ageingScaleGrid} style={indexScale === 0 ? { gridTemplateRows: '33.33% auto' } : indexScale !== 2 ? { gridTemplateRows: '50% 50%' } : {}}>
                        {
                          scale.map((scaleItem, index) => (
                            (index === 0 || index === 2) && <div className={style.customScaleDiv} style={{ minHeight: '18px' }}>
                              <label
                                className={style.labelScale}
                                style={{ backgroundColor: scaleItem.str_color, }}
                              >
                                <div dangerouslySetInnerHTML={{ __html: scaleItem.str_description }}></div>
                              </label>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )
      }
    </>
  );
};

FreshnessFiveScoreScale.propTypes = {
  uuid_brand: PropTypes.string,
  isAdminEdit: PropTypes.bool.isRequired,
};

export default FreshnessFiveScoreScale;
