import React, { useEffect, useState } from "react";
import style from "./styles.module.scss";

import CardColumn from "../card/CardColumn";
import CardRow from "../card/CardRow";
import Text from "../text/Text";

export default function SamplePerformance(props) {
  const [tasters, setTasters] = useState([]);

  useEffect(() => {
    if (props.arr_comments !== null && props.arr_comments.length > 0) {
      filterArrCommentsByTaster(props.arr_comments);
    }
  }, [props.arr_comments]);

  function filterArrCommentsByTaster(arr_comments) {
    let tasters = [];
    if (arr_comments && arr_comments.length > 0) {
      arr_comments.map((comment) => {
        const index = tasters.findIndex((taster) => {
          return taster.uuid_usu_ins === comment.uuid_usu_ins;
        });
        if (index !== -1) {
          tasters[index].arr_comments.push(comment);
        } else {
          tasters.push({
            uuid_usu_ins: comment.uuid_usu_ins,
            str_usu_ins: comment.str_usu_ins,
            flt_score: comment.flt_score,
            arr_comments: [comment],
          });
        }
      });
      setTasters(tasters);
    }
  }
  return (
    <div className={style.container}>
      {tasters.map((taster) => (
        <CardColumn
          key={taster.uuid_usu_ins}
          padding="0 5px"
          className={style.taster}
        >
          <div className={style.tasterHeader}>
            <h3>{taster.str_usu_ins}</h3>
            <h4>{taster.flt_score}</h4>
          </div>
          <div className={style.tasterComments}>
            {taster.arr_comments.map((comment) => (
              <div key={comment.uuid_comment} className={style.comment}>
                <span className={style.commentText}>
                  {comment.str_degree
                    ? `${comment.str_degree} - ${comment.str_comment}`
                    : `${comment.str_comment}`}
                </span>
              </div>
            ))}
          </div>
        </CardColumn>
      ))}
    </div>
  );
}
