import React, { useState } from 'react';
import './headerDropDown.scss';
import { useTranslation } from 'react-i18next';
import { LogOut } from 'react-feather';
import AppService from '../../app.service';
import UserService from '../../modules/services/users/user.service';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../contexts/User';
import CNIMG from '../../assets/languagePic/CN.png';
import ENIMG from '../../assets/languagePic/EN.png';
import PTIMG from '../../assets/languagePic/PT.png';
import ESIMG from '../../assets/languagePic/ES.jpg';
import NLIMG from '../../assets/languagePic/NL.jpg';
import FRIMG from '../../assets/languagePic/FR.jpg';
import DEIMG from '../../assets/languagePic/DE.jpg';
import RUIMG from '../../assets/languagePic/RU.jpg';
import UAIMG from '../../assets/languagePic/UA.jpg';
import KRIMG from '../../assets/languagePic/KR.jpg';
import ITIMG from '../../assets/languagePic/IT.jpg';
import moment from 'moment';
import { useMsal } from '@azure/msal-react';

const HeaderDropDown = props => {
  // services
  const appService = new AppService();
  const userService = new UserService();

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { user, handleUser, removeUser } = useUser();
  const [users, setUsers] = useState(user);
  const [showLanguageSelect, setShowLanguageSelect] = useState(false);
  const { instance, accounts } = useMsal();

  const languageOpts = [
    {
      value: 'en',
      label: 'EN',
      imgUrl: ENIMG,
    },
    {
      value: 'zh',
      label: 'CN',
      imgUrl: CNIMG,
    },
    {
      value: 'pt',
      label: 'PT',
      imgUrl: PTIMG,
    },
    {
      value: 'es',
      label: 'ES',
      imgUrl: ESIMG,
    },
    {
      value: 'nl',
      label: 'NL',
      imgUrl: NLIMG,
    },
    {
      value: 'fr',
      label: 'FR',
      imgUrl: FRIMG,
    },
    {
      value: 'de',
      label: 'DE',
      imgUrl: DEIMG,
    },
    {
      value: 'ru',
      label: 'RU',
      imgUrl: RUIMG,
    },
    {
      value: 'uk',
      label: 'UA',
      imgUrl: UAIMG,
    },
    {
      value: 'kr',
      label: 'KR',
      imgUrl: KRIMG,
    },
    {
      value: 'it',
      label: 'IT',
      imgUrl: ITIMG,
    },
  ];

  const handleImageUpload = (name, file) => {
    const str_date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    appService
      .uploadFile({
        str_name: name,
        str_file: file,
        dat_nowTime: str_date,
      })
      .then(
        res => {
          if (res.str_file_name && res.str_file_name !== '') {
            userService.setImage({ str_image: res.str_file_name }).then(
              res => {
                user.str_image = res.results[0].user_sp_up_image;
                handleUser(user);
                props.toggle();
                setUsers(user);
                props.show(false);
              },
              err => console.log(err),
            );
          } else {
            window.alert(res.message || res);
          }
        },
        error => {
          //console.log(error);
        },
      );
  };

  const handleImageClick = evt => {
    document.getElementById('file_image').click();
  };

  const handleChangeFile = evt => {
    let reader = new FileReader();
    let file = evt.target.files[0];

    reader.onload = () => handleImageUpload(file.name, reader.result);

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleLanguage = row => {
    userService.setLanguage({ str_language: row }).then(
      res => {
        user.str_language = res.results[0].user_sp_up_language;
        handleUser(user);
        i18n.changeLanguage(user.str_language);
        props.toggle();
        props.show(false);
        setShowLanguageSelect(false);
      },
      err => console.log(err),
    );
  };

  const changeLanguageImg = row => {
    let url = '';
    switch (row) {
      case 'zh':
        url = CNIMG;
        break;
      case 'pt':
        url = PTIMG;
        break;
      case 'es':
        url = ESIMG;
        break;
      case 'nl':
        url = NLIMG;
        break;
      case 'fr':
        url = FRIMG;
        break;
      case 'de':
        url = DEIMG;
        break;
      case 'ru':
        url = RUIMG;
        break;
      case 'uk':
        url = UAIMG;
        break;
      case 'kr':
        url = KRIMG;
        break;
      case 'it':
        url = ITIMG;
        break;
      default:
        url = ENIMG;
    }
    return <img src={url} width="32px" alt="" />;
  };

  const handleLanguageSelect = () => {
    setShowLanguageSelect(!showLanguageSelect);
  };

  // const handleLogout = () => {
  //   if (accounts.length > 0) {
  //     instance.logoutRedirect({
  //       postLogoutRedirectUri: '/',
  //     });
  //   } else {
  //     history.push({ pathname: '/' });
  //   }
  //   props.toggle();
  //   removeUser();
  //   localStorage.removeItem('taste');
  //   localStorage.removeItem('idtoken');
  //   localStorage.removeItem('sample');
  //   localStorage.removeItem('profileLevels');
  //   sessionStorage.removeItem('menu_active');
  //   sessionStorage.removeItem('tab_active');
  // };

  return (
    <div className="headerDropDown">
      <ul>
        <li>
          ID: {user.str_abi_id}
        </li>
        <li style={{ cursor: 'pointer' }} onClick={handleImageClick}>
          <input
            className="pictureInput"
            type="file"
            name="file"
            id="file_image"
            onChange={handleChangeFile}
            encType="multipart/form-data"
          />
          {t('dropdown_change_picture')}
        </li>
        <li className="languageSelectBox">
          {t('dropdown_language')}:
          <div className="languageSelect" onClick={handleLanguageSelect}>
            &ensp;
            <span>
              {i18n.language.toUpperCase() === 'ZH'
                ? 'CN'
                : i18n.language.toUpperCase() === 'UK'
                  ? 'UA'
                  : i18n.language.toUpperCase()}
            </span>
            &ensp;
            {changeLanguageImg(i18n.language)}&ensp;
            <span className="dropdownArrow"></span>
          </div>
          {showLanguageSelect && (
            <ul className="languageItemBox">
              {languageOpts.map(item => {
                return (
                  <li
                    onClick={() => {
                      handleLanguage(item.value);
                    }}
                  >
                    <span>{item.label}</span>
                    <img src={item.imgUrl} width="32px" alt="" />
                  </li>
                );
              })}
            </ul>
          )}
        </li>
        {/* <li style={{ cursor: 'pointer' }} onClick={handleLogout}>
          {t('dropdown_logout')}
          <LogOut size={18} />
        </li> */}
      </ul>
    </div>
  );
};


export default HeaderDropDown;
