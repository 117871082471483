import React, { useState, useEffect } from 'react';
import EditRoundTriangleSamplesStyle from './editRoundTriangleSamples.module.scss';
import { ChevronDown, ChevronUp, Shuffle } from 'react-feather';
import {
  CardRow,
  Button,
  TextField,
  CardColumn,
  CustomColumns,
  GenericTable,
  Label,
} from '../../../../components';
import { useHistory } from 'react-router-dom';
import TriangleService from '../../../services/triangleTest/triangle.service';
import { useTranslation } from 'react-i18next';
import configs from '../../../../configs';
import BrandService from '../../../services/brands/brand.service';
import ReportService from '../../../services/reports/report.service';
import beer from '../../../../assets/beer_threshold.svg';
import SampleService from '../../../services/samples/sample.service';
import EditRoundSamplesSample from '../../editRoundSamplesSample/EditRoundSamplesSample';
import UserService from '../../../services/users/user.service';
const triangleService = new TriangleService();
const reportService = new ReportService();
const sampleService = new SampleService();
const userService = new UserService();

const EditRoundTriangleSamples = props => {
  const [filter, setFilter] = useState({});
  const [listSets, setListSets] = useState([]);
  const [deleteInfo, setDeleteInfo] = useState(false);
  const { t } = useTranslation();
  const [openSampleModal, setOpenSampleModal] = useState(false);
  const [showRoundSamples, setShowRoundSamples] = useState(false);
  const [samples, setSamples] = useState([]);
  const [sampleFlag, setSampleFlag] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sampleA, setSampleA] = useState({
    uuid_sample: props.samples.uuid_sample_a ?? '',
    str_sample: props.samples.str_sample_a ?? '',
    str_brand_name: props.samples.str_brand_name_a ?? '',
    str_site_name: props.samples.str_site_name_a ?? '',
    dat_production: props.samples.dat_production_a ?? '',
    dat_expire: props.samples.dat_expire_a ?? '',
    str_batch: props.samples.str_batch_a ?? '',
  });
  const [sampleB, setSampleB] = useState({
    uuid_sample: props.samples.uuid_sample_b ?? '',
    str_sample: props.samples.str_sample_b ?? '',
    str_brand_name: props.samples.str_brand_name_b ?? '',
    str_site_name: props.samples.str_site_name_b ?? '',
    dat_production: props.samples.dat_production_b ?? '',
    dat_expire: props.samples.dat_expire_b ?? '',
    str_batch: props.samples.str_batch_b ?? '',
  });

  useEffect(() => {
    setSampleA({
      uuid_sample: props.samples.uuid_sample_a ?? '',
      str_sample: props.samples.str_sample_a ?? '',
      str_brand_name: props.samples.str_brand_name_a ?? '',
      str_site_name: props.samples.str_site_name_a ?? '',
      dat_production: props.samples.dat_production_a ?? '',
      dat_expire: props.samples.dat_expire_a ?? '',
      str_batch: props.samples.str_batch_a ?? '',
    });
    setSampleB({
      uuid_sample: props.samples.uuid_sample_b ?? '',
      str_sample: props.samples.str_sample_b ?? '',
      str_brand_name: props.samples.str_brand_name_b ?? '',
      str_site_name: props.samples.str_site_name_b ?? '',
      dat_production: props.samples.dat_production_b ?? '',
      dat_expire: props.samples.dat_expire_b ?? '',
      str_batch: props.samples.str_batch_b ?? '',
    });
  }, [props.samples])

  const filterHeaders = [
    { description: t('common_zone'), field: 'str_zone_name' },
    { description: t('common_country'), field: 'str_country_name' },
    { description: t('common_site'), field: 'str_site_name' },
    { description: t('common_brand'), field: 'str_brand_name' },
    {
      description: t('sample_production_date'),
      field: 'dat_production',
      type: 'datetime',
    },
    { description: t('common_sku'), field: 'str_sku_name' },
    { description: t('common_batch'), field: 'str_batch' },
    { description: t('sample_project_name'), field: 'str_name_project' },
    {
      description: t('sample_trial_identifier'),
      field: 'str_trial_identifier',
    },
    {
      description: t('common_kpi_month'),
      field: 'dat_kpi',
      type: 'datemonth',
      cache: true,
      fieldCache: 'sample.dat_kpi',
      notSearchable: true,
      selectKpiMonth: true,
    },
    { description: t('submission_type'), field: 'str_submission_type_name' },
    {
      description: t('sample_tasted_dates'),
      field: 'str_type_name',
      type: 'multi-action',
      data: 'arr_tasted',
    },
  ];
  const [editPermission, setEditPermission] = useState([
    { str_name: 'common_zone', bol_status: null },
    { str_name: 'common_country', bol_status: null },
    { str_name: 'common_site', bol_status: null },
    { str_name: 'common_brand', bol_status: null },
    { str_name: 'sample_production_date', bol_status: null },
    { str_name: 'common_sku', bol_status: null },
    { str_name: 'common_batch', bol_status: null },
    { str_name: 'sample_project_name', bol_status: null },
    { str_name: 'sample_trial_identifier', bol_status: null },
    { str_name: 'common_kpi_month', bol_status: null },
    { str_name: 'submission_type', bol_status: null },
    { str_name: 'sample_tasted_dates', bol_status: null },
  ]);
  const headers = filterHeaders.filter((item, index) => {
    return (
      editPermission[index].bol_status === null ||
      editPermission[index].bol_status === 1
    );
  });

  const handleCheckSample = async (sample, evt) => {
    const tempSamples = [
      ...samples.map(sampleTemp => {
        if (evt.target && sampleTemp.uuid_sample === sample.uuid_sample) {
          sampleTemp.bol_check = evt.target.value;
        }
        return sampleTemp;
      }),
    ];
    setSamples(tempSamples);
  };
  const handleChangeAdd = sample => {
    setSampleFlag(sample);
    setOpenSampleModal(true);
  };

  const handleSetSamplesPag = res => {
    setSamples(res);
  };

  const handleAddSample = () => {
    setLoading(true);
    let samplesTemp = samples.filter(
      evt => evt.bol_check && evt.bol_check === 1,
    );
    let obj = {};
    if (sampleFlag === 'a') {
      obj = {
        ...props.samples,
        uuid_round: props.round.uuid_round,
        uuid_sample: props.samples.uuid_sample,
        str_triangle_test_name: filter.str_triangle_test_name,
        uuid_sample_a: samplesTemp[0].uuid_sample,
        str_brand_name_a: samplesTemp[0].str_brand_name,
        uuid_sample_b: sampleB.uuid_sample,
        str_brand_name_b: sampleB.str_brand_name,
        list_set: listSets,
        bol_del: false,
        sampleAObj: {
          uuid_sample: samplesTemp[0].uuid_sample,
          str_sample: '',
          str_brand_name: samplesTemp[0].str_brand_name,
          str_site_name: samplesTemp[0].str_site_name,
          dat_production: samplesTemp[0].dat_production,
          dat_expire: samplesTemp[0].dat_expire,
          str_batch: samplesTemp[0].str_batch,
        },
        sampleBObj: {
          ...sampleB,
        },
      };
      setSampleA({
        uuid_sample: samplesTemp[0].uuid_sample,
        str_sample: '',
        str_brand_name: samplesTemp[0].str_brand_name,
        str_site_name: samplesTemp[0].str_site_name,
        dat_production: samplesTemp[0].dat_production,
        dat_expire: samplesTemp[0].dat_expire,
        str_batch: samplesTemp[0].str_batch,
      });
    } else if (sampleFlag === 'b') {
      obj = {
        ...props.samples,
        uuid_round: props.round.uuid_round,
        uuid_sample: props.samples.uuid_sample,
        str_triangle_test_name: filter.str_triangle_test_name,
        uuid_sample_a: sampleA.uuid_sample,
        str_brand_name_a: sampleA.str_brand_name,
        uuid_sample_b: samplesTemp[0].uuid_sample,
        str_brand_name_b: samplesTemp[0].str_brand_name,
        list_set: listSets,
        bol_del: false,
        sampleAObj: {
          ...sampleA,
        },
        sampleBObj: {
          uuid_sample: samplesTemp[0].uuid_sample,
          str_sample: '',
          str_brand_name: samplesTemp[0].str_brand_name,
          str_site_name: samplesTemp[0].str_site_name,
          dat_production: samplesTemp[0].dat_production,
          dat_expire: samplesTemp[0].dat_expire,
          str_batch: samplesTemp[0].str_batch,
        },
      };
      setSampleB({
        uuid_sample: samplesTemp[0].uuid_sample,
        str_sample: '',
        str_brand_name: samplesTemp[0].str_brand_name,
        str_site_name: samplesTemp[0].str_site_name,
        dat_production: samplesTemp[0].dat_production,
        dat_expire: samplesTemp[0].dat_expire,
        str_batch: samplesTemp[0].str_batch,
      });
    }
    setFilter({ ...obj });
    props.setData({ ...obj });
    triangleService.saveTriangleRound(obj);
    setLoading(false);
    setSamples(
      samples.map(sampleTemp => {
        sampleTemp.bol_check = 0;
        return sampleTemp;
      }),
    );
    setOpenSampleModal(false);
  };

  const history = useHistory();

  useEffect(() => {
    refreshState();
  }, []);

  useEffect(() => {
    setFilter(props.samples);
    setListSets(props.samples?.list_set);
  }, [props.samples]);

  const refreshState = async () => {
    setFilter(props.samples);
    props.setData({
      ...props.samples,
      str_triangle_test_name: props.samples.str_triangle_test_name,
    });
    setListSets(props.samples?.list_set);

    userService.listCustomColumns('sample_round').then(res => {
      if (
        res.type === 'success' &&
        res.results[0].user_sp_se_column_options?.length ===
        editPermission?.length
      ) {
        setEditPermission(res.results[0].user_sp_se_column_options);
      }
    });
  };

  const handleChangeFilter = async evt => {
    const value = evt.target.value;
    const name = evt.target.name;
    let data = { [evt.target.name]: value };
    const newObj = {
      ...filter,
      ...data,
      list_set: listSets,
    };
    setFilter(newObj);
    props.samples[name] = value;
    const newSample = {
      ...filter,
      uuid_round: props.round.uuid_round,
      uuid_sample: props.samples.uuid_sample,
      str_triangle_test_name: newObj.str_triangle_test_name,
      uuid_brand: newObj.uuid_brand,
      str_sample_a: newObj.str_sample_a,
      str_sample_b: newObj.str_sample_b,
      list_set: newObj.list_set,
      bol_del: false,
    };
    props.setData(newSample);
    const res = await triangleService.saveTriangleRound({ ...newSample });
  };

  const handleDownloadTemplate = uuid_round => {
    reportService.downloadFile({ arr_rounds: [uuid_round] }).then(
      res => {
        if (res.status === 'success' && res.file) {
          let a = document.createElement('a');
          a.setAttribute('target', '_blank');
          a.href = configs.file_api_url + '/v1/' + res.file;
          a.download = res.file;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          window.alert(t(res.message));
        }
      },
      error => { },
    );
  };

  const saveThresholdSample = async () => {
    await triangleService.listTriangleSets({
      uuid_round: props.round.uuid_round,
    });
    if (!filter.str_triangle_test_name) {
      alert(t('its_mandatory_to_input_test_name'));
      return;
    } else if (!filter?.uuid_sample_a && !filter?.uuid_sample_b) {
      alert(t('its_mandatory_to_select_sample_A_and_sample_B'));
      return;
    } else if (!filter?.uuid_sample_a) {
      alert(t('its_mandatory_to_select_sample_A'));
      return;
    } else if (!filter?.uuid_sample_b) {
      alert(t('its_mandatory_to_select_sample_B'));
      return;
    }
    const obj = {
      ...props.samples,
      uuid_round: props.round.uuid_round,
      uuid_sample: props.samples.uuid_sample,
      str_triangle_test_name: filter.str_triangle_test_name,
      uuid_sample_a: sampleA.uuid_sample,
      str_brand_name_a: sampleA.str_brand_name,
      uuid_sample_b: sampleB.uuid_sample,
      str_brand_name_b: sampleB.str_brand_name,
      list_set: listSets,
      bol_del: false,
    };
    const triangleRes = await triangleService.saveTriangleRound(obj);
    if (triangleRes) {
      history.push({ pathname: '/list_rounds' });
    }
  };

  const handleChangeShuffle = async () => {
    const res = await triangleService.suffleSets({
      uuid_sample: props.samples.uuid_sample,
    });
    if (res.type === 'success' && res.results) {
      const triangleRes = await triangleService.listTriangleSets({
        uuid_round: props.round.uuid_round,
      });
      if (triangleRes.type === 'success' && triangleRes.results) {
        setListSets(triangleRes?.results[props.index].list_set);
      } else {
        window.alert(triangleRes?.message);
      }
    } else {
      window.alert(res.message);
    }
  };
  const getNextValue = (value, item) => {
    if (disabledStatus()) {
      return false;
    }
    let count = 0;
    const arr = [];
    arr.push(
      item.str_option_set_1,
      item.str_option_set_2,
      item.str_option_set_3,
    );
    for (const item of arr) {
      if (item === value) {
        count++;
      }
    }
    if (count === 1) {
      alert(t('you_can_not_set_three_identical_samples'));
      return value;
    } else {
      return value === 'A' ? 'B' : 'A';
    }
  };

  const disabledStatus = () => {
    return (
      typeof props.round.arr_status === 'object' &&
      props.round.arr_status.length >= 4 &&
      props.round.arr_status[3] === 'IN_PROGRESS'
    );
  };

  const handleChangeOption = async (evt, str_code, item) => {
    if (disabledStatus()) {
      return false;
    }
    const newArr = listSets;
    const value = evt.target.value;
    const objIndex = listSets.findIndex(sample => {
      return sample.str_code === str_code;
    });
    newArr[objIndex][evt.target.name] = value;
    const newObj = {
      ...newArr[objIndex],
      uuid_round: props.round.uuid_round,
    };
    delete newObj.uuid_triangle_test_sets;

    const res = await triangleService.updateSampleSetAnswer(newObj);
    if (res.type === 'success' && res.results) {
      setListSets([...newArr]);
    }
  };
  const findOne = arr => {
    let newArr = [
      arr.str_option_set_1,
      arr.str_option_set_2,
      arr.str_option_set_3,
    ];
    let sumA = 0;
    let sumB = 0;
    for (let i = 0; i < newArr.length; i++) {
      if (newArr[i] === 'A') {
        sumA++;
      } else if (newArr[i] === 'B') {
        sumB++;
      }
    }
    if (sumA === 1) {
      return 'A';
    }
    if (sumB === 1) {
      return 'B';
    }
  };
  const SamplesCategories = props => {
    return (
      <div
        className={
          disabledStatus()
            ? EditRoundTriangleSamplesStyle.pagedisabled
            : EditRoundTriangleSamplesStyle.pageContainerOptions
        }
      >
        <div className={EditRoundTriangleSamplesStyle.samplesCategories}>
          <span> {props.str_code.padStart(3, '0')}</span>
        </div>
        <div className={EditRoundTriangleSamplesStyle.sampleSet}>
          <SamplesSets
            name="Set 1"
            option={
              listSets.find(sample => {
                return sample.str_code === props.str_code;
              })?.str_option_set_1
            }
            isAnswer={findOne(
              listSets.find(sample => {
                return sample.str_code === props.str_code;
              }),
            )}
            id="str_option_set_1"
            str_code={props.str_code}
          />
          <SamplesSets
            name="Set 2"
            option={
              listSets.find(sample => {
                return sample.str_code === props.str_code;
              })?.str_option_set_2
            }
            isAnswer={findOne(
              listSets.find(sample => {
                return sample.str_code === props.str_code;
              }),
            )}
            id="str_option_set_2"
            str_code={props.str_code}
          />
          <SamplesSets
            name="Set 3"
            option={
              listSets.find(sample => {
                return sample.str_code === props.str_code;
              })?.str_option_set_3
            }
            isAnswer={findOne(
              listSets.find(sample => {
                return sample.str_code === props.str_code;
              }),
            )}
            id="str_option_set_3"
            str_code={props.str_code}
          />
        </div>
      </div>
    );
  };

  const SamplesSets = props => {
    return (
      <div
        className={
          props.option === props.isAnswer
            ? EditRoundTriangleSamplesStyle.selectedAnswer
            : EditRoundTriangleSamplesStyle.samplesCategorieOptions
        }
      >
        <div className={EditRoundTriangleSamplesStyle.optionSelect}>
          <ChevronUp
            style={{ cursor: 'not-allowed' }}
            className={
              disabledStatus()
                ? EditRoundTriangleSamplesStyle.chevronUpDisabled
                : EditRoundTriangleSamplesStyle.chevronUp
            }
            onClick={() =>
              handleChangeOption(
                {
                  target: {
                    value: getNextValue(
                      listSets.find(sample => {
                        return sample.str_code === props.str_code;
                      })[props.id],
                      listSets.find(sample => {
                        return sample.str_code === props.str_code;
                      }),
                    ),
                    name: props.id,
                  },
                },
                props.str_code,
                listSets.find(sample => {
                  return sample.str_code === props.str_code;
                }),
              )
            }
          />

          <div className={EditRoundTriangleSamplesStyle.beerContainer}>
            <img
              src={beer}
              alt={'unlocked'}
              height={44}
              width={40}
              style={{ marginLeft: '-10px' }}
            />
            <span className={EditRoundTriangleSamplesStyle.beerOption}>
              {props.option}
            </span>
          </div>

          <ChevronDown
            className={
              disabledStatus()
                ? EditRoundTriangleSamplesStyle.chevronDownDisabled
                : EditRoundTriangleSamplesStyle.chevronDown
            }
            onClick={() =>
              handleChangeOption(
                {
                  target: {
                    value: getNextValue(
                      listSets.find(sample => {
                        return sample.str_code === props.str_code;
                      })[props.id],
                      listSets.find(sample => {
                        return sample.str_code === props.str_code;
                      }),
                    ),
                    name: props.id,
                  },
                },
                props.str_code,
                listSets.find(sample => {
                  return sample.str_code === props.str_code;
                }),
              )
            }
          />
        </div>
      </div>
    );
  };

  const confirmation = () => {
    return (
      <div className={EditRoundTriangleSamplesStyle.Confirmation}>
        <div className={EditRoundTriangleSamplesStyle.Confirm}>
          <h3 className={EditRoundTriangleSamplesStyle.confirmTitle}>
            {t('confirm_remove')}
          </h3>
          <div>
            <Button
              className={EditRoundTriangleSamplesStyle.common_cancel}
              label={t('common_cancel')}
              onClick={() => {
                setDeleteInfo(false);
              }}
            ></Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              className={EditRoundTriangleSamplesStyle.confirm}
              label={t('confirm')}
              onClick={() => {
                props.deleteTab && props.deleteTab(props.samples.uuid_sample);
                setDeleteInfo(false);
              }}
            ></Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={EditRoundTriangleSamplesStyle.pageContainer}>
      <CardRow flexWrap="wrap" justifyContent="flex-end">
        {props.index !== 0 ? (
          <Button
            onClick={() => {
              setDeleteInfo(true);
            }}
            margin="10px 10px 0px 0px"
            className={EditRoundTriangleSamplesStyle.deleteButton}
            disabled={disabledStatus()}
          >
            {'－'} {t('remove_triangle_test')}
          </Button>
        ) : (
          ''
        )}
      </CardRow>
      {deleteInfo && confirmation()}
      <CardRow flexWrap="wrap">
        <CardColumn margin="10px 0px 0px 0px" padding="10px 10px 0px 0px">
          <TextField
            name={'str_triangle_test_name'}
            margin="0px 10px 0px 0px"
            label={t('triangle_test_name')}
            padding="12.5px 5px"
            value={filter.str_triangle_test_name}
            onChange={handleChangeFilter}
            maxLength="100"
            isRequired={true}
            text
            disabled={disabledStatus()}
          />
        </CardColumn>
        <CardColumn></CardColumn>
      </CardRow>
      <CardRow>
        <CardColumn>
          <CardRow>
            <div style={{ margin: '6px 0px 0px 0px' }}>
              <Label
                required
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
                label={'Sample A'}
              />
            </div>
            <Button
              padding="0"
              margin="0px 0px 0px 10px"
              confirm
              label={'+ ' + t('select_sample')}
              onClick={() => handleChangeAdd('a')}
              disabled={disabledStatus()}
            />
          </CardRow>
          <CardRow>
            <CardColumn>
              <EditRoundSamplesSample
                key={filter.sampleAObj}
                hideDelButton
                hideOder
                state={props.state}
                sample={sampleA}
                commentsName={'str_sample_a'}
                comments={filter.str_sample_a}
                setComments={handleChangeFilter}
                round={props.round}
                showSampleList={() => {
                  setOpenSampleModal(true);
                }}
              />
            </CardColumn>
          </CardRow>
        </CardColumn>
        <CardColumn>
          <CardRow>
            <div style={{ margin: '6px 0px 0px 0px' }}>
              <Label
                required
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
                label={'Sample B'}
              />
            </div>
            <Button
              padding="0"
              margin="0px 0px 0px 10px"
              confirm
              label={'+ ' + t('select_sample')}
              onClick={() => handleChangeAdd('b')}
              disabled={disabledStatus()}
            />
          </CardRow>
          <CardRow>
            <CardColumn>
              <EditRoundSamplesSample
                key={filter.sampleBObj}
                hideDelButton
                hideOder
                state={props.state}
                sample={sampleB}
                commentsName={'str_sample_b'}
                comments={filter.str_sample_b}
                setComments={handleChangeFilter}
                round={props.round}
                showSampleList={() => {
                  setOpenSampleModal(true);
                }}
              />
            </CardColumn>
          </CardRow>
        </CardColumn>
      </CardRow>
      <CardRow flexWrap="wrap" justifyContent="end">
        <Button
          label="Download Design"
          onClick={() => {
            handleDownloadTemplate(props.round.uuid_round);
          }}
          confirm
          margin="20px 10px 0px 0px"
        />

        <Button
          onClick={() => handleChangeShuffle()}
          confirm
          margin="20px 10px 0px 0px"
          className={EditRoundTriangleSamplesStyle.buttonShuffle}
          disabled={disabledStatus()}
        >
          {t('round_randomize_samples_order_button')}
          <Shuffle size="14" />
        </Button>
      </CardRow>

      <div>
        {listSets?.length > 0 &&
          listSets?.map((sample, idx) => {
            return <SamplesCategories key={idx} str_code={sample.str_code} />;
          })}
      </div>

      <div className={EditRoundTriangleSamplesStyle.divButtonSave}>
        <Button
          label={t('common_submit')}
          margin="10px 10px 0px 0px"
          confirm
          disabled={false}
          onClick={e => {
            saveThresholdSample();
          }}
          className={EditRoundTriangleSamplesStyle.saveSample}
        />
      </div>
      {openSampleModal && (
        <div
          className={EditRoundTriangleSamplesStyle.showListModal}
        >
          <div className={EditRoundTriangleSamplesStyle.showListBox}>
            <div className={EditRoundTriangleSamplesStyle.closeButton}>
              <Button
                label="x"
                round
                style={{
                  display: 'flex',
                  width: '30px',
                  fontSize: '18px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '5px',
                }}
                onClick={() => setOpenSampleModal(false)}
              />
            </div>
            {showRoundSamples && (
              <CustomColumns
                title={t('column_options')}
                explain={t(
                  'select_which_of_the_columns_do_you_want_to_be_displayed',
                )}
                round={'sample_round'}
                arrHeader={headers}
                setShow={setShowRoundSamples}
                onChange={setEditPermission}
                values={editPermission}
              />
            )}
            <GenericTable
              editPermission={editPermission}
              setShowRoundSamples={setShowRoundSamples}
              originHeader={filterHeaders}
              arrHeader={headers}
              dataServer={sampleService.listSamplesByServer}
              searchable={true}
              onCheck={handleCheckSample}
              loading={loading}
              rowsPage={6}
              handleSetSamplesPag={handleSetSamplesPag}
              checkboxFilter={{
                fieldName: 'show_all_samples',
                label: t('sample_show_all_samples_label'),
                helpCircle: t('sample_show_all_samples_hint'),
              }}
              isShowRadio={true}
              handleAddSample={() => {
                handleAddSample();
              }}
              hidedropdownOuterList={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditRoundTriangleSamples;
