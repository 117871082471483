import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import listRoundsStatusStyle from './listroundsstatus.module.scss';
import { Label } from '../../../components';
import { Check, X } from 'react-feather';
import RoundService from '../../services/rounds/round.service';
import { useTranslation } from 'react-i18next';

const ListRoundsStatus = props => {
  const { t } = useTranslation();

  const roundService = new RoundService();
  const [position, setPosition] = useState({ xpos: 0, ypos: 0 });

  useEffect(() => {
    refreshState();
  }, [props.status]);

  const refreshState = () => {
    if (props.status && props.status.length) {
      window.document.getElementById('genericTable').onmousemove =
        handleMouseMove;
    }
  };

  const handleMouseMove = mouseEvent => {
    let xpos;
    let ypos;
    if (mouseEvent) {
      //FireFox
      xpos = mouseEvent.x;
      ypos = mouseEvent.y;
    } else {
      //IE
      xpos = window.event.screenX;
      ypos = window.event.screenY;
    }
    setPosition({ xpos: xpos, ypos: ypos });
  };

  return props.status && props.status.length ? (
    <div
      className={listRoundsStatusStyle.statusContainer}
      style={{
        top: position.ypos,
        left: position.xpos,
      }}
    >
      {roundService.listRoundStatus().map(statusTemp => (
        <div
          key={statusTemp.str_status_code}
          className={
            props.status.includes(statusTemp.str_status_code)
              ? listRoundsStatusStyle.statusItemOK
              : listRoundsStatusStyle.statusItemNOK
          }
        >
          {props.status.includes(statusTemp.str_status_code) ? (
            <Check size={16} />
          ) : (
            <X size={16} />
          )}
          <Label label={t(statusTemp.str_name)} neutral margin="5px 10px" />
        </div>
      ))}
    </div>
  ) : (
    ''
  );
};

ListRoundsStatus.propTypes = {
  status: PropTypes.any,
};

export default ListRoundsStatus;
