import ListNextWeekPlan from './ListNextWeekPlan/ListNextWeekPlan.jsx';
import { ProfileProvider } from '../../contexts/Profile';
import NewWeekPlan from './NewWeekPlan/NewWeekPlan.jsx';

export default {
  routeModule: {
    path: '/list_next_week_plan',
    component: ListNextWeekPlan,
    key: 'list_next_week_plan',
    privilege: 'NEXT_WEEK_PLAN_LIST',
    context: ProfileProvider,
    routeComponents: [
      {
        path: '/edit_week_plan',
        component: NewWeekPlan,
        key: 'edit_week_plan',
      },
      {
        path: '/new_week_plan',
        component: NewWeekPlan,
        key: 'new_week_plan',
      },
    ],
    subMenu: 'menu_panel_management',
  },
};

