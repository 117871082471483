import React from 'react';
import Modal from 'react-modal';
import { Label, Button } from '../../../components';
import styles from './listGroupTasters.module.scss';
import { useTranslation } from 'react-i18next';

const ListGroupTastersModal = props => {

  const { t } = useTranslation();

  const renderListTasters = () => {
    return (
      props.tasters.map( (tasterTemp) => ( 
        <div 
          key={tasterTemp.uuid_user} 
          className={styles.tasterItem}
        >
          <Label 
            label={tasterTemp.str_name}
            neutral
            bold
            margin="5px 10px"
          />
        </div>
      ))
    );
  }

  return (
    <Modal className={styles.Modal} overlayClassName={styles.Overlay} {...props}>
      <div className={styles.tastersContainer}>
        {renderListTasters()}
      </div>
      <div className={styles.actionContainer}>
        <Button 
          confirm
          label={t('group_close_modal')}
          onClick={props.onRequestClose}
        />
      </div>
    </Modal>
  )
}

export default ListGroupTastersModal;