import KpiBrands from './KpiBrands';
import EditNewKpiBrands from './editNewKpiBrands/editNewKpiBrands';
import DetailsKpiBrands from './DetailsKpiBrands/DetailsKpiBrands';

export default {
  routeModule: {
    path: '/list_kpi_brands',
    component: KpiBrands,
    key: 'common_kpi_brands',
    privilege: 'KPI_BRAND_EDIT',
    routeComponents: [
      {
        path: '/edit_kpi_brand',
        component: EditNewKpiBrands,
        key: 'edit_round',
        privilege: 'KPI_BRAND_EDIT',
      },
      {
        path: '/new_kpi_brand',
        component: EditNewKpiBrands,
        key: 'new_Round',
        privilege: 'KPI_BRAND_EDIT',
      },
      {
        path: '/details_kpi_brand',
        component: DetailsKpiBrands,
        key: 'new_Round_detail',
        privilege: 'KPI_BRAND_EDIT',
      },
    ],
    subMenu: 'menu_product_management',
  },
};
