import { useState, useCallback, useEffect } from 'react';
import AppService from '../../../app.service';
import BrandService from '../../services/brands/brand.service';
import RoundService from '../../services/rounds/round.service';
import ReportService from '../../services/reports/report.service';
import { useTranslation } from 'react-i18next';
import configs from '../../../configs';
import SampleService from '../../services/samples/sample.service';

const useListReportTasting = () => {
  const { t } = useTranslation();
  const [privilege, setPrivilege] = useState([]);
  const [canRefreshFilterRounds, setCanRefreshFilterRounds] = useState(false);
  const [privilegeThreshold, setPrivilegeThreshold] = useState(null);
  const [roundTypes, setRoundTypes] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [families, setFamilies] = useState([]);
  const [brands, setBrands] = useState([]);
  const [listDecimalSymbol, setListDecimalSymbol] = useState([]);
  const [uuidDecimalSymbol, setUuidDecimalSymbol] = useState([]);
  const [lastChange, setLastChange] = useState('');
  const [filter, setFilter] = useState({});
  const [loading, setLoading] = useState(true);
  const [disabledDownload, setDisabledDownload] = useState(false);
  const [loadingRounds, setLoadingRounds] = useState(false);
  const appService = new AppService();
  const brandService = new BrandService();
  const roundService = new RoundService();
  const reportService = new ReportService();
  const sampleService = new SampleService();

  const orderAlphabetic = (a, b) => {
    if (a.str_name < b.str_name) {
      return -1;
    }
    if (a.str_name > b.str_name) {
      return 1;
    }
    return 0;
  };

  const getPrivilegies = useCallback(() => {
    //GET PRIVILEGES
    appService.listPrivilegesBuReports().then(res => {
      if (res.type === 'success' && res.results) {
        if (
          res.results[0].arr_zones_tmp ||
          res.results[0].arr_countries_tmp ||
          res.results[0].arr_sites_tmp ||
          res.results[0].arr_bu_tmp
        ) {
          const resp = res.results[0];
          setPrivilege({
            arr_zones: [...resp.arr_zones_tmp].sort(orderAlphabetic),
            arr_bus: [...resp.arr_bu_tmp].sort(orderAlphabetic),
            arr_countries: [...resp.arr_countries_tmp].sort(orderAlphabetic),
            arr_sites: [...resp.arr_sites_tmp].sort(orderAlphabetic),
          });
        }
        setLoading(false);
      } else {
        window.alert(res.message);
        setLoading(false);
      }
    });

    //GET PRIVILEGIES THRESHOLD
    appService.listPrivileges('REPORT_THRESHOLD').then(
      res => {
        if (res.type === 'success' && res.results) {
          if (
            res.results[0].arr_zones ||
            res.results[0].arr_countries ||
            res.results[0].arr_sites
          ) {
            setPrivilegeThreshold(res.results[0]);
            setLoading(false);
          }
        } else {
          window.alert(res.message);
          setLoading(false);
        }
      },
      error => {},
    );
  }, [appService]);

  const roundParamsAdapter = filterList => {
    const filteredZones =
      filterList.arr_zones && filterList.arr_zones.length
        ? filterList.arr_zones.map(item => item.uuid_zone)
        : null;
    const filteredCountries =
      filterList.arr_countries && filterList.arr_countries.length
        ? filterList.arr_countries.map(item => item.uuid_country)
        : null;
    const filteredSites =
      filterList.arr_sites && filterList.arr_sites.length
        ? filterList.arr_sites.map(item => item.uuid_site)
        : null;

    const filteredFamilies =
      filterList.arr_families && filterList.arr_families.length
        ? filterList.arr_families.map(item => item.uuid_brand_family)
        : null;

    const filteredBrands =
      filterList.arr_brands && filterList.arr_brands.length
        ? filterList.arr_brands.map(item => item.uuid_brand)
        : null;

    return {
      ...filterList,
      str_uuid_round_type: filterList.arr_round_types,
      arr_zones: filteredZones,
      arr_countries: filteredCountries,
      arr_families: filteredFamilies,
      arr_sites: filteredSites,
      arr_brands: filteredBrands,
      dat_start: filterList.dat_start,
      dat_finish: filterList.dat_end,
    };
  };

  const loadRound = useCallback(
    state => {
      setLoadingRounds(true);
      roundService.listRoundsReport(roundParamsAdapter(filter)).then(
        res => {
          if (res.type === 'success' && res.results) {
            setRounds(res.results);
            if (state?.uuid_round) {
              filter.arr_rounds = res.results.filter(
                e => e.uuid_round == state?.uuid_round,
              );
            }
            //refreshFilters(res.results);
            setLoading(false);
            setLoadingRounds(false);
          } else {
            window.alert(res.message);
            setLoadingRounds(false);
          }
        },
        error => console.log(error),
      );
    },
    [filter, roundService],
  );

  const loadProductRound = useCallback(
    async state => {
      setLoadingRounds(true);
      try {
        const res = await roundService.listRoundsProductReport(
          roundParamsAdapter(filter),
        );
        if (res.type === 'success' && res.results) {
          setRounds(res.results);
          if (state?.uuid_round) {
            filter.arr_rounds = res.results.filter(
              e => e.uuid_round == state?.uuid_round,
            );
          }
          setLoading(false);
        } else {
          window.alert(res.message);
        }
        setLoadingRounds(false);
      } catch (error) {
        console.log(error);
      }
    },
    [filter, roundService],
  );

  const loadFamilies = useCallback(() => {
    brandService.listFamilies().then(
      res => {
        if (res.type === 'success' && res.results) {
          setFamilies(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        console.log(error);
      },
    );
  });

  const LoadDecimalSymbol = useCallback(() => {
    sampleService.listDecimalSymbol().then(
      res => {
        if (res.type === 'success' && res.results) {
          setListDecimalSymbol(res.results);
          const uuidDecimalSymbolArr = res.results.filter(
            item => item.bol_default,
          );
          setUuidDecimalSymbol(
            uuidDecimalSymbolArr.length > 0
              ? uuidDecimalSymbolArr[0].uuid_decimal_symbol
              : res.results[0].uuid_decimal_symbol,
          );
        } else {
          window.alert(res.message);
        }
      },
      error => {
        console.log(error);
      },
    );
  });

  const loadBrands = useCallback(() => {
    brandService.listBrands().then(
      res => {
        if (res.type === 'success' && res.results) {
          setBrands(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        console.log(error);
      },
    );
  });

  const loadRoundTypes = useCallback(() => {
    roundService.listRoundTypes().then(
      res => {
        if (res.type === 'success' && res.results) {
          setRoundTypes(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        console.log(error);
      },
    );
  });

  const handleChange = evt => {
    const value = evt.target.value;
    setLastChange(evt.target.name);
    setFilter({
      ...filter,
      [evt.target.name]: value,
    });
  };

  const handleChangeTestType = evt => {
    setLastChange(evt.target.name);
    const value = evt.target.value
      ? evt.target.value
      : '4ed984d9-021d-4d06-a992-991a24b3d7ce';
    setFilter({
      ...filter,
      [evt.target.name]: value,
    });
  };

  const validateDatesRange = (dateA, dateB) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const a = new Date(dateA);
    const b = new Date(dateB);
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    const daysDiff = Math.floor((utc2 - utc1) / _MS_PER_DAY);
    if (daysDiff < 0) {
      alert(t('round_schedule_start_finish_date'));
      return false;
    }

    if (daysDiff > 366) {
      alert(t('reports_date_range_message'));
      return false;
    }

    return true;
  };

  const handleChangeDates = evt => {
    setLastChange(evt.target.name);
    const value = evt.target.value;

    if (evt.target.name === 'dat_start') {
      if (validateDatesRange(value, filter.dat_end)) {
        setFilter({
          ...filter,
          [evt.target.name]: value,
        });
      } else {
        setFilter({
          ...filter,
          [evt.target.name]: '',
        });
      }
    } else {
      if (validateDatesRange(filter.dat_start, value)) {
        setFilter({
          ...filter,
          [evt.target.name]: value,
        });
      } else {
        setFilter({
          ...filter,
          [evt.target.name]: '',
        });
      }
    }
  };

  const handleDownload = name => {
    if (name === 'tasting') {
      if (!filter.arr_zones || filter.arr_zones?.length <= 0) {
        alert(t('reports_mandatory_zone_filter'));
        return false;
      }
    }
    if (name === 'profile') {
      if (!filter.arr_zones || filter.arr_zones?.length <= 0) {
        alert(t('reports_mandatory_zone_country_site_filter'));
        return false;
      }
      if (!filter.arr_countries || filter.arr_countries?.length <= 0) {
        alert(t('reports_mandatory_zone_country_site_filter'));
        return false;
      }
      if (!filter.arr_sites || filter.arr_sites?.length <= 0) {
        alert(t('reports_mandatory_zone_country_site_filter'));
        return false;
      }
    }

    if ((!filter.dat_start || !filter.dat_end) && !filter.arr_rounds?.length) {
      alert(t('reports_no_dates'));
      return false;
    }

    if (name === 'profile') {
      if (!filter.arr_brands) {
        alert(t('reports_mandatory_brand_filter'));
        return false;
      }
    }

    if (name != 'profile') {
      if (!filter.arr_round_types && !filter.arr_rounds?.length) {
        alert(t('reports_mandatory_test_type'));
        return false;
      }
    }

    const zones = filter.arr_zones?.map(z => z.uuid_zone);
    const countries = filter.arr_countries?.map(c => c.uuid_country);
    const sites = filter.arr_sites?.map(s => s.uuid_site);
    const roundType = filter?.arr_round_types;
    const rounds = filter.arr_rounds?.map(r => r.uuid_round);
    const families = filter.arr_families?.map(bf => bf.uuid_brand_family);
    const brands = filter.arr_brands?.map(b => b.uuid_brand);

    const datesValidation = validateDatesRange(
      filter.dat_start,
      filter.dat_end,
    );

    if (!datesValidation) {
      return false;
    }
    setLoading(true);

    if (name === 'tasting') {
      reportService
        .generate({
          arr_zones: zones,
          arr_countries: countries,
          arr_sites: sites,
          arr_families: families,
          arr_brands: brands,
          arr_round_types: [roundType],
          arr_rounds: rounds,
          dat_start: !rounds?.length ? filter.dat_start : null,
          dat_end: !rounds?.length ? filter.dat_end : null,
          uuid_decimal_symbol: uuidDecimalSymbol,
          bol_closed: false,
        })
        .then(
          res => {
            if (
              res.status === 'success' &&
              res.file &&
              res.file === 'report.xlsx'
            ) {
              setLoading(false);
              window.alert(t('common_report_not_have_data'));
            } else if (
              res.status === 'success' &&
              res.file &&
              res.file !== 'report.xlsx'
            ) {
              setLoading(false);
              let a = document.createElement('a');

              a.href = configs.file_api_url + '/v1/' + res.file;
              a.download = res.file;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            } else {
              setLoading(false);
              window.alert(res.message);
            }
          },
          error => {
            setLoading(false);
            console.log(error);
          },
        );
    }
    if (name === 'profile') {
      reportService
        .generateProductionSite({
          arr_zones: zones,
          arr_countries: countries,
          arr_sites: sites,
          arr_brands: brands,
          arr_rounds: rounds,
          dat_start: filter.dat_start,
          dat_end: filter.dat_end,
          uuid_decimal_symbol: filter.uuid_decimal_symbol,
          bol_closed: false,
        })
        .then(
          res => {
            if (
              res.status === 'success' &&
              res.file &&
              res.file === 'report.xlsx'
            ) {
              setLoading(false);
              window.alert(t('common_report_not_have_data'));
            } else if (
              res.status === 'success' &&
              res.file &&
              res.file !== 'report.xlsx'
            ) {
              setLoading(false);
              let a = document.createElement('a');

              a.href = configs.file_api_url + '/v1/' + res.file;
              a.download = res.file;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            } else {
              setLoading(false);
              window.alert(res.message);
            }
          },
          error => {
            setLoading(false);
            console.log(error);
          },
        );
    }
  };

  return {
    t,
    privilege,
    setPrivilege,
    privilegeThreshold,
    setPrivilegeThreshold,
    roundTypes,
    setRoundTypes,
    rounds,
    setRounds,
    families,
    setFamilies,
    brands,
    setBrands,
    filter,
    setFilter,
    loading,
    setLoading,
    appService,
    roundService,
    brandService,
    reportService,
    loadRound,
    getPrivilegies,
    loadingRounds,
    handleChange,
    handleChangeTestType,
    handleChangeDates,
    handleDownload,
    loadFamilies,
    loadBrands,
    loadRoundTypes,
    LoadDecimalSymbol,
    listDecimalSymbol,
    uuidDecimalSymbol,
    setUuidDecimalSymbol,
    lastChange,
    canRefreshFilterRounds,
    setCanRefreshFilterRounds,
    disabledDownload,
    setDisabledDownload,
    loadProductRound,
  };
};

export default useListReportTasting;

