import { io } from 'socket.io-client';
import configs from '../configs';

let socket = '';
export function createIo() {
  let paths =
    process.env.REACT_APP_STAGE === 'local' ? '/socket' : '/socket/socket';
  socket = io(configs.socket_servers_api_url, {
    path: paths
  });
}

export function IoEmit(name, data) {
  socket.emit(name, { ...data });
}

export function GetSocket() {
  return socket;
}

