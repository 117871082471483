import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cardRowStyles from './cardRow.module.scss';

const CardRow = props => {
  const elementRef = useRef(null);

  const getClassName = () => {
    let classes = cardRowStyles.cardRow + ' ';
    if (props.bordered) {
      classes += cardRowStyles.cardRowBorder + ' ';
    }
    if (props.transparent) {
      classes += cardRowStyles.cardRowTransparent + ' ';
    }
    classes += ' ' + props.className;
    return classes;
  };

  const styleDefault = {
    flex: props.flex ? props.flex : '0',
    padding: props.padding ? props.padding : '0px',
    justifyContent: props.justifyContent ? props.justifyContent : '',
    flexDirection: props.reverse ? 'row-reverse' : 'row',
    flexWrap: props.flexWrap ? props.flexWrap : 'unset',
    alignItems: props.itemAlign ? props.itemAlign : 'unset',
    display: props.display ? props.display : 'flex',
  };

  const styleObj = { ...styleDefault, ...props.style };

  return (
    <div
      ref={elementRef}
      onScroll={props.onScroll}
      className={getClassName()}
      style={styleObj}
    >
      {props.children}
    </div>
  );
};

CardRow.propTypes = {
  flex: PropTypes.number,
  justifyContent: PropTypes.string,
  flexWrap: PropTypes.string,
  className: PropTypes.string,
  itemAlign: PropTypes.string,
  bordered: PropTypes.bool,
  transparent: PropTypes.bool,
  reverse: PropTypes.bool,
  padding: PropTypes.string,
  style: PropTypes.object,
  onScroll: PropTypes.func,
  ref: PropTypes.any,
};

export default CardRow;
