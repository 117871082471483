import ListBreweryProductionSuspension from './listBreweryProductionSuspension/ListBreweryProductionSuspension';
import EditBreweryProductionSuspension from './editBreweryProductionSuspension/EditBreweryProductionSuspension';

export default {
  routeModule: {
    path: '/list_brewery_production_suspension',
    component: ListBreweryProductionSuspension,
    key: 'Suspension_List',
    privilege: 'SAMPLE_MANAGEMENT_LIST',
    routeComponents: [
      {
        path: '/edit_brewery_production_suspension',
        component: EditBreweryProductionSuspension,
        key: 'Edit Suspension',
        privilege: 'SAMPLE_MANAGEMENT_EDIT',
      },
    ],
    subMenu: 'menu_panel_management',
  },
};
