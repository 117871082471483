import React, { useEffect } from 'react';
import editGroupTastersStyle from './editgrouptasters.module.scss';
import PropTypes from 'prop-types';
import {
  Label,
  Card,
  CardRow,
  Checkbox,
  UserImage,
  Button,
} from '../../../components';
import { useTranslation } from 'react-i18next';

const EditRoundTasters = props => {
  const { t } = useTranslation();

  const handleCheckKeyTaster = (evt, index) => {
    if (evt.target) {
      let groupUsers = props.group.arr_users;
      groupUsers[index] = {
        ...groupUsers[index],
        bol_key_taster: !groupUsers[index].bol_key_taster,
      };
      props.setGroup({
        ...props.group,
        arr_users: groupUsers,
      });
    }
  };

  const handleRemoveTaster = (evt, index) => {
    if (evt.target) {
      let groupUsers = props.group.arr_users;
      groupUsers.splice(index, 1);
      props.setGroup({
        ...props.group,
        arr_users: groupUsers,
      });
    }
  };

  const handleTasterList = (user, index) => (
    <div key={index} className={editGroupTastersStyle.tasterCard}>
      <UserImage
        name="str_user_image"
        src={user.str_image}
        margin="0px 4px 0px 8px"
        size={50}
      />
      <div className={editGroupTastersStyle.tasterCardText}>
        <Label label={user.str_name} bold neutral size={12} />
        <Label label={user.str_abi_id} neutral size={12} />
        <Label label={user.str_email} neutral size={12} />
      </div>
      <div className={editGroupTastersStyle.tasterCardButtons}>
        <Checkbox
          label={t('common_key_taster')}
          name="bol_key_taster"
          value={user.bol_key_taster}
          onChange={evt => {
            handleCheckKeyTaster(evt, index);
          }}
          padding="8px 10px 8px 30px"
        />
        <Button
          round
          remove
          label="x"
          margin="5px 0px 5px 10px"
          onClick={evt => {
            handleRemoveTaster(evt, index);
          }}
        />
      </div>
    </div>
  );

  return (
    <Card bordered>
      <CardRow flexWrap="wrap">
        {props.group && props.group.arr_users
          ? props.group.arr_users.map((user, index) =>
              handleTasterList(user, index),
            )
          : ''}
      </CardRow>
    </Card>
  );
};

EditRoundTasters.propTypes = {
  group: PropTypes.any,
  setGroup: PropTypes.any,
};

export default EditRoundTasters;
