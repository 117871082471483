import { useState, useEffect } from 'react';
import SampleAppService from '../../services/samples/sampleApp.service';
import { useTasting } from '../../../contexts/Tasting';

const useTastingVoting = () => {
  const sampleAppService = new SampleAppService();
  const { addCommentsSample } = useTasting();
  const [loading, setLoading] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const [currentReqs, setCurrentReqs] = useState(0);
  const [error, setError] = useState(false);
  const [currentQueue, setCurrentQueue] = useState([]);
  const [currentProcessing, setCurrentProcessing] = useState(null);

  const handleSaveComment = newComment => {
    setLoading(true);
    setCurrentReqs(currentReqs + 1);
    addCommentsSample(newComment);
    setLoading(false);
    setCurrentReqs(prev => prev - 1);
    setCurrentQueue(prev => prev.slice(1));
    setCurrentProcessing(null);
  };

  useEffect(() => {
    if (!currentProcessing && currentQueue.length > 0) {
      setCurrentProcessing(currentQueue[0]);
    }
  }, [currentQueue, currentProcessing]);

  useEffect(() => {
    if (currentProcessing) {
      handleSaveComment(currentProcessing);
    }
  }, [currentProcessing]);

  return {
    currentQueue,
    setCurrentQueue,
    sampleAppService,
    loading,
    setLoading,
    currentReqs,
    setCurrentReqs,
    loadingComments,
    setLoadingComments,
    error,
    setError,
  };
};

export default useTastingVoting;