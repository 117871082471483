import React, { useEffect } from "react";
import { useUser } from "../contexts/User";
import Login from '../modules/Login/Login';
import LoginSSO from '../modules/LoginSSO/LoginSSO';
import LoginAuth from '../modules/LoginAuth/LoginAuth'
import Template from "../modules/Template/Template";
import _ from "lodash";
import { TourMaskProvider } from "../components/tourMask/tourMaskHooks";

const Routes = () => {
  const { isLogged } = useUser();
  useEffect(() => {
    if (
      !isLogged &&
      window.location.pathname !== "/" &&
      window.location.pathname.indexOf('/login_auth') === -1
    ) {
      window.location.href = '/';
    }
  }, [isLogged])

  const { pathname } = window.location;

  return <TourMaskProvider>
    {
      pathname.indexOf('/login_auth') > -1 ?
        <LoginAuth /> :
        !isLogged ? ((
          process.env.REACT_APP_STAGE === "local" ||
            process.env.REACT_APP_STAGE === "dev" ? (
            <Login />
          ) : (
            <LoginSSO />
          )
        )) : (
          <Template />
        )
    }
  </TourMaskProvider>
};

export default Routes;
