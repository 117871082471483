import MainService from './main.service';

export default class BrandService {
    mainService = null;

    constructor() {
        this.mainService = new MainService();
        this.path = '/v1/admin';
    }

    listPackages = () => {
        return this.mainService.get(`${this.path}/packages`);
    }

    updatePackage = (objPackage) => {
        return this.mainService.post(`${this.path}/package`, objPackage);
    }

    deletePackage = (uuidPackage) => {
        return this.mainService.delete(`${this.path}/package/${uuidPackage}`);
    }

    listVolumes = () => {
        return this.mainService.get(`${this.path}/volumes`);
    }
}