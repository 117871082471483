import React, { useState, useEffect } from 'react';
import editSampleProjectStyle from './editsampleproject.module.scss';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  TextArea,
  Card,
  CardRow,
  DropdownField,
  Button,
  DatePickerField,
  UploadButton,
  CardDocument,
  DropdownMultiField,
} from '../../../components';
import { HelpCircle } from 'react-feather';
import SampleService from '../../services/samples/sample.service';
import AppService from '../../../app.service';
import ReportService from '../../services/reports/report.service';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import configs from '../../../configs';
import { useUser } from '../../../contexts/User';

const arr = [];
const deleteFiles = [];

const EditSampleProject = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const sampleService = new SampleService();
  const appService = new AppService();
  const reportService = new ReportService();
  const [disabled, setDisabled] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const [datExp, setDatExp] = useState(null);
  const [datProd, setDatProd] = useState(null);
  const { user } = useUser();

  useEffect(() => {
    for (var i = arr.length; i > 0; i--) {
      arr.pop();
    }

    for (var j = deleteFiles.length; j > 0; j--) {
      deleteFiles.pop();
    }
  }, []);

  useEffect(() => {
    if (
      props.sample.dat_expire !== undefined &&
      props.sample.dat_expire !== null &&
      props.sample.dat_expire !== 'Invalid date'
    ) {
      let date = props.sample.dat_expire.toString();

      date = date.substring(0, 10);

      if (props.sample.dat_expire !== 'Invalid date') {
        setDatExp(`${date}`);
      } else {
        setDatExp(null);
      }
    }
  }, [props.sample.dat_expire]);

  useEffect(() => {
    if (
      props.sample.dat_production !== undefined &&
      props.sample.dat_production !== null &&
      props.sample.dat_production !== 'Invalid date'
    ) {
      let date = props.sample.dat_production.toString();

      date = date.substring(0, 10);

      if (props.sample.dat_production !== 'Invalid date') {
        setDatProd(date);
      } else {
        setDatProd(null);
      }
    }
  }, [props.sample.dat_production]);

  const handleChange = evt => {
    const { name, value } = evt.target;
    let arrDocs = [];

    if (
      name === 'uuid_brand' &&
      props.sample.uuid_site &&
      props.sample.str_name_project &&
      props.sample.str_trial_identifier
    ) {
      sampleService
        .findSampleProject(
          props.sample.uuid_sample,
          props.sample.uuid_site,
          value,
          props.sample.str_name_project,
          props.sample.str_trial_identifier,
        )
        .then(res => {
          if (res.length) {
            alert(t('sample_duplicated_project_message'));
          }
        });
    } else if (
      name === 'uuid_site' &&
      props.sample.uuid_brand &&
      props.sample.str_name_project &&
      props.sample.str_trial_identifier
    ) {
      sampleService
        .findSampleProject(
          props.sample.uuid_sample,
          value,
          props.sample.uuid_brand,
          props.sample.str_name_project,
          props.sample.str_trial_identifier,
        )
        .then(res => {
          if (res.length) {
            alert(t('sample_duplicated_project_message'));
          }
        });
    } else if (evt.target.name === 'str_zip_documents') {
      let dataFilter = _.filter(arr, { str_zip_document: value });
      let dataFilterSample = _.filter(props.sample.str_zip_documents, {
        str_zip_document: value,
      });

      if (dataFilter.length === 0 && dataFilterSample.length === 0) {
        arr.push({ str_zip_document: value, uuid_document: '' });
        arrDocs = _.union(props.sample.str_zip_documents, arr);
      }
    }

    let data =
      evt.target.name !== 'str_zip_documents'
        ? { [evt.target.name]: value }
        : { [evt.target.name]: arrDocs };

    setDisabled(false);
    //DISABLE PACKAGE AND VOLUME IF IS PRODUCT TYPE IS CONGRESS WORT
    if (
      name === 'uuid_product_type' &&
      value === 'e0a056e4-05ba-11eb-b946-02be69c96cde'
    ) {
      setDisabled(true);
      data.uuid_volume = null;
      data.uuid_package = null;
    } else if (
      name === 'uuid_product_type' &&
      value !== 'e0a056e4-05ba-11eb-b946-02be69c96cde'
    ) {
      data.uuid_malt_varieties = null;
    }

    props.setSample(prevState => {
      return { ...prevState, ...data };
    });
  };

  const handleRemove = (evt, index) => {
    deleteFiles.push({ index: index, evt: evt });

    let data = {
      str_zip_documents: props.sample.str_zip_documents.filter(
        (doc, i) => i !== index,
      ),
    };
    for (var i = arr.length; i > 0; i--) {
      arr.pop();
    }
    arr.pop();

    props.setSample(prevState => {
      return { ...prevState, ...data };
    });
  };
  const hanldeSKUChange = () => {
    let sku = '';
    if (
      props.sample.uuid_brand &&
      props.sample.uuid_package &&
      props.sample.uuid_volume &&
      props.brands &&
      props.packages &&
      props.volumes
    ) {
      const brandTemp = props.brands.find(brand => {
        return brand.uuid_brand === props.sample.uuid_brand;
      });
      const packageTemp = props.packages.find(pack => {
        return pack.uuid_package === props.sample.uuid_package;
      });
      const volumeTemp = props.volumes.find(volume => {
        return volume.uuid_volume === props.sample.uuid_volume;
      });
      sku =
        (brandTemp ? brandTemp.str_name + ' - ' : '') +
        (packageTemp ? packageTemp.str_name + ' - ' : '') +
        (volumeTemp ? volumeTemp.str_name : '');
    }
    return sku;
  };

  function objSort(obj) {
    const newObj = {};
    Object.keys(obj)
      .sort()
      .forEach(key => {
        newObj[key] = obj[key];
      });
    return JSON.stringify(newObj);
  }

  const uniqueArr = arr => {
    const set = new Set();
    for (let i = 0; i < arr.length; i++) {
      let str = objSort(arr[i]);
      set.add(str);
    }
    arr = [...set].map(item => {
      return JSON.parse(item);
    });
    return arr;
  };

  const format = arr => {
    let res = {};
    for (const p of arr) {
      res = { ...res, ...p };
    }
    delete res.uuid_submission_type;
    delete res.uuid_country;
    delete res.uuid_site;
    return res;
  };

  const findValue = () => {
    const site =
      props.privilege.arr_sites && props.sample.uuid_country
        ? props.privilege.arr_sites.filter(site => {
            return site.uuid_country === props.sample.uuid_country;
          })
        : [];
    let originSite = '';
    for (const arrSite of props.privilege.arr_sites) {
      if (arrSite.uuid_site === user.uuid_site) {
        originSite = arrSite;
      }
    }
    const res = [];
    const result = [originSite, { str_sku: hanldeSKUChange() }];
    res.push(
      ...site,
      { dat_production: props.sample.dat_production },
      { str_name_project: props.sample.str_name_project },
      { str_trial_identifier: props.sample.str_trial_identifier },
      { str_batch: props.sample.str_batch },
    );
    for (const item of res) {
      for (const key in props.sample) {
        if (
          item?.uuid_site === props.sample[key] ||
          item?.str_name_project === props.sample[key] ||
          item?.str_submission_type_name === props.sample[key] ||
          item?.dat_production === props.sample[key] ||
          item?.str_trial_identifier === props.sample[key] ||
          item?.str_batch === props.sample[key]
        ) {
          result.push(item);
        }
      }
    }
    return format(uniqueArr(result));
  };

  const handleDownload = async () => {
    const defaultUploadObj = {
      dat_kpi: '',
      dat_production: '',
      str_batch: '',
      str_name: '',
      str_sku: '',
      str_submission_type_name: '',
      str_name_project: '',
      str_trial_identifier: '',
      sampleTab: 'Project',
    };
    const uploadObj = {
      ...defaultUploadObj,
      ...findValue(),
    };
    await reportService.generateSampleLabels(uploadObj).then(res => {
      let a = document.createElement('a');
      a.href = configs.file_api_url + '/v1/' + res.file;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };
  const madatoryFieldValidate = () => {
    let sampleTemp = { ...props.sample };
    if (
      !sampleTemp.uuid_site ||
      !sampleTemp.uuid_brand ||
      !sampleTemp.uuid_product_type ||
      !sampleTemp.dat_production ||
      // (!sampleTemp.uuid_volume && sampleTemp.uuid_product_type !== "e0a056e4-05ba-11eb-b946-02be69c96cde") ||
      !sampleTemp.uuid_volume ||
      !sampleTemp.str_name_project?.trim() ||
      !sampleTemp.str_trial_identifier?.trim()
    ) {
      return false;
    }
    return true;
  };
  const goBackPage = () => {
    if (props.closeAction) {
      props.closeAction();
    } else {
      history.goBack();
    }
  }
  const handleSave = () => {
    let sampleTemp = { ...props.sample };

    if (
      !sampleTemp.str_name_project?.trim() ||
      !sampleTemp.str_trial_identifier?.trim()
    ) {
      alert(t('sample_project_no_name_or_trial'));
      return false;
    }

    if (!sampleTemp.uuid_site) {
      alert(t('sample_no_site_message'));
      return false;
    }

    if (!sampleTemp.uuid_brand) {
      alert(t('sample_no_brand_message'));
      return false;
    }

    if (!sampleTemp.uuid_product_type) {
      alert(t('sample_no_product_type'));
      return false;
    }

    if (!sampleTemp.dat_production) {
      alert(t('sample_no_production_date_message'));
      return;
    }

    if (
      sampleTemp.dat_production &&
      sampleTemp.dat_expire &&
      new Date(sampleTemp.dat_production) > new Date(sampleTemp.dat_expire)
    ) {
      alert(t('sample_expire_bigger_than_production'));
      return false;
    }

    if (
      !sampleTemp.uuid_volume &&
      sampleTemp.uuid_product_type !== 'e0a056e4-05ba-11eb-b946-02be69c96cde'
    ) {
      alert(t('sample_no_volume_message'));
      return false;
    }

    if (deleteFiles.length > 0) {
      deleteFiles.forEach(file => {
        if (
          file.evt.uuid_document !== undefined &&
          file.evt.uuid_document !== ''
        ) {
          appService.removeDocument(file.evt.uuid_document);
        }
      });
    }
    if (sampleTemp.uuid_malt_varieties?.length) {
      sampleTemp.uuid_malt_varieties = sampleTemp.uuid_malt_varieties.map(
        item => {
          return item.uuid_malt_varieties;
        },
      );
    }

    if (props.sampleType === 'PRODUCTION') {
      if (!window.confirm(t('sample_production_to_project_confirm_message'))) {
        return false;
      }
    }

    sampleTemp.str_type = 'PROJECT';
    sampleTemp.dat_kpi = null;
    sampleTemp.bol_kpi = false;

    sampleService
      .findSampleProject(
        sampleTemp.uuid_sample,
        sampleTemp.uuid_site,
        sampleTemp.uuid_brand,
        sampleTemp.str_name_project,
        sampleTemp.str_trial_identifier,
      )
      .then(res => {
        if (res.length) {
          alert(t('sample_duplicated_project_message'));
        } else {
          setDisabledSave(true);
          sampleService.updateSample(sampleTemp).then(
            res => {
              if (res.type === 'success' && res.results) {
                let dataFilter = _.filter(arr, { uuid_document: '' });
                if (dataFilter.length > 0) {
                  const upload = new Promise(async (resolve, reject) => {
                    appService
                      .updateDocument({
                        uuid_sample: sampleTemp.uuid_sample,
                        uuid_round: null,
                        str_zip_document: sampleTemp.str_zip_documents,
                      })
                      .then(
                        res => {
                          resolve(true);
                        },
                        error => {
                          //console.log(error);
                          reject(true);
                        },
                      );
                  });

                  upload.then(
                    res => {
                      for (var i = arr.length; i > 0; i--) {
                        arr.pop();
                      }
                      arr.pop();

                      setTimeout(() => {
                        setDisabledSave(false);
                        goBackPage();
                      }, 3000);
                    },
                    error => {},
                  );
                } else {
                  setDisabledSave(false);
                  goBackPage();
                }
              } else {
                setDisabledSave(false);
                window.alert(res.message);
              }
            },
            error => {
              setDisabledSave(false);
              //console.log(error);
            },
          );
        }
      })
      .catch(() => {
        setDisabledSave(false);
        alert(
          'An error occured when validating the sample. If this persists, contact the support.',
        );
      });
  };

  return (
    <div className={editSampleProjectStyle.pageContainer}>
      <Card>
        <CardRow flexWrap="wrap">
          <DropdownField
            name="uuid_zone"
            options={props.privilege.arr_zones ? props.privilege.arr_zones : []}
            valueField="uuid_zone"
            labelField="str_name"
            label={t('common_zone')}
            margin="10px 10px 0px 0px"
            value={props.sample.uuid_zone}
            onChange={value => {
              props.sample.uuid_country = null;
              props.sample.uuid_site = null;
              handleChange(value);
            }}
            isRequired={true}
          />
          <DropdownField
            name="uuid_country"
            options={
              props.privilege.arr_countries && props.sample.uuid_zone
                ? props.privilege.arr_countries.filter(country => {
                    return country.uuid_zone === props.sample.uuid_zone;
                  })
                : []
            }
            valueField="uuid_country"
            labelField="str_name"
            label={t('common_country')}
            margin="10px 10px 0px 0px"
            value={props.sample.uuid_country}
            onChange={value => {
              props.sample.uuid_site = null;
              handleChange(value);
            }}
            isRequired={true}
          />
          <DropdownField
            name="uuid_site"
            options={
              props.privilege.arr_sites && props.sample.uuid_country
                ? props.privilege.arr_sites.filter(site => {
                    return site.uuid_country === props.sample.uuid_country;
                  })
                : []
            }
            valueField="uuid_site"
            labelField="str_name"
            label={t('common_site')}
            margin="10px 10px 0px 0px"
            value={props.sample.uuid_site}
            onChange={handleChange}
            isRequired={true}
          />
        </CardRow>
        <CardRow flexWrap="wrap">
          <DropdownField
            name="uuid_brand"
            options={props.brands.filter(data => {
              return (
                data.uuid_product_type === props.sample.uuid_product_type ||
                !props.sample.uuid_product_type
              );
            })}
            valueField="uuid_brand"
            labelField="str_name"
            label={t('common_brand')}
            margin="10px 10px 0px 0px"
            value={props.sample.uuid_brand}
            onChange={handleChange}
            isRequired={true}
          />
          <DropdownField
            name="uuid_product_type"
            options={props.products.filter(data => {
              return (
                props.brands
                  .filter(brand => {
                    return brand.uuid_brand === props.sample.uuid_brand;
                  })
                  .map(brand => {
                    return brand.uuid_product_type;
                  })
                  .includes(data.uuid_product_type) || !props.sample.uuid_brand
              );
            })}
            valueField="uuid_product_type"
            labelField="str_name_product"
            label={t('common_product_type')}
            margin="10px 10px 0px 0px"
            value={props.sample.uuid_product_type}
            onChange={handleChange}
            isRequired={true}
          />
          <TextField
            name="str_name_project"
            label={t('sample_project_name')}
            margin="10px 10px 0px 0px"
            value={props.sample.str_name_project}
            onChange={handleChange}
            isRequired={true}
          />
        </CardRow>
        <CardRow flexWrap="wrap">
          {props.sample.uuid_product_type ===
          'e0a056e4-05ba-11eb-b946-02be69c96cde' ? (
            <DropdownMultiField
              name="uuid_malt_varieties"
              options={props.maltVarieties ? props.maltVarieties : []}
              valueField="uuid_malt_varieties"
              labelField="str_name_en"
              label={t('sample_malt_varieties')}
              margin="10px 10px 0px 0px"
              value={props.sample.uuid_malt_varieties}
              onChange={value => {
                handleChange(value);
              }}
              maxValueSelect={5}
              messageMaxValue={t('you_can_only_select_up_to_five')}
            />
          ) : (
            <DropdownField
              name="uuid_package"
              options={props.packages}
              valueField="uuid_package"
              labelField="str_name"
              label={t('sample_package')}
              margin="10px 10px 0px 0px"
              value={props.sample.uuid_package}
              disabled={
                props.sample.uuid_product_type ===
                'e0a056e4-05ba-11eb-b946-02be69c96cde'
                  ? true
                  : disabled
              }
              onChange={value => {
                props.sample.uuid_volume = null;
                handleChange(value);
              }}
              isRequired={true}
            />
          )}
          <DropdownField
            name="uuid_volume"
            options={
              props.volumes && props.sample.uuid_package
                ? props.volumes.filter(volume => {
                    return volume.uuid_package === props.sample.uuid_package;
                  })
                : []
            }
            valueField="uuid_volume"
            labelField="str_name"
            label={t('sample_volume')}
            margin="10px 10px 0px 0px"
            disabled={
              props.sample.uuid_product_type ===
              'e0a056e4-05ba-11eb-b946-02be69c96cde'
                ? true
                : disabled
            }
            value={props.sample.uuid_volume}
            onChange={handleChange}
            isRequired={true}
          />
          <TextField
            name="str_trial_identifier"
            label={t('sample_trial_identifier')}
            margin="10px 10px 0px 0px"
            value={props.sample.str_trial_identifier}
            onChange={handleChange}
            isRequired={true}
          />
        </CardRow>
        <CardRow flexWrap="wrap">
          <DatePickerField
            name="dat_production"
            type={'date'}
            label={t('sample_production_date')}
            margin="10px 10px 0px 0px"
            value={datProd}
            onChange={handleChange}
            isRequired={true}
          />
          <DatePickerField
            name="dat_expire"
            type={'date'}
            label={t('sample_expire_date')}
            margin="10px 10px 0px 0px"
            value={datExp}
            onChange={handleChange}
          />
          <div
            style={{
              margin: '10px 10px 0px 0px',
              flex: '1 1 0%',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {t('common_batch')}
              <HelpCircle
                className={editSampleProjectStyle.helpCircleId}
                data-tip={`${t('sample_id_code_message')}`}
              />
              <ReactTooltip
                effect="solid"
                place="top"
                type="warning"
                className={editSampleProjectStyle.tooltip}
              />
            </div>
            <TextField
              name="str_batch"
              value={props.sample.str_batch}
              onChange={handleChange}
            />
          </div>
        </CardRow>
        <CardRow flexWrap="wrap">
          <TextArea
            name="str_comment"
            label={t('common_notes')}
            margin="10px 10px 0px 0px"
            value={props.sample.str_comment}
            onChange={handleChange}
          />
        </CardRow>
        <CardRow display="inline-flex">
          {props.sample.str_zip_documents
            ? props.sample.str_zip_documents.map((doc, i) => (
                <CardDocument
                  name="str_zip_documents"
                  src={doc.str_zip_document}
                  onRemove={() => handleRemove(doc, i)}
                  margin="10px 0px"
                  size={80}
                  width="100px"
                />
              ))
            : null}
        </CardRow>
        <CardRow flexWrap="wrap">
          <React.Fragment>
            <div className={editSampleProjectStyle.uploadCard}>
              <UploadButton
                name="str_zip_documents"
                label={t('sample_upload_document')}
                onUpload={handleChange}
                margin="0px 10px 0px 0px"
                allowExtension={['.jpeg', '.jpg', '.png', '.pdf']}
                multiple={true}
                disabled={!props.sample.uuid_site}
                maxSize={5242880}
              />
              <HelpCircle
                className={editSampleProjectStyle.helpCircle}
                data-tip={`${t(
                  'sample_upload_message',
                )} .jpeg, .jpg, .png, .pdf. (5mb)`}
              />
              <ReactTooltip
                effect="solid"
                place="top"
                type="warning"
                className={editSampleProjectStyle.tooltip}
              />
            </div>
          </React.Fragment>
          <React.Fragment>
            <div className={editSampleProjectStyle.uploadCard}>
              {madatoryFieldValidate() ? (
                <Button
                  blue
                  upload
                  width="155px"
                  height="40px"
                  label={t('download_label')}
                  onClick={handleDownload}
                  margin="0px 0px 0px 20px"
                ></Button>
              ) : (
                <Button
                  blue
                  upload
                  disabled
                  width="155px"
                  height="40px"
                  label={t('download_label')}
                  onClick={handleDownload}
                  margin="0px 0px 0px 20px"
                ></Button>
              )}
            </div>
          </React.Fragment>
        </CardRow>
        <CardRow justifyContent="flex-end" flexWrap="wrap">
          <Button
            label={t('common_cancel')}
            onClick={() => {
              goBackPage();
            }}
            neutral
            margin="10px 10px 0px 0px"
          />
          <Button
            label={t('common_save')}
            onClick={handleSave}
            confirm
            disabled={disabledSave || !props.sample.uuid_site}
            margin="10px 10px 0px 0px"
          />
        </CardRow>
      </Card>
    </div>
  );
};

export default EditSampleProject;
