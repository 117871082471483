import AppService from '../../../app.service';
import configs from '../../../configs';

export default class UserService {
  mainService = null;
  path = null;
  objSocket = null;
  bolConect = false;

  constructor() {
    this.AppService = new AppService();
    this.path = '/v1/admin';
  }

  loginGetSSO = () => {
    return this.AppService.get(
      configs.auth_api_url + '/v1/sso/saml/login',
      false,
    );
  };

  checkAssert = id => {
    return this.AppService.post(
      configs.auth_api_url + '/v1/sso/saml/check-assert',
      { str_req_to: id },
      false,
    );
  };

  loginAuth = data => {
    return this.AppService.post(
      configs.auth_api_url + '/v1/auth/login_oauth',
      data,
    );
  };
}

