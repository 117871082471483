import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardRow,
  DropdownField,
  Label,
  AutoCompleteSearch,
} from '../../../components';
import UserService from '../../services/users/user.service';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import configs from '../../../configs';
import { useUser } from '../../../contexts/User';

const EditEmailConfigurationUsers = props => {
  const userService = new UserService();
  const { user } = useUser();

  const { t } = useTranslation();
  const [filterSearch, setFilterSearch] = useState({
    uuid_zone: user.uuid_zone,
    uuid_country: user.uuid_country,
    uuid_site: user.uuid_site,
  });
  const [searched, setSearched] = useState({});
  const [text, setText] = useState('');
  const [show, setShow] = useState(false);

  const handleSelectUser = evt => {
    if (evt.target && evt.target.value && evt.target.value.uuid_user) {
      const newValue = { ...evt.target.value, bol_cc_recipient: false }
      const groupUsers = props.group.arr_users
        ? props.group.arr_users.concat([newValue])
        : [newValue];

      const hasUser = props.group.arr_users
        ? props.group.arr_users.find(
          u => u.uuid_user === newValue.uuid_user,
        )
        : false;

      setText('');
      setShow(false);

      if (!hasUser) {
        props.setGroup({
          ...props.group,
          arr_users: groupUsers,
        });
      } else {
        alert(t('group_duplicated_user'));
      }
    }
  };

  const handleSearchUsers = text => {
    return new Promise((resolve, reject) => {
      const filterTemp = {
        str_name: text,
        uuid_country: filterSearch.uuid_country,
        uuid_zone: filterSearch.uuid_zone,
        uuid_site: filterSearch.uuid_site,
      };
      userService.findUser(filterTemp).then(
        res => {
          if (res.type === 'success' && res.results) {
            resolve(res.results);
          } else {
            window.alert(res.message);
            reject(res.message);
          }
        },
        error => {
          reject(error);
        },
      );
    });
  };

  const delayedQuery = useCallback(
    _.debounce(text => {
      return new Promise((resolve, reject) => {
        handleSearchUsers(text).then(
          res => {
            resolve(res);
            setSearched(res);
            setShow(true);
          },
          err => {
            reject(err);
          },
        );
      });
    }, configs.defaultDebounce),
    [filterSearch],
  );

  const handleChangeFilter = evt => {
    const value = evt.target.value;

    setFilterSearch({
      ...filterSearch,
      [evt.target.name]: value,
    });
  };

  return (
    <Card bordered>
      <Label label={t('common_filter')} />
      <CardRow flexWrap="wrap">
        <DropdownField
          name="uuid_zone"
          options={props.privilege.arr_zones ? props.privilege.arr_zones : []}
          valueField="uuid_zone"
          labelField="str_name"
          label={t('common_zone')}
          margin="10px 10px 0px 0px"
          value={filterSearch.uuid_zone}
          onChange={value => {
            if (
              filterSearch.uuid_country !== null ||
              filterSearch.uuid_country !== undefined
            ) {
              setFilterSearch(prevState => {
                delete prevState.uuid_country;
                delete prevState.uuid_site;

                return {
                  ...prevState,
                };
              });
            }

            handleChangeFilter(value);
          }}
        />
        <DropdownField
          name="uuid_country"
          options={
            props.privilege.arr_countries && filterSearch.uuid_zone
              ? props.privilege.arr_countries.filter(country => {
                return country.uuid_zone === filterSearch.uuid_zone;
              })
              : []
          }
          valueField="uuid_country"
          labelField="str_name"
          label={t('common_country')}
          margin="10px 10px 0px 0px"
          value={filterSearch.uuid_country}
          onChange={value => {
            if (
              filterSearch.uuid_site !== null ||
              filterSearch.uuid_site !== undefined
            ) {
              setFilterSearch(prevState => {
                delete prevState.uuid_site;

                return {
                  ...prevState,
                };
              });
            }

            handleChangeFilter(value);
          }}
        />
        <DropdownField
          name="uuid_site"
          options={
            props.privilege.arr_sites && filterSearch.uuid_country
              ? props.privilege.arr_sites.filter(site => {
                return site.uuid_country === filterSearch.uuid_country;
              })
              : []
          }
          valueField="uuid_site"
          labelField="str_name"
          label={t('common_site')}
          margin="10px 10px 0px 0px"
          value={filterSearch.uuid_site}
          onChange={handleChangeFilter}
        />
      </CardRow>
      <CardRow flexWrap="wrap">
        <AutoCompleteSearch
          options={searched}
          text={text}
          name="users"
          label={t('common_search_users')}
          margin="10px 10px 0px 0px"
          onChange={text => {
            setText(text.target.value);
            delayedQuery(text.target.value);
          }}
          show={show}
          setShow={setShow}
          onSelect={handleSelectUser}
          minimalToSearch={3}
        />
      </CardRow>
    </Card>
  );
};

EditEmailConfigurationUsers.propTypes = {
  privilege: PropTypes.any,
  group: PropTypes.any,
  setGroup: PropTypes.any,
};

export default EditEmailConfigurationUsers;
