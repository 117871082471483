import MainService from './main.service';

export default class ProfileService {
  mainService = null;
  path = null;

  constructor() {
    this.mainService = new MainService();
    this.path = '/v1/profile';
  }

  listPrivileges = () => {
    return this.mainService.get(`${this.path}/privileges`);
  };

  listProfiles = uuid_profile => {
    return this.mainService.get(`${this.path}/list_profiles/${uuid_profile}`);
  };

  saveProfile = profile => {
    return this.mainService.post(`${this.path}/profile`, profile);
  };

  listAllProfiles = () => {
    return this.mainService.get(`${this.path}/profiles`);
  };

  deleteProfile = uuid_profile => {
    return this.mainService.delete(`${this.path}/profile/${uuid_profile}`);
  };

  saveProfileLevel = (uuid_profile, profileLevel) => {
    return this.mainService.post(
      `${this.path}/profile/${uuid_profile}/level`,
      profileLevel,
    );
  };

  listProfileLevels = uuid_profile => {
    return this.mainService.get(`${this.path}/profile/${uuid_profile}/levels`);
  };

  deleteProfileLevel = (uuid_profile, uuid_profile_level) => {
    return this.mainService.delete(
      `${this.path}/profile/${uuid_profile}/${uuid_profile_level}`,
    );
  };

  getUserPreviligesByZone = (uuid_profile, uuid_user) => {
    return this.mainService.get(
      `${this.path}/user_get_privileges_by_zone/${uuid_profile}/${uuid_user}`,
    );
  };
}

