import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid';
import listFamiliesStyle from './listfamilies.module.scss';
import {
  GenericTable,
  Label
} from '../../../components';
import AppService from '../../../app.service';
import BrandService from '../../services/brands/brand.service';
import { useTranslation } from 'react-i18next';

const ListFamilies = props => {
  
  const { t } = useTranslation();
  const history = useHistory();
  const [families, setFamilies] = useState([]);
  const [privilege, setPrivilege] = useState([]);
  const [loading, setLoading] = useState(false);

	// services
  const appService = new AppService();
  const brandService = new BrandService();

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);
    //GET PRIVILEGES
    appService.listPrivileges('FAMILY_MANAGEMENT_EDIT').then(res => {
      if (res.type === 'success' && res.results) {
        setPrivilege(res.results[0]);
      } else {
        window.alert(res.message);
      }
    }, err => console.log(err));

    //GET SAMPLES
    brandService.listFamilies().then(res => {
      if (res.type === 'success' && res.results) {
        setFamilies(res.results);
        setLoading(false);
      } else {
        window.alert(res.message);
        setLoading(false);
      }
    }, err => {
      //console.log(err);
      setLoading(false);
    });
  };

  const handleNewFamily = useCallback(
    () => {
      history.push({pathname: '/edit_style', state: {values: {uuid_brand_family: uuid()}, privilege: privilege}});
    }, [privilege]
  );

  const handleEditFamily = useCallback(
    (values) => () => {
      history.push({pathname: '/edit_style', state: {values: values, privilege: privilege}});
    }, [privilege]
  );

  const handleRemoveFamily = (values) => {
      if (window.confirm(`${t('family_remove_family')}: ${values.str_name}`)) {
        brandService.deleteFamily(values.uuid_brand_family).then(res => {
          if (res.type === 'success' && res.results) {
            refreshState();
          } else {
            window.alert(res.message);
          }
        }, err => console.log(err));
      }
    }

  const headers = [
    { description: t('common_name'), field: 'str_name'}
  ];

  return (
    <div className={listFamiliesStyle.pageContainer}>
      <Label 
        label={t('family_list_families')}
        bold
        neutral
      />

      <GenericTable 
        arrHeader={headers}
        arrRow={families}
        searchable={true}
        onEdit={privilege.bol_access ? handleEditFamily : null}
        onRemove={privilege.bol_access ? handleRemoveFamily : null}
        newRegister={privilege.bol_access ? {
          onClick: handleNewFamily,
          label: t('family_new_family')
        } : null}
        loading={loading}
      />
    </div>
  );
};

export default ListFamilies;