import React, { useState, useEffect } from 'react';
import editFamilyStyle from './editfamily.module.scss';
import { useHistory } from 'react-router-dom';
import {
    TextField,
    Label,
    Card,
    CardRow,
    Button,
    CardColumn,
} from '../../../components';
import BrandService from '../../services/brands/brand.service';
import { useTranslation } from 'react-i18next';

const EditFamily = props => {
    const { t } = useTranslation();
    const history = useHistory();
    const [family, setFamily] = useState([]);

    // services
    const brandService = new BrandService();

    useEffect(() => {
        refreshState();
    }, []);

    const refreshState = () => {
        setFamily(props.location.state.values);
    };

    const handleChange = evt => {
        const value = evt.target.value;
        setFamily({
            ...family,
            [evt.target.name]: value,
        });
    };

    const handleSave = () => {
        if (!family.str_name_en?.trim()) {
            window.alert(t('family_has_no_english_name'));
            return false;
        }
        brandService.updateFamily(family).then(
            res => {
                if (res.type === 'success' && res.results) {
                    history.goBack();
                } else {
                    window.alert(res.message);
                }
            },
            error => {
                //console.log(error);
            },
        );
    };

    return (
        <div className={editFamilyStyle.pageContainer}>
            <Label label={t('family_edit_family')} bold neutral />

            <Card bordered>
                <CardRow flexWrap="wrap">
                    <TextField
                        name="str_name_en"
                        label={`${t('common_family')} (English)`}
                        margin="10px 10px 0px 0px"
                        value={family.str_name_en}
                        onChange={handleChange}
                    />
                    <TextField
                        name="str_name_pt"
                        label={`${t('common_family')} (Portuguese)`}
                        margin="10px 10px 0px 0px"
                        value={family.str_name_pt}
                        onChange={handleChange}
                    />
                    <TextField
                        name="str_name_es"
                        label={`${t('common_family')} (Spanish)`}
                        margin="10px 10px 0px 0px"
                        value={family.str_name_es}
                        onChange={handleChange}
                    />
                </CardRow>

                <CardRow flexWrap="wrap">
                    <TextField
                        name="str_name_de"
                        label={`${t('common_family')} (German)`}
                        margin="10px 10px 0px 0px"
                        value={family.str_name_de}
                        onChange={handleChange}
                    />
                    <TextField
                        name="str_name_fr"
                        label={`${t('common_family')} (French)`}
                        margin="10px 10px 0px 0px"
                        value={family.str_name_fr}
                        onChange={handleChange}
                    />
                    <TextField
                        name="str_name_it"
                        label={`${t('common_family')} (Italian)`}
                        margin="10px 10px 0px 0px"
                        value={family.str_name_it}
                        onChange={handleChange}
                    />
                </CardRow>

                <CardRow flexWrap="wrap">
                    <TextField
                        name="str_name_nl"
                        label={`${t('common_family')} (Dutch)`}
                        margin="10px 10px 0px 0px"
                        value={family.str_name_nl}
                        onChange={handleChange}
                    />
                    <TextField
                        name="str_name_ru"
                        label={`${t('common_family')} (Russian)`}
                        margin="10px 10px 0px 0px"
                        value={family.str_name_ru}
                        onChange={handleChange}
                    />
                    <TextField
                        name="str_name_uk"
                        label={`${t('common_family')} (Ukranian)`}
                        margin="10px 10px 0px 0px"
                        value={family.str_name_uk}
                        onChange={handleChange}
                    />
                </CardRow>
                <CardRow>
                    <TextField
                        name="str_name_zh"
                        label={`${t('common_family')} (Chinese)`}
                        margin="10px 10px 0px 0px"
                        value={family.str_name_zh}
                        onChange={handleChange}
                    />
                    <TextField
                        name="str_name_kr"
                        label={`${t('common_family')} (Korean)`}
                        margin="10px 10px 0px 0px"
                        value={family.str_name_kr}
                        onChange={handleChange}
                    />
                    <CardColumn></CardColumn>
                </CardRow>
                <CardRow justifyContent="flex-end" flexWrap="wrap">
                    <Button
                        label={t('common_cancel')}
                        onClick={() => {
                            history.goBack();
                        }}
                        neutral
                        margin="35px 10px 0px 0px"
                    />
                    <Button
                        label={t('common_save')}
                        onClick={handleSave}
                        confirm
                        margin="35px 10px 0px 0px"
                    />
                </CardRow>
            </Card>
        </div>
    );
};

export default EditFamily;
