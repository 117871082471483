import notificationBarStyle from './NotificationBar.module.scss';
import { useTranslation } from 'react-i18next';
import { X } from 'react-feather';
import bubbleImg from '../../assets/bubble.png';

const ItemBar = ({ row, removeItem }) => {
  const { t } = useTranslation();

  const closeBar = () => {
    removeItem();
  };

  return (
    <div className={notificationBarStyle.ItemBar}>
      <p className={notificationBarStyle.title}>
        <img src={bubbleImg} alt="" className={notificationBarStyle.beerImg} />
        <p className={notificationBarStyle.text}>
          {row.oldName && (
            <span className={notificationBarStyle.name}>
              {t(row.oldName)}&ensp;
            </span>
          )}
          {row.title && <span>{t(row.title)}</span>}
          {row.newName && (
            <span className={notificationBarStyle.name}>
              &ensp;{t(row.newName)}
            </span>
          )}
        </p>
      </p>
      <p className={notificationBarStyle.closeBtn}>
        <X className={notificationBarStyle.x} onClick={closeBar} />
      </p>
    </div>
  );
};

export default ItemBar;

