import React, { useState, useEffect } from 'react';
import editCategoryStyle from './editcategory.module.scss';
import { useHistory } from 'react-router-dom';
import {
    TextField,
    Label,
    Card,
    CardRow,
    DropdownField,
    Button,
    CardColumn,
} from '../../../components';
import RoundService from '../../services/rounds/round.service';
import { useTranslation } from 'react-i18next';

const EditCategory = props => {
    const { t } = useTranslation();
    const history = useHistory();
    const [privilege, setPrivilege] = useState([]);
    const [category, setCategory] = useState({});

    // services
    const roundService = new RoundService();

    useEffect(() => {
        refreshState();
    }, []);

    const refreshState = () => {
        setCategory(props.location.state.values);
        setPrivilege(props.location.state.privilege);
    };

    const handleChange = evt => {
        const value = evt.target.value;
        setCategory({
            ...category,
            [evt.target.name]: value,
        });
    };

    const handleSave = () => {
        const categoryTemp = category;

        if (categoryTemp.str_name_en?.trim().length === 0) {
            window.alert(
                t('common_cannot_be_empty', {
                    field: `${t('common_category')} (English)`,
                }),
            );
            return;
        }

        roundService.updateCategory(categoryTemp).then(
            res => {
                if (res.type === 'success' && res.results) {
                    history.goBack();
                } else {
                    window.alert(res.message);
                }
            },
            error => {
                //console.log(error);
            },
        );
    };

    return (
        <div className={editCategoryStyle.pageContainer}>
            <Label label={t('category_edit_category')} bold neutral />

            <Card bordered>
                <CardRow flexWrap="wrap">
                    <TextField
                        name="str_name_en"
                        label={`${t('common_category')} (English)`}
                        margin="10px 10px 0px 0px"
                        value={category.str_name_en}
                        onChange={handleChange}
                    />
                    <TextField
                        name="str_name_pt"
                        label={`${t('common_category')} (Portuguese)`}
                        margin="10px 10px 0px 0px"
                        value={category.str_name_pt}
                        onChange={handleChange}
                    />
                    <TextField
                        name="str_name_es"
                        label={`${t('common_category')} (Spanish)`}
                        margin="10px 10px 0px 0px"
                        value={category.str_name_es}
                        onChange={handleChange}
                    />
                </CardRow>

                <CardRow flexWrap="wrap">
                    <TextField
                        name="str_name_de"
                        label={`${t('common_category')} (German)`}
                        margin="10px 10px 0px 0px"
                        value={category.str_name_de}
                        onChange={handleChange}
                    />
                    <TextField
                        name="str_name_fr"
                        label={`${t('common_category')} (French)`}
                        margin="10px 10px 0px 0px"
                        value={category.str_name_fr}
                        onChange={handleChange}
                    />
                    <TextField
                        name="str_name_it"
                        label={`${t('common_category')} (Italian)`}
                        margin="10px 10px 0px 0px"
                        value={category.str_name_it}
                        onChange={handleChange}
                    />
                </CardRow>

                <CardRow flexWrap="wrap">
                    <TextField
                        name="str_name_nl"
                        label={`${t('common_category')} (Dutch)`}
                        margin="10px 10px 0px 0px"
                        value={category.str_name_nl}
                        onChange={handleChange}
                    />
                    <TextField
                        name="str_name_ru"
                        label={`${t('common_category')} (Russian)`}
                        margin="10px 10px 0px 0px"
                        value={category.str_name_ru}
                        onChange={handleChange}
                    />
                    <TextField
                        name="str_name_uk"
                        label={`${t('common_category')} (Ukranian)`}
                        margin="10px 10px 0px 0px"
                        value={category.str_name_uk}
                        onChange={handleChange}
                    />
                </CardRow>
                <CardRow flexWrap="wrap">
                    <TextField
                        name="str_name_zh"
                        label={`${t('common_category')} (Chinese)`}
                        margin="10px 10px 0px 0px"
                        value={category.str_name_zh}
                        onChange={handleChange}
                    />
                    <TextField
                        name="str_name_kr"
                        label={`${t('common_category')} (Korean)`}
                        margin="10px 10px 0px 0px"
                        value={category.str_name_kr}
                        onChange={handleChange}
                    />

                    <CardColumn></CardColumn>
                </CardRow>
                <CardRow justifyContent="flex-end" flexWrap="wrap">
                    <Button
                        label={t('common_cancel')}
                        onClick={() => {
                            history.goBack();
                        }}
                        neutral
                        margin="35px 10px 0px 0px"
                    />
                    <Button
                        label={t('common_save')}
                        onClick={handleSave}
                        confirm
                        margin="35px 10px 0px 0px"
                    />
                </CardRow>
            </Card>
        </div>
    );
};

export default EditCategory;
