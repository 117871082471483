import ListUsers from './listUsers/ListUsers';
import EditUser from './editUser/EditUser';
import {ProfileProvider} from '../../contexts/Profile';

export default {
    routeModule: {
        path: '/list_user',
        component: ListUsers,
        key: 'user_list_users',
        privilege: 'USER_MANAGEMENT_LIST',
        context: ProfileProvider,
        exact: true,
        routeComponents: [{
            path: '/edit_user',
            component: EditUser,
            key: 'Edit User',
            privilege: 'USER_MANAGEMENT_EDIT'
        }]
    }
};
