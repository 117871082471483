import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ButtonList from '../buttonList/ButtonList';
import CardRow from '../card/CardRow';
import { useTranslation } from 'react-i18next';
import PinyinMatch from 'pinyin-match';
import { useUser } from '../../contexts/User';

const AttributesCategory = props => {
  const { t } = useTranslation();
  const [currentSelected, setCurrentSelected] = useState(null);
  const [attrs, setAttrs] = useState([]);
  const [categorySelected, setCategorySelected] = useState('');
  const { user } = useUser();

  useEffect(() => {
    refreshState();
  }, [props.list]);

  const refreshState = () => {
    setCategorySelected(props.freshness ? 'CbC' : 'AC');
    if (props.freshness) {
      handleOnClickCategoryByAttr('af0e25e0-c535-11ea-8822-000000000000');
    } else {
      handleOnClickCategoryByAttr('');
    }
  };

  const handleOnClickCategoryByAlpha = value => {
    if (value) {
      let filteredComments;
      if (user.str_language === 'zh') {
        if (value === 'All') {
          filteredComments = props.list;
        } else {
          filteredComments = props.list.filter(
            item =>
              PinyinMatch.match(item.str_name, value).length > 0 &&
              PinyinMatch.match(item.str_name, value)[0] === 0,
          );
        }
      } else if (value !== 'All') {
        filteredComments = props.list.filter(attr => {
          return attr[props.valueField ?? 'value'][0].toUpperCase() === value;
        });
      } else {
        filteredComments = props.list;
      }

      const orderedComments = filteredComments.sort((a, b) =>
        a[props.valueField]
          .toLowerCase()
          .localeCompare(b[props.valueField].toLowerCase()),
      );
      const map = new Map();
      for (const item of orderedComments) {
        if (!map.has(item.str_name) && !map.has(item.uuid_comment)) {
          map.set(item.str_name, item);
        }
      }
      setAttrs([...map.values()]);
      setCurrentSelected(value);
    } else {
      const orderedComments = props.list.sort((a, b) =>
        a[props.valueField]
          .toLowerCase()
          .localeCompare(b[props.valueField].toLowerCase()),
      );
      const map = new Map();
      for (const item of orderedComments) {
        if (!map.has(item.str_name) && !map.has(item.uuid_comment)) {
          map.set(item.str_name, item);
        }
      }
      setAttrs([...map.values()]);
    }
  };

  const handleOnClickCategoryByAttr = value => {
    if (value) {
      const filteredComments = props.list.filter(attr => {
        return attr['uuid_category']?.toUpperCase() === value.toUpperCase();
      });
      setCurrentSelected(value);
      setAttrs(filteredComments);
    } else {
      const orderedComments = props.list.sort((a, b) =>
        a[props.valueField]
          .toLowerCase()
          .localeCompare(b[props.valueField].toLowerCase()),
      );
      const map = new Map();
      for (const item of orderedComments) {
        if (!map.has(item.str_name) && !map.has(item.uuid_comment)) {
          map.set(item.str_name, item);
        }
      }
      setAttrs([...map.values()]);
    }
  };

  const handleOnClickCategoryTab = e => {
    let value = e.target.value;
    setCategorySelected(value);
    if (value === 'CbC') {
      handleOnClickCategoryByAttr('af0e25e0-c535-11ea-8822-000000000000');
    } else {
      handleOnClickCategoryByAlpha('All');
    }
  };

  return (
    <Fragment>
      <CardRow flexWrap="wrap">
        <ButtonList
          style={{
            flexBasis: '200px',
            width: '200px',
            maxWidth: '200px',
            backgroundColor: '#fff',
            color: '#716f71',
            whiteSpace: 'nowrap',
            border: '1px solid #c6c6c6',
          }}
          list={[
            {
              label: t('common_comment_by_category'),
              value: 'CbC',
            },
            {
              label: t('common_alphabetic_comment'),
              value: 'AC',
            },
          ]}
          onClick={handleOnClickCategoryTab}
          defaultSelected={categorySelected}
        />
      </CardRow>
      {categorySelected === 'CbC' ? (
        <CardRow flexWrap="wrap">
          {props.categories.categories && (
            <ButtonList
              arrow
              style={{ flexBasis: '120px', width: '120px', maxWidth: '120px' }}
              list={props.categories.categories}
              onClick={e => handleOnClickCategoryByAttr(e.target.value)}
              currentSelected={currentSelected}
            />
          )}
        </CardRow>
      ) : (
        <CardRow flexWrap="wrap">
          <ButtonList
            arrow
            style={{
              flexBasis: '50px',
              width: '50px',
              maxWidth: '50px',
            }}
            defaultSelected="All"
            currentSelected={currentSelected}
            list={props.categories.aphabe}
            onClick={e => handleOnClickCategoryByAlpha(e.target.value)}
          />
        </CardRow>
      )}

      <CardRow flexWrap="wrap">
        <ButtonList
          style={{
            flexBasis: '120px',
            width: '120px',
            maxWidth: '120px',
            backgroundColor: '#ebe9eb',
            color: '#000',
          }}
          list={attrs}
          disabled={props.disabled}
          valueField={props.valueField}
          labelField={props.labelField}
          onClick={props.onClick}
        />
      </CardRow>
    </Fragment>
  );
};

AttributesCategory.propTypes = {
  onClick: PropTypes.func.isRequired,
  labelField: PropTypes.string,
  valueField: PropTypes.string,
  list: PropTypes.array,
  style: PropTypes.object,
  freshness: PropTypes.bool,
  categories: PropTypes.object,
};

export default AttributesCategory;
