import ListProcessCheck from './listProcessCheck/listProcessCheck';
import EditProcessCheck from './editProcessCheck/editProcessCheck';

export default {
  privilege: 'PROCESS_CHECK',
  routeModule: {
    path: '/list_process_check',
    privilege: 'PROCESS_CHECK',
    component: ListProcessCheck,
    key: 'process_check',
    routeComponents: [
      {
        path: '/edit_process_check',
        component: EditProcessCheck,
        key: 'Edit Comment',
      },
    ],
  },
};

