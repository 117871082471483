import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid';
import listCategoriesStyle from './listcategories.module.scss';
import {
  GenericTable,
  Label
} from '../../../components';
import AppService from '../../../app.service';
import RoundService from '../../services/rounds/round.service';
import { useTranslation } from 'react-i18next';

const ListCategories = props => {
  
  const { t } = useTranslation();
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [privilege, setPrivilege] = useState([]);
  const [loading, setLoading] = useState(false);

	// services
  const appService = new AppService();
  const roundService = new RoundService();

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);
    //GET PRIVILEGES
    appService.listPrivileges('COMMENT_MANAGEMENT_EDIT').then((res) => {
      if(res.type === 'success' && res.results){
        setPrivilege(res.results[0]);
      }else{
        window.alert(res.message);
      }
    }, (error) => {
      //console.log(error);
    });

    //GET SAMPLES
    roundService.listCategories().then((res) => {
      if(res.type === 'success' && res.results){
        setCategories(res.results);
        setLoading(false);
      }else{
        window.alert(res.message);
        setLoading(false);
      }
    }, (error) => {
      //console.log(error);
      setLoading(false);
    });
  };

  const handleNewCategory = useCallback(
    () => {
      history.push({pathname: '/edit_flavor_category', state: {values: {uuid_category: uuid()}, privilege: privilege}});
    }, [privilege]
  );

  const handleEditCategory = useCallback(
    (values) => () => {
      history.push({pathname: '/edit_flavor_category', state: {values: values, privilege: privilege}});
    }, [privilege]
  );

  const handleRemoveCategory = useCallback(
    (values) => {
      if(window.confirm(`${t('category_remove_category')}: ${values.str_name}`)){

        roundService.deleteCategory(values.uuid_category).then((res) => {
          if(res.type === 'success' && res.results){
            refreshState();
          }else{
            window.alert(res.message);
          }
        }, (error) => {
          //console.log(error);
        });

      }
    }, []
  );

  const headers = [
    { description: t('common_name'), field: 'str_name'}
  ];

  return (
    <div className={listCategoriesStyle.pageContainer}>
      <Label 
        label={t('category_list_categories')}
        bold
        neutral
      />

      <GenericTable 
        arrHeader={headers}
        arrRow={categories}
        searchable={true}
        onEdit={privilege.bol_access ? handleEditCategory : null}
        onRemove={privilege.bol_access ? handleRemoveCategory : null}
        newRegister={privilege.bol_access ? {
          onClick: handleNewCategory,
          label: t('category_new_category')
        } : null}
        loading={loading}
      />
    </div>
  );
};

export default ListCategories;