import MainService from './main.service';

export default class TriangleService {
  mainService = null;
  constructor() {
    this.mainService = new MainService();
    this.path = '/v1/admin';
    this.path_app = '/v1/app';
  }

  saveTriangleRound = body => {
    return this.mainService.post(`${this.path}/round/samples`, body);
  };
  suffleSets = body => {
    return this.mainService.post(`${this.path}/shuffle-sets`, body);
  };

  getTriangleCode = body => {
    return this.mainService.post(
      `${this.path_app}/triangle_test/get_code`,
      body,
    );
  };

  updateTriangleSet = body => {
    return this.mainService.post(
      `${this.path_app}/update-triangle-set
    `,
      body,
    );
  };

  listTriangleSets = body => {
    return this.mainService.get(
      `${this.path}/list_rounds/${body.uuid_round}/samples`,
      body,
    );
  };

  listTriangleSample = body => {
    return this.mainService.post(`${this.path_app}/list-triangle-sample`, body);
  };

  updateSampleAnswer = body => {
    return this.mainService.post(`${this.path_app}/update-sample-answer`, body);
  };

  updateSampleComment = body => {
    return this.mainService.post(
      `${this.path_app}/update-sample-comment`,
      body,
    );
  };

  updateCommentOrder = body => {
    return this.mainService.post(
      `${this.path_app}/update_triangle_test_comment_order
      `,
      body,
    );
  };

  updateSampleStatus = body => {
    return this.mainService.post(
      `${this.path_app}/update-sample-status
      `,
      body,
    );
  };

  updateSampleSetAnswer = body => {
    return this.mainService.post(
      `${this.path}/update-sample-set
      `,
      body,
    );
  };

  downloadFile = body => {
    return this.mainService.post(
      `${this.path}/generate/triangle_test/design
      `,
      body,
    );
  };
}

