import ListFamilies from './listFamilies/ListFamilies';
import EditFamily from './editFamily/EditFamily';

export default {
    routeModule: {
        path: '/list_styles',
        component: ListFamilies,
        key: 'family_list_families',
        privilege: 'FAMILY_MANAGEMENT_LIST',
        routeComponents: [{
            path: '/edit_style',
            component: EditFamily,
            key: 'edit_family',
            privilege: 'FAMILY_MANAGEMENT_EDIT'
        }],
        subMenu: 'menu_product_management'
    }
};
