import MainService from './main.service';

export default class BrandService {
  mainService = null;

  constructor() {
    this.mainService = new MainService();
    this.path = '/v1/admin';
  }

  listFamilies = () => {
    return this.mainService.get(`${this.path}/families`);
  };

  updateFamily = family => {
    return this.mainService.post(`${this.path}/family`, family);
  };

  deleteFamily = uuid_family => {
    return this.mainService.delete(`${this.path}/family/${uuid_family}`);
  };

  listBrands = () => {
    return this.mainService.get(this.path + '/brands');
  };

  deleteBrand = uuid_brand => {
    return this.mainService.delete(this.path + '/brand/' + uuid_brand);
  };

  updateBrand = brand => {
    return this.mainService.post(this.path + '/brand', brand);
  };

  listKpiBrands = () => {
    return this.mainService.get(this.path + '/list_kpi_brands');
  };

  updateKpiBrandInformation = brand => {
    return this.mainService.post(this.path + '/create_edit_kpi_brands', brand);
  };

  updateStatusKpiBrand = brand => {
    return this.mainService.post(this.path + '/update_status_kpi_brand', brand);
  };

  listKpiBrandsStatus = () => {
    return this.mainService.get(this.path + '/list_kpi_brands_status');
  };

  saveCustomScoreScaleBrand = customScale => {
    return this.mainService.post(
      this.path + '/save_custom_score_scale_brand',
      customScale,
    );
  };

  getBrandCustomScale = uuidBrand => {
    return this.mainService.get(
      this.path + '/getCustomScaleBrand/' + uuidBrand,
    );
  };

  deleteCustomScaleComment = uuidCustomScaleComment => {
    return this.mainService.delete(
      this.path + '/deleteCustomScaleComment/' + uuidCustomScaleComment,
    );
  };

  getScaleIntensities = customScale => {
    return this.mainService.get(this.path + '/getScaleIntensities');
  };
}

