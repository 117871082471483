import ListReports from './listReports/LisReports';

export default {
    routeModule: {
        path: '/list_reports',
        component: ListReports,
        key: 'report_list_report',
        privilege: 'REPORT_LIST',
        routeComponents: []
    }
};

