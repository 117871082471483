import React from 'react';
import classes from './editpackagevolume.module.scss';
import { TextField, Button } from '../../../components';
import PropTypes from 'prop-types';

const EditPackageVolume = props => {

  return (
    <div className={classes.cardVolume}>
      <TextField
        margin="0px 10px 8px 0px"
        name="str_name"
        value={props.volume.str_name}
        onChange={props.onChange}
      />
      <Button 
        label="X" 
        margin="8px 0px 0px 0px"
        round 
        remove 
        onClick={props.onRemove}
      />
    </div>
  );
};

EditPackageVolume.propTypes = {
	volume: PropTypes.object.isRequired,
	onChange: PropTypes.func,
	onRemove: PropTypes.func
}

export default EditPackageVolume;