import React, { useState, useEffect } from 'react';
import listConfigurationStyle from './listConfiguration.module.scss';
import {
  GenericTable,
  Card,
  CardColumn,
  CardRow,
  TextField,
  Button,
  DropdownField,
} from '../../../components';
import ProfileService from '../../services/users/profile.service';
import AlcoholService from '../../services/users/alcohol.service';
import AppService from '../../../app.service';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../contexts/User';

export default function ListConfiguration() {
  const { t } = useTranslation();
  const [profiles, setProfiles] = useState([]);
  const [privilege, setPrivilege] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const [uuidZone, setUuidZone] = useState(user.uuid_zone);
  const [uuidCountry, setUuidCountry] = useState(user.uuid_country);
  const [uuidSite, setUuidSite] = useState(user.uuid_site);
  const [concentration, setConcentration] = useState('');
  const profileService = new ProfileService();
  const appService = new AppService();
  const alcoholService = new AlcoholService();
  const [zoneUnit, setZoneUnit] = useState('');

  useEffect(() => {
    refreshState();
  }, []);

  const handleChange = evt => {
    const value = evt.target.value;
    let reg = new RegExp(
      /^(([0-9][0-9]*)|(([0]\.\d{1,2}|[0-9][0-9]*\.\d{1,2})))$/,
    );
    if (evt.target.value && reg.test(value) === false) {
      return alert(
        t('please_input_a_positive_number_up_to_n_decimal_places', {
          field: 2,
        }),
      );
    }
    setConcentration(value);
  };

  const refreshState = async () => {
    setLoading(true);
    try {
      const res = await appService.listPrivileges('USER_MANAGEMENT_EDIT');
      if (res.type === 'success' && res.results) {
        setPrivilege(res.results[0]);
      } else {
        window.alert(res.message);
      }
    } catch (error) {
      window.alert(error);
    }

    try {
      const res = await profileService.listPrivileges();
      if (res.type === 'success' && res.results) {
      } else {
        window.alert(res.message);
      }
    } catch (error) {
      setLoading(false);
      window.alert(error);
    }

    try {
      const res = await alcoholService.alcoholGetStandard(uuidSite);
      if (res.type === 'success' && res.results) {
        setConcentration(
          res.results[0]?.flt_alcohol_concentration
            ? res.results[0].flt_alcohol_concentration
            : '',
        );
      } else {
        window.alert(res.message);
      }
    } catch (error) {
      window.alert(error);
    }
    try {
      const res = await alcoholService.getZoneAlcoholUnit(uuidZone);
      if (res.type === 'success' && res.results) {
        setZoneUnit(res.results[0]?.str_unit ? res.results[0].str_unit : '');
      } else {
        window.alert(res.message);
      }
    } catch (error) {
      window.alert(error);
    }

    try {
      const listrRes = await alcoholService.listUserPagination();
      if (listrRes.type === 'success' && listrRes.results) {
        setProfiles([...listrRes.results]);
        setLoading(false);
      } else {
        setLoading(false);
        window.alert(listrRes.message);
      }
    } catch (error) {
      window.alert(error);
      setLoading(false);
    }
  };

  const handleToggleChange = async (row, index) => {
    profiles[index].bol_driving = !profiles[index].bol_driving;
    setProfiles([...profiles]);
    await alcoholService.alcoholUserDrive({
      uuid_user: row.uuid_user,
      bol_drive: profiles[index].bol_driving,
    });
  };

  const headers = [
    { description: t('common_name'), field: 'str_name' },
    { description: t('common_email'), field: 'str_email' },
    { description: t('common_zone'), field: 'str_zone_name' },
    { description: t('common_country'), field: 'str_country_name' },
    { description: t('common_site'), field: 'str_site_name' },
    {
      description: t('driving'),
      type: 'template_hidden',
      action: handleToggleChange,
      isShow: true,
      field: 'bol_driving',
      ruleToShow: 'TOGGLE',
      notSearchable: true,
    },
  ];

  const handleZoneChange = async evt => {
    setUuidZone(evt.target.value);
    setUuidCountry('');
    setUuidSite('');

    try {
      const res = await alcoholService.getZoneAlcoholUnit(evt.target.value);
      if (res.type === 'success' && res.results) {
        setZoneUnit(res.results[0]?.str_unit ? res.results[0].str_unit : '');
      } else {
        window.alert(res.message);
      }
    } catch (error) {
      window.alert(error);
    }
  };

  const handleCountryChange = evt => {
    setUuidCountry(evt.target.value);
    setUuidSite('');
  };

  const handleSiteChange = async evt => {
    setUuidSite(evt.target.value);
    try {
      const res = await alcoholService.alcoholGetStandard(evt.target.value);
      if (res.type === 'success' && res.results) {
        setConcentration(
          res.results[0]?.flt_alcohol_concentration
            ? res.results[0].flt_alcohol_concentration
            : '',
        );
      } else {
        window.alert(res.message);
      }
    } catch (error) {
      window.alert(error);
    }
  };

  const handleSave = async () => {
    setSaveLoading(true);
    const alcoholInfo = {
      uuid_zone: uuidZone,
      uuid_country: uuidCountry,
      uuid_site: uuidSite,
      flt_alcohol_concentration: concentration,
    };
    await alcoholService.alcoholSaveStandard(alcoholInfo);
    setSaveLoading(false);
  };

  return (
    <Card transparent margin="0px">
      <CardRow transparent flexWrap="wrap">
        <CardColumn padding="5px" flex={3}>
          <Card bordered>
            <DropdownField
              name="uuid_zone"
              options={privilege.arr_zones ? privilege.arr_zones : []}
              valueField="uuid_zone"
              labelField="str_name"
              label={t('common_zone')}
              margin="10px 10px 0px 0px"
              value={uuidZone}
              onChange={e => {
                handleZoneChange(e);
              }}
            />
            <DropdownField
              name="uuid_country"
              options={
                privilege.arr_countries && uuidZone
                  ? privilege.arr_countries.filter(country => {
                      return country.uuid_zone === uuidZone;
                    })
                  : []
              }
              valueField="uuid_country"
              labelField="str_name"
              label={t('common_country')}
              margin="10px 10px 0px 0px"
              value={uuidCountry}
              onChange={e => {
                handleCountryChange(e);
              }}
            />
            <DropdownField
              name="uuid_site"
              options={
                privilege.arr_sites && uuidCountry
                  ? privilege.arr_sites.filter(site => {
                      return site.uuid_country === uuidCountry;
                    })
                  : []
              }
              valueField="uuid_site"
              labelField="str_name"
              label={t('common_site')}
              margin="10px 10px 10px 0px"
              value={uuidSite}
              onChange={e => handleSiteChange(e)}
            />
            <CardRow>
              <TextField
                name="str_name"
                label={`${t('maximum_alcohol_concentration')} ${
                  zoneUnit ? `(${zoneUnit})` : ''
                }`}
                margin="0px 10px 0px 0px"
                value={concentration}
                number
                onChange={handleChange}
              />
            </CardRow>
            <CardRow justifyContent="flex-end">
              {!uuidSite || !concentration ? (
                <div className={listConfigurationStyle.saveBtn}>
                  <Button
                    label={t('common_save')}
                    margin="10px 0px 0px 0px"
                    disabled
                    justifyContent="flex-end"
                    width="160px"
                    borderRadius="6px"
                  ></Button>
                </div>
              ) : (
                <div className={listConfigurationStyle.saveBtn}>
                  <Button
                    label={t('common_save')}
                    margin="10px 0px 0px 0px"
                    onClick={() => {
                      handleSave();
                    }}
                    loading={saveLoading}
                    confirm
                    justifyContent="flex-end"
                    width="160px"
                    borderRadius="6px"
                  ></Button>
                </div>
              )}
            </CardRow>
          </Card>
        </CardColumn>

        <CardColumn padding="5px" flex={7}>
          <GenericTable
            arrHeader={headers}
            arrRow={profiles}
            searchable={true}
            loading={loading}
            handleToggleChange={handleToggleChange}
          />
        </CardColumn>
      </CardRow>
    </Card>
  );
}

