import MainService from '../rounds/main.service';
import SampleAppService from '../../services/samples/sampleApp.service';

export default class RoundAppService {
  mainService = null;
  sampleService = null;
  path = null;

  constructor() {
    this.path = '/v1/app';
    this.sampleService = new SampleAppService();
    this.mainService = new MainService();
    this.body = { dat_last: '1991-04-01T15:52:47.000Z' };
  }

  listRoundSampleTasting = () => {
    return new Promise((resolve, reject) => {
      this.mainService
        .post(this.path + '/round_samples/list', this.body)
        .then(res => {
          if (res.type === 'success' && res.results) {
            resolve(res.results);
          } else {
            reject(res);
          }
        });
    });
  };

  flovarCode = code => {
    return this.mainService.post(
      this.path + '/flavors_identification/save_code',
      code,
    );
  };

  flavorlistCode = code => {
    return this.mainService.post(
      this.path + '/flavors_identification/get_code_detail',
      code,
    );
  };

  FlavorCardList = roundId => {
    return this.mainService.get(
      this.path + '/list_rounds/' + roundId + '/flavors_identifications',
    );
  };

  getFlavorPreview = uuid_round => {
    return this.mainService.get(
      this.path + '/flavors_identification_code/' + uuid_round,
    );
  };

  getFlavorPreviewHeaders = uuid_round => {
    return this.mainService.get(
      this.path + '/flavors_identification/' + uuid_round,
    );
  };

  flavorSaveAnswer = body => {
    return this.mainService.post(
      this.path + '/flavors_identification/save_answer',
      body,
    );
  };

  listRounds = () => {
    return this.mainService.post(this.path + '/list', this.body);
  };

  updateStart = ({ uuid_round }, dat_act_start) => {
    return this.mainService.post(this.path + '/start_taste', {
      uuid_round: uuid_round,
      dat_act_start: dat_act_start,
    });
  };

  updateFinish = ({ uuid_round }, dat_act_end) => {
    return this.mainService.post(this.path + '/finish_taste', {
      uuid_round: uuid_round,
      dat_act_end: dat_act_end,
    });
  };

  getRound = ({ uuid_round }) => {
    return this.mainService.post(this.path + '/taste', {
      uuid_round: uuid_round,
    });
  };

  getTasteDiscussion = ({ uuid_round }) => {
    return this.mainService.post(this.path + '/getTaste_discussion', {
      uuid_round: uuid_round,
    });
  };

  getFlavorCode = uuid_round => {
    return this.mainService.post(
      this.path + '/flavors_identification/get_code',
      uuid_round,
    );
  };

  getRoundDiscussion = ({ uuid_round }) => {
    return this.mainService.post(this.path + '/taste_discussion', {
      uuid_round: uuid_round,
    });
  };

  getRoundOkNok = ({ uuid_round }) => {
    return this.mainService.post(this.path + '/round_category/list', {
      uuid_round: uuid_round,
    });
  };
  getNextRound = ({ uuid_round, type, cur_date }) => {
    return this.mainService.post(this.path + '/next_round_list', {
      uuid_round: uuid_round,
      type: type,
      cur_date: cur_date,
    });
  };
  getRoundStatus = ({ uuid_round, cur_date }) => {
    return this.mainService.post(this.path + '/next_round_status', {
      uuid_round: uuid_round,
      cur_date: cur_date,
    });
  };

  getRoundOkNokDiscussion = ({ uuid_round }) => {
    return this.mainService.post(
      this.path + '/round_category_discussion/list',
      { uuid_round: uuid_round },
    );
  };

  listSamples = uuid_round => {
    return new Promise((resolve, reject) => {
      this.mainService
        .post(this.path + '/list_samples', this.body)
        .then(res => {
          let links = [];

          if (res.type === 'success' && res.results) {
            const roundSample = res.results.filter(roundSample => {
              return (
                roundSample.dat_del === null &&
                roundSample.uuid_round === uuid_round
              );
            });

            this.sampleService.listRoundSample('/list', this.body).then(res => {
              if (res.type === 'success' && res.results) {
                const sample = res.results.filter(sample => {
                  return sample.dat_del === null;
                });

                roundSample.map(roundSampleTemp => {
                  sample.map(sampleTemp => {
                    if (
                      sampleTemp.uuid_sample === roundSampleTemp.uuid_sample
                    ) {
                      links.push({
                        uuid_round_sample:
                          roundSampleTemp.uuid_round + sampleTemp.uuid_sample,
                        uuid_round: roundSampleTemp.uuid_round,
                        int_order: roundSampleTemp.int_order_app,
                        ...sampleTemp,
                      });
                    }
                  });
                });

                resolve(
                  links.filter(link => {
                    return link.uuid_round === uuid_round;
                  }),
                );
              }
            });
          }
        });
    });
  };

  listDegrees = () => {
    return new Promise((resolve, reject) => {
      this.mainService.post(this.path + '/list_degrees', this.body).then(
        res => {
          if (res.type === 'success' && res.results) {
            const degrees = res.results.filter(degree => {
              return degree.dat_del === null;
            });
            resolve(degrees);
          }
        },
        error => {
          reject(error);
        },
      );
    });
  };

  listComments = () => {
    return new Promise((resolve, reject) => {
      this.mainService.post(this.path + '/list_comments', this.body).then(
        res => {
          if (res.type === 'success' && res.results) {
            const comments = res.results.filter(comment => {
              return comment.dat_del === null;
            });
            resolve(comments);
          } else {
            reject(res);
          }
        },
        error => {
          reject(error);
        },
      );
    });
  };

  listCommentsCategory = () => {
    return new Promise((resolve, reject) => {
      const result = {
        aphabe: [
          { label: 'All', value: 'All' },
          { label: 'A', value: 'A' },
          { label: 'B', value: 'B' },
          { label: 'C', value: 'C' },
          { label: 'D', value: 'D' },
          { label: 'E', value: 'E' },
          { label: 'F', value: 'F' },
          { label: 'G', value: 'G' },
          { label: 'H', value: 'H' },
          { label: 'I', value: 'I' },
          { label: 'J', value: 'J' },
          { label: 'K', value: 'K' },
          { label: 'L', value: 'L' },
          { label: 'M', value: 'M' },
          { label: 'N', value: 'N' },
          { label: 'O', value: 'O' },
          { label: 'P', value: 'P' },
          { label: 'Q', value: 'Q' },
          { label: 'R', value: 'R' },
          { label: 'S', value: 'S' },
          { label: 'T', value: 'T' },
          { label: 'U', value: 'U' },
          { label: 'V', value: 'V' },
          { label: 'W', value: 'W' },
          { label: 'X', value: 'X' },
          { label: 'Y', value: 'Y' },
          { label: 'Z', value: 'Z' },
        ],
      };
      this.mainService.post(this.path + '/list_comments', this.body).then(
        res => {
          if (res.type === 'success' && res.results) {
            const comments = res.results.filter(comment => {
              return comment.dat_del === null;
            });
            let distinct = [];
            res.results.map(comments => {
              if (
                !distinct.find(
                  category => category.value === comments.uuid_category,
                )
              ) {
                distinct.push({
                  value: comments.uuid_category,
                  label: comments.str_name_comments_categories,
                });
              }
            });
            result.categories = distinct;
            resolve(result);
          } else {
            reject(res);
          }
        },
        error => {
          reject(error);
        },
      );
    });
  };

  validateRoundTie(samples) {
    return new Promise((resolve, reject) => {
      const hasSameRank = samples.filter(x =>
        samples.some(
          o =>
            o.int_rank &&
            o.int_rank === x.int_rank &&
            o.uuid_sample !== x.uuid_sample,
        ),
      );

      if (hasSameRank.length) {
        resolve(false);
      } else {
        resolve(true);
      }
      /*let data = samples.map((sample) => {                
                return {
                    uuid_sample: sample.uuid_sample,
                    rank: (sample.int_rank=='-' || sample.int_rank=='') ? null : sample.int_rank,
                    score: sample.flt_score
                };
            });            

            let isValid=false;            
            const samplesPerScore = this.mainService.groupBy(data,'score');
            Object.keys(samplesPerScore).map((score)=>{
                if(score !== 'null' && samplesPerScore[score].length > 1 ){                                        
                    const samplesPerRank = this.mainService.groupBy(samplesPerScore[score],'rank');                    
                    Object.keys(samplesPerRank).map((rank)=>{   
                        if(samplesPerRank[rank].length > 1){                            
                            resolve(false);
                            return;
                        }
                        isValid=true;
                        // resolve(true); 
                    });
                }else {
                    isValid=true;
                    // resolve(true)
                }
            });       
            resolve(isValid)*/
    });
  }

  validateSamplesEmpty(samples) {
    let empty = false;
    return new Promise((resolve, reject) => {
      samples.map(sampleTemp => {
        if (
          sampleTemp.arr_comments.length === 0 ||
          sampleTemp.flt_score === null
        ) {
          empty = true;
        }
      });
      resolve(empty);
    });
  }

  listScoresDiscussion = uuid_round => {
    return this.mainService.post(`${this.path}/discussion/scores`, {
      uuid_round: uuid_round,
    });
  };

  listCommentsDiscussion = uuid_round => {
    return this.mainService.post(`${this.path}/discussion/comments`, {
      uuid_round: uuid_round,
    });
  };

  listScoreScales = params => {
    return this.mainService.post(this.path + '/score_scales', params);
  };

  splitTaste = uuid_round => {
    return this.mainService.post(this.path + '/split_taste', uuid_round);
  };

  getShowSampleDetails = uuid_round => {
    return this.mainService.get(
      this.path + '/show_sample_details/' + uuid_round,
    );
  };
  listRoundFlavor = data => {
    return this.mainService.post(this.path + '/list_round_flavor', data);
  };
}

