import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';

import editRoundSamplesStyle from './editroundsamples.module.scss';
import PropTypes from 'prop-types';
import ThresholdService from '../../services/threshold/threshold.service';
import BrandService from '../../services/brands/brand.service';
import RoundService from '../../services/rounds/round.service';
import {
  Label,
  CardRow,
  DropdownField,
  Button,
  TextField,
  CardColumn,
  HelpModalThreshold,
  Checkbox,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronUp, Shuffle, HelpCircle, X } from 'react-feather';
import beer from '../../../assets/beer_threshold.svg';
import configs from '../../../configs';
import ReportService from '../../services/reports/report.service';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

const reportService = new ReportService();

const EditRoundSamplesThreshold = props => {
  const [samples, setSamples] = useState([]);
  const [flavors, setFlavors] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [units, setUnits] = useState([]);
  const [filter, setFilter] = useState({});
  const [brands, setBrands] = useState([]);
  const [showModalHelp, setShowModalHelp] = useState(false);

  const history = useHistory();

  const [concentration, setConcentration] = useState(
    props.round.flt_concentration,
  );

  const [str_concentration, setStrConcentration] = useState(
    props.round.str_concentration,
  );

  const thresholdService = new ThresholdService();
  const brandService = new BrandService();
  const roundService = new RoundService();
  const { t } = useTranslation();

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    thresholdService.listSets({ uuid_round: props.round.uuid_round }).then(
      res => {
        if (res.type === 'success' && res.results) {
          setSamples(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        console.log(error);
      },
    );

    thresholdService
      .listFlavors({ uuid_round_type: props.round.uuid_round_type })
      .then(
        res => {
          if (res.type === 'success' && res.results) {
            setFlavors(res.results);
            handleSetFilter('uuid_flavor', props.round.uuid_flavor);
          } else {
            window.alert(res.message);
          }
        },
        error => {
          console.log(error);
        },
      );

    thresholdService
      .listSuppliers({ uuid_round_type: props.round.uuid_round_type })
      .then(
        res => {
          if (res.type === 'success' && res.results) {
            setSuppliers(res.results);
            handleSetFilter('uuid_supplier', props.round.uuid_supplier);
          } else {
            window.alert(res.message);
          }
        },
        error => {
          console.log(error);
        },
      );

    thresholdService.listUnits().then(
      res => {
        if (res.type === 'success' && res.results) {
          setUnits(res.results);
          handleSetFilter(
            'flt_concentration',
            props.round.flt_concentration ? props.round.flt_concentration : '0',
          );
          handleSetFilter('uuid_unity', props.round.uuid_unity);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        console.log(error);
      },
    );

    brandService.listBrands().then(
      res => {
        if (res.type === 'success' && res.results) {
          setBrands(res.results);
          handleSetFilter('uuid_brand', props.round.uuid_brand);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        console.log(error);
      },
    );

    handleSetFilter('bol_layout_horizontal', props.round.bol_layout_horizontal);
    handleSetFilter('threshold_batch_code', props.round.threshold_batch_code);
    handleSetFilter('flt_incremental_step', props.round.flt_incremental_step);
    handleSetFilter(
      'flt_set3_concentration',
      props.round.flt_set3_concentration,
    );
  };

  function handleShowModalHelp() {
    setShowModalHelp(!showModalHelp);
  }

  const handleChange = (evt, str_code) => {
    const value = evt.target.value;
    const newArr = samples;
    const arrAux = [];
    const objIndex = samples.findIndex(sample => {
      return sample.str_code === str_code;
    });

    newArr[objIndex][evt.target.name] = value;
    thresholdService.updateSet(newArr[objIndex]).then(
      res => {
        if (res.type === 'success' && res.results) {
          const arr = newArr;

          arr.forEach(sample => {
            arrAux.push(sample);
          });

          setSamples(arrAux);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        console.log(error);
      },
    );
  };

  const handleSetFilter = (name, value) => {
    const data = { [name]: value };

    setFilter(prevState => {
      return { ...prevState, ...data };
    });

  };

  const handleChangeFilter = evt => {
    const value = evt.target.value;
    const name = evt.target.name;
    let data = { [evt.target.name]: value };

    if ((name === 'uuid_flavor' || name === 'uuid_brand') && !value) {
      return false;
    }

    if (name === 'flt_incremental_step' || name === 'flt_set3_concentration') {
      let str_concentration_arr = JSON.parse(JSON.stringify(str_concentration));
      let flt_incremental_step = filter.flt_incremental_step;
      if (name === 'flt_set3_concentration') {
        str_concentration_arr[2] = value;
      } else {
        flt_incremental_step = value;
      }
      if (flt_incremental_step && str_concentration_arr[2]) {
        str_concentration_arr[0] = parseFloat(
          (
            str_concentration_arr[2] / Math.pow(flt_incremental_step, 2)
          ).toFixed(3),
        ) || 0;
        str_concentration_arr[1] = parseFloat(
          (
            str_concentration_arr[2] / Math.pow(flt_incremental_step, 1)
          ).toFixed(3),
        ) || 0;
        str_concentration_arr[3] = parseFloat(
          (
            str_concentration_arr[2] * Math.pow(flt_incremental_step, 1)
          ).toFixed(3),
        ) || 0;
        str_concentration_arr[4] = parseFloat(
          (
            str_concentration_arr[2] * Math.pow(flt_incremental_step, 2)
          ).toFixed(3),
        ) || 0;
        str_concentration_arr[5] = parseFloat(
          (
            str_concentration_arr[2] * Math.pow(flt_incremental_step, 3)
          ).toFixed(3),
        ) || 0;
      } else if (!flt_incremental_step || !str_concentration_arr[2]) {
        str_concentration_arr[0] =
          str_concentration_arr[1] =
          str_concentration_arr[3] =
          str_concentration_arr[4] =
          str_concentration_arr[5] =
          '';
      }
      setStrConcentration(str_concentration_arr);
      data = {
        ...data,
        str_concentration: str_concentration_arr,
        flt_incremental_step: flt_incremental_step,
      };
    }

    setFilter(prevState => {
      return { ...prevState, ...data };
    });

    roundService.updateRound({ ...props.round, ...data }).then(
      res => {
        if (res.type === 'success' && res.results) {
          if (
            (name === 'uuid_flavor' && filter.uuid_brand) ||
            (name === 'uuid_brand' && filter.uuid_flavor)
          ) {
            thresholdService
              .listSets({ uuid_round: props.round.uuid_round })
              .then(
                res => {
                  if (res.type === 'success' && res.results) {
                    setSamples(res.results);
                  } else {
                    window.alert(res.message);
                  }
                },
                error => {
                  console.log(error);
                },
              );
          }

          props.setRound(prevState => {
            return { ...prevState, ...data };
          });
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  };

  const saveThresholdSample = () => {
    if (!filter.flt_incremental_step || !filter.flt_set3_concentration) {
      window.alert(t('incremental_set3_concentration_required'));
    } else if (!!filter.uuid_brand && !!filter.uuid_flavor) {
      let newRound = props.round;
      newRound.uuid_brand = filter.uuid_brand;
      newRound.uuid_flavor = filter.uuid_flavor;
      newRound.threshold_batch_code = filter.threshold_batch_code;
      newRound.flt_concentration = filter.flt_concentration;
      newRound.uuid_unity = filter.uuid_unity;
      newRound.uuid_supplier = filter.uuid_supplier;
      newRound.flt_incremental_step = filter.flt_incremental_step;
      newRound.flt_set3_concentration = filter.flt_set3_concentration;

      roundService.updateRound(newRound).then(res => {
        if (res.type === 'success' && res.results) {
          history.push({ pathname: '/list_rounds' });
        } else {
          window.alert(res.message);
        }
      });
    } else {
      window.alert(t('threshold_samples_required'));
    }
  };

  const handleChangeFilterValue = (inputValue, name) => {
    const value = inputValue;
    const data = { [name]: value };
    setFilter(prevState => {
      return { ...prevState, ...data };
    });
    roundService.updateRound({ ...props.round, ...data }).then(
      res => {
        if (res.type === 'success' && res.results) {
          props.setRound(prevState => {
            return { ...prevState, ...data };
          });
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  };

  const handleChangeConcentration = evt => {
    setConcentration(evt.target.value);
    handleChangeFilter(evt);
  };

  const delayedQuery = useCallback(
    _.debounce((text, name) => {
      handleChangeFilterValue(text, name);
    }, configs.defaultDebounce),
    [],
  );

  const handleDownloadTemplate = uuid_round => {
    reportService.generateTemplateThreshold(uuid_round).then(
      res => {
        if (res.status === 'success' && res.file) {
          let a = document.createElement('a');
          a.setAttribute('target', '_blank');
          a.href = configs.file_api_url + '/v1/' + res.file;
          a.download = res.file;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          window.alert(t(res.message));
        }
      },
      error => {
        //console.log(error);
      },
    );
  };

  const handleChangeShuffle = () => {
    thresholdService.suffleSets({ uuid_round: props.round.uuid_round }).then(
      res => {
        if (res.type === 'success' && res.results) {
          thresholdService
            .listSets({ uuid_round: props.round.uuid_round })
            .then(
              res => {
                if (res.type === 'success' && res.results) {
                  setSamples(res.results);
                } else {
                  window.alert(res.message);
                }
              },
              error => {
                console.log(error);
              },
            );
        } else {
          window.alert(res.message);
        }
      },
      error => {
        console.log(error);
      },
    );
  };

  const getNextValue = value => {
    return value === 'A' ? 'B' : value === 'B' ? 'C' : 'A';
  };

  const getPrevValue = value => {
    return value === 'C' ? 'B' : value === 'B' ? 'A' : 'C';
  };

  const SamplesCategories = props => {
    return (
      <div className={editRoundSamplesStyle.pageContainerOptions}>
        <div className={editRoundSamplesStyle.samplesCategories}>
          <span>Code {props.str_code.padStart(3, '0')}</span>
        </div>
        <SamplesSets
          name="Set 1"
          option={
            samples.find(sample => {
              return sample.str_code === props.str_code;
            })?.str_option_set_1
          }
          id="str_option_set_1"
          str_code={props.str_code}
        />
        <SamplesSets
          name="Set 2"
          option={
            samples.find(sample => {
              return sample.str_code === props.str_code;
            })?.str_option_set_2
          }
          id="str_option_set_2"
          str_code={props.str_code}
        />
        <SamplesSets
          name="Set 3"
          option={
            samples.find(sample => {
              return sample.str_code === props.str_code;
            })?.str_option_set_3
          }
          id="str_option_set_3"
          str_code={props.str_code}
        />
        <SamplesSets
          name="Set 4"
          option={
            samples.find(sample => {
              return sample.str_code === props.str_code;
            })?.str_option_set_4
          }
          id="str_option_set_4"
          str_code={props.str_code}
        />
        <SamplesSets
          name="Set 5"
          option={
            samples.find(sample => {
              return sample.str_code === props.str_code;
            })?.str_option_set_5
          }
          id="str_option_set_5"
          str_code={props.str_code}
        />
        <SamplesSets
          name="Set 6"
          option={
            samples.find(sample => {
              return sample.str_code === props.str_code;
            })?.str_option_set_6
          }
          id="str_option_set_6"
          str_code={props.str_code}
        />
      </div>
    );
  };

  const SamplesSets = props => {
    return (
      <div className={editRoundSamplesStyle.samplesCategorieOptions}>
        <div className={editRoundSamplesStyle.optionSet}>
          <span>{props.name}</span>
        </div>
        <div className={editRoundSamplesStyle.optionSelect}>
          <ChevronUp
            style={{ color: '#B11F24', cursor: 'pointer' }}
            onClick={() =>
              handleChange(
                {
                  target: {
                    value: getPrevValue(
                      samples.find(sample => {
                        return sample.str_code === props.str_code;
                      })[props.id],
                    ),
                    name: props.id,
                  },
                },
                props.str_code,
              )
            }
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              src={beer}
              alt={'unlocked'}
              height={44}
              width={40}
              style={{ marginLeft: '-10px' }}
            />
            <span
              style={{
                marginTop: '-28.5px',
                fontWeight: '700',
                fontSize: '16px',
                color: '#000',
              }}
            >
              {props.option}
            </span>
          </div>

          <ChevronDown
            style={{ color: '#B11F24', cursor: 'pointer', marginTop: '10px' }}
            onClick={() =>
              handleChange(
                {
                  target: {
                    value: getNextValue(
                      samples.find(sample => {
                        return sample.str_code === props.str_code;
                      })[props.id],
                    ),
                    name: props.id,
                  },
                },
                props.str_code,
              )
            }
          />
        </div>
      </div>
    );
  };

  const handleStrConcentration = (e, index) => {
    let arr = JSON.parse(JSON.stringify(str_concentration));
    arr[index] = e.target.value;
    setStrConcentration(arr);
    let newRound = props.round;
    newRound.uuid_brand = filter.uuid_brand;
    newRound.uuid_flavor = filter.uuid_flavor;
    newRound.threshold_batch_code = filter.threshold_batch_code;
    newRound.flt_concentration = filter.flt_concentration;
    newRound.uuid_unity = filter.uuid_unity;
    newRound.uuid_supplier = filter.uuid_supplier;
    newRound.str_concentration = arr;
    roundService.updateRound(newRound);
  };

  return (
    <div className={editRoundSamplesStyle.pageContainer}>
      <CardRow
        style={{
          justifyContent: 'center',
          paddingBottom: '10px',
          paddingTop: '10px',
        }}
      >
        <Label
          bold
          style={{ color: '#000', fontSize: '14px' }}
          label={t('threshold_information_helper_sample')}
        >
          {' '}
        </Label>
      </CardRow>
      <CardRow flexWrap="wrap">
        <CardColumn
          margin="0px"
          style={{ flexDirection: 'column' }}
          padding="10px 10px 0px 0px"
        >
          <DropdownField
            name="uuid_brand"
            options={brands}
            valueField="uuid_brand"
            labelField="str_name"
            label={t('threshold_select_base_beer')}
            margin="10px 0px 0px 0px"
            value={filter.uuid_brand}
            onChange={handleChangeFilter}
            isRequired={true}
          />
        </CardColumn>
        <CardColumn
          style={{ display: 'flex', flexDirection: 'row' }}
          padding="10px 10px 0px 0px"
        >
          <TextField
            name="flt_concentration"
            label={t('concentration_of_the_base_beer')}
            margin="10px 10px 0px 0px"
            padding="12.5px 5px"
            value={concentration}
            onChange={handleChangeConcentration}
            text
          />

          <DropdownField
            name="uuid_unity"
            options={units}
            valueField="uuid_unity"
            labelField="str_name"
            label={t('common_unit')}
            margin="10px 0px 0px 0px"
            value={filter.uuid_unity}
            onChange={handleChangeFilter}
          />
        </CardColumn>
      </CardRow>

      <CardRow flexWrap="wrap" justifyContent="center" itemAlign="flex-end">
        <div
          style={{
            margin: '10px 10px 0px 0px',
            flex: '1 1 0%',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Label label={t('incremental_step')} required={true} />
            <HelpCircle
              className={editRoundSamplesStyle.helpCircleId}
              data-tip={`${t('concentration_increments_between_levels')}`}
            />
            <ReactTooltip
              effect="solid"
              place="top"
              type="warning"
              className={editRoundSamplesStyle.tooltip}
            />
          </div>
          <TextField
            name="flt_incremental_step"
            margin="0px 10px 0px 0px"
            padding="12.5px 5px"
            value={filter.flt_incremental_step}
            onChange={handleChangeFilter}
            maxLength="255"
            text
          />
        </div>

        <TextField
          name="flt_set3_concentration"
          label={t('set3_concentration')}
          margin="10px 10px 0px 10px"
          padding="12.5px 5px"
          value={filter.flt_set3_concentration}
          onChange={handleChangeFilter}
          maxLength="255"
          text
          isRequired={true}
        />
        <DropdownField
          name="uuid_flavor"
          options={flavors}
          valueField="uuid_flavor"
          labelField="str_name"
          label={t('common_flavor')}
          margin="10px 10px 0px 10px"
          value={filter.uuid_flavor}
          onChange={handleChangeFilter}
          isRequired={true}
        />
        <DropdownField
          name="uuid_supplier"
          options={suppliers}
          valueField="uuid_supplier"
          labelField="str_name"
          label={t('common_supplier')}
          margin="10px 10px 0px 0px"
          value={filter.uuid_supplier}
          onChange={handleChangeFilter}
        />
        <TextField
          name="threshold_batch_code"
          label={t('common_batch_code')}
          margin="10px 10px 0px 0px"
          padding="12.5px 5px"
          value={filter.threshold_batch_code}
          onChange={e => {
            delayedQuery(e.target.value, e.target.name);
          }}
          maxLength="255"
          text
        />

        <div className={editRoundSamplesStyle.checkboxHorizontal}>
          <Checkbox
            label={t('common_horizontal')}
            name="bol_layout_horizontal"
            value={filter.bol_layout_horizontal ? 1 : 0}
            onChange={handleChangeFilter}
            padding="10px 10px 8px 30px"
          />
        </div>
        <CardColumn style={{ display: 'flex', alignItems: 'flex-end' }}>
          <HelpCircle
            size="24"
            color="#F49B00"
            onClick={() => handleShowModalHelp()}
          />
        </CardColumn>
        <HelpModalThreshold
          show={showModalHelp}
          filter={filter.bol_layout_horizontal ? 1 : 0}
          onClick={() => handleShowModalHelp()}
          center
        >
          <CardRow style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <X
              size="24"
              onClick={() => handleShowModalHelp()}
              style={{ width: '30px', height: '30px' }}
            ></X>
          </CardRow>
        </HelpModalThreshold>
      </CardRow>

      <CardRow flexWrap="wrap" justifyContent="end">
        <Button
          label="Download Design"
          onClick={() => {
            handleDownloadTemplate(props.round.uuid_round);
          }}
          confirm
          margin="20px 10px 0px 0px"
        />

        <Button
          onClick={() => handleChangeShuffle()}
          confirm
          margin="20px 10px 0px 0px"
          style={{
            backgroundColor: '#0075FF',
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {t('round_randomize_samples_order_button')}
          <Shuffle size="14" />
        </Button>
      </CardRow>
      <CardRow className={editRoundSamplesStyle.pageContainerOptions}>
        {samples.length > 0 && (
          <div className={editRoundSamplesStyle.ConcentrationFlavor}>
            <span> {t('concentration_of_the_flavor')}</span>
          </div>
        )}
        {samples.length > 0 &&
          str_concentration.map((item, index) => {
            return (
              <TextField
                maxWidth="166px"
                margin="0 50px 0 0"
                style={{
                  width: '100%',
                  flex: 1,
                  textAlign: 'center',
                  border: 'none',
                  backgroundColor: '#F1F1F1',
                }}
                index={index}
                name={`concentration_flavor`}
                value={item}
                disabled={true}
              />
            );
          })}
      </CardRow>
      {samples.length > 0 &&
        samples.map((sample, idx) => (
          <SamplesCategories key={idx} str_code={sample.str_code} />
        ))}
      <div className={editRoundSamplesStyle.divButtonSave}>
        <Button
          label={t('common_submit')}
          margin="10px 10px 0px 0px"
          confirm
          disabled={false}
          onClick={e => {
            saveThresholdSample();
          }}
          style={{
            borderRadius: '6px',
            width: '160px',
            marginLeft: 'auto',
            marginRight: 0,
          }}
        />
      </div>
    </div>
  );
};

EditRoundSamplesThreshold.propTypes = {
  privilege: PropTypes.any,
  round: PropTypes.any,
  setRound: PropTypes.any,
};

export default EditRoundSamplesThreshold;
