import ListCategories from './listCategories/ListCategories';
import EditCategories from './editCategory/EditCategory';

export default {
    routeModule: {
        path: '/list_categories',
        component: ListCategories,
        key: 'category_list_categories',
        privilege: 'COMMENT_MANAGEMENT_LIST',
        routeComponents: [{
            path: '/edit_flavor_category',
            component: EditCategories,
            key: 'Edit Category',
            privilege: 'COMMENT_MANAGEMENT_EDIT'
        }],
        subMenu: 'menu_attributes_management'
    }
};
