import configs from '../../../configs';
import { OptionsHeader } from '../headers';

export default class MainService {
  user = null;

  constructor() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
  }

  get(url) {
    return new Promise((resolve, reject) => {
      fetch(configs.malt_varieties_api_url + url, {
        method: 'get',
        credentials: 'include',
        headers: OptionsHeader,
      })
        .then(res => {
          resolve(res.json());
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  post(url, body) {
    return new Promise((resolve, reject) => {
      fetch(configs.malt_varieties_api_url + url, {
        method: 'post',
        credentials: 'include',
        headers: OptionsHeader,
        body: JSON.stringify(body),
      })
        .then(res => {
          resolve(res.json());
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  delete(url) {
    return new Promise((resolve, reject) => {
      fetch(configs.malt_varieties_api_url + url, {
        method: 'delete',
        credentials: 'include',
        headers: OptionsHeader,
      })
        .then(res => {
          resolve(res.json());
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
