import React, { useState, useEffect } from 'react';
import editcategoriesStyle from './editcategories.module.scss';
import { useHistory } from "react-router-dom";
import {
  TextField,
  Label,
  Card,
  CardRow,
  Button,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import SampleService from "../../services/samples/sample.service";

const EditCategories = props => {

  const { t } = useTranslation();
  const history = useHistory();
  const [result, setResult] = useState({});

	// services
  const sampleService = new SampleService();
  
  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setResult(props.location.state.values);
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setResult({
      ...result,
      [evt.target.name]: value
    });
  };

  const handleSave = () => {
    const resultTemp = result;
    
    if (resultTemp.str_name_en?.trim().length === 0) {
      window.alert(t('common_cannot_be_empty', {field: `${t('common_category_name')} (English)`}));
      return;
    }

    sampleService.saveAdminCategories(resultTemp).then((res) => {
      if(res.type === 'success' && res.results){
        history.goBack();
      }else{
        window.alert(res.message);
      }
    }, (error) => {
      //console.log(error);
    });
  };
  
  return (
    <div className={editcategoriesStyle.pageContainer}>
      <Label 
        label={t('categories_edit_category')}
        bold
        neutral
      />
      
      <Card bordered>
        <CardRow flexWrap="wrap">
          <TextField
            name="str_name_en"
            label={`${t('common_category_name')} (English)`}
            margin="10px 10px 0px 0px"
            value={result.str_name_en}
            onChange={handleChange}
          />
          <TextField
            name="str_name_pt"
            label={`${t('common_category_name')} (Portuguese)`}
            margin="10px 10px 0px 0px"
            value={result.str_name_pt}
            onChange={handleChange}
          />
          <TextField
            name="str_name_es"
            label={`${t('common_category_name')} (Spanish)`}
            margin="10px 10px 0px 0px"
            value={result.str_name_es}
            onChange={handleChange}
          />
        </CardRow>

        <CardRow flexWrap="wrap">
          <TextField
            name="str_name_de"
            label={`${t('common_category_name')} (German)`}
            margin="10px 10px 0px 0px"
            value={result.str_name_de}
            onChange={handleChange}
          />
          <TextField
            name="str_name_fr"
            label={`${t('common_category_name')} (French)`}
            margin="10px 10px 0px 0px"
            value={result.str_name_fr}
            onChange={handleChange}
          />
          <TextField
            name="str_name_it"
            label={`${t('common_category_name')} (Italian)`}
            margin="10px 10px 0px 0px"
            value={result.str_name_it}
            onChange={handleChange}
          />
        </CardRow>

        <CardRow flexWrap="wrap">
          <TextField
            name="str_name_nl"
            label={`${t('common_category_name')} (Dutch)`}
            margin="10px 10px 0px 0px"
            value={result.str_name_nl}
            onChange={handleChange}
          />
          <TextField
            name="str_name_ru"
            label={`${t('common_category_name')} (Russian)`}
            margin="10px 10px 0px 0px"
            value={result.str_name_ru}
            onChange={handleChange}
          />
          <TextField
            name="str_name_uk"
            label={`${t('common_category_name')} (Ukranian)`}
            margin="10px 10px 0px 0px"
            value={result.str_name_uk}
            onChange={handleChange}
          />
        </CardRow>
        <CardRow justifyContent="flex-end" flexWrap="wrap">
          <Button 
            label={t('common_cancel')}
            onClick={() => {
              history.goBack();
            }}
            neutral
            margin="35px 10px 0px 0px"
          />
          <Button 
            label={t('common_save')}
            onClick={handleSave}
            confirm
            margin="35px 10px 0px 0px"
          />
        </CardRow>
      </Card>
    </div>
  );
};

export default EditCategories;
