import ListGroups from './listGroups/ListGroups';
import EditGroup from './editGroup/EditGroup';

export default {
    routeModule: {
        path: '/list_groups',
        component: ListGroups,
        key: 'group_list_groups',
        privilege: 'GROUP_MANAGEMENT_LIST',
        routeComponents: [
            {
                path: '/edit_group',
                component: EditGroup,
                key: 'edit_group',
                privilege: 'GROUP_MANAGEMENT_EDIT'
            },
            {
                path: '/new_group',
                component: EditGroup,
                key: 'new_roup',
                privilege: 'GROUP_MANAGEMENT_EDIT'
            }
        ],
        subMenu: 'menu_panel_management'
    }
};
