import React, { useState, useEffect, useMemo } from 'react';
import { HelpCircle, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import arrKpiBrandsGlobal from './arrKpiBrandsGlobal.json';
import arrKpiBrandsFocusStrategic from './arrKpiBrandsFocusStrategic.json';
import styleKpiBrands from './DetailsKpiBrands.module.scss';
import BrandService from '../../services/brands/brand.service';
import {
  Card,
  CardColumn,
  CardRow,
  DropdownField,
  Label,
  DropdownMultiField,
  Modal,
  Text,
  TextArea,
  Button,
} from '../../../components';
import AppService from '../../../app.service';
import { BrandStatusEnum } from '../../../enums/Brand/BrandStatus.enum';

const DetailsKpiBrands = props => {
  const { t } = useTranslation();
  const [privilege, setPrivilege] = useState([]);
  const [filter, setFilter] = useState({
    kpi_year: props.location.state.values.kpi_year,
    arr_sites: [props.location.state.values],
  });
  const [brands, setBrands] = useState([]);
  const [listKpiBrandsStatus, setListKpiBrandsStatus] = useState([]);
  const [open, setOpen] = useState(9999);
  const [note, setNote] = useState('');
  const [softDrinksKpis, setSoftDrinksKpis] = useState([]);

  const filteredListKpiBrandsStatus = useMemo(
    () =>
      listKpiBrandsStatus.filter(
        item => item?.uuid_kpi_brand_status !== BrandStatusEnum.CREATED,
      ),
    [listKpiBrandsStatus],
  );

  // services
  const appService = new AppService();
  const brandService = new BrandService();

  useEffect(() => {
    refreshState();
    setRows();
  }, []);

  const refreshState = () => {
    //GET PRIVILEGES FOR EDIT/NEW
    appService.listPrivileges('KPI_BRAND_APPROVE').then(
      res => {
        if (res.type === 'success' && res.results) {
          setPrivilege(res.results[0]);
        } else {
          window.alert(res.message);
        }
      },
      () => {
        //console.log(error);
      },
    );

    brandService.listBrands().then(
      res => {
        if (res.type === 'success' && res.results) {
          setBrands(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );

    brandService.listKpiBrandsStatus().then(
      res => {
        if (res.type === 'success' && res.results) {
          setListKpiBrandsStatus(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  };

  const setRows = () => {
    const softDrinks = [{}, {}, {}, {}];

    props.location.state.values.arr_kpi_brands
      .filter(kpi => kpi.str_type === '4')
      .forEach(softDrink => (softDrinks[softDrink.int_order] = softDrink));

    const orderedSoftDrinks = softDrinks.sort((a, b) =>
      a.int_order > b.int_order ? 1 : -1,
    );

    setSoftDrinksKpis(orderedSoftDrinks);
  };

  const handleChange = evt => {
    const value = evt.target.value;

    if ((evt.target.name === 'kpi_year' && value === '') || value === null) {
      setFilter({
        kpi_year: null,
        arr_sites: [],
      });
      return false;
    }

    setFilter({
      ...filter,
      [evt.target.name]: value,
    });
  };

  const handleChangeStatus = (evt, uuid_kpi_brand, index) => {
    const value = evt.target.value;

    if (value !== 'dcb5e443-94a7-11ec-ad45-000000000000') {
      brandService
        .updateStatusKpiBrand({
          uuid_kpi_brand: uuid_kpi_brand,
          uuid_status: value,
        })
        .then(
          res => {
            if (res.type === 'success' && res.results) {
              window.alert('Your status ​​have been saved successfully!');
              const arrSites = filter.arr_sites;

              arrSites[index].uuid_status = value;
              arrSites[index].status = listKpiBrandsStatus.find(
                sta => sta.uuid_kpi_brand_status === value,
              ).str_name;
              arrSites[index].tx_note = '';

              setNote('');

              setFilter({
                ...filter,
                arr_sites: arrSites,
              });
            } else {
              window.alert(res.message);
            }
          },
          error => {},
        );
    } else {
      setNote(filter?.arr_sites[index]?.tx_note);
      setOpen(index);
    }
  };

  const handleChangeStatusNote = (uuid_kpi_brand, index) => {
    const uuid_status = 'dcb5e443-94a7-11ec-ad45-000000000000';
    brandService
      .updateStatusKpiBrand({
        uuid_kpi_brand: uuid_kpi_brand,
        uuid_status: uuid_status,
        note: note,
      })
      .then(
        res => {
          if (res.type === 'success' && res.results) {
            window.alert('Your status ​​have been saved successfully!');
            const arrSites = filter.arr_sites;

            arrSites[index].uuid_status = uuid_status;
            arrSites[index].status = listKpiBrandsStatus.find(
              sta => sta.uuid_kpi_brand_status === uuid_status,
            ).str_name;
            arrSites[index].tx_note = note;

            setOpen(9999);
            setNote('');

            setFilter({
              ...filter,
              arr_sites: arrSites,
            });
          } else {
            window.alert(res.message);
          }
        },
        error => {},
      );
  };

  const kpiBrandRowHTML = KpiBrand => {
    return (
      <>
        <CardRow
          flexWrap="wrap"
          padding="0px 0px 0px 0px"
          style={{ backgroundColor: '#fff' }}
        >
          <Label
            label={
              !!KpiBrand
                ? brands.filter(
                    brand => brand.uuid_brand === KpiBrand?.uuid_brand,
                  )[0]?.str_name
                : '-'
            }
            bold
            neutral
            style={{
              padding: '12px 5px 12px 5px',
              color: '#656872',
              height: '45px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            size={14}
          />
        </CardRow>
        <CardRow
          flexWrap="wrap"
          padding="0px 0px 0px 0px"
          style={{ backgroundColor: '#f8f8f8' }}
        >
          <Label
            label={!!KpiBrand ? KpiBrand?.flt_target : '-'}
            neutral
            bold
            style={{
              padding: '12px 5px 12px 5px',
              color: '#656872',
              height: '45px',
              fontWeight: '700',
            }}
            size={14}
          />
        </CardRow>
      </>
    );
  };

  return (
    <div>
      <Label label={`${t('common_kpi_brands')} Details`} bold neutral />

      <Card bordered>
        <div className={styleKpiBrands.pageContainerHeader}>
          <CardRow flexWrap="wrap" padding="0px 0px 10px 0px">
            <CardColumn padding="5px" flex={1}>
              <CardRow flexWrap="wrap">
                <DropdownField
                  name="kpi_year"
                  options={[
                    { str_name: '2021', kpi_year: '2021' },
                    { str_name: '2022', kpi_year: '2022' },
                    { str_name: '2023', kpi_year: '2023' },
                    { str_name: '2024', kpi_year: '2024' },
                  ]}
                  valueField="kpi_year"
                  labelField="str_name"
                  label={`${t('common_year')}`}
                  margin="10px 10px 0px 0px"
                  onChange={handleChange}
                  value={filter?.kpi_year}
                />
                <DropdownMultiField
                  name="arr_sites"
                  options={
                    props.location.state.listKpiBrands && filter?.kpi_year
                      ? props.location.state.listKpiBrands.filter(site => {
                          return site.kpi_year === filter.kpi_year;
                        })
                      : []
                  }
                  valueField="uuid_site"
                  labelField="str_name_site"
                  label={`${t('common_site')}`}
                  margin="10px 5px 0px 0px"
                  value={filter?.arr_sites}
                  onChange={handleChange}
                  autoSelect={false}
                  maxValueSelect={5}
                  messageMaxValue={`${t('common_maximun_sites')} 5.`}
                  disabled={filter?.arr_sites.length >= 0 ? false : true}
                />
              </CardRow>
            </CardColumn>
          </CardRow>
        </div>
      </Card>

      <Card bordered>
        <Label label={t('common_global_brands')} bold neutral size={18} />
        <CardRow>
          {filter?.arr_sites.map((siteBrands, index) => (
            <CardColumn
              key={index}
              style={{ padding: '15px 0px 0px 0px', margin: '0px' }}
            >
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{
                  backgroundColor: '#f8f8f8',
                  marginBottom: '3px',
                  borderTopWidth: '1px',
                  borderTopColor: '#cfcfd1',
                  borderTopStyle: 'solid',
                }}
              >
                <Label
                  label="&nbsp;"
                  bold
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                    fontWeight: '700',
                  }}
                  size={14}
                />
              </CardRow>
              {siteBrands.uuid_status ===
              BrandStatusEnum.WAITING_FOR_APPROVAL ? (
                <CardRow
                  flexWrap="wrap"
                  padding="0px 0px 0px 0px"
                  style={{
                    backgroundColor: '#f8f8f8',
                    marginBottom: '3px',
                    height: '60px',
                  }}
                >
                  <Label
                    label={t('common_action')}
                    bold
                    neutral
                    style={{
                      padding: '12px 5px 12px 5px',
                      color: '#656872',
                      height: '45px',
                      fontWeight: '700',
                    }}
                    size={14}
                  />
                </CardRow>
              ) : null}
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{
                  backgroundColor: '#f8f8f8',
                  borderBottomWidth: '1px',
                  borderBottomColor: '#cfcfd1',
                  borderBottomStyle: 'solid',
                }}
              >
                <Label
                  label={t('common_status')}
                  bold
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                    fontWeight: '700',
                  }}
                  size={14}
                />
              </CardRow>
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{ backgroundColor: '#fff' }}
              >
                <Label
                  label={`${t('common_global_brand')} 1`}
                  bold
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                    fontWeight: '700',
                  }}
                  size={14}
                />
              </CardRow>
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{ backgroundColor: '#f8f8f8' }}
              >
                <Label
                  label={`${t('common_target_global_brand')} 1`}
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                  }}
                  size={14}
                />
              </CardRow>
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{ backgroundColor: '#fff' }}
              >
                <Label
                  label={`${t('common_global_brand')}  2`}
                  bold
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                    fontWeight: '700',
                  }}
                  size={14}
                />
              </CardRow>
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{ backgroundColor: '#f8f8f8' }}
              >
                <Label
                  label={`${t('common_target_global_brand')} 2`}
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                  }}
                  size={14}
                />
              </CardRow>
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{ backgroundColor: '#fff' }}
              >
                <Label
                  label={`${t('common_global_brand')}  3`}
                  bold
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                    fontWeight: '700',
                  }}
                  size={14}
                />
              </CardRow>
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{ backgroundColor: '#f8f8f8' }}
              >
                <Label
                  label={`${t('common_target_global_brand')} 3`}
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                  }}
                  size={14}
                />
              </CardRow>
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{ backgroundColor: '#fff' }}
              >
                <Label
                  label={`${t('common_global_brand')}  4`}
                  bold
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                    fontWeight: '700',
                  }}
                  size={14}
                />
              </CardRow>
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{ backgroundColor: '#f8f8f8' }}
              >
                <Label
                  label={`${t('common_target_global_brand')} 4`}
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                  }}
                  size={14}
                />
              </CardRow>
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{ backgroundColor: '#fff' }}
              >
                <Label
                  label={`${t('common_focus_brand')} 1`}
                  bold
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                    fontWeight: '700',
                  }}
                  size={14}
                />
              </CardRow>
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{ backgroundColor: '#f8f8f8' }}
              >
                <Label
                  label={`${t('common_target_focus_brand')} 1`}
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                  }}
                  size={14}
                />
              </CardRow>
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{ backgroundColor: '#fff' }}
              >
                <Label
                  label={`${t('common_focus_brand')} 2`}
                  bold
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                    fontWeight: '700',
                  }}
                  size={14}
                />
              </CardRow>
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{ backgroundColor: '#f8f8f8' }}
              >
                <Label
                  label={`${t('common_target_focus_brand')} 2`}
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                  }}
                  size={14}
                />
              </CardRow>
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{ backgroundColor: '#fff' }}
              >
                <Label
                  label={`${t('common_strategic_brand')} 1`}
                  bold
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                    fontWeight: '700',
                  }}
                  size={14}
                />
              </CardRow>
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{ backgroundColor: '#f8f8f8' }}
              >
                <Label
                  label={`${t('common_target_strategic_brand')} 1`}
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                  }}
                  size={14}
                />
              </CardRow>
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{ backgroundColor: '#fff' }}
              >
                <Label
                  label={`${t('common_strategic_brand')} 2`}
                  bold
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                    fontWeight: '700',
                  }}
                  size={14}
                />
              </CardRow>
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{ backgroundColor: '#f8f8f8' }}
              >
                <Label
                  label={`${t('common_target_strategic_brand')} 2`}
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                  }}
                  size={14}
                />
              </CardRow>

              {softDrinksKpis.map((sd, index) => {
                const kpiNumber = index + 1;

                return (
                  <>
                    <CardRow
                      flexWrap="wrap"
                      padding="0px 0px 0px 0px"
                      style={{ backgroundColor: '#fff' }}
                    >
                      <Label
                        label={`${t('soft_drinks_brand')} ${kpiNumber}`}
                        bold
                        neutral
                        style={{
                          padding: '12px 5px 12px 5px',
                          color: '#656872',
                          height: '45px',
                          fontWeight: '700',
                        }}
                        size={14}
                      />
                    </CardRow>
                    <CardRow
                      flexWrap="wrap"
                      padding="0px 0px 0px 0px"
                      style={{ backgroundColor: '#f8f8f8' }}
                    >
                      <Label
                        label={`${t('soft_drinks_brand_target')} ${kpiNumber}`}
                        neutral
                        style={{
                          padding: '12px 5px 12px 5px',
                          color: '#656872',
                          height: '45px',
                        }}
                        size={14}
                      />
                    </CardRow>
                  </>
                );
              })}
            </CardColumn>
          ))}
          {filter?.arr_sites.map((siteBrands, index) => (
            <CardColumn
              key={index}
              style={{ padding: '15px 0px 0px 0px', margin: '0px' }}
            >
              <Modal className={styleKpiBrands.Modal} show={open === index}>
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    padding: '10px 15px',
                    justifyContent: 'flex-end',
                    display: 'flex',
                    marginBottom: '30px',
                  }}
                >
                  <button
                    style={{
                      cursor: 'pointer',
                      height: '35px',
                      width: '35px',
                      color: '#fff',
                      border: '1px solid',
                      borderRadius: '4px',
                      fontSize: '18px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#666666',
                      marginBottom: '10px',
                    }}
                    onClick={() => {
                      setOpen(9999);
                      setNote('');
                    }}
                  >
                    <X />
                  </button>
                </div>
                <div>
                  <CardRow
                    className={styleKpiBrands.cardRowModal}
                    style={{ marginTop: '-30px' }}
                  >
                    <Text className={styleKpiBrands.h3Title}>
                      {t('common_state_reason_rejecting')}{' '}
                      {`'${filter?.arr_sites[open]?.str_name_site}'`}
                    </Text>
                  </CardRow>
                  <CardRow
                    flexWrap="wrap"
                    padding="0px 0px 0px 0px"
                    style={{ margin: '10px' }}
                  >
                    <TextArea
                      name="tx_note"
                      value={note}
                      onChange={evt => setNote(evt.target.value)}
                    />
                  </CardRow>
                  <CardRow justifyContent="center" flexWrap="wrap">
                    <Button
                      label={t('common_save')}
                      onClick={() =>
                        handleChangeStatusNote(
                          filter?.arr_sites[open].uuid_kpi_brand,
                          open,
                        )
                      }
                      confirm
                      margin="10px 10px 10px 10px"
                    />
                  </CardRow>
                </div>
              </Modal>

              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{
                  backgroundColor: '#f8f8f8',
                  marginBottom: '3px',
                  borderTopWidth: '1px',
                  borderTopColor: '#cfcfd1',
                  borderTopStyle: 'solid',
                }}
              >
                <Label
                  label={siteBrands.str_name_site}
                  bold
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                    fontWeight: '700',
                  }}
                  size={14}
                />
              </CardRow>
              {siteBrands.uuid_status ===
              BrandStatusEnum.WAITING_FOR_APPROVAL ? (
                <CardRow
                  flexWrap="wrap"
                  padding="0px 0px 0px 0px"
                  style={{
                    backgroundColor: '#f8f8f8',
                    marginBottom: '3px',
                    height: '60px',
                  }}
                >
                  <DropdownField
                    name="kpi_brand_status"
                    options={filteredListKpiBrandsStatus}
                    valueField="uuid_kpi_brand_status"
                    labelField="str_name"
                    margin="0px 10px 0px 0px"
                    onChange={evt =>
                      handleChangeStatus(evt, siteBrands.uuid_kpi_brand, index)
                    }
                    value={siteBrands.uuid_status}
                    disabled={
                      !(
                        privilege.bol_access === 1 &&
                        privilege.arr_zones.some(
                          arr => arr.uuid_zone === siteBrands.uuid_zone,
                        ) === true &&
                        privilege.arr_sites.some(
                          arr => arr.uuid_site === siteBrands.uuid_site,
                        ) === true
                      )
                    }
                    isTable={true}
                  />
                </CardRow>
              ) : null}
              <CardRow
                flexWrap="wrap"
                padding="0px 0px 0px 0px"
                style={{
                  alignItems: 'center',
                  backgroundColor: '#f8f8f8',
                  borderBottomWidth: '1px',
                  borderBottomColor: '#cfcfd1',
                  borderBottomStyle: 'solid',
                }}
              >
                <Label
                  label={siteBrands.status}
                  neutral
                  style={{
                    padding: '12px 5px 12px 5px',
                    color: '#656872',
                    height: '45px',
                  }}
                  size={14}
                />
                {siteBrands.tx_note !== '' && siteBrands.tx_note !== null && (
                  <>
                    <HelpCircle
                      className={styleKpiBrands.helpCircle}
                      data-tip={`${t('common_rejected_note')}: ${
                        siteBrands.tx_note
                      }`}
                    />
                    <ReactTooltip
                      effect="solid"
                      place="top"
                      type="warning"
                      className={styleKpiBrands.tooltip}
                    />
                  </>
                )}
              </CardRow>
              {/** GLOBAL BRANDS **/}
              {arrKpiBrandsGlobal.map((kpi_brands, index) => {
                const globalBrands = siteBrands?.arr_kpi_brands.filter(
                  kpi => Number(kpi.str_type) === 1,
                );
                const indexBrandExists = globalBrands.find(
                  global => global.int_order == index && !global.bol_removed,
                );
                return (
                  <React.Fragment key={`${indexBrandExists}-${index}`}>
                    {kpiBrandRowHTML(indexBrandExists)}
                  </React.Fragment>
                );
              })}

              {/** FOCUS BRANDS **/}
              {arrKpiBrandsFocusStrategic.map((kpi_brands, index) => {
                const focusBrands = siteBrands?.arr_kpi_brands.filter(
                  kpi => Number(kpi.str_type) === 2,
                );
                const indexBrandExists = focusBrands.find(
                  focus => focus.int_order == index && !focus.bol_removed,
                );
                return (
                  <React.Fragment key={`${indexBrandExists}-${index}`}>
                    {kpiBrandRowHTML(indexBrandExists)}
                  </React.Fragment>
                );
              })}

              {/** STRATEGIC BRANDS **/}
              {arrKpiBrandsFocusStrategic.map((kpi_brands, index) => {
                const strategicBrands = siteBrands?.arr_kpi_brands.filter(
                  kpi => Number(kpi.str_type) === 3,
                );
                const indexBrandExists = strategicBrands.find(
                  strategic =>
                    strategic.int_order == index && !strategic.bol_removed,
                );
                return (
                  <React.Fragment key={`${indexBrandExists}-${index}`}>
                    {kpiBrandRowHTML(indexBrandExists)}
                  </React.Fragment>
                );
              })}

              {softDrinksKpis.map((kpi_brands, index) => {
                const softDrinkBrands = siteBrands?.arr_kpi_brands.filter(
                  kpi => Number(kpi.str_type) === 4,
                );
                const indexBrandExists = softDrinkBrands.find(
                  strategic =>
                    strategic.int_order == index && !strategic.bol_removed,
                );
                return (
                  <React.Fragment key={`${indexBrandExists}-${index}`}>
                    {kpiBrandRowHTML(indexBrandExists)}
                  </React.Fragment>
                );
              })}
            </CardColumn>
          ))}
        </CardRow>
      </Card>
    </div>
  );
};

export default DetailsKpiBrands;
