import React, {useState,Fragment} from 'react';

import { CardRow, Label, CardColumn } from "../";
import { ArrowDown, ArrowUp } from "react-feather";
import Styles from './accordion.module.scss';
import Spinner from "../spinner/Spinner";

function Accordion(props) {
  const [isOpen,setIsOpen] = useState(false)
  return (
    <Fragment>
    <CardRow>
      <CardColumn
        padding="15px"
        style={{ position: "relative", cursor: "pointer" }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Label
          // style={Styles.label}
          label={props.label}
          bold
        />
        <span className={Styles.buttonShow}>
          {isOpen ? <ArrowUp /> : <ArrowDown />}
        </span>
      </CardColumn>
    </CardRow>
    {isOpen ? (
      <Fragment>
        {props.loading ? (
          <Spinner />
        ) : props.children}
      </Fragment>
    ) : (
      ""
    )}
  </Fragment>
  );
}

export default Accordion;