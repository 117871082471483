import MainService from './main.service';

export default class MaltVarietiesService {
    mainService = null;

    constructor() {
        this.mainService = new MainService();
        this.path = '/v1/admin';
    }

    listMaltVarieties = () => {
        return this.mainService.get(`${this.path}/list_malt_varieties`);
    }

    deleteMaltVarieties = ( malt_varieties ) => {
        return this.mainService.delete(this.path + '/malt_varieties/' + malt_varieties);
    }

    updateMaltVarieties = ( malt_varieties ) => {
        return this.mainService.post(this.path + '/malt_varieties', malt_varieties);
    }

    listMalt = ( malt_varieties ) => {
        return this.mainService.get(this.path + '/malt_varieties/' + malt_varieties);
    }
}
