import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Label, TextField, Checkbox, LabelSelection, Spinner } from '..';
import { Search, HelpCircle } from 'react-feather';
import dropdownMultiStyle from './dropdownmultifield.module.scss';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

const getClassName = (classA, classB) => {
  return `${classA} ${classB}`;
};
const DropdownMultiField = props => {
  const [show, setShow] = useState(false);
  const [text, setText] = useState('');
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [key, setKey] = useState();
  const { t } = useTranslation();
  const [AllTemp] = useState({
    str_int_name_round: t('select_all'),
    Selected: 'All',
  });

  useEffect(() => {
    const optionsTemp = props.options ? props.options : [];
    const selectedTemp =
      props.value && props.value.length > 0 ? props.value : [];
    const order = typeof props.bolOrder != 'undefined' ? props.bolOrder : true;
    if (order) {
      setOptions(
        optionsTemp.sort((a, b) => {
          if (Number(a[props.labelField]) > Number(b[props.labelField])) {
            return 1;
          }
          if (Number(a[props.labelField]) < Number(b[props.labelField])) {
            return -1;
          }
          return 0;
        }),
      );
    } else {
      setOptions(optionsTemp);
    }

    setSelected(selectedTemp);
    setKey(uuid());

    //IF ONLY ONE OPTION ALREADY SELECT IT
    if (
      props.autoSelect &&
      optionsTemp.length === 1 &&
      selectedTemp.length === 0
    ) {
      setSelected(optionsTemp);
      const res = { target: { value: optionsTemp, name: props.name } };
      props.onChange(res);
    }
  }, [props.value, props.options]);

  const handleChangeDropdownMulti = evt => {
    if (evt && evt.target) {
      let optionsTemp = props.options.filter(option => {
        return (
          option[props.labelField]
            .toString()
            .trim()
            .toLowerCase()
            .indexOf(evt.target.value.toString().trim().toLowerCase()) !== -1
        );
      });
      setText(evt.target.value);
      setOptions(optionsTemp);
    }
  };

  const handleSelect = (evt, option) => {
    let selectedTemp = selected;
    const optionsTemp = options;
    //SELECT
    if (evt.target && evt.target.value) {
      //Select All
      if (option.Selected === 'All') {
        selectedTemp = [AllTemp, ...optionsTemp];
      } else if (
        selectedTemp.length >= props.maxValueSelect &&
        props.maxValueSelect !== undefined
      ) {
        alert(props.messageMaxValue);
        return false;
      } else {
        selectedTemp.push(option);
        if (selectedTemp.length === optionsTemp.length) {
          selectedTemp = [AllTemp, ...selectedTemp];
        }
      }
    }

    //UNSELECT
    if (evt.target && !evt.target.value) {
      if (option.Selected === 'All') {
        selectedTemp = [];
      } else {
        selectedTemp = selected.filter(optionTemp => {
          return (
            optionTemp[props.valueField] !== option[props.valueField] &&
            !optionTemp.Selected
          );
        });
      }
    }
    const res = { target: { value: selectedTemp, name: props.name } };
    props.onChange(res);
  };

  const handleShowList = () => (
    <div>
      <div className={dropdownMultiStyle.dropdownMultiList}>
        <div>
          <Search />
          <TextField
            name="str_text"
            margin="-8px 0px 0px 0px"
            padding="8px 2px 8px 30px"
            value={text}
            onChange={handleChangeDropdownMulti}
          />
        </div>
        {/* Select All */}
        {props.selsctAll && (
          <div className={dropdownMultiStyle.dropdownMultiOption} key={`all`}>
            {handleShowRowComponent({
              str_int_name_round: t('select_all'),
              Selected: 'All',
            })}
          </div>
        )}

        {options.map((option, index) => (
          <div
            className={dropdownMultiStyle.dropdownMultiOption}
            key={`${key}${index}`}
          >
            {handleShowRowComponent(option)}
          </div>
        ))}
      </div>
      <div
        className={dropdownMultiStyle.dropdownMultiOuterList}
        onClick={evt => {
          setShow(false);
        }}
      ></div>
    </div>
  );

  const handleShowRowComponent = option => {
    const bol_selected = selected.some(selectedTemp => {
      return selectedTemp[props.valueField] === option[props.valueField];
    });

    return (
      <Checkbox
        label={option[props.labelField]}
        name="bol_selected"
        margin="8px"
        padding="6px 8px 6px 28px"
        value={bol_selected}
        onChange={evt => {
          handleSelect(evt, option);
        }}
      />
    );
  };

  return (
    <div
      style={{
        margin: props.margin ? props.margin : '10px',

        flex: props.flex ? props.flex : 1,
        justifyContent: 'flex-start',
      }}
      className={dropdownMultiStyle.dropdownMultiContainer}
    >
      <div className={dropdownMultiStyle.labelContainer}>
        <Label
          label={props.label ? props.label : ''}
          required={props.isRequired}
        />
        {props.showHint ? (
          <div className={dropdownMultiStyle.hintContainer}>
            <HelpCircle
              data-tip={props.hintText}
              style={{ color: '#B11F24', top: -2 }}
              size="20"
              data-for={props.hintID}
            />
            <ReactTooltip
              effect="solid"
              place="top"
              type="warning"
              style={{ whiteSpace: 'normal' }}
              id={props.hintID}
            />
          </div>
        ) : null}
        {props.isLoading ? (
          <div className={dropdownMultiStyle.spinner}>
            <Spinner />
          </div>
        ) : (
          <></>
        )}
      </div>
      {selected && selected.length > 0 ? (
        <div
          onClick={evt => {
            setShow(!show);
          }}
          className={getClassName(
            dropdownMultiStyle.dropdownMultiSelectionContainer,
            props.disabled && dropdownMultiStyle.disabledContainer,
          )}
        >
          {selected.map(selectedTemp =>
            selectedTemp.Selected === 'All' ? (
              ''
            ) : (
              <LabelSelection
                key={`${key}${selectedTemp[props.valueField]}`}
                label={selectedTemp[props.labelField]}
                onRemove={
                  !props.disabled
                    ? evt => {
                        handleSelect(evt, selectedTemp);
                      }
                    : null
                }
              />
            ),
          )}
        </div>
      ) : (
        <div
          onClick={evt => {
            setShow(!show);
          }}
          className={getClassName(
            dropdownMultiStyle.dropdownMultiSelectionContainer,
            props.disabled && dropdownMultiStyle.disabledContainer,
          )}
        >
          {props.placeholder ? props.placeholder : t('common_select_option')}
          <span
            style={{ display: props.hideArrow ? 'none' : 'block' }}
            className={dropdownMultiStyle.dropdownArrow}
          ></span>
        </div>
      )}
      {show && !props.disabled ? handleShowList() : null}
    </div>
  );
};

DropdownMultiField.propTypes = {
  options: PropTypes.array.isRequired,
  valueField: PropTypes.string.isRequired,
  labelField: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  hideArrow: PropTypes.bool,
  disabled: PropTypes.bool,
  flex: PropTypes.number,
  maxValueSelect: PropTypes.number,
  messageMaxValue: PropTypes.string,
  isRequired: PropTypes.bool,
  showHint: PropTypes.bool,
  hintText: PropTypes.string,
  isLoading: PropTypes.bool,
};

DropdownMultiField.defaultProps = {
  autoSelect: true,
};

export default DropdownMultiField;
