import React, { useState, useEffect } from 'react';
import editBrandStyle from './editbrand.module.scss';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  Label,
  Card,
  CardRow,
  DropdownField,
  DropdownMultiField,
  Button,
  CardColumn,
  UploadButton,
  CardImage,
  PdfViewerModal,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import BrandService from '../../services/brands/brand.service';
import SampleService from '../../services/samples/sample.service';
import { HelpCircle } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import ModalScoreScale from './ModalScoreScale';

const DetailBrand = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [privilege, setPrivilege] = useState([]);
  const [families, setFamilies] = useState([]);
  const [products, setProducts] = useState([]);
  const [brand, setBrand] = useState({});
  const [checkZone, setCheckZone] = useState(false);
  const [open, setOpen] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isNewBrand, setIsNewBrand] = useState(true);

  // services
  const brandService = new BrandService();
  const sampleService = new SampleService();

  useEffect(() => {
    setIsNewBrand(props.location.state.values.isNew);
    const refreshState = () => {
      setDisabled(
        props.location.state.privilege.bol_access ? false : true,
      );
      const brandTemp = props.location.state.values;
      const privilegeTemp = props.location.state.privilege;

      setCheckZone(false);
      setBrand(brandTemp);
      setPrivilege(privilegeTemp);

      brandService.listFamilies().then(
        res => {
          if (res.type === 'success' && res.results) {
            setFamilies(res.results);
          } else {
            window.alert(res.message);
          }
        },
        error => {
          //console.log(error);
        },
      );

      sampleService.listProducts().then(
        res => {
          if (res.type === 'success' && res.results) {
            setProducts(res.results);
          } else {
            window.alert(res.message);
          }
        },
        error => {
          //console.log(error);
        },
      );

      if (brandTemp.arr_zones) {
      } else {
        setCheckZone(true);
      }
    };

    refreshState();
  }, []);

  const handleChange = evt => {
    //console.log('evt--------------------------------', evt);
    if (evt.target !== undefined) {
      //console.log('ENTROU1111111');
      const value = evt.target.value;
      setBrand({
        ...brand,
        [evt.target.name]: value,
      });
    }
  };

  const modalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleClosePdf = () => {
    setOpen('');
  };

  const handlePDF = lng => {
    setOpen(lng);
  };

  return (
    <div className={editBrandStyle.pageContainer}>
      <Label
        label={
          props.location.state.privilege.bol_access
            ? t('brand_edit_brand')
            : t('brand_list_brands')
        }
        bold
        neutral
      />

      <ModalScoreScale
        isOpen={modalOpen}
        onCloseModal={modalToggle}
        brand={brand}
        disabled={disabled}
      />

      <Card bordered>
        <div className={editBrandStyle.pageContainerHeader}>
          <CardRow flexWrap="wrap" padding="0px 0px 10px 0px">
            <CardColumn padding="5px" flex={1}>
              <CardRow flexWrap="wrap">
                {brand.str_img_brand ? (
                  brand.str_img_brand.split('.').pop() ===
                    'pdf' ? (
                    <PdfViewerModal
                      label={`${t(
                        'common_brand',
                      )} Logo PDF`}
                      data={brand.str_img_brand}
                      onClick={() =>
                        handlePDF(brand.str_img_brand)
                      }
                      open={open === brand.str_img_brand}
                      closeForm={handleClosePdf}
                      loading={false}
                      downloadFileName={
                        brand.str_img_brand
                      }
                      name={brand.str_img_brand}
                    />
                  ) : (
                    <CardImage
                      name="str_img_brand"
                      src={brand.str_img_brand}
                      margin="10px 0px"
                      size={150}
                    />
                  )
                ) : (
                  <div
                    className={editBrandStyle.uploadButton}
                  >
                    <UploadButton
                      name="str_img_brand"
                      label={t('brand_upload_brand_logo')}
                      onUpload={handleChange}
                      margin="0px 5px 0px 0px"
                      allowExtension={[
                        '.png',
                        '.jpg',
                        '.jpeg',
                      ]}
                      maxSize={5242880}
                      disabled={disabled}
                    />
                    <HelpCircle
                      data-tip={t(
                        'brand_upload_help_message',
                      )}
                      className={
                        editBrandStyle.helpCircle
                      }
                    />
                    <ReactTooltip
                      effect="solid"
                      place="top"
                      type="warning"
                      className={editBrandStyle.tooltip}
                    />
                  </div>
                )}
              </CardRow>
            </CardColumn>
            <CardColumn padding="5px" flex={3}>
              <CardRow flexWrap="wrap">
                <DropdownField
                  name="uuid_brand_family"
                  options={families}
                  valueField="uuid_brand_family"
                  labelField="str_name"
                  label={t('common_family')}
                  margin="10px 0px 0px 0px"
                  value={brand.uuid_brand_family}
                  onChange={handleChange}
                  disabled={disabled}
                />
              </CardRow>
              <CardRow>
                <DropdownMultiField
                  name="arr_zones"
                  options={
                    privilege.arr_zones
                      ? privilege.arr_zones
                      : []
                  }
                  valueField="uuid_zone"
                  labelField="str_name"
                  label={t('common_zone')}
                  margin="10px 10px 0px 0px"
                  value={brand.arr_zones}
                  disabled={!checkZone || disabled}
                  onChange={value => {
                    brand.arr_countries = null;
                    brand.arr_sites = null;
                    handleChange(value);
                  }}
                />
              </CardRow>
              <CardRow>
                <DropdownField
                  name="uuid_product_type"
                  options={products}
                  valueField="uuid_product_type"
                  labelField="str_name_product"
                  label={t('common_product_type')}
                  margin="10px 10px 0px 0px"
                  value={brand.uuid_product_type}
                  onChange={handleChange}
                  disabled={disabled}
                />
              </CardRow>
            </CardColumn>
          </CardRow>
        </div>

        <CardRow flexWrap="wrap">
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_en"
                label={`${t('common_brand')} (English)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_en}
                onChange={handleChange}
                disabled={disabled}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_en ? (
                brand?.str_img_profile_en.split('.').pop() ===
                  'pdf' ? (
                  <div
                    style={{
                      width: '220px',
                      marginTop: '10px',
                    }}
                  >
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_en}
                      onClick={() =>
                        handlePDF(
                          brand.str_img_profile_en,
                        )
                      }
                      closeForm={handleClosePdf}
                      open={
                        open ===
                        brand.str_img_profile_en
                      }
                      loading={false}
                      downloadFileName={
                        brand.str_img_profile_en
                      }
                      name="str_img_profile_en"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_en"
                    src={brand.str_img_profile_en}
                    margin="10px 0px"
                  />
                )
              ) : null}
            </CardRow>
          </CardColumn>
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_pt"
                label={`${t('common_brand')} (Portuguese)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_pt}
                onChange={handleChange}
                disabled={disabled}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_pt ? (
                brand?.str_img_profile_pt.split('.').pop() ===
                  'pdf' ? (
                  <PdfViewerModal
                    label={`${t('common_brand')} PDF`}
                    data={brand.str_img_profile_pt}
                    onClick={() =>
                      handlePDF(brand.str_img_profile_pt)
                    }
                    closeForm={handleClosePdf}
                    open={open === brand.str_img_profile_pt}
                    loading={false}
                    downloadFileName={
                      brand.str_img_profile_pt
                    }
                    name="str_img_profile_pt"
                  />
                ) : (
                  <CardImage
                    name="str_img_profile_pt"
                    src={brand.str_img_profile_pt}
                    margin="10px 0px"
                  />
                )
              ) : null}
            </CardRow>
          </CardColumn>
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_es"
                label={`${t('common_brand')} (Spanish)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_es}
                onChange={handleChange}
                disabled={disabled}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_es ? (
                brand?.str_img_profile_es.split('.').pop() ===
                  'pdf' ? (
                  <div
                    style={{
                      width: '220px',
                      marginTop: '10px',
                    }}
                  >
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_es}
                      onClick={() =>
                        handlePDF(
                          brand.str_img_profile_es,
                        )
                      }
                      closeForm={handleClosePdf}
                      open={
                        open ===
                        brand.str_img_profile_es
                      }
                      loading={false}
                      downloadFileName={
                        brand.str_img_profile_es
                      }
                      name="str_img_profile_es"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_es"
                    src={brand.str_img_profile_es}
                    margin="10px 0px"
                  />
                )
              ) : null}
            </CardRow>
          </CardColumn>
        </CardRow>

        <CardRow flexWrap="wrap">
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_de"
                label={`${t('common_brand')} (German)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_de}
                disabled={disabled}
                onChange={handleChange}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_de ? (
                brand?.str_img_profile_de.split('.').pop() ===
                  'pdf' ? (
                  <div
                    style={{
                      width: '220px',
                      marginTop: '10px',
                    }}
                  >
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_de}
                      onClick={() =>
                        handlePDF(
                          brand.str_img_profile_de,
                        )
                      }
                      closeForm={handleClosePdf}
                      open={
                        open ===
                        brand.str_img_profile_de
                      }
                      loading={false}
                      downloadFileName={
                        brand.str_img_profile_de
                      }
                      name="str_img_profile_de"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_de"
                    src={brand.str_img_profile_de}
                    margin="10px 0px"
                  />
                )
              ) : null}
            </CardRow>
          </CardColumn>
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_fr"
                label={`${t('common_brand')} (French)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_fr}
                onChange={handleChange}
                disabled={disabled}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_fr ? (
                brand?.str_img_profile_fr.split('.').pop() ===
                  'pdf' ? (
                  <div
                    style={{
                      width: '220px',
                      marginTop: '10px',
                    }}
                  >
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_fr}
                      onClick={() =>
                        handlePDF(
                          brand.str_img_profile_fr,
                        )
                      }
                      closeForm={handleClosePdf}
                      open={
                        open ===
                        brand.str_img_profile_fr
                      }
                      loading={false}
                      downloadFileName={
                        brand.str_img_profile_fr
                      }
                      name="str_img_profile_fr"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_fr"
                    src={brand.str_img_profile_fr}
                    margin="10px 0px"
                  />
                )
              ) : null}
            </CardRow>
          </CardColumn>
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_it"
                label={`${t('common_brand')} (Italian)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_it}
                onChange={handleChange}
                disabled={disabled}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_it ? (
                brand?.str_img_profile_it.split('.').pop() ===
                  'pdf' ? (
                  <div
                    style={{
                      width: '220px',
                      marginTop: '10px',
                    }}
                  >
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_it}
                      onClick={() =>
                        handlePDF(
                          brand.str_img_profile_it,
                        )
                      }
                      closeForm={handleClosePdf}
                      open={
                        open ===
                        brand.str_img_profile_it
                      }
                      loading={false}
                      downloadFileName={
                        brand.str_img_profile_it
                      }
                      name="str_img_profile_it"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_it"
                    src={brand.str_img_profile_it}
                    margin="10px 0px"
                  />
                )
              ) : null}
            </CardRow>
          </CardColumn>
        </CardRow>

        <CardRow flexWrap="wrap">
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_nl"
                label={`${t('common_brand')} (Dutch)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_nl}
                onChange={handleChange}
                disabled={disabled}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_nl ? (
                brand?.str_img_profile_nl.split('.').pop() ===
                  'pdf' ? (
                  <div
                    style={{
                      width: '220px',
                      marginTop: '10px',
                    }}
                  >
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_nl}
                      onClick={() =>
                        handlePDF(
                          brand.str_img_profile_nl,
                        )
                      }
                      closeForm={handleClosePdf}
                      open={
                        open ===
                        brand.str_img_profile_nl
                      }
                      loading={false}
                      downloadFileName={
                        brand.str_img_profile_nl
                      }
                      name="str_img_profile_nl"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_nl"
                    src={brand.str_img_profile_nl}
                    margin="10px 0px"
                  />
                )
              ) : null}
            </CardRow>
          </CardColumn>
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_ru"
                label={`${t('common_brand')} (Russian)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_ru}
                onChange={handleChange}
                disabled={disabled}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_ru ? (
                brand?.str_img_profile_ru.split('.').pop() ===
                  'pdf' ? (
                  <div
                    style={{
                      width: '220px',
                      marginTop: '10px',
                    }}
                  >
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_ru}
                      onClick={() =>
                        handlePDF(
                          brand.str_img_profile_ru,
                        )
                      }
                      closeForm={handleClosePdf}
                      open={
                        open ===
                        brand.str_img_profile_ru
                      }
                      loading={false}
                      downloadFileName={
                        brand.str_img_profile_ru
                      }
                      name="str_img_profile_ru"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_ru"
                    src={brand.str_img_profile_ru}
                    margin="10px 0px"
                  />
                )
              ) : null}
            </CardRow>
          </CardColumn>
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_uk"
                label={`${t('common_brand')} (Ukranian)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_uk}
                disabled={disabled}
                onChange={handleChange}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_uk ? (
                brand?.str_img_profile_uk.split('.').pop() ===
                  'pdf' ? (
                  <div
                    style={{
                      width: '220px',
                      marginTop: '10px',
                    }}
                  >
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_uk}
                      onClick={() =>
                        handlePDF(
                          brand.str_img_profile_uk,
                        )
                      }
                      closeForm={handleClosePdf}
                      open={
                        open ===
                        brand.str_img_profile_uk
                      }
                      loading={false}
                      downloadFileName={
                        brand.str_img_profile_uk
                      }
                      name="str_img_profile_uk"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_uk"
                    src={brand.str_img_profile_uk}
                    margin="10px 0px"
                  />
                )
              ) : null}
            </CardRow>
          </CardColumn>
        </CardRow>
        <CardRow>
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_zh"
                label={`${t('common_brand')} (Chinese)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_zh}
                disabled={disabled}
                onChange={handleChange}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_zh ? (
                brand?.str_img_profile_zh.split('.').pop() ===
                  'pdf' ? (
                  <div className={editBrandStyle.zhModal}>
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_zh}
                      onClick={() =>
                        handlePDF(
                          brand.str_img_profile_zh,
                        )
                      }
                      closeForm={handleClosePdf}
                      open={
                        open ===
                        brand.str_img_profile_zh
                      }
                      loading={false}
                      downloadFileName={
                        brand.str_img_profile_zh
                      }
                      name="str_img_profile_zh"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_zh"
                    src={brand.str_img_profile_zh}
                    margin="10px 0px"
                  />
                )
              ) : null}
            </CardRow>
          </CardColumn>
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_kr"
                label={`${t('common_brand')} (Korean)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_kr}
                disabled={disabled}
                onChange={handleChange}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_kr ? (
                brand?.str_img_profile_kr.split('.').pop() ===
                  'pdf' ? (
                  <div className={editBrandStyle.zhModal}>
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_kr}
                      onClick={() =>
                        handlePDF(
                          brand.str_img_profile_kr,
                        )
                      }
                      closeForm={handleClosePdf}
                      open={
                        open ===
                        brand.str_img_profile_kr
                      }
                      loading={false}
                      downloadFileName={
                        brand.str_img_profile_kr
                      }
                      name="str_img_profile_kr"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_kr"
                    src={brand.str_img_profile_kr}
                    margin="10px 0px"
                  />
                )
              ) : null}
            </CardRow>
          </CardColumn>
          <CardColumn></CardColumn>
        </CardRow>
        <CardRow justifyContent="flex-end" flexWrap="wrap">
          <Button
            label={t('common_cancel')}
            onClick={() => {
              history.goBack();
            }}
            neutral
            margin="35px 10px 0px 0px"
            zIndex={0}
          />
        </CardRow>
      </Card>
    </div>
  );
};

export default DetailBrand;

