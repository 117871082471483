import ListBreathalyzers from './listBreathalyzers/ListBreathalyzers';

export default {
  routeModule: {
    path: '/list_breathalyzers',
    component: ListBreathalyzers,
    key: 'list_breathalyzer',
    routeComponents: [],
  },
};
